import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";


const hrefsArr = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const PageFRCKDN = ({url, header, children, style = {}}) => {
    return <>
        <div className={s.page}>
            <div className={s.content}>
                <div className={s.newHeader}>
                    <h2>
                        Федеральный ресурсный центр комиссий по делам несовершеннолетних и защите их прав
                    </h2>
                </div>


                <p style={{color: "#212529", lineHeight: "24px"}}>
                    <p>Создание эффективной системы профилактики безнадзорности несовершеннолетних, социального
                        сиротства и семейного неблагополучия – один из важнейших критериев успешного
                        социально-экономического развития общества. Достичь этого возможно только объединив усилия всех
                        субъектов системы профилактики безнадзорности и правонарушений несовершеннолетних, направленных
                        на решение организационных, управленческих, нормативно-правовых и социально-педагогических
                        проблем.
                        <br>
                        </br>
                        <p>
                            С целью выстраивания работы, направленной на повышение эффективности деятельности комиссий
                            по делам несовершеннолетних и защите их прав, а также координации межведомственного
                            взаимодействия органов и учреждений системы профилактики безнадзорности и правонарушений
                            несовершеннолетних в сфере защиты детства создан Федеральный ресурсный центр
                            организационно-методического сопровождения деятельности комиссий по делам несовершеннолетних
                            и защите их прав (далее – ФРЦ КДН и ЗП).<br>
                        </br></p>
                        ФРЦ КДН и ЗП создан приказом ФГБУ «Центр защиты прав и интересов детей от 30 ноября 2021 № 62-1
                        и функционирует с 1 февраля 2022 года.<br>
                        </br><p>
                            Сегодня в Российской Федерации сформированы 2 836 региональных и территориальных
                            (муниципальных) комиссий по делам несовершеннолетних и защите их прав, деятельность которых
                            обеспечивают 5 542 специалистов. За 2020 год профилактическая работа организована различными
                            органами и учреждениями системы профилактики в отношении 446 873 несовершеннолетних, 122 947
                            подростка по итогам работы сняты с ведомственных учетов по причине улучшения ситуации.<br>
                        </br></p>
                        Комиссии по делам несовершеннолетних и защите их прав являются коллегиальными органами системы
                        профилактики, обеспечивающими координацию деятельности органов и учреждений системы профилактики
                        безнадзорности и правонарушений несовершеннолетних по предупреждению безнадзорности,
                        беспризорности, правонарушений и антиобщественных действий несовершеннолетних, выявлению и
                        устранению причин и условий, способствующих этому, обеспечению защиты прав и законных интересов
                        несовершеннолетних, социально-педагогической реабилитации несовершеннолетних, находящихся в
                        социально опасном положении, выявлению и пресечению случаев вовлечения несовершеннолетних в
                        совершение преступлений, других противоправных, антиобщественных действий, а также случаев
                        склонения их к суицидальным действиям.<br>
                        </br><p>
                            Роль комиссий в системе дружественного к ребенку правосудия – это организация мер по защите
                            и восстановлению прав несовершеннолетних и профилактике преступлений; мер, позволяющих
                            ребенку избежать соприкосновения с судебной системой и системой исполнения наказаний
                            способом проведения с подростком индивидуальной профилактической работы, которая
                            восстанавливает нарушенные права несовершеннолетнего правонарушителя, а иногда и
                            потерпевшего, обеспечивает предупреждение совершения повторных правонарушений, и, тем самым,
                            обеспечивает безопасность общества.<br>
                        </br></p>
                        14 января 2022 года исполнилось 104 года со дня создания комиссий по делам несовершеннолетних и
                        защите их прав – российского специализированного государственного органа по вопросам
                        профилактики безнадзорности и правонарушений несовершеннолетних, защиты детей от негативных
                        влияний социальной среды.<br>
                        </br><p>
                            Работа КДНиЗП – это ежедневное соприкосновение с судьбами людей, детскими проблемами, это
                            огромная ответственность за судьбу каждого ребенка. Неравнодушие к детям, умение создать
                            обстановку доверительности, доброта и терпение помогают комиссиям в решении проблем семьи, а
                            значит в оказании помощи ребенк
                        </p>
                    </p>
                </p>
            </div>
            <div>
                <p></p>
                <Hrefs/>
            </div>
        </div>
    </>


}

export const Hrefs = ({hrefs}) => {
    return <div className={s.hrefs}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, href, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default PageFRCKDN