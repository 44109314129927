import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLineSUVU} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"


const hrefsArr = [
    {text: "Новости", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {text: "Нормативное регулирование деятельности СУВУ", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {
        text: "Информационно-методические материалы",
        to: "/spec-suvu/informatsionno-analiticheskie-materialy/"
    },
    {text: "Всероссийские мероприятия", to: "/spec-suvu/meropriyatiya/"},
    {text: "Люди. События. Факты.", to: "/spec-suvu/ludi-fakti-sobitiya/"},
]
const FilesSuvuPeople = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/suvu").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>

        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Люди. События. Факты.
                </h2>
                <FileLeftLineSUVU text={respData[14]?.description} refers={respData[14]?.file} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesSuvuPeople