import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News011024_1.jpeg"
import img1 from "../../assets/News/News011024_2.png"

const News011024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в сентябре 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Во II полугодии 2024 года сотрудниками отдела медиации Центра защиты прав и интересов детей продолжена
            работа по сопровождению работы служб медиации специальных учебно-воспитательных учреждений, подведомственных
            Минпросвещения России.
            <p></p>
            Мероприятия прошли 26 – 27 сентября с Щекинским СУВУ, Астраханским СУВУ, Ишимбайским СУВУ, Абаканским СУВУ,
            Неманским СУВУ в дистанционном формате.
            <p></p>

            26 сентября состоялась консультация для специалистов служб медиации, на которой обсуждались проведенные
            мероприятия и достижения служб, а также их текущее состояние.
            <p></p>
            <p></p>

            27 сентября на интерактивных занятиях для обучающихся были проведены деловые игры, направленные на
            формирование навыков ведения переговоров в ситуации неопределенности. Занятия были выстроены таким образом,
            чтобы создать условия для общения обучающихся разных полов.
            <p></p>

            Обучающиеся и сотрудники положительно оценили интерактивный формат занятий, дружескую и уважительную
            атмосферу встреч.
            <p></p>
            Мероприятия проводили сотрудники отдела медиации Центра защиты прав и интересов детей: аналитик – Быкова
            Марина Викторовна, аналитик – Тюльканов Сергей Леонидович и аналитик II категории – Жодзишская
            Лидия Николаевна.
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <ToNews/>
    </div>
}


export default News011024;