import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News010522.jpg"

const News010522 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Поздравление Министра просвещения Российской Федерации Сергея Кравцова с 1 Мая – Праздником Весны и Труда
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Дорогие друзья!</p>
            <p>Уважение к труду – то качество, которое важно воспитывать у ребят с самого раннего возраста. Это наша
                общая ответственность: родителей, учителей, детских движений и объединений. Поддерживая друг друга в
                этой работе, мы расширяем возможности для развития подрастающего поколения, создаем условия для ранней
                профориентации, прививаем любовь к усердию и целеустремленности в делах.</p>
            <p>Важные изменения происходят на уровне среднего профессионального образования. В рамках масштабной
                программы «Профессионалитет», запущенной с этого года, появятся молодые специалисты, которые обладают
                всеми необходимыми трудовыми навыками и личностными качествами, понимают, что такое востребованный и
                квалифицированный труд. Важно воспринимать происходящие изменения как шанс заявить о себе,
                продемонстрировать свои умения, реализуя ключевые для страны проекты.</p>
            <p>От всей души поздравляю с праздником и желаю здоровья, побольше счастливых моментов в вашей жизни и
                успехов во всех начинаниях!</p>
            <p><strong>Министр просвещения Сергей Кравцов</strong></p>
        </div>
        <ToNews/>
    </div>
}


export default News010522;