import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SecondSSSPM.png"

const SecondSSSPM = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            II Всероссийское совещание школьных служб примирения и медиации
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>15 ноября 2022 г. состоялось II Всероссийское совещание школьных служб примирения и медиации. В
                мероприятии приняли участие члены Правительственной комиссии по делам несовершеннолетних и защите их
                прав, представители аппарата Уполномоченного по правам ребенка в Российской Федерации, федеральных
                органов исполнительной власти, общественных, научных организаций и экспертных сообществ, представителей
                органов исполнительной власти субъектов Российской
                Федерации, осуществляющих государственное управление в сфере образования, образовательных организаций,
                подведомственных
                Минпросвещения России.</p>
            <p>Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей».</p>
            <p>
                Форматы проведения:
            </p>
            <p>
                - очный и дистанционный (пленарные доклады, работа круглых столов и секции «Марафон практик»);
            </p>
            <p>
                - заочный (публикация материалов в электронном сборнике).
            </p>
            <p>
                Всероссийское совещание проводилось Минпросвещения России во исполнение пункта 8 Межведомственного плана
                комплексных мероприятий по реализации Концепции развития сети служб медиации в целях реализации
                восстановительного правосудия в отношении детей, в том числе совершивших общественно опасные деяния, но
                не достигших возраста, с которого наступает уголовная ответственность в Российской Федерации, до 2025
                года, утвержденного протоколом заседания Правительственной комиссии по делам несовершеннолетних и защите
                их прав от 25 сентября 2019 г. № 23.
            </p>
        </div>
    </div>
}


export default SecondSSSPM;