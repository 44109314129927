import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/StoryNamedWE.jpg"

const StoryNamedWE = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийский конкурс творческих работ для обучающихся СУВУ «История по имени МЫ»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>С 26 по 29 сентября 2022 года сотрудники отдела медиации ФГБУ «Центр защиты прав и интересов детей»
                приняли участие в качестве экспертов и членов жюри во Всероссийском конкурсе творческих работ для
                обучающихся специальных учебно-воспитательных учреждений с международным участием «История по имени
                МЫ»!</p>
            <p>Конкурс проводился в дистанционном формате в рамках популяризации медиативно-восстановительных
                технологий, ФГБПОУ «Рефтинское специальное учебно-воспитательное учреждение закрытого типа».</p>
            <p>Быкова Марина Викторовна, начальник отдела медиации, Козлов Анатолий Андреевич, аналитик II категории
                отдела медиации и Коновалов Антон Юрьевич, аналитик отдела медиации совместно с другими экспертами
                конкурса оценивали выступления и работы участников в номинациях:</p>
            <p>– лучший видеоролик «Медиация глазами детей» (групповая, заочная);</p>
            <p>– лучшая графическая реклама «Тебе поможет медиатор!» (групповая, заочная);</p>
            <p>– лучший рисунок «Мы решили помириться!» (для возрастной категории от 8 до 10 лет);</p>
            <p>– сочинение на тему «Письмо другу, с которым в ссоре» (для возрастной категории от 8 до 10 лет);</p>
            <p>– «Мастер красноречия» – эссе-приветствие «Я – медиатор!» (индивидуальная, онлайн – формат);</p>
            <p>– лучший разбор кейса с конфликтной ситуацией «Лабиринт» (групповая, онлайн – формат).</p>
            <p>В конкурсе принял участие 161 обучающийся и педагог специальных учебно-воспитательных учреждений
                федерального, регионального и муниципального подчинения.</p>
            <p>Сотрудники отдела медиации выступили в методической мастерской конкурса «От Идеи до Реальности»:</p>
            <p>Открывал Методическую мастерскую Козлов Анатолий Андреевич, с семинаром «Говори смело!» для специалистов
                служб медиации СУВУ и педагогов учреждений. На семинаре были рассмотрены медиативные техники и
                восстановительные технологии, как часть коммуникативной компетентности подростков.</p>
            <p>Коновалов Антон Юрьевич поделился опытом внедрения Восстановительного правосудия в специальных
                учебно-воспитательных учреждениях.</p>
            <p>Данный конкурс показал важность деятельности служб медиации в специальных учебно-воспитательных
                учреждениях. Конкурсанты показали навыки владения медиативными технологиями и получили заслеженные
                награды.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default StoryNamedWE;