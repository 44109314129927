import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/Mediacia/FeaturesOfTeenagers.jpeg"
import YouTube from "react-youtube";

const FeaturesOfTeenagers = ({children, header}) => {
    const [newsData, setNews] = useState([])


    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Особенности подросткового возраста с точки зрения психологии, социологии и этологии:
            подростковая девиантность
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.mediacia}>
            <p>
                10 апреля 2020 года состоялся вебинар на тему: «Особенности подросткового возраста с точки зрения
                психологии, социологии и этологии: подростковая девиантность» для родителей, педагогов, специалистов.
            </p>
            <p>
                Ведущий вебинара: аналитик Центра, психолог, кандидат социологических наук Дубровский Роман Геннадьевич.
                На вебинаре речь шла о природе «конфликта поколений», ее этологическом и социологическом объяснении; об
                инстинктивных программах в поведении подростков; о позитивной девиантности, как ресурсе профилактики
                негативных явлений среди подростков.
            </p>
            <p>
                В вебинаре приняли участие 1196 родителей, педагогов, специалистов из 73 регионов России.
            </p>
        </div>
        <div style={{margin: "0 auto"}}>
            <YouTube videoId="HhucqBtPJeg"/>
        </div>
    </div>
}


export default FeaturesOfTeenagers