import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import img from "../../assets/Mediacia/ConsultationDeviationWebinar.png"

const ConsultationDeviationWebinar = ({children, header}) => {
    const [newsData, setNews] = useState([])


    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Консультирование по вопросам детско-родительских отношений, девиантного поведения
            несовершеннолетних состоялось 29 апреля 2020 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.mediacia}>
            <p>
                <strong>29 апреля 2020 года</strong> в рамках работы Московского международного салона образования –
                2020 состоялся
                вебинар, посвященный консультированию по вопросам детско-родительских отношений, девиантному поведению
                несовершеннолетних. Ведущим вебинара, <strong>Дубровским Романом Геннадьевичем</strong>, кандидатом социологических наук,
                психологом, аналитиком ФГБУ «Центр защиты прав и интересов детей» в ходе встречи были раскрыты причины
                противоречий между родителями и подростками, рассмотрены характерные родительские ошибки, рассказаны
                варианты помощи родителям и подросткам, сформулированы правила выбора психолога, представлена стратегия
                восстановления здоровых отношений в семье. <br/>
                Вебинар прослушали <strong>1218</strong> слушателей из <strong>66</strong> регионов.

            </p>
            <p>
                Ссылка на вебинар: <a href="https://www.youtube.com/watch?v=4sALjyZzVds">https://www.youtube.com/watch?v=4sALjyZzVds</a>
            </p>
        </div>
    </div>
}


export default ConsultationDeviationWebinar