import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News121224.PNG"

const News121224 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги Всероссийского конкурса социальной рекламы «Стиль
            жизни – Здоровье! 2024»!
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Завершился федеральный этап Всероссийского конкурса социальной рекламы
            в области формирования культуры здорового образа жизни среди обучающихся
            «Стиль жизни – Здоровье! 2024».
            <p></p>
            Задачи конкурса заключаются в привлечении внимания обучающихся к
            социально значимым проблемам общества; пропаганде культуры безопасного и
            здорового образа жизни; просвещения детей и подростков в вопросах ведения
            здорового и безопасного образа жизни; создания условий для открытого,
            доверительного общения, возможностей самопроявления обучающихся в процессе
            творческой работы над проектом и т.д.
            <p></p>
            В конкурсе принимали участие обучающиеся образовательных организаций
            субъектов Российской Федерации двух возрастных групп: 8–12 лет и 13–18 лет.
            <p></p>
            Участники представляли на конкурс работы в двух номинациях: «Социальный
            видеоролик по пропаганде здорового и безопасного образа жизни, направленный на
            профилактику зависимого поведения обучающихся» и «Наглядный раздаточный
            материал по пропаганде здорового и безопасного образа жизни, направленный на
            профилактику зависимого поведения обучающихся».
            <p></p>
            Конкурс проводился в два этапа:
            <ul>
                <li>региональный этап – по 31 октября 2024 года;</li>
                <li>федеральный этап – с 1 по 29 ноября 2024 года.</li>
            </ul>
            В региональном этапе Всероссийского конкурса приняли участие 11084
            обучающихся образовательных организаций из 71 субъекта Российской Федерации.
            Общее число конкурсных работ, отправленных на региональный этап Конкурса,
            составило 5444.
            <p></p>
            На федеральном этапе конкурса рассмотрено 242 работы, подготовленные 683
            участниками. В результате подведения итогов федерального этапа конкурса членами
            Жюри было определено 22 работы, занявшие призовые места.
            <p></p>
            Среди лауреатов и дипломантов представлены обучающиеся образовательных
            организаций из 19 субъектов Российской Федерации: республики Коми, Крым,
            Мордовия; Краснодарский, Пермский, Ставропольский края; Брянская,
            Воронежская, Кировская, Ростовская, Самарская, Сахалинская, Свердловская,
            Смоленская, Томская, Ярославская области; г. Москва, г. Санкт-Петербург; Ямало-
            Ненецкий автономный округ.
            <p></p>
            Более подробно с результатами конкурса можно ознакомиться на <a style={{fontWeight: "bold"}} target="_blank"
                                                                            href="https://2024.social.edu-contests.ru/">официальном
            сайте Всероссийского конкурса социальной рекламы «Стиль жизни – Здоровье! 2024»</a>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News121224;