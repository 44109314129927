import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SupportOfMediationServicesMarch2022.jpg"
import img2 from "../../assets/News/SupportOfMediationServicesMarch20221.jpg"
import img3 from "../../assets/News/SupportOfMediationServicesMarch20222.jpg"
import img4 from "../../assets/News/SupportOfMediationServicesMarch20223.jpg"
import img5 from "../../assets/News/SupportOfMediationServicesMarch20224.jpg"
import img6 from "../../assets/News/SupportOfMediationServicesMarch20225.jpg"
import img7 from "../../assets/News/SupportOfMediationServicesMarch20226.jpg"

const SupportOfMediationServicesMarch2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ в марте
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                В марте отдел медиации ФГБУ «Центр защиты прав и интересов детей» провел первые мероприятия по
                сопровождению служб медиации СУВУ, они включали групповые консультации специалистов служб и
                интерактивные занятия с обучающимися.
            </p>
            <p>
                Мероприятия прошли в дистанционном формате 10 – 11 марта (Известковское СУВУ, Омское СУВУ,
                Санкт-Петербургское СУВУ), 17 – 18 марта (Калтанское СУВУ, Майкопское СУВУ), 31 марта – 1 апреля
                (Себежское СУВУ, Куртамышское СУВУ, Орловское СУВУ).
            </p>
            <p>
                В рамках консультаций с сотрудниками обсуждалось текущее состояние службы и планы на 2022 год. В
                практической части встречи анализировалась конфликтная ситуация между учеником и учителем,
                рассматривались варианты урегулирования и профилактики подобных случаев.
            </p>
            <p>
                На интерактивных занятиях обучающиеся из разных СУВУ обсуждали причины конфликта и его составные части.
                Участники встречи разобрали ряд тематических кейсов, предлагали свои варианты их решения и оценивали
                варианты, озвученные сверстниками. Занятия прошли в доверительной и комфортной атмосфере.
            </p>
            <p>
                Продолжение мероприятий запланировано на апрель-май 2022 года.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <div>
            <img src={img7}/>
        </div>
        <ToNews/>
    </div>
}


export default SupportOfMediationServicesMarch2022;