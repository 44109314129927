import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {actions} from "../../Redux/Blank.js"
import { useNavigate } from "react-router-dom";
import s from "../common/FilePage/PageFiles.module.css"
import styl from "./Default.module.css"


const BlankPage = ({children}) => {
    const BlankText = useSelector((state) => state.BlankPage.text)
    const BlankImg = useSelector((state) => state.BlankPage.img)
    
    return<div className={styl.blank}>
        <h1>
        {BlankText}
        </h1>
        <div>
            <img src={BlankImg}/>
        </div>
    </div>
}
export const BlankComponent = ({ico, text1, header, img, text2}) => {
    const dispatch = useDispatch()

    const setBlankPageData = (data) => dispatch(actions.addCommentsActioncreator(data))
    const history = useNavigate()
    return<div onClick={() =>{ 
        setBlankPageData({text:text1, img:img, children:header})
        history("/blank")
    }}className={s.fileleftlineRed}>
    <img src={ico}/>
    <div>
        {header}
    </div>
</div>
}

export default BlankPage