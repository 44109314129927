import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News280324_1.jpg"
import img2 from "../../assets/News/News280324_2.jpg"
import img3 from "../../assets/News/News280324_3.jpg"
import img4 from "../../assets/News/News280324_4.jpg"
import img5 from "../../assets/News/News280324_5.JPG"
import img6 from "../../assets/News/News280324_6.jpg"
import img7 from "../../assets/News/News280324_7.jpg"

const News280324 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Семинар о медиативно-восстановительных технологиях в системе профилактики безнадзорности и правонарушений
            несовершеннолетних Тамбовской области 21-22 марта 2024 г.
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            21 и 22 марта 2024 г. в городе Тамбове прошёл семинар по совершенствованию межведомственного взаимодействия
            в системе воспитания и профилактики безнадзорности и правонарушений несовершеннолетних в рамках проведения
            медиативно-восстановительных программ.
            <p></p>
            В нём приняли участие руководители и специалисты образовательных организаций и учреждений социального
            обслуживания, комиссий по делам несовершеннолетних и защите их прав и школьных служб медиации и примирения
            Тамбовской области. К обсуждению актуальных вопросов профилактики безнадзорности и правонарушений также
            подключились старшеклассники.
            <p></p>
            Начальник отдела медиации ФГБУ «Центр защиты прав и интересов детей» Анатолий Андреевич Козлов провёл
            семинар-практикум «Медиативный подход в системе профилактики безнадзорности и правонарушений
            несовершеннолетних».
            <p></p>
            «Конфликты среди детей – это нормально и даже полезно для их социализации. Важно учить подростков
            конструктивно их решать, чтобы у ребёнка не происходило ситуаций, когда для достижения своих интересов он
            применяет силу. Потому что агрессивное решение ситуации зачастую может приводить к конфликту с законом. В
            качестве понятных и действенных инструментов отстаивания своей позиции и достижения желаемого результата мы
            обучаем детей и подростков медиативно-восстановительным технологиям», рассказывает А.А. Козлов.
            <p></p>
            Ирина Александровна Бобровская, начальник отдела образовательных программ федерального центра ФГБНУ
            «Институт изучения детства, семьи и воспитания» провела проектную сессию «Разработка эффективной базовой
            модели межведомственного взаимодействия в сфере профилактики правонарушений подростков». Эксперт Института
            Рустем Рамзиевич Максудов выступил ведущим семинара-практикума «Восстановительный подход в работе с
            конфликтами и девиантным поведением».
            <p></p>
            В результате совместной работы была разработана эффективная базовая модель межведомственного взаимодействия
            в сфере профилактики правонарушений среди несовершеннолетних. Участники практикума освоили техники медиации
            и провели медиативную беседу и восстановительную программу.
            <p></p>
            Центром запланировано дальнейшее сотрудничество с Тамбовской областью в направлении развития медиативного и
            восстановительного подходов в работе специалистов системы профилактики.
            <p></p>
            Репортаж мероприятия на канале Вести Тамбов можно <strong><a
            href="https://vestitambov.ru/new/v-tambove-federalnye-spikery-rasskazali-o-tom-kak-snizit-rost-pravonarushenij-sovershjonnyh-nesovershennoletnimi/">посмотреть
            по ссылке</a></strong>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <div>
            <img src={img7}/>
        </div>
        <ToNews/>
    </div>
}


export default News280324;