import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2705242.jpg"

const News2705242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему бесконфликтного общения с подростками
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            16 мая 2024 года начальник отдела медиации Центра защиты прав и интересов детей Анатолий Андреевич Козлов по
            приглашению Фонда поддержки семьи и детей «Хранители детства» провел вебинар для специалистов детских домов
            и иных учреждений, участвующих в программе корпоративного наставничества фонда.
            <p></p>
            На вебинаре Анатолий Андреевич рассказал об основных принципах выстраивания дружественного, уважительного и
            конструктивного общения между взрослыми и детьми. Также были рассмотрены базовые медиативные технологии,
            которые специалисты могут использовать в своей работе.
            <p></p>
            С 2015 года Фонд помогает подросткам и молодежи уязвимых групп в осознанном выборе профессии и получении
            первого опыта работы при поддержке корпоративных наставников. Для подростков проводятся очные и онлайн
            профориентационные мероприятия, обучающие курсы на портале <a
            href="https://naprimerku.ru/">Naprimerku.ru</a>, стажировки в компаниях-партнерах
            при поддержке наставников, а для специалистов в области профориентации - вебинары и тренинги.
            <p></p>
            Сейчас деятельность Фонда охватывает уже 2300 сирот, детей и молодых взрослых с ОВЗ. Благодаря Фонду 500
            подростков получат первый опыт работы летом 2024 года.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News2705242;