import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import ico1 from "../../../assets/link.ico";
import {hrefsArrFPF} from "./FilesPublicationsForms";
import {FileLeftLineKDN} from "./FilesKDNNews";


const FPF1718 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    2017/18 учебный год
                </h2>
                <FileLeftLineKDN text={respData[282]?.description} refers={respData[282]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[283]?.description} refers={respData[283]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[284]?.description} refers={respData[284]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[285]?.description} refers={respData[285]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[286]?.description} refers={respData[286]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[287]?.description} refers={respData[287]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[288]?.description} refers={respData[288]?.file} ico={ico}/>
                <FileLeftLineKDN text={"Перейти на сайт передачи результатов мониторинга субъектами РФ"}
                                 refers={"http://monitoring.fcprc.ru/"} ico={ico1}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default FPF1718