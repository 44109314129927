import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2706253_1.jpg"
import img2 from "../../assets/News/News2706253_2.jpg"
import img3 from "../../assets/News/News2706253_3.jpg"

const News2706243 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в июне 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В июне 2024 года отдел медиации ФГБУ «Центр защиты прав и интересов детей» провёл завершающие в первом
            полугодии онлайн мероприятия по сопровождению служб медиации в СУВУ, подведомственных Минпросвещения России,
            в формате консультаций и большой игры для воспитанников.
            <p></p>
            6 июня состоялась общая онлайн-консультация для всех сотрудников служб медиации СУВУ на тему «Технологии
            медиации в практической работе», в которой приняли участие Каргатское СУВУ, Абаканское СУВУ, Астраханское
            СУВУ, Себежское СУВУ, Щекинское СУВУ, Майкопское СУВУ, Куртамышское СУВУ, Рефтинское СУВУ,
            Санкт-Петербургское СУВУ, Калтанское СУВУ, Ишимбайское СУВУ и Неманское СУВУ.
            <p></p>
            На встрече коллеги обменивались профессиональным опытом и приняли участие в коллективном разборе кейса из
            практики СУВУ с позиций медиативного подхода.
            <p></p>
            19 июня состоялась общая онлайн-консультация для всех сотрудников служб медиации СУВУ по теме «Планирование
            деятельности службы медиации».
            <p></p>
            С сотрудниками служб Щекинского СУВУ, Омского СУВУ, Каргатского СУВУ, Себежского СУВУ, Майкопского СУВУ,
            Санкт-Петербургского СУВУ, Куртамышского СУВУ, Ишимбайского СУВУ, Неманского СУВУ, Рефтинского СУВУ,
            Абаканского СУВУ и Калтанского СУВУ были обсуждены планы на 2024 год.
            <p></p>
            14 июня 2024 года была проведена Большая деловая игра для обучающихся СУВУ.
            <p></p>
            В ней приняли участие обучающиеся Ишимбайского СУВУ, Абаканского СУВУ, Раифского СУВУ, Омского СУВУ,
            Куртамышского СУВУ, Себежского СУВУ, Щекинского СУВУ, Каргатского СУВУ, Санкт-Петербургского СУВУ,
            Орловского СУВУ, Калтанского СУВУ, Астраханского СУВУ и Майкопского СУВУ.
            <p></p>
            В нескольких раундах игры все участники - ребята и девушки - пообщались друг с другом, обсудили что делать,
            если возник конфликт, и чем может помочь медиация.
            <p></p>
            Игра была организована как для знакомых с медиацией обучающихся, так и новичков. Все дети имели возможность
            высказаться, формат игры позволил создать условия для общения мальчиков и девочек на новом, уважительном и
            внимательном уровне отношения друг к другу.
            <p></p>
            Обучающиеся дали позитивную обратную связь об игре, выразили слова благодарности ребятам из других СУВУ,
            организаторам и сотрудникам служб медиации СУВУ за интересное взаимодействие и возможность принять участие в
            мероприятии.
            <p></p>
            <a style={{fontWeight: "bold"}}
               href="https://fcprc.ru/media/media/behavior/Программа_вебинаров_СУВУ_апрель-август_2024.docx"
               target="_blank">ПРОГРАММА ВЕБИНАРОВ </a>
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News2706243;