import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/OrganizationSystemWork.jpg"

const OrganizationSystemWork = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Вебинар по теме «Организация системной работы по профилактике раннего вовлечения в
            незаконное потребление наркотических средств и психотропных веществ обучающихся образовательных организаций
            в субъектах Российской Федерации»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div>
            <p>
                11 ноября 2021 года состоялся вебинар по теме «Организация системной работы по профилактике раннего
                вовлечения в незаконное потребление наркотических средств и психотропных веществ обучающихся
                образовательных организаций в субъектах Российской Федерации».
            </p>
            <p>
                В вебинаре приняли участие специалисты органов исполнительной власти субъектов Российской Федерации,
                осуществляющих государственное управление в сфере образования, и региональные операторы, ответственные
                за организацию и проведение социально-психологического тестирования среди обучающихся, в том числе
                организацию профилактической работы в образовательных организациях субъектов Российской Федерации. Всего
                в совещании приняло участие 735 человек из 85 субъектов Российской Федерации.
            </p>
            <p>
                Костыряченко Юлия Александровна, заместитель директора Департамента государственной политики в сфере
                защиты прав детей Министерства просвещения Российской Федерации, поприветствовала участников совещания и
                осветила в своем выступлении ключевые вопросы проведения социально-психологического тестирования
                обучающихся общеобразовательных и профессиональных образовательных организаций, направленного на раннее
                выявление незаконного потребления наркотических средств и психотропных веществ в 2021/22 учебном году.
            </p>
            <p>
                Заева Ольга Вячеславовна, начальник отдела организационно-методического сопровождения развития системы
                профилактики девиантного поведения несовершеннолетних ФГБУ «Центр защиты прав и интересов детей»,
                подготовила сообщение, посвященное организационным вопросам сопровождения профилактической деятельности
                образовательных организаций в субъектах Российской Федерации, результатам Всероссийского опроса
                родителей по информированности о профилактике ПАВ (www.опрос-родителей-о-пав.рф). Кроме этого, в
                выступлении были анонсированы мероприятия, которые состоятся в рамках проведения Всероссийской акции
                #СТОП ВИЧ/СПИД. В рамках подготовки к акции Центром организованы и проводятся Всероссийский онлайн-опрос
                педагогов о профилактике распространения ВИЧ-инфекции среди обучающихся (www.опрос-педагогов-о-вич.рф) и
                Всероссийский онлайн-опрос молодежи, направленный на определение уровня компетенции в области
                профилактики распространения ВИЧ-инфекции (www.опрос-молодежи-о-вич.рф). В основе инструментария опроса
                – самоисследование уровня информированности и характера субъектного отношения у молодежи и педагогов к
                вопросам профилактики ВИЧ-инфекции, в том числе среди обучающихся.
            </p>
            <p>
                Минюрова Светлана Алигарьевна, ректор ФГБОУ ВО «Уральский государственный педагогический университет»,
                выступила с докладом на тему «Реализация проекта по доработке методики социально-психологического
                тестирования обучающихся».
            </p>
            <p>
                Опытом организационно-методического сопровождения профилактики аддиктивного и девиантного поведения
                несовершеннолетних в Камчатском крае поделилась Прозорова Елена Викторовна, директор КГАУ «Камчатский
                центр психолого-педагогической реабилитации и коррекции», главный внештатный педагог-психолог
                Камчатского края.
            </p>
            <p>
                Кульмач Елена Геннадьевна, заместитель директора Департамента образования и науки Костромской области,
                представила региональную модель профилактики употребления психоактивных веществ в детско-подростковой и
                молодежной среде на примере опыта региона.
            </p>
            <p>
                С докладом на тему «Особенности организации профилактической работы с обучающимися группы риска
                вовлечения в аддиктивное поведение» выступила Вариошкина Елена Николаевна, главный внештатный
                педагог-психолог Костромской области.
            </p>
            <p>
                Гречишникова Анна Сергеевна, главный специалист-эксперт отдела дополнительного образования и воспитания
                Управления образования и науки Тамбовской области, осветила меры по повышению эффективности профилактики
                незаконного потребления наркотических средств и психотропных веществ среди несовершеннолетних,
                предпринимаемые в Тамбовской области.
            </p>
            <p>МАТЕРИАЛЫ ВЕБИНАРА:</p>
            <ul>
                <li><a href="https://youtu.be/WIbWa0jbuhk">ЗАПИСЬ ВЕБИНАРА</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/2021-11-23-vebinar1.pdf">ПРОГРАММА ВЕБИНАРА</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/2021-11-23-vebinar2.pdf">ПРЕЗЕНТАЦИЯ О.В. ЗАЕВОЙ</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/2021-11-23-vebinar3.pdf">ПРЕЗЕНТАЦИЯ Е.В. ПРОЗОРОВОЙ</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/2021-11-23-vebinar4.pdf">ПРЕЗЕНТАЦИЯ Е.Г. ГУЛЬМАЧ</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/2021-11-23-vebinar5.pdf">ПРЕЗЕНТАЦИЯ Е. Н. ВАРИОШКИНОЙ</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/2021-11-23-vebinar6.pdf">ПРЕЗЕНТАЦИЯ А. С. ГРЕЧИШНИКОВОЙ</a></li>
            </ul>
        </div>
    </div>
}


export default OrganizationSystemWork