import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";

const ISNPRVP3 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.newHeader}>
            <h2>
                III. Информационно-методическое обеспечение системы профилактики безнадзорности и правонарушений
                несовершеннолетних
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>
            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>
                <p>Значимость развития информационно-методического обеспечения системы профилактики безнадзорности и
                    правонарушений несовершеннолетних обусловлено необходимостью повышения эффективности и
                    результативности деятельности субъектов профилактики. В частности внедрение электронных
                    информационных систем выявления и учета детей, оказавшихся в трудной жизненной ситуации; разработка
                    и апробация моделей профилактической работы с детьми в девиантным поведением; организацию и
                    проведение научных исследований для создания современных технологий работы с подростками и детьми, с
                    социально уязвимыми группами молодежи, индивидуальной профилактики девиантного поведения, методик
                    социальной работы с семьями; изучение актуального опыта, внедрение и распространение опыта
                    профилактической работы, обновление учебного и научно-методического сопровождения деятельности по
                    профилактике асоциальных проявлений в молодежной среде; использование современных форм повышения
                    квалификации работников органов и учреждений системы профилактики безнадзорности и правонарушений
                    несовершеннолетних и др.</p>
                <p>Информационно-методическое обеспечение системы профилактики безнадзорности и правонарушений
                    несовершеннолетних должно включать:</p>
                <p>— анализ и распространение лучшей практики, технологий и методов профилактики, используемых в сфере
                    правонарушений несовершеннолетних, включая анализ и обобщение опыта формирования единого
                    межведомственного учета несовершеннолетних и (или) семей, находящихся в социально опасном положении,
                    посредством создания регионального банка данных;</p>
                <p>— просвещение родителей (законных представителей) по вопросам профилактики безнадзорности и
                    правонарушений несовершеннолетних, устранения факторов, им способствующих;</p>
                <p>— разработку и использование методов воздействия на несовершеннолетних, не связанных с применением
                    наказания, на основе проведения научных исследований в области психологии девиантного поведения
                    несовершеннолетних.</p>
                <p><strong>Результаты реализации Концепции за 2017 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/pp1.pdf">Аналитическая справка п.25.pdf ( 0.2 Мб)</a></p>
                <p><strong>Результаты реализации Концепции за 2018 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/pp2.pdf">Аналитическая справка_п.25.pdf ( 0.2 Мб)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/pp3.pdf">Информационно-аналитические материалы_п.27.pdf ( 0.3 Мб)</a></p>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default ISNPRVP3