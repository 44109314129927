import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"


const FilesAnticor = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/aboutus").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div>
                <div className={s.newHeader}>
                    <h2 style={{maxWidth: "1300px"}}>
                        Противодействие коррупции
                    </h2>
                    <h3 style={{
                        fontWeight: "normal",
                        marginTop: "50px",
                        color: "#212529",
                        textAlign: "left",
                        maxWidth: "1200px",
                        fontFamily: "Segoe UI",
                        fontSize: "24px"
                    }}>
                        Федеральные Законы РФ
                    </h3>
                </div>
                <FileLeftLine text={respData[80]?.description} refers={respData[80]?.file} ico={ico}/>
                <FileLeftLine text={respData[81]?.description} refers={respData[81]?.file} ico={ico}/>
                <FileLeftLine text={respData[82]?.description} refers={respData[82]?.file} ico={ico}/>
                <FileLeftLine text={respData[83]?.description} refers={respData[83]?.file} ico={ico}/>
            </div>
        </div>

        <div className={s.filesLeftPage}>
            <div>
                <div className={s.newHeader}>
                    <h3 style={{
                        fontWeight: "normal",
                        color: "#212529",
                        textAlign: "left",
                        maxWidth: "1200px",
                        fontFamily: "Segoe UI",
                        fontSize: "24px"
                    }}>
                        Указы президента РФ
                    </h3>
                </div>
                <FileLeftLine
                    text="Указ Президента РФ от 29.12.2022 № 968 «Об особенностях исполнения обязанностей, соблюдения ограничений и запретов в области противодействия коррупции некоторыми категориями граждан в период проведения специальной военной операции»"
                    refers="https://fcprc.ru/media/media/behavior/Указ_Президента_РФ_от_29.12.2022_N_968_Об_особенностях_испо.rtf"
                    ico={ico}/>
                <FileLeftLine text={respData[84]?.description} refers={respData[84]?.file} ico={ico}/>
                <FileLeftLine text={respData[85]?.description} refers={respData[85]?.file} ico={ico}/>
                <FileLeftLine text={respData[86]?.description} refers={respData[86]?.file} ico={ico}/>
                <FileLeftLine text={respData[87]?.description} refers={respData[87]?.file} ico={ico}/>
                <FileLeftLine text={respData[88]?.description} refers={respData[88]?.file} ico={ico}/>
                <FileLeftLine text={respData[89]?.description} refers={respData[89]?.file} ico={ico}/>
                <FileLeftLine text={respData[90]?.description} refers={respData[90]?.file} ico={ico}/>
                <FileLeftLine text={respData[91]?.description} refers={respData[91]?.file} ico={ico}/>
                <FileLeftLine text={respData[92]?.description} refers={respData[92]?.file} ico={ico}/>
                <FileLeftLine text={respData[93]?.description} refers={respData[93]?.file} ico={ico}/>
                <FileLeftLine text={respData[94]?.description} refers={respData[94]?.file} ico={ico}/>

            </div>
        </div>

        <div className={s.filesLeftPage}>
            <div>
                <div className={s.dopHeader}>
                    <h3 style={{
                        fontWeight: "normal",
                        marginTop: "50px",
                        color: "#3a4663",
                        textAlign: "left",
                        maxWidth: "1200px",
                        fontFamily: "Segoe UI",
                        fontSize: "24px"
                    }}>
                        Постановления правительства РФ
                    </h3>
                </div>
                <FileLeftLine text={respData[95]?.description} refers={respData[96]?.file} ico={ico}/>
                <FileLeftLine text={respData[96]?.description} refers={respData[96]?.file} ico={ico}/>
                <FileLeftLine text={respData[97]?.description} refers={respData[97]?.file} ico={ico}/>
                <FileLeftLine text={respData[98]?.description} refers={respData[98]?.file} ico={ico}/>
                <FileLeftLine text={respData[99]?.description} refers={respData[99]?.file} ico={ico}/>
                <FileLeftLine text={respData[100]?.description} refers={respData[100]?.file} ico={ico}/>
                <FileLeftLine text={respData[101]?.description} refers={respData[101]?.file} ico={ico}/>
            </div>
        </div>
        <div className={s.dopHeader}>
            <h3 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                fontFamily: "Segoe UI",
                fontSize: "24px"
            }}>
                Ведомственные приказы
            </h3>
        </div>

        <div className={s.filesLeftPage}>
            <div>
                <FileLeftLine text={respData[102]?.description} refers={respData[102]?.file} ico={ico}/>
                <FileLeftLine text={respData[103]?.description} refers={respData[103]?.file} ico={ico}/>
                <FileLeftLine text={respData[104]?.description} refers={respData[104]?.file} ico={ico}/>
                <FileLeftLine text={respData[105]?.description} refers={respData[105]?.file} ico={ico}/>
                <FileLeftLine text={respData[106]?.description} refers={respData[106]?.file} ico={ico}/>
                <FileLeftLine text={respData[107]?.description} refers={respData[107]?.file} ico={ico}/>
                <FileLeftLine text={respData[108]?.description} refers={respData[108]?.file} ico={ico}/>
                <FileLeftLine text={respData[109]?.description} refers={respData[109]?.file} ico={ico}/>
            </div>
        </div>
        <div className={s.dopHeader}>
            <h3 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                fontFamily: "Segoe UI",
                fontSize: "24px"
            }}>
                Локальные акты ФГБУ "Центр защиты прав и интересов детей"
            </h3>
        </div>
        <div className={s.filesLeftPage}>
            <div>
                <FileLeftLine text={respData[110]?.description} refers={respData[110]?.file} ico={ico}/>
                <FileLeftLine text={respData[111]?.description} refers={respData[111]?.file} ico={ico}/>
                <FileLeftLine text={respData[112]?.description} refers={respData[112]?.file} ico={ico}/>
            </div>

        </div>
        <div className={s.dopHeader}>
            <h3 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                fontFamily: "Segoe UI",
                fontSize: "24px"
            }}>
                Методические рекомендации
            </h3>
        </div>
        <div className={s.filesLeftPage}>
            <div>
                <FileLeftLine text={respData[113]?.description} refers={respData[113]?.file} ico={ico}/>
                <FileLeftLine text={respData[114]?.description} refers={respData[114]?.file} ico={ico}/>
                <FileLeftLine text={respData[115]?.description} refers={respData[115]?.file} ico={ico}/>
                <FileLeftLine text={respData[116]?.description} refers={respData[116]?.file} ico={ico}/>
                <FileLeftLine text={respData[117]?.description} refers={respData[117]?.file} ico={ico}/>
                <FileLeftLine text={respData[118]?.description} refers={respData[118]?.file} ico={ico}/>
                <FileLeftLine text={respData[119]?.description} refers={respData[119]?.file} ico={ico}/>
                <FileLeftLine text={respData[120]?.description} refers={respData[120]?.file} ico={ico}/>
                <FileLeftLine text={respData[121]?.description} refers={respData[121]?.file} ico={ico}/>
                <FileLeftLine text={respData[122]?.description} refers={respData[122]?.file} ico={ico}/>
            </div>

        </div>

    </>
}


export default FilesAnticor