import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import IcoPMPK from "../../../assets/logo_pmpk.svg"
import {ArrowRightOutlined, CheckSquareFilled} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import ImgPopup from "../Popup/PopupImg"
import axios from "axios";



const Hotline5 = ({url, header, children, style = {}}) => {
    const [active, setActive] = useState(false)
    const [isSubmited, setSubmittingForm] = useState(true)
    const [politics, setPolitics] = useState(false)
    function validateEmail(value) {
        let error;
        if (!value) {
            error = 'Обязательное поле';
        }
        return error;
    }
    return <>
        <div className={s.page}>
            <div className={s.content}>
                <h2 style={{fontWeight: "normal", fontSize: "30px", color: "#3a4463"}}>
                Регистрация закрыта
                </h2>
                {/* <div>
<div style={{fontSize:"22px"}}>
«Организация психолого-педагогического сопровождения обучающихся», 72 ак.ч.
</div>
<p></p>
<img src={IcoPMPK} style={{ boxShadow:" 0 4px 11px rgb(0 0 0 / 30%)", borderRadius:"10px"}}/>
<p></p>
<div>
Программа повышения квалификации для региональных команд из числа специалистов органов государственной власти субъектов Российской Федерации в сфере образования, профессорско-преподавательского состава региональных институтов развития образования, административных, педагогических и иных работников образовательных организаций, осуществляющих общее образование обучающихся с ограниченными возможностями здоровья, по вопросам организации психолого-педагогического сопровождения обучающихся.</div>
<div>
Цель программы: совершенствование профессиональных компетенций специалистов, осуществляющих психолого-педагогическое сопровождение обучающихся с ОВЗ, с инвалидностью.
</div>
<div>
При успешной сдаче итоговой аттестации обучающиеся получают удостоверение о повышении квалификации на 72 ак.ч.
</div>

                <p></p>
                <div>
                    <div className={s.hotlineHref}>
                        <input type="checkbox" onClick={() => {
                            politics? setPolitics(false): setPolitics(true)
                        }}/>
                        Я даю согласие согласие на обработку <a href="https://fcprc.ru/media/media/behavior/Приказ__39_от_29.05.2023.pdf">персональных данных</a>
                    </div>
                    <br/>
                    {politics ? <div onClick={() => {
                        setActive(true)
                    }} style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "pink",
                        width: "230px",
                        marginBottom: "70px",
                        cursor: "pointer",
                        borderRadius: "10px"
                    }}>
                        Перейти к заполнению
                    </div> : <div style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "#c97171",
                        width: "230px",
                        marginBottom: "70px",

                        borderRadius: "10px"
                    }}>
                        Перейти к заполнению
                    </div>}
                </div>
<p>
    <h3>

    </h3>
</p>
</div> */}
{/* <ImgPopup active={active & isSubmited} setActive={setActive}>
                    <div className={s.form}>
                        
                        <Formik
                            initialValues={{email: '',}}
                        
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                        axios.post("https://fcprc.ru/api/edupmpk", {'регион': values.subject, 'образование': values.dir, 'гражд': values.Gra,
                                       'место_работы': values.textT, 'должность': values.textQ, 'имя': values.name, 'почта': values.email, 'телефон': values.phone, 'фамилия':
                                        values.lastname, 'отчество': values.surname, 'фамилияД':values.lastnameD, 'СерияД': values.series, 'НомерД': values.number, 'ДР':values.dateB, 
                                        'Пол': values.sex, 'СНИЛС': values.snils,
                                }, {      xsrfCookieName: 'csrftoken',
                                         xsrfHeaderName: 'X-CSRFTOKEN',
                                     })                                      
                                    setSubmitting(false);
                                    setActive(false)
                                    setSubmittingForm(false)
                                }, 400);
                            }}
                        >
                            {({errors, touched, isSubmitting}) => (
                                <Form>
                                    
                                    <div>
                                    <div>
                                            <div>
                                                <label fore="dir" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Образование</label>
                                            </div>
                                            <Field component="select" name="dir" placeholder="Выберите" >
                                                <option> Выберите </option>
                                                <option> Среднее профессиональное образование </option>
                                                <option> Высшее образование </option>
                                            </Field>
                                             {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="lastnameD" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Фамилия указанная в дипломе об образовании
                                                </label>
                                            </div>
                                            <Field type="lastnameD" name="lastnameD"
                                                   placeholder=""/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="series" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Серия документа об образовании
                                                </label>
                                            </div>
                                            <Field type="series" name="series"
                                                   placeholder=""/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="number" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Номер документа об образовании
                                                </label>
                                            </div>
                                            <Field type="number" name="number"
                                                   placeholder=""/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="lastname" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Фамилия
                                                </label>
                                            </div>
                                            <Field type="lastname" name="lastname" 
                                                   placeholder="Ваша Фамилия"/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="name" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Имя
                                                </label>
                                            </div>
                                            <Field type="name" name="name" 
                                                   placeholder="Ваше Имя"/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="surname" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Отчество
                                                </label>
                                            </div>
                                            <Field type="surname" name="surname" 
                                                   placeholder="Ваше Отчество"/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="dateB" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Дата рождения
                                                </label>
                                            </div>
                                            <Field type="dateB" name="dateB"
                                                   placeholder=""/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="sex" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Пол</label>
                                            </div>
                                            <Field component="select" name="sex" placeholder="sex">
                                                <option> Выберите </option>
                                                <option> Мужской </option>
                                                <option> Женский </option>
                                            </Field>
                                             {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="snils" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>СНИЛС
                                                </label>
                                            </div>
                                            <Field type="number" name="snils"
                                                   placeholder="Номер снилс"/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="Gra" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Гражданство
                                                </label>
                                            </div>
                                            <Field type="Gra" name="Gra"
                                                   placeholder=""/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="phone" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Номер телефона
                                                </label>
                                            </div>
                                            <Field type="phone" name="phone"
                                                   placeholder="Телефон для связи"/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="email" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>EMAIL
                                                </label>
                                            </div>
                                            <Field type="email" name="email"
                                                   placeholder="E-mail для обратной связи"/>
                                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        
                                    </div>
                                    
                                    <div>
                                        <div>
                                            <label fore="textT" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Место работы</label>
                                        </div>
                                        <Field component="textarea" type="text" name="textT" placeholder="..."/>
                                         {errors.name && touched.name && <div>{errors.name}</div>}
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="textQ" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Должность</label>
                                        </div>
                                        <Field component="textarea" type="text" name="textQ" placeholder="..."/>
                                         {errors.name && touched.name && <div>{errors.name}</div>}
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="subject" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}} validate={validateEmail}>Регион</label>
                                        </div>
                                        <Field type="text" name="subject" validate={validateEmail} />
                                         {errors.name & touched.name & <div>{errors.name}</div>}
                                    </div>
                                    <div className={s.send} >
                                        <p></p>
                                        <button>Отправить</button>
                                        <div>
                                        * Все поля обязательны для заполнения
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </ImgPopup>
                 */}

                
            </div>
           
        </div>
    </>


}
/*

*/

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <a href={to}>
                    {text}
                </a>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}

export default Hotline5