import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const BestInclusiveSchool2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            «Лучшая инклюзивная школа России – 2022»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>26-28 октября состоится федеральный этап IX Всероссийского конкурса <strong><a href="http://lish.mgppu.ru/">«Лучшая инклюзивная школа
                России – 2022».</a></strong></p>
            <p>Ежегодный конкурс «Лучшая инклюзивная школа России» объединяет лучшие инклюзивные практики и
                образовательные технологии с 2013 года. Организаторы IX Всероссийского конкурса «Лучшая инклюзивная
                школа России» — Министерство просвещения Российской Федерации и ФГБОУ ВО «Московский государственный
                психолого- педагогический университет», соорганизаторы — РООИ «Перспектива» и Ассоциация инклюзивных
                школ.</p>
        </div>
        <ToNews/>
    </div>
}


export default BestInclusiveSchool2022;