import React from "react";
import ico from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";
import {FileLeftLineKDN, HrefsKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const FilesKDNMer = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Мероприятия
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2024
                </h1>
                <FileLeftLineKDN
                    text={"Вебинар для ответственных секретарей комиссий по делам несовершеннолетних и защите их прав, а также специалистов, обеспечивающих деятельность комиссий по делам несовершеннолетних и защите их прав"}
                    refers={"/news/260624"} ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2023
                </h1>
                <FileLeftLineKDN
                    text={"«О межведомственном взаимодействии в сфере профилактики безнадзорности и правонарушений несовершеннолетних комиссий по делам несовершеннолетних и защите их прав и органов по делам молодежи»"}
                    refers={"/news/231023"} ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2022
                </h1>
                <FileLeftLineKDN
                    text={"«Об особенностях рассмотрения комиссиями по делам несовершеннолетних и защите их прав отдельных категорий дел об административных правонарушениях в отношении несовершеннолетних»"}
                    refers={"/oork"} ico={ico}/>
                <FileLeftLineKDN
                    text={"«Об актуальных вопросах, возникающих в деятельности комиссий по делам несовершеннолетних и защите их прав при рассмотрении дел об административных правонарушениях»"}
                    refers={"/oavvvd"} ico={ico}/>
                <div>
                    <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                        2021
                    </h1>
                    <div>
                        <FileLeftLineKDN
                            text={"Вебинар по актуальным вопросам деятельности комиссий по делам несовершеннолетних и защите их прав для ответственных секретарей комиссий субъектов Российской Федерации и специалистов, обеспечивающих деятельность указанных комиссий, по теме: «О проблемных вопросах правоприменительной практики комиссий по делам несовершеннолетних и защите их прав по результатам мониторинга практики организации деятельности комиссий», 15 сентября 2021 г."}
                            refers={"/opvp"} ico={ico}/>
                        <FileLeftLineKDN
                            text={"Вебинар по актуальным вопросам деятельности комиссий по делам несовершеннолетних и защите их прав для ответственных секретарей комиссий субъектов Российской Федерации и специалистов, обеспечивающих деятельность указанных комиссий, по теме: «Об организации мониторинга деятельности комиссий по делам несовершеннолетних и защите их прав», 4 февраля 2021 г."}
                            refers={"/oomdk"} ico={ico}/>
                    </div>
                </div>
            </div>
            <div style={{marginTop: "-3px"}}>
                <HrefsKDN hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </>
}
export default FilesKDNMer