import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News231123.jpg"

const News231123 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            V Всероссийская конференция по вопросам реализации Десятилетия детства
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            21 ноября 2023 г. Министерство просвещения Российской Федерации и ФГБУ «Центр защиты прав и интересов детей»
            провели
            V Всероссийскую конференцию по вопросам реализации Десятилетия детства <strong>«Современные вызовы детства:
            стратегии обеспечения благополучия детей и семей с детьми»</strong>.
            <p></p>
            В Конференции участвовали сенаторы и депутаты Федерального Собрания Российской Федерации, Уполномоченный при
            Президенте Российской Федерации по правам ребенка, члены Координационного совета при Правительстве
            Российской Федерации по проведению в Российской Федерации Десятилетия детства и руководители его рабочих
            групп, а также представители федеральных и региональных органов исполнительной власти, некоммерческих
            организаций и ведущие российские эксперты.
            <p></p>
            Директор Центра Гусев Борис Борисович принял участие
            в Пленарном заседании, на котором с приветственным словом к участникам обратились Заместитель Председателя
            Правительства Российской Федерации, Председатель Координационного Совета при Правительстве Российской
            Федерации по проведению в Российской Федерации Десятилетия детства Т.А. Голикова, председатель Комитета
            Совета Федерации по науке, образованию и культуре Л.С. Гумерова, академик Российской академии образования,
            президент Российской академии образования О.Ю. Васильева, Министр просвещения Российской Федерации С.С.
            Кравцов и Уполномоченный при Президенте Российской Федерации по правам ребенка М.А. Львова-Белова, которая в
            том числе рассказала о проектах, которые реализуются совместно с Федеральным центром развития программ
            социализации подростков.
            <p></p>
            Также в рамках Конференции проведены пять секций, в ходе которых были рассмотрены
            образовательные и медицинские аспекты всестороннего развития личности; эффективные практики социальной
            поддержки семей с детьми; актуальная проблематика и результаты научных исследований проблем детства;
            обеспечение безопасности детей и современный подход к инфраструктуре детства; актуальные вопросы поддержки
            детей-сирот и детей, оставшихся без попечения родителей; презентация и обсуждение наиболее успешных практик
            реализации Десятилетия детства.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News231123;