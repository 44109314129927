import React from "react";
import Falldown from "../common/FalldownList/Falldown";
import img21 from "../../assets/img/1.png"
import s from "./Behavior.module.css"
import {NavLink} from "react-router-dom";
import ico1 from "../../assets/Behavior/edu1.png"
import ico2 from "../../assets/Behavior/edu2.png"
import ico3 from "../../assets/Behavior/edu3.png"
import doc from "../../assets/img/doc.svg"
import {ArrowRightOutlined} from "@ant-design/icons"


const Education = () => {
    return <>
        <div className={s.newHeader}>
            <h2>
                Образование
            </h2>
        </div>
        <div className={s.fall}>
            <PinkBlock title="Дополнительное профессиональное образование" url="https://dpo.fcprc.ru/"/>
            <PinkBlock title="Вебинары" url="/webs"/>
        </div>


        <div className={s.ico} style={{backgroundImage: `url(${ico2})`, zIndex: 3, opacity: 0.7}}>

        </div>

    </>


}


const PinkBlock = ({title, url}) => {
    return (
        <div className={s.block}>
            <div className={s.blockHead}>
                <div className={s.text}>
                    <img src={doc}/>
                    {title}
                </div>
                <div className={s.button} style={{textDecoration: "none"}}>
                    <div>
                        <a href={url}>
                            Перейти <ArrowRightOutlined style={{color: "red", width: "7px"}}/>
                        </a>
                    </div>
                </div>

            </div>
            <div className={s.ico}
                 style={{backgroundImage: `url(${ico1})`, zIndex: 3, opacity: 0.7}}>

            </div>
        </div>
    )
}

export default Education