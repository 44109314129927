import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import SimpleImageSlider from "react-simple-image-slider";
import img1 from "../../assets/News/News170624_1.jpg";
import img2 from "../../assets/News/News170624_2.jpg";
import img3 from "../../assets/News/News170624_3.jpg";
import img4 from "../../assets/News/News170624_4.jpg";
import img5 from "../../assets/News/News170624_5.jpg";
import img6 from "../../assets/News/News170624_6.jpg";
import img7 from "../../assets/News/News170624_7.jpg";
import img8 from "../../assets/News/News170624_8.jpg";
import img9 from "../../assets/News/News170624_9.jpg";
import img10 from "../../assets/News/News170624_10.jpg";
import img11 from "../../assets/News/News170624_11.jpg";
import img12 from "../../assets/News/News170624_12.jpg";
import img13 from "../../assets/News/News170624_13.jpg";
import img14 from "../../assets/News/News170624_14.jpg";
import img15 from "../../assets/News/News170624_15.jpg";
import img16 from "../../assets/News/News170624_16.jpg";
import img17 from "../../assets/News/News170624_17.jpg";
import img18 from "../../assets/News/News170624_18.jpg";
import img19 from "../../assets/News/News170624_19.jpg";
import img20 from "../../assets/News/News170624_20.jpg";


const News170624 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            На Всероссийской конференции обсудили вопросы дальнейшей реализации мероприятий Десятилетия детства
        </h2>
        <div>
            <img src={img20}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В павильоне № 57 Международной выставки-форума «Россия» состоялась VI Всероссийская конференция «Реализация
            Десятилетия детства с учетом новых тенденций государственной политики в сфере детства». Ее участниками стали
            члены Координационного совета при Правительстве Российской Федерации по проведению в Российской Федерации
            Десятилетия детства, представители федеральных и региональных органов исполнительной власти, уполномоченные
            по правам ребенка субъектов Российской Федерации, ведущие российские эксперты в сфере детства.
            <p></p>
            Приветствие участникам конференции направил заместитель Председателя Правительства Российской Федерации
            Дмитрий Чернышенко. Он отметил, что сегодня в России проживают 24,5 млн семей, в которых воспитываются свыше
            30 млн детей, их интересы всегда были и остаются первостепенными. Вице-премьер также подчеркнул, что в
            настоящее время в стране формируется единая система воспитания на основе взаимодействия детских и молодежных
            объединений.
            <p></p>
            <i>«Благодаря модернизации инфраструктуры сферы образования ежегодно увеличивается число детей, посещающих
                различные секции и кружки, создаются дополнительные места в дошкольных и общеобразовательных
                организациях.
                Отдельное внимание уделяется детям, которые нуждаются в особой поддержке государства, оказались в
                трудной
                жизненной ситуации, детям с ограниченными возможностями здоровья, инвалидностью, детям-сиротам.
                Выстроена
                современная оперативная система социальной поддержки семей с детьми, ориентированная на их
                индивидуальные
                потребности и позволяющая получать необходимую государственную помощь», </i>– сообщил Дмитрий
            Чернышенко.
            <p></p>
            Министр просвещения Российской Федерации Сергей Кравцов в своем видеообращении к участникам конференции
            поблагодарил педагогов, врачей, работников сферы культуры и спорта, представителей молодежной политики и
            социальной защиты, сотрудников правоохранительных органов из всех регионов страны. Он подчеркнул, что в том
            числе именно они оперативно реагируют на насущные вопросы семей с детьми.
            <p></p>
            «Сегодня важно проанализировать результаты работы, а также определить новые векторы с учетом задач,
            поставленных Президентом нашей страны. Именно эти вопросы вам предстоит обсудить в рамках конференции.
            Уверен, что при вашей поддержке и помощи вместе мы сможем найти верные пути развития инициатив в сфере
            детства. Желаю плодотворной работы и всего самого доброго», – сказал Сергей Кравцов.
            <p></p>
            С приветственным словом к участникам конференции обратились заместитель Председателя Совета Федерации
            Федерального Собрания Российской Федерации Инна Святенко, первый заместитель председателя Комитета
            Государственной Думы по защите семьи, вопросам отцовства, материнства и детства Татьяна Буцкая и
            Уполномоченный при Президенте Российской Федерации по правам ребенка Мария Львова-Белова.
            <p></p>
            На пленарном заседании конференции заместитель главы Минпросвещения России Денис Грибов отметил, что итоги
            реализации Десятилетия детства за последние семь лет демонстрируют преимущества межведомственного
            взаимодействия и комплексного подхода.
            <p></p>
            <i>«В активной разработке сегодня находятся новые национальные проекты «Семья», а также «Молодежь и дети». С
                учетом современных вызовов и тенденций в сфере детства перед нами стоит задача до конца года
                актуализировать
                мероприятийную часть Плана Десятилетия детства и, что самое важное, ожидаемые результаты мероприятий.
                Эту
                работу необходимо будет провести на региональном уровне», </i>– подчеркнул Денис Грибов.
            <p></p>
            Участники пленарного заседания обсудили подходы к трансформации мероприятий Десятилетия детства с учетом
            новых национальных целей развития, уделили внимание вопросам укрепления традиционных семейных ценностей,
            поддержки семей с детьми, а также поделились наиболее успешными практиками реализации мероприятий
            Десятилетия детства.
            <p></p>
            Кроме того, Денис Грибов вручил руководителям рабочих групп при Координационном совете при Правительстве
            Российской Федерации по проведению в Российской Федерации Десятилетия детства благодарственные письма
            Минпросвещения России.
            <p></p>
            С мотивирующими докладами на пленарном заседании выступили член Комитета Совета Федерации по науке,
            образованию и культуре Елена Писарева, первый заместитель Министра труда и социальной защиты Российской
            Федерации Ольга Баталина, академик Российской академии наук Лейла Намазова-Баранова, руководитель Аппарата
            Уполномоченного при Президенте Российской Федерации по правам ребенка Алексей Газарян и директор дивизиона
            «Качество жизни» автономной некоммерческой организации «Агентство стратегических инициатив» по продвижению
            новых проектов Александр Пироженко.
            <p></p>
            В рамках конференции также состоялась панельная дискуссия, посвященная тенденциям государственной политики в
            сфере детства. Ее модераторами стали директор Департамента государственной политики в сфере защиты прав
            детей Минпросвещения России Лариса Фальковская и корреспондент канала «Новости Первых» Общероссийского
            общественно-государственного движения детей и молодежи «Движение Первых» Александра Шмитова. Эксперты в
            областях образования, культуры, спорта и молодежной политики обсудили актуальные направления и вопросы,
            которые предстоит решить в ближайшие три года в рамках Десятилетия детства.
            <p></p>
            Справочно
            <p></p>
            В 2017 году Президент России Владимир Путин подписал указ об объявлении 2018–2027 годов в Российской
            Федерации Десятилетием детства.
            <p></p>
            Организатор VI Всероссийской конференции «Реализация Десятилетия детства с учетом новых тенденций
            государственной политики в сфере детства» – Минпросвещения России. Оператор мероприятия – Центр защиты прав
            и интересов детей.
            <p></p>
            <SimpleImageSlider images={[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19]} width={1000} height={757}
                               showBullets={true}
                               autoPlay={true}
                               autoPlayDelay={5}
                               showNavs={true}/>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News170624;