import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/OPVP.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import file1 from '../../../assets/Files/Pr-VII.pdf'
import file2 from '../../../assets/Files/Rez-VIII.pdf'
import file3 from '../../../assets/Files/Sb-1.pdf'
import file4 from '../../../assets/Files/SB-2.pdf'
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const EightVSPV = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    VIII Всероссийское совещание по вопросу организации деятельности комиссий по делам
                    несовершеннолетних и
                    защите их прав «Маршрут межведомственного взаимодействия органов и учреждений системы профилактики
                    безнадзорности и правонарушений несовершеннолетних: современный контекст и новые модели»
                </h2>
            </div>


            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    16 ноября 2021 г. состоялось VIII Всероссийское совещание по вопросу организации деятельности
                    комиссий по делам несовершеннолетних и защите
                    их прав (далее соответственно – Всероссийское совещание, комиссии) на тему: «Маршрут
                    межведомственного взаимодействия органов и учреждений системы профилактики безнадзорности и
                    правонарушений несовершеннолетних: современный контекст и новые модели», организованное
                    Минпросвещения России совместно с федеральным государственным бюджетным учреждением «Центр защиты
                    прав и интересов детей» и Центром научно-методического обеспечения системы профилактики
                    безнадзорности и правонарушений несовершеннолетних Института законотворчества федерального
                    государственного бюджетного образовательного учреждения высшего образования «Саратовская
                    государственная юридическая академия».
                </p>
                <p>В этом году онлайн-формат мероприятия объединил участников из всех субъектов Российской Федерации, в
                    том числе члены Правительственной комиссии по делам несовершеннолетних и защите их прав. Учитывая
                    актуальность психологического компонента в организации работы с ребенком и его семьей, в мероприятии
                    также приняли участие главные внештатные педагоги-психологи в системах образования субъектов
                    Российской Федерации.</p>
                <p>Всероссийское совещание стало одной из точек конструктивного диалога по вопросам профилактики
                    безнадзорности и правонарушений несовершеннолетних с учетом современных социальных тенденций. На
                    пленарном заседании обсуждались пути реализации приоритетных направлений государственной политики в
                    сфере профилактики, ключевые проблемы и эффективные практики, которые будут способствовать
                    обновлению формата превентивной деятельности.</p>
                <p>
                    По итогам обсуждения внесены предложения, в части: организации повышения квалификации представителей
                    органов и учреждений системы профилактики по актуальным вопросам межведомственного взаимодействия;
                    содействия цифровизации системы профилактики, оптимизации информационного обмена в сфере
                    предупреждения неблагополучия несовершеннолетних; организации совместной работы комиссий и
                    психологических служб с участием главных внештатных педагогов-психологов в субъектах Российской
                    Федерации по выявлению и реагированию на резонансные ситуации с участием несовершеннолетних;
                    развития практики предупреждения социального сиротства, семейного неблагополучия, обеспечения
                    сохранности кровной семьи для ребенка через оказание помощи с применением имеющихся ресурсов, в том
                    числе с привлечением органов опеки и попечительства к деятельности по профилактике безнадзорности
                    несовершеннолетних и иные.
                </p>
                <p>
                    Реализация выработанных рекомендаций будет способствовать обновлению формата превентивной
                    деятельности органов и учреждений системы профилактики, в том числе комиссий, органов опеки и
                    попечительства, а также укреплению совместной работы по защите детства.
                </p>
                <p>
                    <a href={file1}>Программа VIII
                        Всероссийского совещания КДН и ЗП</a>
                </p>
                <p>
                    <a href={file2}>Проект резолюции VIII
                        Всероссийского совещания КДН и ЗП</a>
                </p>
                <p><a href={file3}>Сборник методических
                    рекомендаций для КДН и ЗП</a></p>
                <p><a href={file4}>Сборник материалов VIII
                    Всероссийского совещания КДН и ЗП</a></p>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default EightVSPV