import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News190723_1.jpg"
import img2 from "../../assets/News/News190723_2.png"
import img3 from "../../assets/News/News190723_3.jpeg"

const News190723 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в апреле 2023 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div className={s.refNews} style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В апреле 2023 года отделом медиации Центра защиты прав и интересов детей были проведены мероприятия по
                сопровождению работы служб медиации специальных учебно-воспитательных учреждений, подведомственных
                Минпросвещения России.</p>
            <p>Мероприятия прошли в дистанционном формате:
                <br/>
                6 – 7 апреля с Ишимбайским СУВУ, Щекинским СУВУ, Майкопским СУВУ и Каргатским СУВУ;
                <br/>
                20 – 21 апреля с Известковским СУВУ, Себежским СУВУ, Абаканским СУВУ и Куртамышским СУВУ;
                <br/>
                27 – 28 апреля с Ишимбайским СУВУ, Куртамышским СУВУ, Рефтинским СУВУ и Раифским СУВУ.</p>
            <p>Мероприятия включали в себя консультации специалистов служб и проведение интерактивных занятий с
                обучающимися.</p>
            <p>В рамках консультаций специалисты служб совместно с сотрудниками Центра разбирали практические
                конфликтные ситуации из опыта работы служб. Также обсуждались планы на 2023 год и текущее состояние
                служб.</p>
            <p>Интерактивные занятия для обучающихся прошли в формате деловой игры и были ориентированы на развитие
                навыков ведения конструктивного диалога в ситуации неопределенности. Сотрудники отдела медиации Центра
                выстроили структуру занятий так, чтобы были комфортные условия для общения обучающихся обоих полов.</p>
            <p>Встречи проходили в дружеской и уважительной атмосфере. Обучающиеся и сотрудники положительно оценили
                интерактивный формат занятий.</p>
            <p>Мероприятия проводили: начальник отдела медиации Центра защиты прав и интересов детей – Быкова Марина
                Викторовна и аналитик II категории отдела медиации – Козлов Анатолий Андреевич.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News190723;