import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/Putin1.jpg"

const Putin24061 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Уважаемые коллеги! 
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>

        Уважаемые коллеги!
            </p>
Сегодня наша страна столкнулась с неординарной ситуацией. Президент России дал бескомпромиссную оценку произошедшему - предательство и мятеж. 
            <p></p>
            
Мы четко осознаем суть происходящего и всецело поддерживаем решение главы государства - противостоять попыткам дестабилизировать нашу страну изнутри, попыткам разобщить население. 
            <p></p>
Осуждаем антиконституционные действия. Власти и компетентные органы делают все, чтобы обеспечить безопасность людей, предотвратить все негативные последствия, стабилизировать ситуацию. 
            <p></p>
Уверены, только вместе мы сможем ее преодолеть и продолжим строить лучшую суверенную систему образования, суверенную страну! Просим поддерживать друг друга, руководствоваться здравым смыслом и прислушиваться к рекомендациям властей. Из этой ситуации мы выйдем только сильнее - вместе с Россией, вместе с Президентом!
        </div>
        <p></p>
        <ToNews/>
    </div>
}


export default Putin24061;