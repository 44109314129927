import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/PreventionOfTheSpreadHIV2022.jpg"

const PreventionOfTheSpreadHIV2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            «Лучшая инклюзивная школа России – 2022»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>29–30 ноября 2022 года на вебинарной платформе ФГБУ «Центр защиты прав и интересов детей» состоялись
                мероприятия, направленные на организацию профилактической работы по предупреждению распространения
                ВИЧ-инфекции среди детей, подростков и молодежи.</p>
            <p>Специалисты Центра провели Всероссийский вебинар для педагогических работников образовательных
                организаций «Деятельность образовательных организаций по профилактике распространения ВИЧ-инфекции среди
                обучающихся» и Всероссийское родительское собрание «Профилактика распространения ВИЧ-инфекции и
                формирование ответственного и безопасного поведения детей, подростков и молодежи»</p>
            <p>С приветственным словом от имени Департамента государственной политики в сфере защиты прав детей
                Министерства просвещения Российской Федерации к участникам вебинара обратилась Татьяна Сергеевна
                Бердникова, советник Департамента. В своем выступлении Татьяна Сергеевна сообщила, что профилактическая
                деятельность образовательных организаций, в том числе в части предотвращения распространения
                ВИЧ-профилактики среди обучающихся, является одной из стратегических целей государственной политики,
                реализуемой Департаментом. Татьяна Сергеевна также отметила, что на государственном уровне усилилось
                внимание к воспитательной работе, что, безусловно, связано и с подходами к организации профилактической
                работы с обучающимися.</p>
            <p>Далее Ольга Вячеславовна Заева, начальник отдела профилактики девиантного поведения несовершеннолетних
                Центра, раскрыла особенности организационно-методического сопровождения деятельности образовательных
                организаций по профилактике распространения ВИЧ-инфекции среди обучающихся. Ольга Вячеславовна описала
                основные направления организации, технологии профилактической работы, и заметила, что в настоящее время
                особенное внимание нужно уделять воспитанию тех ценностей и норм поведения несовершеннолетних, которые
                могут позволить сформировать ответственное отношение к своему здоровью.</p>
            <p>Ольга Вячеславовна пригласила педагогов поучаствовать в онлайн-опросе, направленном на самоисследование
                уровня информированности и характера субъектного отношения педагогов к деятельности в области
                профилактики распространения ВИЧ-инфекции, формирования здорового и безопасного образа жизни обучающихся
                (www.опрос-педагогов-о-вич.рф). В 2021 и 2022 году в опросе приняли участие более 200 тыс. педагогов из
                85 субъектов Российской Федерации.</p>
            <p>Врач-инфекционист ГБУЗ МО «Московская областная больница им. проф. Розанова В. Н.» Анастасия Вячеславовна
                Патран осветила медицинские вопросы профилактики распространения ВИЧ-инфекции, основные пути и способы
                передачи ВИЧ. Анастасия Вячеславовна отметила, что безопасное поведение (включающее в себя регулярное
                тестирование на ВИЧ и другие инфекции) является ключевым фактором профилактики распространения ВИЧ.</p>
            <p>Ольга Николаевна Кирьянова, директор Благотворительного фонда помощи детям с социально значимыми
                заболеваниями «Дети плюс», осветила опыт работы Фонда в помощи детям с заболеванием ВИЧ-инфекция и
                профилактике их дискриминации в обществе. Ольга Николаевна напомнила, что информация о медицинских
                диагнозах и иные сведения, полученные при медицинском обследовании и лечении, составляют врачебную
                тайну. Было подчеркнуто, что дети с заболеванием ВИЧ-инфекция не должны подвергаться дискриминации и
                стигматизации, а должны получать поддержку и принятие со стороны участников образовательных отношений в
                школе. В рамках работы вебинара состоялась презентация видеоролика Фонда, посвященного профилактике
                дискриминации и стигматизации обучающихся с ВИЧ в образовательной среде и обществе в целом.</p>
            {/*<div>*/}
            {/*    Video*/}
            {/*</div>*/}
            <p>Во Всероссийском вебинаре приняли участие 8 165 педагогических работников образовательных организаций из
                всех субъектов Российской Федерации. В форме заочного участия доступ к материалам получили 11 225
                специалистов.</p>
            <p>На Всероссийском родительском собрании заместитель директора Центра Елена Геннадьевна Артамонова во
                вступительном слове обозначила, что образовательная среда обладает огромным профилактическим
                потенциалом, потому что именно в школе подросток проводит огромную часть своей жизни. В силах педагогов
                и родителей сделать все возможное для того, чтобы ценности и привычки детей и подростков способствовали
                формированию здорового образа жизни, предосторожного стиля поведения и их здоровому включению в
                социум.</p>
            <p>На родительском собрании выступила врач-инфекционист ГБУЗ МО «Московская областная больница им. проф.
                Розанова В. Н.» Анастасия Вячеславовна Патран, раскрывшая для родителей особенности медицинской
                профилактики ВИЧ-инфекции среди подростков и молодежи.</p>
            <p>Ольга Вячеславовна Заева, начальник отдела профилактики девиантного поведения Центра, отметила важность
                личного примера родителей в формировании у подростков здоровьесберегающего поведения, исключающего риски
                заражения ВИЧ-инфекцией, и пригласила родителей принять участие подростков во Всероссийском
                онлайн-опросе молодежи, направленном на определение уровня компетенции в области профилактики
                распространения ВИЧ-инфекции, в котором уже приняли участие более 800 тыс. респондентов со всей
                России.</p>
            <p>Елизавета Дмитриевна Токарева, клинический психолог Благотворительного Фонда «Дети плюс», рассказала о
                том, как поговорить с детьми о ВИЧ. Елизавета Дмитриевна отметила, что в зависимости от возраста
                содержание беседы родителя с детьми о таком заболевании, как ВИЧ-инфекция, должно меняться: так,
                дошкольникам необходимо прививать навыки заботы о здоровье, младшим школьникам можно описывать,
                например, механизм работы иммунитета. Подростков необходимо информировать о ВИЧ, о его путях передачи и
                способах профилактики.</p>
            <p>Во Всероссийском родительском собрании приняли участие 11 998 родителей (законных представителей) из всех
                субъектов Российской Федерации. В форме заочного участия доступ к материалам получили 27 986
                родителей.</p>
            <p style={{
                fontFamily: "Proxima Nova Rg",
                fontSize: "30px",
                color: "#4A4663"
            }}>Материалы Всероссийского вебинара:</p>
            <p><a href="https://www.youtube.com/watch?v=zsQiLWoNSjA">Просмотреть видеозапись вебинара</a></p>
            <p style={{
                fontFamily: "Proxima Nova Rg",
                fontSize: "30px",
                color: "#4A4663"
            }}>Материалы Всероссийского родительского собрания:</p>
            <p><a href="https://www.youtube.com/watch?v=zsQiLWoNSjA">Просмотреть видеозапись вебинара</a></p>
        </div>
        <ToNews/>
    </div>
}


export default PreventionOfTheSpreadHIV2022;