import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/KSWarZone1.jpg"
import img2 from "../../assets/News/KSWarZone2.jpg"

const KSWarZone = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Круглый стол на тему: «Работа с детьми, имеющими опыт проживания в зоне военных действий»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>29 сентября состоялся круглый стол, который провела Уполномоченный при Президенте
                Российской Федерации по правам ребенка Мария Львова-Белова.
                Модератором выступил руководитель Федерального центра развития программ социализации
                подростков — структурного подразделения Центра защиты прав и интересов детей Валерий
                Майоров.</p>
            <p>Основной темой дискуссии стал актуальный вопрос помощи детям, пережившим опыт боевых
                действий, и последствия его влияния на психику и здоровье ребенка. Еще одна важная задача —
                социальная интеграция детей, выросших в другом культурном и информационном пространстве, в
                современные реалии и географические особенности России.</p>
            <p>Мария Львова-Белова рассказала о своем опыте работы с детьми из Сирии, Ирака и Донбасса и
                отметила, что необходимо обращать внимание, например, на уровень полученного детьми
                образования и приложить максимум усилий для социализации и устранения пробелов в
                обучении.</p>
            <p>На круглом столе с приглашенными спикерами и специалистами обсуждались психологические
                особенностей проявления поведенческих реакций у детей, прибывших из зоны боевых действий;
                особенности оказания специализированной психиатрической помощи детям, имеющим опыт
                проживания в зоне боевых действий, интеграции в образовательный процесс детей из семей
                вынужденных переселенцев в Ростовской области..</p>
            <p>Отдельной темой для дискуссии стала социальная адаптация детей, прибывших из зон боевых
                действий в Сирийской Арабской Республике и Республике Ирак в Дагестан, а также вопрос
                оказания психологической помощи в обучении таким детям и подросткам, находящимся в
                шоковом и постшоковом состоянии, затронуты вопросы по оказании психологической работы и
                профилактике экстремизма и радикализации.
            </p>
            <p>В рамках встречи Уполномоченный при Президенте Российской Федерации по правам ребенка
                рассказала о задачах Федерального центра развития программ социализации подростков: это
                поддержка и распространение уже существующих психолого-педагогических практик, поиск и
                разработка новых, создание площадки для общения и обмена опытом экспертов и специалистов
                и, конечно, обучение всех заинтересованных сторон.
                </p>
            <p>Уполномоченный подчеркнула важность сотрудничества с силовыми структурами и органами
                безопасности при решении общих задач, взаимодействия в вопросах выявления и
                предотвращения угроз, оперативной и розыскной деятельности, расследования и профилактики
                правонарушений.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default KSWarZone