import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News0305 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Внесены изменения в состав Правительственной комиссии по делам несовершеннолетних и защите их прав
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В состав Правительственной комиссии по делам несовершеннолетних и защите их прав, утвержденный
                распоряжением Правительства Российской Федерации от 1 октября 2012 г. № 1817-р внесены изменения.
                Соответствующее распоряжение подписал премьер-министр Российской Федерации Михаил Мишустин.</p>
            <p>Согласно документу, заместитель Министра науки и высшего образования Российской Федерации Могилевский
                Константин Ильич заменил в составе комиссии заместителя Министра науки и высшего образования Российской
                Федерации Гатиятова Айрата Ринатовича.</p>
            <p>
                Также в состав Правительственной комиссии по делам несовершеннолетних и защите их прав вошел
                председатель правления Общероссийского общественно-государственного движения детей
                и молодежи «Движение Первых» Гуров Григорий Александрович, сменив в составе комиссии исполнительного
                директора общероссийской общественно-государственной детско-юношеской организации «Российское движение
                школьников» Мокшину Марину Александровну.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News0305;