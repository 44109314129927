import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News20062022Pskov.jpg"

const News20062022Pskov = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            В Пскове с 22 по 25 июня пройдет международный фестиваль особенных людей «Другое искусство»
        </h2>
        <div style={{margin: "0 auto", textAlign: "center"}}>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>С 22 по 25 июня 2022 г. в г. Пскове Центром защиты прав и интересов детей при поддержке Министерства
                просвещения Российской Федерации и администрации Псковской области организован международный фестиваль
                особенных людей «Другое искусство».</p>
            <p>Фестиваль имеет большое значение для привлечения внимания общества к искусству, представляемому людьми с
                ограниченными возможностями здоровья, для понимания обществом значения социокультурной инклюзии.</p>
            <p>Цель фестиваля – включение людей с инвалидностью в жизнь общества с помощью театрального, музыкального,
                изобразительного искусства, а также привлечение внимания государственных структур, общественных и
                коммерческих организаций к решению проблем жизнеустройства людей с инвалидностью в обществе.</p>
            <p>Фестиваль «Другое искусство» проводится в Пскове с 2016 года и станет пятым по счету в 2022 году.
                Программа форума включает пять направлений: театральное, выставочное, музыкальное, образовательное и
                кинематографическое. Участники «Другого искусства» – творческие коллективы, включающие детей и взрослых
                с инвалидностью, деятели искусства, культуры, специального образования из Пскова и Псковской области,
                Санкт-Петербурга, Москвы, Великого Новгорода, Краснодара, Брянска, Иркутска, Орска, а также из
                Республики Беларусь. Ожидается, что в Псковском фестивале примут участие более 180 актеров, художников,
                музыкантов.</p>
            <p>В рамках деловой части фестиваля 23.06.2022 в очно-дистанционном формате состоится круглый стол
                «Образование и социокультурная инклюзия людей с выраженными интеллектуальными нарушениями, с тяжелыми
                множественными нарушениями развития». В обсуждении актуальных вопросов современного образования и
                включения людей с особенностями развития в жизнь общества примут участие родители, представители
                педагогической науки и практики, органов власти, общественных организаций.</p>
            <p>Актуальную информацию и программу фестиваля «Другое искусство» можно найти на официальном сайте: <a
                href="https://differart.com/">https://differart.com/</a>.</p>
        </div>
        <ToNews/>
    </div>
}


export default News20062022Pskov;