import React from "react";
import s from "./AboutUs.module.css"
import graph from '../../assets/grafik1.jpg'
import {useMediaQuery} from "react-responsive";

const AboutUs = () => {
    const isSmall = useMediaQuery({ query: "(max-width: 1200px)" });

    return <>
        <div className={s.newsPage}>
            <div className={s.newHeader}>
                <h2>
                    О нас
                </h2>
                <div className={s.textEnt}>
                    Федеральное государственное бюджетное учреждение «Центр защиты прав и интересов детей» (далее —
                    Центр)
                    является подведомственным учреждением Министерства просвещения Российской Федерации.
                    <br/>
                    Пройдя путь реорганизации и преобразований, Центр на протяжении 20 лет ведет свою деятельность,
                    решая
                    научно-методические, информационные, просветительские задачи в сфере сопровождения детей и
                    подростков в
                    образовательной среде. Также в фокусе внимания — педагогические работники, специалисты в области
                    профилактики, родители (законные представители) несовершеннолетних обучающихся.
                    <br/>
                    Одно из ключевых направлений деятельности Центра — развитие системы профилактики девиантного
                    поведения
                    обучающихся.
                    <br/>
                    Центром осуществляется мониторинг реализации Концепции развития системы профилактики безнадзорности
                    и
                    правонарушений несовершеннолетних в субъектах Российской Федерации. Итогом реализации данной
                    Концепции
                    станет сформированная система дополнительных мер, направленных на выявление и устранение причин и
                    условий
                    противоправного поведения несовершеннолетних.
                    <br/>
                    Сопряженные задачи решаются в ракурсе повышения эффективности деятельности комиссий по делам
                    несовершеннолетних и защите их прав, а также координации межведомственного взаимодействия органов и
                    учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних в сфере защиты
                    детства.
                    <br/>
                    Специалисты Центра оказывают методическую поддержку специальным учебно-воспитательным учреждениям.
                    <br/>
                    Центром осуществляется сопровождение социально-психологического тестирования обучающихся
                    образовательных
                    организаций, которое проводится с 2014 года на всей территории Российской Федерации.
                    <br/>
                    Ежегодно проводится Всероссийский онлайн-опрос молодежи по определению уровня компетенции в области
                    профилактики распространения ВИЧ-инфекции. В 2020 году стартовал Всероссийский онлайн-опрос знаний
                    родителей
                    о профилактике зависимого поведения обучающихся.
                    <br/>
                    Для педагогов, психологов, специалистов в сфере профилактики, родителей и подростков специалистами
                    Центра
                    разработана библиотека методических материалов по вопросам оказания поддержки и помощи в ситуациях
                    рискованного, аутодеструктивного поведения; профилактики раннего вовлечения несовершеннолетних в
                    незаконное
                    потребление наркотических средств и психотропных веществ; формирования ценностей здорового образа
                    жизни
                    и
                    обеспечения психологической безопасности образовательной среды.
                    <br/>
                    Не менее значимое направление деятельности Центра — научно-экспертное исследование вопросов защиты
                    детей
                    от
                    сексуальной эксплуатации и сексуального насилия и методическое обеспечение правового регулирования
                    вопросов
                    защиты прав ребенка в рамках Конвенции ООН о правах ребенка.
                    <br/>
                    Специалисты Центра проводят мониторинговое исследование и оказывают методическую поддержку по
                    вопросам
                    обучения детей с ограниченными возможностями здоровья и инвалидностью.
                    <br/>
                    С целью формирования единой системы работы специалистов психолого-медико-педагогических комиссий
                    (ПМПК)
                    на
                    базе Центра создан Федеральный ресурсный центр ПМПК.
                    <br/>
                    Для руководителей и специалистов ПМПК, а также педагогов и специалистов, оказывающих помощь детям с
                    ограниченными возможностями здоровья в образовательных организациях и центрах
                    психолого-педагогической,
                    медицинской и социальной помощи, в Центре разработаны информационные порталы поддержки.
                    <br/>
                    Созданный в 2019 году на базе Центра Федеральный ресурсный центр в сфере защиты прав детей-сирот и
                    детей,
                    оставшихся без попечения родителей, осуществляет методическое сопровождение органов исполнительной
                    власти
                    регионов, в том числе по вопросам деятельности организаций для детей-сирот.
                    <br/>
                    Центр готовит предложения по совершенствованию законодательства в сфере защиты прав детей; работает
                    с
                    организациями для детей-сирот, школами приемных родителей, службами сопровождения замещающих семей;
                    осуществляет экспертное сопровождение деятельности регионов по вопросам защиты имущественных прав
                    детей-сирот.
                    <br/>
                    С 2022 года один раз в два года проводится Всероссийский конкурс в сфере профессиональной
                    деятельности
                    специалистов органов опеки и попечительства в отношении несовершеннолетних граждан «На стороне
                    ребенка».
                    <br/>
                    Центр проводит Всероссийский конкурс профессионального мастерства «Педагог-психолог России».
                    <p><br/></p>
                </div>
                <div className={s.metHelp}>
                    Оказываем методическую помощь 2443 Территориальным органам опеки и попечительства в России
                </div>
                <div>
                    <img src={graph}/>
                </div>
            </div>
        </div>
    </>
}

export default AboutUs