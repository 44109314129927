import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import img1 from "../../../assets/Behavior/BehaviorPP1.jpg"
import img2 from "../../../assets/Behavior/BehaviorPP2.jpg"
import img3 from "../../../assets/Behavior/BehaviorPP3.jpg"
import img4 from "../../../assets/Behavior/BehaviorPP4.jpg"
import img5 from "../../../assets/Behavior/BehaviorPP5.jpg"
import img6 from "../../../assets/Behavior/BehaviorPP6.jpg"
import img7 from "../../../assets/Behavior/BehaviorPP7.jpg"
import img8 from "../../../assets/Behavior/BehaviorPP8.jpg"
import img9 from "../../../assets/Behavior/BehaviorPP9.jpg"
import img10 from "../../../assets/Behavior/BehaviorPP10.jpg"
import img11 from "../../../assets/Behavior/BehaviorPP11.jpg"
import img12 from "../../../assets/Behavior/BehaviorPP12.jpg"
import img13 from "../../../assets/Behavior/BehaviorPP13.jpg"
import img14 from "../../../assets/Behavior/BehaviorPP14.jpg"
import img15 from "../../../assets/Behavior/BehaviorPP15.jpg"
import img16 from "../../../assets/Behavior/BehaviorPP16.jpg"
import img17 from "../../../assets/Behavior/BehaviorPP17.jpg"
import img18 from "../../../assets/Behavior/BehaviorPP18.jpg"
import img19 from "../../../assets/Behavior/BehaviorPP19.jpg"
import img20 from "../../../assets/Behavior/BehaviorPP20.jpg"
import img21 from "../../../assets/Behavior/BehaviorPP21.jpg"
import img22 from "../../../assets/Behavior/BehaviorPP22.jpg"
import img23 from "../../../assets/Behavior/BehaviorPP23.jpg"
import img24 from "../../../assets/Behavior/BehaviorPP24.jpg"
import img25 from "../../../assets/Behavior/BehaviorPP25.jpg"
import img26 from "../../../assets/Behavior/BehaviorPP26.jpg"
import img27 from "../../../assets/Behavior/BehaviorPP27.jpg"
import img28 from "../../../assets/Behavior/BehaviorPP28.jpg"
import img29 from "../../../assets/Behavior/BehaviorPP29.jpg"
import img30 from "../../../assets/Behavior/BehaviorPP30.jpg"
import img31 from "../../../assets/Behavior/BehaviorPP31.jpg"
import img32 from "../../../assets/Behavior/BehaviorPP32.jpg"
import img33 from "../../../assets/Behavior/BehaviorPP33.jpg"
import img34 from "../../../assets/Behavior/BehaviorPP34.jpg"
import img35 from "../../../assets/Behavior/BehaviorPP35.jpg"
import img36 from "../../../assets/Behavior/BehaviorPP36.jpg"
import img37 from "../../../assets/Behavior/BehaviorPP37.jpg"
import img38 from "../../../assets/Behavior/BehaviorPP38.jpg"
import img39 from "../../../assets/Behavior/BehaviorPP39.jpg"
import img40 from "../../../assets/Behavior/BehaviorPP40.jpg"
import img41 from "../../../assets/Behavior/BehaviorPP41.jpg"
import img42 from "../../../assets/Behavior/BehaviorPP42.jpg"
import img43 from "../../../assets/Behavior/BehaviorPP43.jpg"
import img44 from "../../../assets/Behavior/BehaviorPP45.jpg"
import img45 from "../../../assets/Behavior/BehaviorPP46.jpg"


const BehaviorPP = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.header}>
                <h2 style={{
                    fontWeight: "normal",
                    color: "#3a4663",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "30px",
                    textAlign: "start"
                }}>
                    Психолого-педагогические аспекты формирования ценности здоровья, культуры здорового и безопасного
                    образа жизни в системе образования: Сборник научно-методических материалов
                </h2>
            </div>
            <div style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>Психолого-педагогические аспекты формирования ценности здоровья, культуры здорового и безопасного
                    образа жизни в системе образования: Сборник научно-методических материалов /Выпуск II / Сост. и
                    научн. ред. Н.Ю. Синягина, Е.Г. Артамонова.-М.: АНО «ЦНПРО», 2014.-208с.</p>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Образование личности: стандарты и ценности:
                    Сборник научно-методических материалов международных научно-практических конференций
                    «Теория и практика поликультурного воспитания в образовательной среде»,
                    «Актуальные вопросы социализации личности в современных условиях»
                    / Сост. и науч. ред. Н. Ю. Синягина, Е. Г. Артамонова. — М.: АНО «ЦНПРО», 2015. — 320 с.</strong>
                </p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img1}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img2}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img34}>Аннотация в полный размер...</a>
                        </td>
                        <td>
                            <a href="https://fcprc.ru/media/media/behavior/standarty_soderjanie_2015.pdf">Содержание...</a>
                        </td>
                    </tr>
                </table>
            </div>

            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Психолого-педагогические аспекты формирования ценности здоровья,
                    культуры здорового и безопасного образа жизни в системе образования:
                    Сборник научно-методических материалов /Выпуск II
                    / Сост. и научн. ред. Н. Ю. Синягина, Е. Г. Артамонова.-М.: АНО «ЦНПРО», 2014.-208с.</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img3}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img4}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img35}>Аннотация в полный размер...</a>
                        </td>
                        <td>
                            <a href="https://fcprc.ru/media/media/behavior/Doc1.pdf">Содержание...</a>
                        </td>
                    </tr>
                </table>
            </div>

            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Развитие воспитательного пространства вуза в свете государственных требований
                    к качеству профессионального образования:
                    Сборник научно-методических материалов /Выпуск II
                    / Сост. и научн. ред. Н. Ю. Синягина, Е. Г. Артамонова.-М.: АНО «ЦНПРО», 2014.-208 с.</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img5}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img6}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img36}>Аннотация в полный размер...</a>
                        </td>
                        <td>
                            <a href="https://fcprc.ru/media/media/behavior/Doc2.pdf">Содержание...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Дети и лето: отдых, здоровье, развитие. Сборник научно-методических материалов и
                    нормативно-правовых документов
                    / Под ред. В. А. Березиной. — М.: АНО «ЦНПРО», 2013. — 336 с.</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img7}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img8}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img37}>Аннотация в полный размер...</a>
                        </td>
                        <td>
                            <a href="https://fcprc.ru/media/media/behavior/detiileto_soderzanie.pdf">Содержание...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Психологические аспекты детского суицида: технологии профилактики.
                    Сборник материалов международной практической конференции.
                    (Электронное издание)</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img9}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img10}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img38}>Аннотация в полный размер...</a>
                        </td>
                        <td>
                            <a href="https://fcprc.ru/media/media/behavior/Sbornik_materialov.pdf">Читать...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Сборник научно-методических материалов
                    Психолого-педагогические основания формирования ценности здоровья,
                    культуры здорового и безопасного образа жизни
                    в системе образования.</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img11}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img12}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img39}>Аннотация в полный размер...</a>
                        </td>
                        <td>
                            <a href="https://fcprc.ru/media/media/behavior/1_2013_zdorovie.doc">Содержание
                                сборника...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Сборник научно-методических материалов
                    Развитие воспитательного пространства вуза в свете новых требований
                    к качеству профессионального образования.</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img13}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img14}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img40}>Аннотация в полный размер...</a>
                        </td>
                        <td>
                            <a href="https://fcprc.ru/media/media/behavior/1_2013_zdorovie.doc">Содержание
                                сборника...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>О Родине, о подвигах, о славе: Сборник работ — победителей Всероссийского конкурса
                    курсовых, дипломных работ, связанных с героическим прошлым России, важнейшими событиями в жизни
                    народа.</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img15}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img16}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img41}>Аннотация в полный размер...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>II Cборник научно-методических материалов Психолого-педагогические
                    и социальные проблемы гуманизации пространства детства</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img17}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img18}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img42}>Аннотация в полный размер...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Сборник научно-методических материалов
                    Проблемы развития личности и формирования здорового образа жизни: ценностно-мотивационный
                    подход</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img19}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img20}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img43}>Аннотация в полный размер...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{fontSize: "17px", color: "#212529", textAlign: "center"}} className={s.concept}>
                <p><strong>Сборник научно-методических материалов
                    «Толерантность и духовность в российском обществе: возможности системы образования».</strong></p>
            </div>
            <div className={s.concept}>
                <table>
                    <tr>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img21}/>
                        </th>
                        <th style={{width: "30vw", maxWidth: "370px"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img22}/>
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <a href={img44}>Аннотация в полный размер...</a>
                        </td>
                    </tr>
                </table>
            </div>
            <p></p>
            <div className={s.concept}>
                <table style={{width: "60vw", maxWidth: "750px"}}>
                    <tr>
                        <th style={{height: "30px"}}>
                            Сборник научно-методических материалов
                            «Актуальные проблемы образования и воспитания:
                            профессиональное мастерство и индивидуальный стиль современного специалиста».
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>В сборнике, посвященном 10-летию Центра, рассматриваются проблемы воспитания, развития,
                                социально-педагогической поддержки и духовно-нравственного образования детей и молодежи,
                                вопросы реализации содержания общего и дополнительного образования.</p>
                            <p>Сборник может представлять интерес для социальных работников, психологов, педагогов, а
                                также аспирантов и студентов старших курсов, соответствующих специальностей, работников
                                системы дополнительного образования и специалистов управления образованием.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img23}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Сборник Международной научно-практической заочной конференции
                            «Актуальные вопросы современного образования»»
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>В сборнике представлены материалы Международной научно-практической заочной конференции
                                «Актуальные вопросы современного образования», состоявшейся 23 апреля 2010 г.
                                Конференция посвящена обсуждению важнейших проблем современного образования, среди
                                которых психолого-акмеологический подход в образовании, формирование и реализация
                                современных методов управления образовательными системами, использование инновационных
                                технологий профессиональной подготовки, актуальные проблемы лингвистики, различные
                                аспекты работы с молодежью.</p>
                            <p>Сборник адресован руководителям и специалистам в области основного общего, среднего и
                                высшего профессионального образования, специалистам в области инновационного менеджмента
                                и управления человеческими ресурсами, аспирантам, студентам, преподавателям.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img24}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Сборник Всероссийской научно-практической конференции
                            «Опыт работы с одаренными детьми в современной России»
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>Опыт работы с одаренными детьми в современной России. Сборник материалов Всероссийской
                                научно-практической конференции / Науч. ред. Н. Ю. Синягина, Н. В. Зайцева. — М.:
                                Арманов-центр, 2010. — 312 с.</p>
                            <p>В сборнике материалов Всероссийской научно-практической конференции «Опыт работы с
                                одаренными детьми в современной России» приведены материалы выступлений ученых и
                                практиков по проблеме, отражены пути их решения.</p>
                            <p>Сборник адресован психологам, педагогам, воспитателям, работникам системы
                                дополнительного образования и специалистам органов, осуществляющих управление в сфере
                                образования.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img25}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Сборник «Актуальные проблемы образования и воспитания:
                            ценностно-мотивационный подход»
                            2009 год.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>Актуальные проблемы образования и воспитания: ценностно-мотивационный подход: Сборник
                                научно-методических материалов/ Сост. и науч.ред. Н. Ю. Синягина, А. Г. Болелова. — М.:
                                Арманов-центр, 2009. — 224 с.</p>
                            <p>В сборнике научно-методических материалов «Актуальные проблемы образования и воспитания:
                                ценностно-мотивационный подход» 2009 года отражены новейшие разработки в области
                                сохранения и укрепления здоровья, работы с одаренными детьми, формирования
                                конструктивных взаимоотношений в молодежной среде, рассмотрены актуальные вопросы
                                воспитания и дополнительного образования детей и молодежи, пути духовной,
                                психологической, социальной поддержки.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img26}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Инновационные технологии социально-педагогической поддержки
                            и духовно-нравственного образования детей и молодежи
                            2008 г.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>По материалам Международной научно-практической конференции «Эффективные инновационные
                                технологии и опыт работы с „трудными“ подростками» / Сост. и науч. ред. Н. Ю. Синягина,
                                А. Г, Болелова. — М.: Арманов-центр, 2008. — 224 с.</p>
                            <p>В сборнике рассматриваются проблемы воспитания, развития, социально-педагогической
                                поддержки и духовно-нравственного образования детей и молодежи, анализируются
                                особенности современной подростковой среды, обосновываются направления работы с
                                «трудными» подростками, в том числе продуктивные способы решения выделенных проблем
                                средствами образования.</p>
                            <p>Сборник может представлять интерес для социальных работников, психологов, педагогов, а
                                также аспирантов и студентов старших курсов, соответствующих специальностей. Работников
                                системы дополнительного образования и специалистов управления образования.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img27}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Актуальные проблемы образования и воспитания
                            2008 г.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>Сборник материалов научно-практической конференции «Опыт работы с одаренными детьми в
                                современной России» / Науч. ред. Н. Ю. Синягина; Сост. Н. В. Зайцева, Я. О. Венцлавская.
                                М.: Арманов-центр, 2008. — 160 с.</p>
                            <p>В сборнике приведены материалы выступлений ученых и практиков по обсуждению проблемы,
                                отражены пути их решения.</p>
                            <p>Адресован психологам, педагогам, воспитателям, работникам системы дополнительного
                                образования и специалистам органов, осуществляющих управление в сфере образования.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img28}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Опыт воспитания: педагогические странствия по регионам России
                            2008 г.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>Опыт воспитания: педагогические странствия по регионам России. — М.:
                                Образовательно-издательский «Арманов — центр», 2008. — 208 с.</p>
                            <p>Книга посвящена 90-летию системы дополнительного образования детей в России, в ней
                                собран опыт воспитания в образовательных учреждениях различных регионов Российской
                                Федерации.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img29}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            О Родине, о подвигах, о славе…
                            Сборник работ победителей всероссийских конкурсов
                            2008 год.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>В сборнике представлены работы победителей Всероссийских конкурсов курсовых, дипломных
                                работ, связанных с героическим прошлым России, важнейшими событиями в жизни народа, и
                                научно-исследовательских и творческих работ молодых ученых «Меня оценят в XXI веке»</p>
                            <p>Работы представлены в сокращенном варианте, с сохранением авторской редакции.</p>

                            <p>Книга предназначена для педагогов, организаторов патриотического воспитания в
                                образовательных учреждениях, руководителей патриотических объединений, работников
                                системы дополнительного образования и специалистов управления образования.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img30}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            О Родине, о подвигах, о славе…
                            Сборник работ победителей всероссийских конкурсов
                            2007 год.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>В сборнике представлены работы победителей Всероссийских конкурсов курсовых, дипломных
                                работ, связанных с героическим прошлым России, важнейшими событиями в жизни народа, и
                                научно-исследовательских и творческих работ молодых ученых «Меня оценят в XXI веке»</p>
                            <p>Работы представлены в сокращенном варианте, с сохранением авторской редакции.</p>

                            <p>Книга предназначена для педагогов, организаторов патриотического воспитания в
                                образовательных учреждениях, руководителей патриотических объединений, работников
                                системы дополнительного образования и специалистов управления образованием.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img31}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Актуальные проблемы образования и воспитания, 2007 г.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>В сборнике материалов научно-практической конференции «Опыт работы с одаренными детьми в
                                современной России» учеными и специалистами анализируются наиболее актуальные проблемы
                                образования и воспитания.</p>
                            <p>Адресован психологам, педагогам, воспитателям, работникам системы дополнительного
                                образования и специалистам управления образованием.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img32}/>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th style={{height: "30px"}}>
                            Проблемы развития личности и формирования здорового образа жизни, 2006 г.
                        </th>
                    </tr>
                    <tr style={{height: "30px"}}>
                        <td>
                            <p>В сборнике научно-методических материалов учеными и специалистами анализируются наиболее
                                актуальные проблемы развития личности и формирования здорового образа жизни.</p>
                            <p>Адресован психологам, педагогам, воспитателям, работникам системы дополнительного
                                образования и специалистам управления образования.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "30vw", maxWidth: "370px", margin: "0 auto", textAlign: "center"}}>
                            <img style={{width: "29vw", maxWidth: "360px"}} src={img33}/>
                        </td>
                    </tr>
                </table>
                <p></p>
            </div>
        </div>

        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet, to
                     }) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default BehaviorPP