import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News01082022.jpg"

const News01082022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вниманию специалистов! Аналитический доклад «Изучение мотивов проявления девиантного поведения обучающимися,
            причин, условий, способствующих формированию такого поведения, а также мер, принимаемых по предупреждению и
            профилактике девиантного поведения обучающихся»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>ФГБУ «Центр защиты прав и интересов детей» в рамках реализации государственного задания на 2022 год и в
                соответствии с поручением Министра просвещения Российской Федерации С.С. Кравцова, при координации
                Департамента государственной политики в сфере защиты прав детей Минпросвещения России проведено изучение
                мотивов проявления девиантного поведения обучающимися, причин, условий, способствующих формированию
                такого поведения, а также мер, принимаемых по предупреждению и профилактике девиантного поведения
                обучающихся, с учетом актуальных тенденций, рисков и вызовов современных реалий.</p>
            <p>Ключевым результатом проведенного изучения мотивов, причин и условий девиаций среди обучающихся стало
                формирование Перечня эффективных мер, предлагаемых в целях предупреждения и профилактики девиантного
                поведения несовершеннолетних.</p>
            <p>Материалы исследования, представленные в аналитическом докладе, адресованы специалистам сферы
                профилактики, педагогическим работникам и могут быть полезны при разработке воспитательных программ и
                планов профилактической работы в образовательных организациях.</p>
            <p><a
                href="https://frcopeca.ru/wp-content/uploads/2022/08/analiticheskij-doklad_motivy-i-prichiny-deviacij_2022.pdf">АНАЛИТИЧЕСКИЙ
                ДОКЛАД Мотивы и причины девиации 2022</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News01082022;