import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News031123.jpg"

const News031123 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Поздравляем всех с Днем народного единства!
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В этот день мы отмечаем важное историческое событие – объединение наших предков, которое заложило основу для
            единения нашей многонациональной и многокультурной страны.
            <p></p>
            Великая Россия всегда славилась разнообразием и богатством культур. Несмотря на различия, мы находим
            общность в наших идеалах, ценностях и чувстве принадлежности к одной большой, прекрасной стране.
            <p></p>
            День народного единства напоминает нам о важности взаимного уважения и сотрудничества между разными
            национальностями и этническими группами, о стойкости нашего гражданского духа и стремлении к мирной жизни и
            процветанию.
            <p></p>
            Празднование этого дня объединяет нас, скрепляет еще прочнее, позволяет смотреть в будущее с надеждой и
            уверенностью. Вместе мы можем преодолеть любые трудности и совершить большие дела.
            <p></p>
            Желаем всем крепкого здоровья, счастья и благополучия.
            И пусть наши сердца всегда полнятся любовью к нашей прекрасной Родине и гордостью за ее достижения.
            <p></p>
            С Днем народного единства, друзья!<br/>
            Давайте сохранять эту неослабевающую нить, связующую нас всех!
            <p></p>
            С уважением,<br/>
            Директор ФГБУ «Центр защиты прав и интересов детей»<br/>
            Гусев Б.Б.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News031123;