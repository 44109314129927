export const ReestrUpdMediacia = [
    [
        [
            {
                col1: "Центральный федеральный округ",
                col2: "Белгородская область",
                col3: "1",
                col4: "ФГАОУ ВО «Белгородский государственный национальный исследовательский университет»",
                col5: "308015, г. Белгород, ул. Победы, 85",
                col6: "1. Программа профессиональной переподготовки «Медиация. Особенности применения медиации» (312 ч.) 2. Программа профессиональной переподготовки «Юрист-медиатор» (272 ч.);	3. Программа повышения квалификации «Медиация. Курс подготовки тренеров медиаторов» (144 ч.)4. Программа повышения квалификации «Анализ и разрешение семейных конфликтов в процедуре медиации» (72 ч.)5. Программа повышения квалификации «Медиация. Базовый курс» (128 ч.)6. Программа повышения квалификации «Медиация в судебной деятельности» (72 ч.)7. Программа повышения квалификации «Медиация в образовательном процессе» (72 ч.)8. Программа повышения квалификации «Медиация, как альтернативный способ разрешения споров» (36 ч.)9. Программа повышения квалификации «Документационное и нормативно-правовое обеспечение медиативной деятельности» (36 ч.)10. Программа повышения квалификации «Школьная медиация» (72 ч.)11. Программа повышения квалификации «Семейная медиация» (72 ч.)12. Программа повышения квалификации «Восстановительная медиация» (72 ч.)",
                col7: "https:\/\/bsuedu.ru\/bsu, http:\/\/uf.bsu.edu.ru\/index.php\/str\/department\/mediation",
                col8: "8 (4722) 30-14-75",
                col9: "cm@bsu.edu.ru rubanov@bsu.edu.ru cmipt@mail.ru",
            },
        ],
        [
            {
                col1: "Центральный федеральный округ",
                col2: "Воронежская область",
                col3: 1,
                col4: "ФГБОУ ВО «Воронежский государственный университет», Центр правовых инноваций и примирительных процедур",
                col5: "394018, г. Воронеж, Площадь Университетская, д.1",
                col6: "1. Программа повышения квалификации «Медиация. Базовый курс» (120 ч.) 2. Программа повышения квалификации «Медиация. Особенности применения медиации» (312 ч.) 3. Программа повышения квалификации «Медиация. Курс подготовки тренеров медиаторов» (144 ч.)",
                col7: "http:\/\/www.vsu.ru, http:\/\/mediation.vsu.ru\/training-for-mediators",
                col8: "8(473) 220-89-24 8-920-450-29-99 8 (473) 255-04-81",
                col9: "center.vsu@mail.ru lazebnaya@pdi.vsu.ru",

            },],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Ивановская область",
                col3: "1",
                col4: "ОГАУ ДПО ИО «Университет непрерывного образования и инноваций»",
                col5: "152022, г. Иваново, ул. Б. Воробьевская, д.80",
                col6: "Программа повышения квалификации «Проектирование школьной службы примирения: выбор модели, методическое сопровождение» (36 ч.)",
                col7: "https:\/\/unoi.ru",
                col8: "8 (4932) 38-62-77",
                col9: "office_unoi@ivreg.ru",

            },
        ],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Калужская область",
                col3: "1",
                col4: "ГАОУ ДПО «Калужской области Калужский государственный институт развития образования»",
                col5: "248000 г. Калуга, ул. Гагарина, д.1, строение 1",
                col6: "Программа повышения квалификации «Школьные службы примирения» (72 ч.)",
                col7: "http:\/\/kgiro.kalugaedu.ru",
                col8: "8(4842)22-61-52",
                col9: "kgimo_psluzhba@mail.ru",
            },
        ],
        [


            {
                col1: "Центральный федеральный округ",
                col2: "Костромская область",
                col3: "1",
                col4: "ОГБОУ ДПО «Костромской областной институт развития образования»",
                col5: "156005, г. Кострома, ул. Сусанина Ивана, д. 52",
                col6: "Программа повышения квалификации «Восстановительные технологии в образовательном процессе» (72 ч.)",
                col7: "http:\/\/eduportal44.ru",
                col8: "8 (4942) 31-77-91",
                col9: "koiro.kostroma@yandex.ru",

            },
        ],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Курская область",
                col3: "1",
                col4: "ОГБУ ДПО «Курский институт развития образования»",
                col5: "305004, г. Курск, ул. Садовая, 31",
                col6: "Программа повышения квалификации «Медиация как высокоэффективный инструмент урегулирования школьных конфликтов»",
                col7: "http:\/\/new.kiro46.ru",
                col8: "8 (4712) 70-78-02",
                col9: "kiro-46@mail.ru",

            },
        ],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Липецкая область",
                col3: "1",
                col4: "ФГБОУ ВО «Елецкий государственный университет им. И.А. Бунина»",
                col5: "399770 г. Елец, ул. Коммунаров, д. 28",
                col6: "Программа профессиональной переподготовки «Медиация: базовый курс»",
                col7: "https:\/\/elsu.ru",
                col8: "8 (4746) 72-04-63",
                col9: "main@elsu.ru",
            },
            {
                col1: "Центральный федеральный округ",
                col2: "Липецкая область",
                col3: "2",
                col4: "ГАУ ДПО «Институт развития образования Липецкой области»",
                col5: "398043, г. Липецк, ул. Циолковского, д.18",
                col6: "Программа повышения квалификации «Организация службы примирения в восстановительном подходе»",
                col7: "https:\/\/iom48.ru",
                col8: "8 (4742) 32-94-60",
                col9: "rector_gaudpo@admlr.lipetsk.ru",

            },
        ],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Москва",
                col3: "1",
                col4: "ФГБУ «Центр защиты прав и интересов детей»",
                col5: "115093, г. Москва, ул. Люсиновская, д. 51",
                col6: "Программа профессиональной переподготовки «Медиативно-восстановительные технологии в образовательной организации» (36 ч.)",
                col7: "https:\/\/dpo.fcprc.ru",
                col8: "8 (499) 444-08-06, доб. 100 77",
                col9: "dpo@fcprcgov.ru",

            },
            {
                col1: "Центральный федеральный округ ",
                col2: "Москва",
                col3: "2",
                col4: "ФГБОУ ВО «Московский государственный психолого-педагогический университет»",
                col5: "123290 г. Москва, Шелепихинская набережная, д. 2А, стр. 2, каб. 402 ",
                col6: "1. Программа повышения квалификации «Медиация в системе образования» (36 ч.) 2. Программа профессиональной переподготовки «Медиация: базовый курс» (256 ч.) 3. Магистерская программа «Восстановительный подход и медиативные технологии в образовании и системе профилактики социальных рисков»",
                col7: "https:\/\/dpo.mgppu.ru, https:\/\/mgppu.ru",
                col8: "8 (495) 607-16-87 8 (499) 259-47-51 8 (499) 256-10-27 ",
                col9: "kurs@dpo.mgppu.ru",
            },

            {
                col1: "Центральный федеральный округ",
                col2: "Москва",
                col3: "3",
                col4: "ФГБОУ ВО «Московский государственный юридический университет имени О.Е. Кутафина (МГЮА)»",
                col5: "125993 г. Москва, ул. Садовая-Кудринская, д. 9",
                col6: "1. Программа повышения квалификации «Медиация. Базовый курс» (120 ч.) 2. Программа повышения квалификации «Медиация. Курс подготовки тренеров медиаторов» (105 ч.)",
                col7: "https:\/\/msal.ru",
                col8: "8 (499) 244-85-13",
                col9: "msal@msal.ru",
            },

            {
                col1: "Центральный федеральный округ",
                col2: "Москва",
                col3: "4",
                col4: "ГБУ «Городской психолого-педагогический центр Департамента образования и науки города Москвы»",
                col5: "109443, город Москва, Есенинский бульвар, д. 12, корп. 2",
                col6: "Программа повышения квалификации «Деятельность специалиста службы примирения в общеобразовательной организации»",
                col7: "https:\/\/gppc.ru, https:\/\/forum.gppc.ru",
                col8: "8 (499) 172-10-19",
                col9: "gppc@edu.mos.ru",

            },
            {
                col1: "Центральный федеральный округ",
                col2: "Москва",
                col3: "5",
                col4: "ФГБОУ ВО «Государственный академический университет гуманитарных наук»",
                col5: "119049 г. Москва, пер. Мароновский, д.26",
                col6: "Дополнительная программа профессиональной переподготовки «Медиация. Основы»",
                col7: "https:\/\/gaugn.ru",
                col8: "8 (499) 238-02-10; 8 (965) 340-77-69",
                col9: "dpo@gaugn.ru"
            },

            {
                col1: "Центральный федеральный округ",
                col2: "Москва",
                col3: "6",
                col4: "ФГАОУ ВО «Российский университет дружбы народов имени Патриса Лумумбы»",
                col5: "117198, г. Москва, ул. Миклухо-Маклая, д. 6",
                col6: "Программа профессиональной переподготовки «Программа подготовки медиаторов» (580 ч.)",
                col7: "www.rudn.ru",
                col8: "8 (499) 936-87-87",
                col9: "rudn@rudn.ru",

            },

            {
                col1: "Центральный федеральный округ",
                col2: "Москва",
                col3: "7",
                col4: "ГАУ города Москвы «Институт дополнительного профессионального образования работников социальной сферы»",
                col5: "105066, г. Москва, 1-й Басманный переулок, д. 10",
                col6: "1. Программа профессиональной переподготовки «Медиация в социальной сфере» (256 ч.) 2. Программа повышения квалификации «Медиация в социальной сфере. Супервизия» (36 ч.)",
                col7: "https:\/\/soc-education.ru",
                col8: "8 (495) 607-50-65 8 (495) 607-05-91",
                col9: "ipk@mos.ru"
            },
        ],
        [
            {
                col1: "Центральный федеральный округ",
                col2: "Московская область",
                col3: "1",
                col4: "ГАОУ ДПО «Корпоративный университет развития образования Московской области»",
                col5: "141006, Московская область, г. Мытищи, ул. Индустриальная, д. 13",
                col6: "Реализация дополнительных профессиональных программ повышения квалификации и профессиональной переподготовки по основам медиации для руководящих и педагогических работников образовательных организаций Московской области",
                col7: "https:\/\/dpo.asou-mo.rukuro",
                col8: "8 (499)189-12-76",
                col9: "mo_asou@mosreg.ru",

            },
        ],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Рязанская область",
                col3: "1",
                col4: "ОГБУ ДПО «Рязанский институт развития образования»",
                col5: "390023 г. Рязань, ул. Урицкого, д. 2а",
                col6: "1. Программа повышения квалификации «Использование технологий медиации руководителями образовательных организаций» 2. Программа повышения квалификации «Использование технологии медиации педагогами-психологами» 3. Программа профессиональной переподготовки «Медиация (урегулирование споров с помощью процедуры медиации)»",
                col7: "https:\/\/rirorzn.ru",
                col8: "8 (4912) 44-63-92 8 (4912) 44-49-79",
                col9: "rirorzn@yandex.ru"
            },

            {
                col1: "Центральный федеральный округ",
                col2: "Рязанская область",
                col3: "2",
                col4: "АНО альтернативного разрешения споров «Рязанский центр медиации и права»",
                col5: "390005 г. Рязань, ул. 1-я Железнодорож-ная, д. 60А пом. Н1",
                col6: "Программа профессиональной переподготовки «Профессиональная медиация» (256 ч.)",
                col7: "http:\/\/httpwww.mediatorrzn.tilda.ws",
                col8: "8 (4912) 96-01-32",
                col9: "mediator-rzn@mail.ru",

            },
            {
                col1: "Центральный федеральный округ",
                col2: "Рязанская область",
                col3: "3",
                col4: "АНО центр психолого-педагогической и социальной помощи «РЕСУРС»",
                col5: "390023, г. Рязань ул. Новая, д. 53Б",
                col6: "Программа повышения квалификации «Медиация в образовании»",
                col7: "http:\/\/anocsppsp-resurs.tilda.ws\/#contacts",
                col8: "8 (915) 623-95-33",
                col9: "cppspresurs@yandex.ru",

            },
        ],
        [
            {
                col1: "Центральный федеральный округ",
                col2: "Смоленская область",
                col3: "1",
                col4: "ГАУ ДПО «Смоленский областной институт развития образования»",
                col5: "214000, г. Смоленск, ул. Октябрьской революции, д. 20А",
                col6: "Программа повышения квалификации «Медиация в образовательной организации» (72 ч.)",
                col7: "http:\/\/www.dpo-smolensk.ru",
                col8: "8 (4812)  38-21-57",
                col9: "iro67ru@yandex.ru",

            },
        ],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Тамбовская область",
                col3: "1",
                col4: "ТОГОАУ ДПО «Институт повышения квалификации работников образования»",
                col5: "392000, г.Тамбов, ул.Советская, 108",
                col6: "Программа повышения квалификации «Организация содержания деятельности школьных служб примирения»  (72 ч.)",
                col7: "https:\/\/ipk.68edu.ru",
                col8: "8 (4752) 63-41-26",
                col9: "toipkro@obraz.tambov.gov.ru",

            },
        ], [

        {
            col1: "Центральный федеральный округ",
            col2: "Тверская область",
            col3: "1",
            col4: "ФГБОУ ВО «Тверской государственный университет»",
            col5: "170100, г. Тверь, ул. Желябова, д.33",
            col6: "Программа повышения квалификации «Основы бизнес-медиации»",
            col7: "https:\/\/tversu.ru",
            col8: "8 (4822) 34-24-52",
            col9: "rector@tversu.ru",

        },
        {
            col1: "Центральный федеральный округ",
            col2: "Тверская область",
            col3: "2",
            col4: "Тверской филиал ФГБОУ ВО «Российская академия народного хозяйства и государственной службы при Президенте Российской Федерации»",
            col5: "170008 г. Тверь, ул. Вагжанова, д.7",
            col6: "Программа профессиональной переподготовки «Медиация в социальной сфере»",
            col7: "http:\/\/tver.ranepa.ru",
            col8: "8 (4822) 41-50-17 8 (4822) 41-50-28",
            col9: "lapushkina-ee@ranepa.ru"
        },
    ],
        [


            {
                col1: "Центральный федеральный округ",
                col2: "Тульская область",
                col3: "1",
                col4: "ГОУ ДПО «Институт повышения квалификации и профессиональной переподготовки работников образования Тульской области»",
                col5: "300041, г. Тула, ул. Ленина, д. 22",
                col6: "Программа повышения квалификации «Организация медиативной службы в современной образовательной организации»",
                col7: "http:\/\/ipk-tula.ru",
                col8: "8 (4872) 56-73-62",
                col9: "gou.ipk@tularegion.ru",

            },
        ],
        [

            {
                col1: "Центральный федеральный округ",
                col2: "Ярославская область",
                col3: "1",
                col4: "ГАУ ДПО «Ярославской области Институт развития образования»",
                col5: "150014, г. Ярославль, ул. Богдановича, 16",
                col6: "Программа повышения квалификации «Медиация: конструктор восстановительных программ» (56 ч.)",
                col7: "http:\/\/www.iro.yar.ru",
                col8: "8 (4852) 23-08-14, 23-06-53",
                col9: "ulanova@iro.yar.ru",

            }
        ]
    ],
    [
        [
            {
                col1: "Северо-Западный федеральный округ",
                col2: "Архангельская область",
                col3: "1",
                col4: "ГБКУ Архангельской области общего типа «Котласский центр социальной помощи семье и детям Маяк»",
                col5: "165300, г. Котлас, ул. Маяковского, д. 36 Б",
                col6: "Программа повышения квалификации «Основы медиативного подхода в разрешении семейных споров. Базовый курс»",
                col7: "https:\/\/mayak-kotlas.nubex.rucontact",
                col8: "(81837)  5-40-70",
                col9: "kotlsrcn@yandex.ru",

            },
            {
                col1: "Северо-Западный федеральный округ",
                col2: "Архангельская область",
                col3: "2",
                col4: "ГБУ Архангельской области для детей, нуждающихся в психолого-педагогической и медико-социальной помощи, «Центр психолого-медико-социального сопровождения «Надежда»",
                col5: "163069, г. Архангельск, ул. Попова, д. 43",
                col6: "1. Программа повышения квалификации «Восстановительная медиация по делам о преступлениях и правонарушениях несовершеннолетних, о совершении насилия в отношении детей в семьях»; 2. Программа повышения квалификации «Восстановительные технологии для педагогов школьных служб примирения»; 3. Программа повышения квалификации «Семейные конференции»; 4. Программа повышения квалификации «Программа подготовки тренеров восстановительной медиации»; 5. Программа повышения квалификации «Обучение тренеров семейных конференций»",
                col7: "https:\/\/nadejdaarh.ru/",
                col8: "(8182) 20-18-37",
                col9: "centr_nadejda@mail.ru",
            },
        ], [
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Вологодская область",
            col3: "1",
            col4: "ФГБОУ ВО «Вологодский государственный университет»",
            col5: "160000, г. Вологда, ул. Ленина, д. 15",
            col6: "Программа повышения квалификации «Применение медиативных технологий в деятельности организаций социального обслуживания» (36 ч.)",
            col7: "https:\/\/vogu35.ru",
            col8: "8 (8172) 72-46-45",
            col9: "kanz@vogu35.ru",

        },
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Вологодская область",
            col3: "2",
            col4: "АОУ ДПО «Вологодский институт развития образования»",
            col5: "160011, Вологодская область, г. Вологда, ул. Козленская д. 57",
            col6: "Программа повышения квалификации «Восстановительные технологии (в том числе медиация) в образовании»",
            col7: "https:\/\/viro.edu.ru",
            col8: "(8172) 75-84-00",
            col9: "viro@viro.edu.ru",

        },
    ], [

        {
            col1: "Северо-Западный федеральный округ",
            col2: "Калининградская область",
            col3: "1",
            col4: "МАУ города Калининграда «Учебно-методический образовательный центр»",
            col5: "236006, г. Калининград, ул. Менделеева, д. 29",
            col6: "1. Программа повышения квалификации «Основы медиации»; 2. Программа повышения квалификации «Медиация. Особенности применения медиации в образовательной организации»",
            col7: "https:\/\/umocklgd.ru",
            col8: "8 (4012) 928272",
            col9: "metod_otd@edu.klgd.ru"
        },
    ], [
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Ленинградская область",
            col3: "1",
            col4: "ГАОУ ДПО «Ленинградский областной институт развития образования»",
            col5: "197136, г. Санкт-Петербург, Чкаловский пр., 25а лит. А.",
            col6: "Программа повышения квалификации «Служба медиации в образовательной организации» (72 ч.)",
            col7: "https:\/\/www.loiro.ru",
            col8: "8 (812) 372-50-39",
            col9: "office@loiro.ru",

        },
    ], [
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Новгородская область",
            col3: "1",
            col4: "ФГБОУ ВО «Новгородский государственный университет имени Ярослава Мудрого»",
            col5: "173003, г. Великий Новгород, ул. Большая Санкт-Петербургская, д. 41",
            col6: "Программа повышения квалификации «Медиация: базовый курс»",
            col7: "https:\/\/www.novsu.ru",
            col8: "8 (8162) 62-72-44, 8 (8162) 97-42-46",
            col9: "novsu@novsu.ru"
        },
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Новгородская область",
            col3: "2",
            col4: "ГБУ ДПО «Региональный институт профессионального развития»",
            col5: "173001, Великий Новгород, ул. Новолучанская, д. 27",
            col6: "Программа повышения квалификации «Современные методы регулирования конфликтных ситуаций в образовательных организациях посредством процедуры медиации»",
            col7: "https:\/\/dpo53.ru",
            col8: "8 (8162) 77-14-63",
            col9: "mail@dpo53.ru"
        }
    ], [
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Псковская область",
            col3: "1",
            col4: "ГБОУ ДПО «Псковский областной институт повышения квалификации работников образования»",
            col5: "180000, г. Псков, ул. Гоголя, д. 14",
            col6: "Программа повышения квалификации «Деятельность службы школьной медиации»",
            col7: "http:\/\/poipkro.pskovedu.ru",
            col8: "8(8112)66-27-93",
            col9: "org1069@pskovedu.ru",

        },
    ], [
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Республика Карелия",
            col3: "1",
            col4: "ГАУ ДПО «Карельский институт развития образования»",
            col5: "185005, г. Петрозаводск, ул. Правды, д. 31",
            col6: "Программа повышения квалификации «Применение медиативной технологии в образовательных организациях» (36 ч.)",
            col7: "https:\/\/kiro-karelia.ru",
            col8: "8 (8142) 57-40-90, 8 (981) 400-18-30",
            col9: "rector@kiro-karelia.ru"
        },
    ], [
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Республика Коми",
            col3: "1",
            col4: "ФГБОУ ВО «Сыктывкарский государственный университет имени Питирима Сорокина»",
            col5: "167001, г. Сыктывкар, Октябрьский пр-т, д. 55",
            col6: "Программа повышения квалификации «Медиация. Базовый курс»",
            col7: "https:\/\/syktsu.ru\/edu\/additional-education\/sign-up",
            col8: "8 (8212) 390-311",
            col9: "dpp.ino@syktsu.ru",

        },
    ], [
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Санкт-Петербург",
            col3: "1",
            col4: "Санкт-Петербургское ГБУ «Городской центр социальных программ и профилактики асоциальных явлений среди молодежи «КОНТАКТ»",
            col5: "196070, г. Санкт-Петербург, Фрунзе ул., д.4",
            col6: "Программа профессиональной переподготовки «Медиация. Базовый курс» (252 ч.)",
            col7: "https:\/\/profcenter.spb.ru\/медиация",
            col8: "8 (812) 747-29-51, 8 (953) 363-70-42",
            col9: "mediation-kontakt@mail.ru"
        },
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Санкт-Петербург",
            col3: "2",
            col4: "ГБУ ДПО «Санкт-Петербургская академия постдипломного педагогического образования»",
            col5: "191002, г. Санкт-Петербург, ул. Ломоносова, дом 11–13",
            col6: "1. Программа повышения квалификации «Развитие социального интеллекта учащихся и медиация в образовании» (108 ч.); 2. Программа повышения квалификации «Организация деятельности школьных служб медиации» (36 ч.)",
            col7: "https:\/\/spbappo.ru",
            col8: "8 (812) 409-82-82",
            col9: "appo@obr.gov.spb.ru",
        },
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Санкт-Петербург",
            col3: "3",
            col4: "ГАОУ ВО «Санкт-Петербургский государственный институт психологии и социальной работы»",
            col5: "199178, г. Санкт-Петербург, 12-я линия В.О., д. 13, лит. А",
            col6: "Программа профессиональной переподготовки «Профессиональная медиация» (560 ч.)",
            col7: "https:\/\/psysocwork.ru\/obrazovanie\/dopolnitelnoe-obrazovanie\/programmy-professionalnoj-perepodgotovki\/professionalnaya-mediaciya",
            col8: "8 (812) 321-25-31",
            col9: "fdogipsr@yandex.ru",

        },
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Санкт-Петербург",
            col3: "4",
            col4: "ФГБОУ ВО «Санкт-Петербургский государственный университет»",
            col5: "199034, г. Санкт-Петербург, Университетская наб., д. 7–9",
            col6: "Программа профессиональной переподготовки «Медиатор общего профиля» (260 ч.)",
            col7: "http:\/\/www.conflictology.spb.ru\/%D0%A8%D0%BA%D0%BE%D0%BB%D0%B0_%D0%BC%D0%B5%D0%B4%D0%B8%D0%B0%D1%82%D0%BE%D1%80%D0%BE%D0%B2_0",
            col8: "8 (812) 328-9771",
            col9: "arsenieva-maria@mail.ru",

        },
        {
            col1: "Северо-Западный федеральный округ",
            col2: "Санкт-Петербург",
            col3: "5",
            col4: "Высшая экономическая школа ФГБОУ ВО «Санкт-Петербургский государственный экономический университет»",
            col5: "191023, Санкт-Петербург, набережная канала Грибоедова, 34, к. 235, 237",
            col6: "Программа повышения квалификации «Медиация. Базовый курс» (120 ч.)",
            col7: "http:\/\/www.hes.spb.ru\/programs\/refreshers.php?iid=2440&parent=237",
            col8: "8 (812) 339-04-41, 8 (812) 312-87-37, 8 (921) 983-25-92",
            col9: "consult@hes.spb.ru",
        },
    ],],
    [[
        {
            col1: "Южный федеральный округ",
            col2: "Астраханская область",
            col3: "1",
            col4: "ФГБОУ ВО «Астраханский государственный университет им. В.Н. Татищева»",
            col5: "414056, г. Астрахань, ул. Татищева, д. 20а",
            col6: "1. Программа повышения квалификации «Медиация. Стратегии общения и способы урегулирования конфликтов в образовательной организации»; 2. Программа повышения квалификации «Управление конфликтами в образовании»\"",
            col7: "https:\/\/asu.edu.ru",
            col8: "8 (8512) 24-64-00",
            col9: "asu@asu.edu.ru",
        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Волгоградская область",
            col3: "1",
            col4: "ГАОУ ДПО «Волгоградская государственная Академия последипломного образования»",
            col5: "400012, г. Волгоград, ул. Новодвинская, д.19а,",
            col6: "Программа повышения квалификации «Организация школьных служб примирения и восстановительной медиации»",
            col7: "https:\/\/vgapkro.rustruktura-akademiitsentrynmc-psihologo-pedagogicheskogo-soprovozhdeniya",
            col8: "8 (8442) 606-622",
            col9: "respsi@mail.ru",

        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Краснодарский край",
            col3: "1",
            col4: "ГБОУ ДПО «Институт развития образования Краснодарского края»",
            col5: "350080, г. Краснодар, ул. Сормовская, д. 167",
            col6: "Программа повышения квалификации «Медиация и восстановительные технологии в практике профилактики и урегулирования конфликтов между участниками образовательных отношений»",
            col7: "https\/\/:iro23.ru",
            col8: "8 (861)203-53-01",
            col9: "post@iro23.ru",

        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Республика Адыгея",
            col3: "1",
            col4: "ФГБОУ ВО «Майкопский государственный технологический университет»",
            col5: "385000, г. Майкоп, ул. Первомайская, д. 191",
            col6: "Программа повышения квалификации «Медиация межнациональной и межконфессиональной сферы»",
            col7: "https:\/\/mkgtu.ru",
            col8: "8 (8772) 52-31-31",
            col9: "info@mkgtu.ru",

        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Ростовская область",
            col3: "1",
            col4: "ФГАОУ ВО «Южный федеральный университет»",
            col5: "344006, г. Ростов-на-Дону, ул. Большая Садовая, д. 105/42",
            col6: "Программа повышения квалификации «Основы медиации»",
            col7: "https:\/\/sfedu.ru, https:\/\/isir.sfedu.ru, https:\/\/sfedu.ru\/www\/stat_pages22.show?p=EDU\/N14548\/D",
            col8: "8 (919) 886-13-35",
            col9: "samartirosyan@sfedu.ru",
        },
        {
            col1: "Южный федеральный округ",
            col2: "Ростовская область",
            col3: "2",
            col4: "Ростовский филиал ФГБОУ ВО «Российский государственный университет правосудия»",
            col5: "117418, г. Москва, ул. Новочермушкинская, 69; фактический адрес: 344038, г. Ростов-на-Дону, пр. Ленина, 66",
            col6: "1. Программа повышения квалификации «Медиация. Базовый курс»; 2. Профессиональная переподготовка «Специалист в сфере медиации»; 3. Программа повышения квалификации «Основы школьной медиации»",
            col7: "https:\/\/rb.rgup.ru\/\/?modpagesid1085",
            col8: "8 (8632) 97-57-16",
            col9: "mediaciarostov@mail.ru",
        },
        {
            col1: "Южный федеральный округ",
            col2: "Ростовская область",
            col3: "3",
            col4: "ГБУ ДПО РО «Ростовский институт повышения квалификации и профессиональной переподготовки работников образования»",
            col5: "344011, г. Ростов-на-Дону, пер. Гвардейский, 2 / 51",
            col6: "Программа повышения квалификации «Стратегии и техники медиации в образовательной среде» (16 ч.)",
            col7: "https:\/\/www.ripkro.ru",
            col8: "8 (863)307-51-65",
            col9: "institut@roipkpro.ru",

        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Севастополь",
            col3: "1",
            col4: "ФГАОУ ВО «Севастопольский государственный университет»",
            col5: "200053, г. Севастополь, ул. Университетская, д.33",
            col6: "1. Программа повышения квалификации «Технологии медиации как средства ресоциализации в условиях социальной неопределённости» (72 ч.); 2. Программа повышения квалификации «Медиация как средство в работе с девиациями в подростковом возрасте» (144 ч.)",
            col7: "https:\/\/www.sevsu.ru",
            col8: "8 (8692) 435-002, 8 (978) 822-95-93",
            col9: "Info@sevsu.ru",
        },
        {
            col1: "Южный федеральный округ",
            col2: "Севастополь",
            col3: "2",
            col4: "ГАОУ ДПО города Севастополя «Институт развития образования»",
            col5: "299011, г. Севастополь, ул. Советская, д. 54",
            col6: "1. Программа повышения квалификации «Медиация и восстановительные технологии в практике профилактики и урегулирования конфликтов между участниками образовательных отношений» (72 ч.)",
            col7: ",https:\/\/www.sev-iro.ru",
            col8: "8 (8692)54-32-70, 8 (8692)54-39-90",
            col9: "iro_sevdpo@bk.ru",
        },
    ],],
    [
        [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Кабардино-Балкарская Республика",
                col3: "1",
                col4: "ГБУ ДПО «Центр непрерывного повышения профессионального мастерства педагогических работников Минпросвещения КБР»",
                col5: "360005, г. Нальчик, ул. Ленина, д. 8",
                col6: "Программа повышения квалификации «Организации работы службы медиациив образовательной организации»",
                col7: "https:\/\/cnppm.ru",
                col8: "(8662) 77-15-07",
                col9: "rcdo_kbr@mail.ru",

            },
        ], [
        {
            col1: "Северо-Кавказский федеральный округ",
            col2: "Карачаево-Черкесская Республика",
            col3: "1",
            col4: "РГБУ ДПО «Карачаево-Черкесский республиканский институт повышения квалификации работников образования»",
            col5: "369000, Карачаево-Черкесская Республика,г.Черкесск, ул. Фабричная, д.139",
            col6: "Программа повышения квалификации «Школьная медиация»",
            col7: "http:\/\/povyshenie09.ru",
            col8: "(8782) 20-78-97",
            col9: "kchripkro@mail.ru",

        },
    ], [
        {
            col1: "Северо-Кавказский федеральный округ",
            col2: "Республика Дагестан",
            col3: "1",
            col4: "ГБУ ДПО «Дагестанский институт развития образования»",
            col5: "367027,  г. Махачкала, ул.  Генерала Магомедтагирова (Казбекова), д. 159",
            col6: "Курсы повышения квалификации «Восстановительные технологии (в том числе и медиация) в разрешении конфликтных ситуаций в образовательной среде» (72 ч.)",
            col7: "https:\/\/xn--d1aish.xn--p1ai",
            col8: "8 (8722) 64-60-65",
            col9: "dipkpkrd@mail.ru",

        },
    ], [
        {
            col1: "Северо-Кавказский федеральный округ",
            col2: "Республика Северная Осетия (Алания)",
            col3: "1",
            col4: "ГБОУ ДПО «Северо-Осетинский республиканский институт повышения квалификации работников образования»",
            col5: "362040, г. Владикавказ, ул.Кирова 46; ул.Ленина 69",
            col6: "1. Программа повышения квалификации «Медиация в дошкольной образовательной организации: задачи, нормативно-правовая база, основные направления деятельности»; 2. Программа повышения квалификации «Школьная медиация. Базовый курс»\"",
            col7: "http:\/\/www.soripkro.ru",
            col8: "(867-2) 53-62-38",
            col9: "soripkro@mail.ru",
        },
    ],
        [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Ставропольский край",
                col3: "1",
                col4: "ФГАОУ ВО «Северо-Кавказский федеральный университет»",
                col5: "355017, г. Ставрополь, ул. Пушкина, 1",
                col6: "1. Программа повышения квалификации «Медиация в образовательной организации»; 2. Программа повышения квалификации «Школьные службы примирения»",
                col7: "https:\/\/ppf.ncfu.ru, https:\/\/www.ncfu.ru/glavnaya",
                col8: "8 (8652) 94-37-30, 8 (8652) 94 41 29",
                col9: "gkozlovskaia@ncfu.ru",
            }
        ],
        [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Чеченская Республика",
                col3: "1",
                col4: "ГБУ ДПО «Институт развития образования Чеченской Республики»",
                col5: "366007, г. Грозный, ул. Лермонтова, 2",
                col6: "Программа повышения квалификации «Формирование медиативных навыков педагогических работников образовательных организаций» (72 ч.)",
                col7: "https:\/\/govzalla.ru",
                col8: "8 (928) 087-73-99, 8 (8712) 21-22-24",
                col9: "sector21@bk.ru"
            }],],
    [
        [
            {
                col1: "Приволжский федеральный округ",
                col2: "Кировская область",
                col3: "1",
                col4: "ФГБОУ ВО «Вятский государственный университет»",
                col5: "610000, г. Киров, ул. Московская, д. 36",
                col6: "Программа повышения квалификации «Медиация в образовании» (16 ч.)",
                col7: "https:\/\/www.vyatsu.ru\/nash-universitet\/obrazovatelnaya-deyatel-nost\/dopolnitel-noe-professional-noe-obrazovanie\/five\/mediatsiya-v-obrazovanii-16-chasov.html",
                col8: "+7 (8332) 742-579",
                col9: "vv_utemov@vyatsu.ru",

            },
            {
                col1: "Приволжский федеральный округ",
                col2: "Кировская область",
                col3: "2",
                col4: "ОГОАУ ДПО «Институт развития образования Кировской области»",
                col5: "610046, г. Киров, ул. Романа Ердякова, д 232",
                col6: "Программа повышения квалификации «Восстановительная медиация в образовательной организации: стратегии развития и практика применения»",
                col7: "https:\/\/kirovipk.ru",
                col8: "(8332) 25-54-42",
                col9: "kirovipk@kirovipk.ru",

            },
        ], [
        {
            col1: "Приволжский федеральный округ",
            col2: "Нижегородская область",
            col3: "1",
            col4: "ФГБОУ ВО «Нижегородский государственный лингвистический университет им. Н.А. Добролюбова»",
            col5: "603155, г. Нижний Новгород, ул. Минина, д. 31а",
            col6: "Программа повышения квалификации «Медиация и доверительная коммуникация»",
            col7: "https:\/\/lunn.ru",
            col8: "8 (831) 436-15-75",
            col9: "admdep@lunn.ru",
        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Нижегородская область",
            col3: "2",
            col4: "ГБОУ ДПО «Нижегородский институт развития образования»",
            col5: "603122, г. Нижний Новгород, ул. Ванеева, д. 203",
            col6: "Программа повышения квалификации «Школьный медиатор Программа повышения квалификации» (72 ч.)",
            col7: "http:\/\/www.niro.nnov.ru",
            col8: "8 (831) 417-75-49",
            col9: "secr@niro.nnov.ru",

        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Нижегородская область",
            col3: "3",
            col4: "ФГАОУ ВО «Национальный исследовательский Нижегородский государственный университет им. Н.И. Лобачевского»",
            col5: "603022, г. Нижний Новгород, пр. Гагарина, 23",
            col6: "1. Программа профессиональной переподготовки «Медиация. Базовый курс» (252 ч.), 2. Программа повышения квалификации «Медиация в образовательном пространстве. Расширенный курс» (120 ч.), 3. Программа повышения квалификации «Школьная медиация. Базовый курс» (108 ч.), 4. Программа повышения квалификации «Медиация в профессиональной деятельности педагогических работников» (72 ч.); 5. Программа повышения квалификации «Медиация в образовательном пространстве» (36 ч.)",
            col7: "http:\/\/www.unn.ru/site, http:\/\/www.unn.ru",
            col8: "8 (831) 462-30-85, 8 (831) 462-38-74",
            col9: "unn@unn.ru, irinapetrova@fsn.unn.ru\"",
        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Нижегородская область",
            col3: "4",
            col4: "ФГБОУ ВО «Нижегородский государственный педагогический университет имени Козьмы Минина»",
            col5: "603005, г. Нижний Новгород, ул. Ульянова,1",
            col6: "Программа повышения квалификации «Служба школьной медиации в воспитательном пространстве школы» (144 ч.)",
            col7: "https:\/\/mininuniver.ru",
            col8: "8(831) 262-20-44",
            col9: "mininuniver@mininuniver.ru",

        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Нижегородская область",
            col3: "5",
            col4: "АНО Нижегородской области «Социальный центр юридической и психологической помощи «Нижегородка»",
            col5: "603000, г. Нижний Новгород, ул. Б.Покровская, 60, офис 31",
            col6: "1. Программа повышения квалификации «Урегулирование конфликта между родителями и детьми»; 2. Программа повышения квалификации «Подготовка профессиональных медиаторов»",
            col7: "https:\/\/helpnizhegorodka.ru",
            col8: "8 (831) 423-78-22",
            col9: "lider.povolzhenn@mail.ru"
        },
    ], [
        {
            col1: "Приволжский федеральный округ",
            col2: "Оренбургская область",
            col3: "1",
            col4: "АНО ДПО «ИКТ «Статус»",
            col5: "460006, г. Оренбург, ул. Пролетарская, д. 86",
            col6: "1. Программа профессиональной переподготовки «Медиация. Базовый курс» (260 ч.); 2. Программа повышения квалификации «Служба школьной медиации» (72 ч.); 3. Программа повышения квалификации «Профилактика педагогического насилия в образовательной среде (организация служб примирения)» (72 ч.)",
            col7: "https:\/\/orenstatus.ru",
            col8: "8 (897) 847-73-25, 8 (3532) 43-01-43",
            col9: "orenstatus-ofis@mail.ru",
        },
    ], [
        {
            col1: "Приволжский федеральный округ",
            col2: "Пермский край",
            col3: "1",
            col4: "ФГБОУ ВО «Пермский государственный национальный исследовательский университет»",
            col5: "614068, Пермский край, г. Пермь, ул. Генкеля, 5а",
            col6: "1. Программа повышения квалификации «Восстановительный подход в разрешении конфликтных и криминальных ситуаций в работе с несовершеннолетними и их семьями»; 2. Программа повышения квалификации «Медиация. Базовый курс»",
            col7: "https:\/\/rice.psu.ru",
            col8: "8 (342) 239-68-98",
            col9: "rice@psu.ru",
        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Пермский край",
            col3: "2",
            col4: "ФГАОУ ВО «Национальный исследовательский университет «Высшая школа экономики»",
            col5: "614070,  г. Пермь, ул. Студенческая, д. 38",
            col6: "Программа повышения квалификации «Медиация. Управление конфликтами»",
            col7: "https:\/\/perm.hse.ru",
            col8: "8 (342) 205-52-50",
            col9: "infoperm@hse.ru",

        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Пермский край",
            col3: "3",
            col4: "АНО ДПО «Высшая школа психологии «Пси-Профи»",
            col5: "614039, г. Пермь, ул. Швецова, д. 39, оф. 218",
            col6: "Программа повышения квалификации «Медиация и управление переговорами»",
            col7: "https:\/\/psy-profi.com",
            col8: "8  (952) 335-44-88",
            col9: "School@psy-profi.com",

        },
    ], [
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Башкортостан",
            col3: "1",
            col4: "ФГБОУ ВО «Уфимский университет науки и технологий»",
            col5: "450076, г. Уфа, ул. Заки Валиди, д. 32",
            col6: "Программа повышения квалификации «Медиация как альтернативный способ урегулирования споров» (36 ч.).",
            col7: "https:\/\/www.uust.ru",
            col8: "8 (347) 272-63-70",
            col9: "rector@uust.ru",

        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Башкортостан",
            col3: "2",
            col4: "ФГБОУ ВО «Башкирский государственный педагогический университет имени М. Акмуллы»",
            col5: "450008, г. Уфа, ул. Октябрьской революции, 3-а",
            col6: "1. Программа повышения квалификации «Семейная медиация» (146 ч.), 2. Программа профессиональной переподготовки «Медиатор социальных конфликтов» (256 ч.), 3. Программа повышения квалификации «Семейный медиатор» (72 ч.), 4. Программа повышения квалификации «Базовый курс медиации» (120 ч.)",
            col7: "https:\/\/bspu.ru\/news\/4123?ysclid=lp8aini3ys259527864",
            col8: "8 (347) 287-99-91, 8 (917) 453-25-35",
            col9: "office@bspu.ru",
        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Башкортостан",
            col3: "3",
            col4: "Центр медиации при некоммерческом партнерстве «Ассоциация благотворительных организаций Республики Башкортостан»",
            col5: "450104, г Уфа, ул. Российская, д. 43/13",
            col6: "1. Программа профессиональной переподготовки «Медиатор социальных конфликтов» (256 ч.), 2. Программа повышения квалификации «Медиатор в образовательной организации» (72 ч.), 3. Программа повышения квалификации «Семейный медиатор» (72 ч.), 4. Программа повышения квалификации «Базовый курс медиации» (72 ч.)",
            col7: "http:\/\/project3174845.tilda.ws, https:\/\/www.facebook.com\/blagolubim.ural\/?ref=page_internal",
            col8: "8 (987) 025-94-04, 8 (917) 35-04-113",
            col9: "kurssonko20@gmail.com",

        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Башкортостан",
            col3: "4",
            col4: "Центр медиации при АНО ДО «ФБС»",
            col5: "450059, г Уфа, проспект Октября, д 33",
            col6: "1. Программа профессиональной переподготовки «Медиатор социальных конфликтов» (256 ч.), 2. Программа повышения квалификации «Медиатор в образовательной организации» (72 ч.), 3. Программа повышения квалификации «Семейный медиатор» (72 ч.), 4. Программа повышения квалификации «Базовый курс медиации» (72 ч.)",
            col7: "http:\/\/fbs-rb.ru",
            col8: "8 (347) 292-72-99, 8 (917) 430-32-33",
            col9: "fbs@ufamail.ru",
        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Башкортостан",
            col3: "5",
            col4: "Башкирский институт социальных технологий (филиал) ОУП ВО «Академия труда и социальных отношений»",
            col5: "450054, г. Уфа, проспект Октября, д. 74/2",
            col6: "Программа повышения квалификации «Медиация (базовый курс)»",
            col7: "https:\/\/ufabist.ru",
            col8: "8 (987) 142-05-15",
            col9: "neli21@inbox.ru",

        },
    ], [
        {
            col1: "Приволжский федеральный округ,",
            col2: "Республика Мордовия",
            col3: "1",
            col4: "ГБУ ДПО Республики Мордовия «Центр непрерывного повышения профессионального мастерства педагогических работников - «Педагог13.ру»",
            col5: "430027, г.Саранск, ул.Транспортная. д.19",
            col6: "1. Программа повышения квалификации «Медиация: курс подготовки медиаторов для образовательных организаций» (72 ч.); 2. Программа повышения квалификации «Медиация в образовательной организации: решаем конфликты по-новому» (36 ч.).\"",
            col7: "https:\/\/pedagog13.edurm.ru",
            col8: "8 (8342) 32-17-35",
            col9: "pedagog13@e-mordovia.ru",
        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Мордовия",
            col3: "2",
            col4: "ФГБОУ ВО «Мордовский государственный педагогический университет им. М.Е.Евсевьева»",
            col5: "430007, г.Саранск, ул.Студенческая, 11А",
            col6: "Программа повышения квалификации «Психологические основы медиации» (72 ч.)",
            col7: "http:\/\/www.mordgpi.ru",
            col8: "8(8342)33-92-98",
            col9: "yashkovaan@mail.ru",

        },
    ], [
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Татарстан",
            col3: "1",
            col4: "ФГАОУ ВО «Казанский (Приволжский) федеральный университет»",
            col5: "420008, г. Казань, ул. Кремлевская, д. 18.",
            col6: "Программа повышения квалификаци «Медиация. Базовый курс» (120 ч.)",
            col7: "https:\/\/kpfu.ru",
            col8: "8 (843) 233-79-05",
            col9: "Ekaterina.Snarskaja@kpfu.ru",

        },
        {
            col1: "Приволжский федеральный округ",
            col2: "Республика Татарстан",
            col3: "2",
            col4: "ГАУ ДПО «Институт развития образования Республики Татарстан»",
            col5: "420015, г.Казань, ул. Большая Красная, д.68",
            col6: "Программа повышения квалификаци «Медиация в системе образования: организация, нормативно-правовое обеспечение» (72 ч.).",
            col7: "http:\/\/irort.ru",
            col8: "8 (843) 236-62-42",
            col9: "irort@irort.ru",

        }
    ],],
    [
        [
            {
                col1: "Уральский федеральный округ",
                col2: "Свердловская область",
                col3: "1",
                col4: "ФГБОУ ВО «Уральский государственный юридический университет имени В.Ф. Яковлева»",
                col5: "620066, г. Екатеринбург, ул. Комсомольская, д. 21",
                col6: "1. Программа повышения квалификаци «Медиация. Базовый курс», 2. Программа повышения квалификаци «Особенности применения медиации по наследственным спорам», 3. Программа повышения квалификаци «Особенности применения медиации по семейным спорам»",
                col7: "https:\/\/usla.ru",
                col8: "8 (343) 375-15-36",
                col9: "mediacia@usla.ru",
            },
            {
                col1: "Уральский федеральный округ",
                col2: "Свердловская область",
                col3: "2",
                col4: "АНО ДПО «Уральская академия медиаций и комплексной безопасности»",
                col5: "620043, г. Екатеринбург, ул. Репина, д. 101, кв. 16",
                col6: "1. Программа профессиональной переподготовки «Роль специалиста в области медиативных технологий (медиатора) и возможности медиативных технологий в деятельности образовательных организаций и организаций социальной сферы в современных условиях» (250 ч.), 2. Программа повышения квалификации «Роль тренера-медиатора как координатора развития медиативных технологий в образовательных организациях и организациях социальной сферы» (72 ч.), 3. Программа повышения квалификации «Медиация как основа деятельности школьных служб примирения» (24 ч.)",
                col7: "https:\/\/uramkb.ru",
                col8: "8 (912) 244-01-60",
                col9: "uramkb@mail.ru",
            },
            {
                col1: "Уральский федеральный округ",
                col2: "Свердловская область",
                col3: "3",
                col4: "ГБУ Свердловской области «Центр психолого -  педагогическоймедицинской и социальной помощи «Ладо»",
                col5: "623380, г. Полевской, м-н Черемушки, д. 24",
                col6: "1. Программа повышения квалификации «Организационное и нормативно-правовое обеспечение создания школьных служб примирения» (16 часов), 2. Программа повышения квалификации «Школьные службы примирения» (72 часа)",
                col7: "https:\/\/centerlado.ru",
                col8: "8 (34350) 5-77-87",
                col9: "centerlado@yandex.ru"
            },
        ], [

        {
            col1: "Уральский федеральный округ",
            col2: "Тюменская область",
            col3: "1",
            col4: "ГАОУ ДПО «Тюменский областной государственный институт развития регионального образования»",
            col5: "625000, г. Тюмень, ул. Советская, д. 56",
            col6: "1. Программа повышения квалификации «Медиация. Базовый курс» (72 ч., 140 ч.), 2. Программа повышения квалификации «Школьная медиация, как способ формирования бесконфликтной среды в образовательной организации» (36 ч., 72 ч.), 3. Программа повышения квалификации «Применение медиативного подхода и медиативных технологий в работе педагога» (36 ч.)\"",
            col7: "https:\/\/togirro.ru",
            col8: "8 (3452) 58-20-36",
            col9: "info@togirro.ru"
        },
        {
            col1: "Уральский федеральный округ",
            col2: "Тюменская область",
            col3: "2",
            col4: "АНО «Западно-Сибирский региональный Центр медиации и права» ",
            col5: "625062, Тюменская область, г. Тюмень, ул. Федюнинского, д. 19, кв. 72 ",
            col6: "1. Программа повышения квалификации «Медиация. Базовый курс» (120 ч.); 2. Программа профессиональной переподготовки «Медиатор социальных конфликтов» (260 ч.).",
            col7: "http:\/\/zsrcmp.ru",
            col8: "8 (3452) 36-03-66",
            col9: "centrmediacii72@mail.ru"
        }
    ], [
        {
            col1: "Уральский федеральный округ",
            col2: "Ханты-Мансийский автономный округ - Югра",
            col3: "1",
            col4: "АНО ДПО Ханты-Мансийского автономного округа Югры «Институт развития образования»",
            col5: "628011, г. Ханты-Мансийск, ул. Чехова, д. 12, стр. А",
            col6: "Программа повышения квалификации «Организация и содержание деятельности образовательной организации по урегулированию конфликтов в рамках школьной службы примирения (медиации)»",
            col7: "https:\/\/iro86.ru",
            col8: "8 (3467) 388-336",
            col9: "iro@iro86.ru"
        },
        {
            col1: "Уральский федеральный округ",
            col2: "Ханты-Мансийский автономный округ - Югра",
            col3: "2",
            col4: "ФГБОУ ВО «Нижневартовский государственный университет»",
            col5: "628605, г. Нижневартовск, ул. Ленина, 56",
            col6: "Программа повышения квалификации «Школьная служба примирения и медиативная культура педагога» (72 ч.)",
            col7: "https:\/\/nvsu.ru",
            col8: "8 (3466) 44-39-50",
            col9: "nvsu@nvsu.ru"
        },
    ], [
        {
            col1: "Уральский федеральный округ",
            col2: "Челябинская область",
            col3: "1",
            col4: "МБУ ДПО «Центр развития образования города Челябинска» на базе Ресурсного центра медиации г.Челябинска МБУ ЦППМСП Калининского района г. Челябинска",
            col5: "454078, г. Челябинск, ул.Барбюса, д. 65а  (МБУ ДПО ЦРО); 454084, г.Челябинск, ул. Калинина, д. 17 (Ресурсный центр медиации г.Челябинска)",
            col6: "1. Программа повышения квалификации «Медиативный подход при разрешении конфликтов в образовательной среде»(72 ч.), 2. Программа повышения квалификации «Медиативный подход при разрешении конфликтов в образовательной среде для руководителейобразовательных организаций» (36 ч.), 3. Программа повышения квалификации «Нарративная медиация при работе с несовершеннолетними, склонными к девиантному поведению» (36 ч.), 4. Программа повышения квалификации «Школьные службы примирения» (72 ч., 36 ч.",
            col7: "https:\/\/cro.chel-edu.ru, http:\/\/rcm74.ru",
            col8: "8 (351) 700-10-20, 700-10-30 МБУ ДПО ЦРО, 8 (351) 232-05-32 Ресурсный центр медиации г.Челябинска\"",
            col9: "mail@cro74.ru",
        },
        {
            col1: "Уральский федеральный округ",
            col2: "Челябинская область",
            col3: "2",
            col4: "ФГБОУ ВО «Южно-Уральский государственный гуманитарно-педагогический университет»",
            col5: "454080, г. Челябинск, Ленина, 69",
            col6: "1. Программа повышения квалификации «Медиативные технологии в системе образования» (72 ч.), 2. Программа профессиональной переподготовки «Педагогическая конфликтология и медиативные технологии» (300-500 ч.)",
            col7: "https:\/\/www.cspu.ru",
            col8: "8 (902) 602-63-68",
            col9: "sokolovana-2011@mail.ru",
        },
        {
            col1: "Уральский федеральный округ",
            col2: "Челябинская область",
            col3: "3",
            col4: "ГБПОУ «Челябинский педагогический колледж №2»",
            col5: "454081, г. Челябинск,  ул. Горького 79",
            col6: "Программа повышения квалификации «Медиация в сфере образования» (72 ч.)",
            col7: "https:\/\/www.чпк2.рф",
            col8: "8 (351) 772-19-26 доб.113  8 908 061 36 78",
            col9: "cgpk-2@mail.ru",

        },
    ],],
    [
        [
            {
                col1: "Сибирский федеральный округ",
                col2: "Алтайский край",
                col3: "1",
                col4: "ФГБОУ ВО «Алтайский государственный университет»",
                col5: "656049, г. Барнаул, пр-т Ленина, 61",
                col6: "1. Программа повышения квалификации «Школьная медиация: базовый курс подготовки школьного медиатора», 2. Программа повышения квалификации «Специалист по медиации и проведению деловых переговоров», 3. Программа повышения квалификации «Медиация. Посредничество в конфликте»",
                col7: "https:\/\/www.asu.ru",
                col8: "8 (3852) 291-291",
                col9: "rector@asu.ru",
            },
            {
                col1: "Сибирский федеральный округ",
                col2: "Алтайский край",
                col3: "2",
                col4: "КАУ ДПО «Алтайский институт развития образования им А.М. Топорова»",
                col5: "656049, г. Барнаул, пр-т Социалистический, д. 60",
                col6: "Программа повышения квалификации «Организация деятельности школьной службы примирения в штабе воспитательной работы школы» (24 ч.)",
                col7: "https:\/\/www.iro22.ru",
                col8: "8 (3852) 55-58-87",
                col9: "info@iro22.ru",

            },
        ], [
        {
            col1: "Сибирский федеральный округ",
            col2: "Иркутская область",
            col3: "1",
            col4: "ФГБОУ ВО «Иркутский государственный университет»",
            col5: "664003, г. Иркутск, ул. Карла Маркса, д. 1",
            col6: "1. Программа повышения квалификации «Медиация в образования», 2. Программа профессиональной переподготовки «Медиация в профессиональной деятельности»",
            col7: "https:\/\/isu.ru\/ru\/index.html",
            col8: "8 (395-2) 201-891, 8 (395-2) 521-939",
            col9: "paromonova@isu.ru"
        },
        {
            col1: "Сибирский федеральный округ",
            col2: "Иркутская область",
            col3: "2",
            col4: "ГАУ ДПО «Институт развития образования Иркутской области»",
            col5: "664007, г. Иркутск, ул. Красноказачья, 10 «а»",
            col6: "1. Программа повышения квалификации «Медиативные технологии как ресурс профилактики суицидального поведения несовершеннолетних», 72ак. ч., 2. Программа повышения квалификации «Организация деятельности службы школьной медиации», 48 ак. ч.",
            col7: "http:\/\/www.iro38.ru",
            col8: "+7 (3952) 500-904",
            col9: "info@iro38.ru"
        },
    ], [
        {
            col1: "Сибирский федеральный округ",
            col2: "Кемеровская область",
            col3: "1",
            col4: "ФГБОУ ВО «Сибирский государственный индустриальный университет»",
            col5: "654007,г. Новокузнецк, Центральный р-н, ул. Кирова, здание 42.",
            col6: "Программа повышения квалификации «Медиатор в сфере образования»",
            col7: "https:\/\/fdpo.sibsiu.ru",
            col8: "8 (3843) 74-94-90",
            col9: "msv7@list.ru",

        },
        {
            col1: "Сибирский федеральный округ",
            col2: "Кемеровская область",
            col3: "2",
            col4: "ГОО «Кузбасский региональный центр психолого-педагогической, медицинской и социальной помощи Здоровье и развитие личности»",
            col5: "650023, г. Кемерово, пр. Ленина, д. 126 А",
            col6: "Программа повышения квалификации «Восстановительные технологии и службы примирения в системе образования  профилактики»",
            col7: "https:\/\/kuzrc.ru",
            col8: "8 (3842) 77-06-46",
            col9: "info@kuzrc.ru",

        },
    ], [
        {
            col1: "Сибирский федеральный округ",
            col2: "Красноярский край",
            col3: "1",
            col4: "ФГАОУ ВО «Сибирский федеральный университет»",
            col5: "660041, г. Красноярск, пр. Свободный, д. 79",
            col6: "1. Программа повышения квалификации «Практики социокультурной медиации в образовании» (24 ч.), 2. Программа повышения квалификации «Медиативная культура педагога» (36 ч.), 3. Программа повышения квалификации «Теоретические и практические аспекты восстановительных медиативных технологий в образовательных организациях» (72 ч.), 4. Программа повышения квалификации «Медиация в решении конфликтов с участием несовершеннолетних. Эффективная работа службы медиации (примирения) в образовательной организации» (72 ч.), 5. Программа повышения квалификации «Актуальные вопросы внедрения института медиации в систему работы с несовершеннолетними» (18 ч.), 6. Программа повышения квалификации «Медиация. Базовый курс» (144 ч.)»",
            col7: "https:\/\/www.sfu-kras.ru",
            col8: "8 (391) 206-22-22",
            col9: "office@sfu-kras.ru"
        },
        {
            col1: "Сибирский федеральный округ",
            col2: "Красноярский край",
            col3: "2",
            col4: "КГАУ ДПО «Красноярский краевой институт повышения квалификации и профессиональной переподготовки работников образования»",
            col5: "660079, г. Красноярск, ул.Александра Матросова, д. 19",
            col6: "Программа повышения квалификации «Медиация. Особенности применения медиации в образовательной организации»",
            col7: "https:\/\/kipk.ru",
            col8: "8 (963) 955-03-72",
            col9: "yadryshnikova@kipk.ru",

        },
    ], [
        {
            col1: "Сибирский федеральный округ",
            col2: "Новосибирская область",
            col3: "1",
            col4: "ГАУ ДПО «Новосибирский институт повышения квалификации и переподготовки работников образования»",
            col5: "630007, г. Новосибирск, Красный проспект, д. 2",
            col6: "Программа повышения квалификации «Медиация, как альтернативная процедура разрешения конфликта в образовательной организации»",
            col7: "https:\/\/nipkipro.ru",
            col8: "8 (383) 223 78 81",
            col9: "gau@edu54.ru",

        },
    ], [
        {
            col1: "Сибирский федеральный округ",
            col2: "Республика Тыва",
            col3: "1",
            col4: "ГБУ «Республиканский Центр психолого-медико-социального сопровождения Сайзырал»",
            col5: "667001, г. Кызыл, ул. Рабочая, д.  56",
            col6: "Программа повышения квалификации «Организация службы примирения в восстановительном подходе»",
            col7: "https:\/\/saizyral.rtyva.ru",
            col8: "8 (394-22) 5-33-20",
            col9: "rzpmss@yandex.ru",

        },
    ], [
        {
            col1: "Сибирский федеральный округ",
            col2: "Республика Хакасия",
            col3: "1",
            col4: "ГАОУ РХ ДПО «Хакасский институт развития образования и повышения квалификации»",
            col5: "655017, г. Абакан, ул. Пушкина, д. 105",
            col6: "Программа повышения квалификации «Функционирование школьной службы примирения: проблемы и пути решения» (16 ч.)",
            col7: "https:\/\/ipk19.ru",
            col8: "8 (3902) 24-14-60",
            col9: "xakiroipk19@r-19.ru",
        },
    ]], [
        [
            {
                col1: "Дальневосточный федеральный округ",
                col2: "Республика Саха (Якутия)",
                col3: "1",
                col4: "ГБУ ДПО «Республиканский методический центр психолого-педагогического сопровождения»",
                col5: "677000, г. Якутск,  ул. Ломоносова 35, корп. 5",
                col6: "Программа повышения квалификации «Деятельность школьных служб примирениямедиации в системе образования Республики Саха (Якутия)»",
                col7: "https:\/\/14rcpmss.obr.sakha.gov.ru",
                col8: "8 (4112) 507-110",
                col9: "rdrmc@mail.ru",

            },
        ], [
            {
                col1: "Дальневосточный федеральный округ",
                col2: "Сахалинская область",
                col3: "1",
                col4: "Сахалинский институт железнодорожного транспорта филиал ФГБОУ ВО «Дальневосточный государственный университет путей сообщения» в г. Южно-Сахалинске",
                col5: "693007, г. Южно-Сахалинск, ул. Физкультурная, д. 126 В",
                col6: "Программа профессиональной переподготовки «Специалист в области медиации»",
                col7: "http:\/\/www.dvgups-sakh.ru",
                col8: "8 (4242) 72-96-70\", факс: 8 (4242) 72-96-70",
                col9: "dvgups-sakh@mail.ru"
            },
        ], [
            {
                col1: "Дальневосточный федеральный округ",
                col2: "Хабаровский край",
                col3: "1",
                col4: "ФГБОУ ВО «Российский государственный университет правосудия»",
                col5: "680014, г. Хабаровск, Восточное шоссе, д. 49",
                col6: "1. Программа профессиональной переподготовки «Медиация в судебной деятельности: правовые и психологические аспекты» (256 ч.), 2. Программа профессиональной переподготовки «Медиация. Особенности применения медиации в образовательных организациях» (256 ч.)",
                col7: "https:\/\/rgup.ru",
                col8: "8 (4212) 97-01-25",
                col9: "dvfrap@yandex.ru",
            },
        ], [
            {
                col1: "Дальневосточный федеральный округ",
                col2: "Чукотский автономный округ",
                col3: "1",
                col4: "ГАУ ДПО Чукотского автономного округа «Чукотский институт развития образования и повышения квалификации»",
                col5: "689000, г. Анадырь, ул. Беринга, д. 7",
                col6: "Программа повышения квалификации «Медиация в общеобразовательных организациях и социально-реабилитационных центрах для несовершеннолетних»",
                col7: "http:\/\/chao.chiroipk.ru",
                col8: "8(42722) 2-61-97",
                col9: "chiroipk@mail.ru",

            }
        ]
    ]
]