import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News240622.jpg"

const News240622 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Состоится X Всероссийский Съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения
            родителей
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>7-8 июля 2022 г. в режиме онлайн трансляции состоится X Всероссийский Съезд руководителей организаций для
                детей-сирот и детей, оставшихся без попечения родителей.</p>
            <p>В центре внимания съездов прошлых лет – это реструктуризация сети организаций для детей-сирот на
                территории субъектов Российской Федерации с учетом региональных особенностей, обеспечение условий
                пребывания в них детей, отвечающих требованиям постановления Правительства Российской Федерации от 24
                мая 2014 г. № 481.</p>
            <p>Ключевой темой юбилейного Съезда является подготовка детей в организациях для детей-сирот к
                самостоятельному проживанию и успешной социальной адаптации в самостоятельной жизни (внедрение программ
                воспитания, психологическое сопровождение детей, показатели и оценка готовности и успешности выпускников
                организаций для детей-сирот в самостоятельной жизни).</p>
            <p>Кроме того, планируется обсуждение вопросов, касающихся реализации мероприятий по переходу до 2024 года к
                единой ведомственной подчиненности организаций для детей-сирот и органов опеки и попечительства в
                субъектах Российской Федерации, а также разработки концепции единой модели организации для детей-сирот
                (для детей всех возрастов и особенностей развития).</p>
        </div>
        <ToNews/>
    </div>
}


export default News240622;