import React from "react";
import ico from "../../../assets/fileicored.svg"
import ico1 from "../../../assets/link.ico"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const ImprovementEffectiveSystem = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])

    return <>
        <div className={s.newHeader}>
            <h2>
                II. Развитие эффективной модели системы профилактики безнадзорности и правонарушений несовершеннолетних
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>

            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>

                <p>Развитие эффективной модели системы профилактики безнадзорности и правонарушений несовершеннолетних
                    принадлежит к числу основных задач российского государства. Актуальной является проблема поиска
                    более совершенных мер превенции преступности и борьбы с правонарушениями среди
                    несовершеннолетних.</p>
                <p>Современные эффективные модели системы профилактики безнадзорности и правонарушений
                    несовершеннолетних должны основываться на личностно ориентированном и системном подходах.
                    Профилактическая работа должна быть направлена прежде всего на укрепление института семьи,
                    возрождение и сохранение духовно-нравственных традиций семейных отношений, развитие программ
                    профилактики семейного неблагополучия, повышение уровня жизни.</p>
                <p>Меры профилактического воздействия должны предусматривать раннее предупреждение правонарушений,
                    непосредственное предупреждение правонарушений и предупреждение повторных правонарушений.</p>
                <p>Раннее предупреждение правонарушений несовершеннолетних должно обеспечивать создание благоприятных
                    условий для их социального развития, раскрытие и усиление их положительных личностных ресурсов до
                    возникновения реальной угрозы совершения ими правонарушений. Раннее предупреждение правонарушений
                    несовершеннолетних должно предполагать усиление роли института семьи, повышение эффективности
                    государственной поддержки семьи, развитие единой образовательной (воспитывающей) среды, а также меры
                    по раннему выявлению и профилактике девиантного поведения несовершеннолетних. Непосредственное
                    предупреждение правонарушений и предупреждение повторных правонарушений несовершеннолетних должно
                    включать организацию работы с несовершеннолетними, уже нарушившими предписания закона, оказание
                    помощи несовершеннолетним в позитивном изменении социальной микросреды, в которой они находятся, и
                    их самих.
                </p>
                <p><strong>Результаты реализации Концепции 2024 год</strong></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Реестр_региональных_ресурсных_центров_ноябрь_2024_года.pdf">Реестр
                    региональных ресурсных центров, обеспечивающих обобщение
                    и тиражирование эффективного опыта, современных технологий профилактической работы, повышение
                    профессионального уровня специалистов органов и учреждений системы профилактики безнадзорности
                    и правонарушений несовершеннолетних, в том числе на базе специальных учебно-воспитательных
                    учреждений закрытого и открытого типа </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Перечень_региональных_ресурсных_центов_органы_опеки.pdf">
                    Реестр региональных ресурсных центров в сфере защиты прав детей-сирот и детей, оставшихся без попечения родителей</a></p>
                <p><strong>Результаты реализации Концепции 2023 год</strong></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Реестр_региональных_ресурсных_центров_обеспечивающих_обобщение_2023_CcuJ8TU.pdf">Реестр
                    региональных ресурсных центров, обеспечивающих обобщение и тиражирование
                    эффективного опыта, современных технологий профилактической работы, повышение профессионального
                    уровня специалистов органов и учреждений системы профилактики безнадзорности и правонарушений
                    несовершеннолетних, в том числе на базе специальных учебно-воспитательных учреждений закрытого и
                    открытого типа (пункт 7) </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Положение_о_реестре_ресурсных_центров_обеспечивающих_обобщение.pdf">Положение
                    о реестре ресурсных центров, обеспечивающих обобщение и тиражирование эффективного опыта,
                    современных технологий профилактической работы, повышение профессионального уровня специалистов
                    органов и учреждений системы профилактики безнадзорности
                    и правонарушений несовершеннолетних, в том числе на базе специальных учебно-воспитательных
                    учреждений закрытого и открытого типа </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Состав_эксперной_комисии_по_включению_в_реестр_региональных_ресурсных_центров.pdf">Состав
                    экспертной комиссии по включению в реестр региональных ресурсных центров, обеспечивающих обобщение и
                    тиражирование эффективного опыта, современных технологий профилактической работы, повышение
                    профессионального уровня специалистов органов и учреждений системы профилактики безнадзорности
                    и правонарушений несовершеннолетних, в том числе на базе специальных учебно-воспитательных
                    учреждений закрытого и открытого типа
                </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационно-аналитические_материалы_по_исполнеию_в_2023_году_в_субъекта_vn2dPU3.pdf">Информационно-аналитические
                    материалы по организации в субъектах Российской Федерации в 2023 году мероприятий по популяризации и
                    информированию населения о возможностях получения экстренной психологической помощи, организация
                    деятельности групп мобильной помощи специалистами органов и учреждений системы профилактики
                    безнадзорности
                    и правонарушений несовершеннолетних (пункт 8)

                </a></p>

                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационные_материалы_по_разработке_и_реализации_программ_поддержки.pdf">Информационные
                    материалы по разработке и реализации программ поддержки молодежных и подростковых общественных
                    организаций, волонтерского движения, программ по трудоустройству (пункт 16)
                </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационыые_материалы_по_разработке_и_реализации_комплексных.pdf">Информационыые
                    материалы по разработке и реализации комплексных социально-психологических программ (пункт 17)
                </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационные_материалы_по_разработке_и_реализации_программ_по_развитию__vl41AjD.pdf">Информационные
                    материалы по разработке и реализации программ по развитию системы внешкольной работы с
                    несовершеннолетними (пункт 18)
                </a></p>

                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационно-аналитические_материалы_по_исполнению_в_2023_году_в_субъект_Q1J2mgW.pdf">Информационно-аналитические
                    материалы по обеспечению условий для оказания психолого-педагогической и медицинской помощи в
                    субъектах Российской Федерации детям и подросткам, имеющим психологические травмы, пострадавшим от
                    насилия либо жестокого обращения или ставшим свидетелями таких противоправных действий, в том числе
                    для реализации задач расследования преступлений, связанных с несовершеннолетними различных
                    процессуальных статусов (пункт 24)

                </a></p>
                <p><strong>Результаты реализации Концепции 2022 год</strong></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационно-аналитические_материалы_пункт_6_gaeqnEJ.pdf">Информационно-аналитические
                    материалы по организации и проведению Всероссийского дня правовой
                    помощи детям (пункт 6)</a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Реестр_региональных_ресурсных_центров_обеспечивающих_обобщение.pdf">Реестр
                    региональных ресурсных центров, обеспечивающих обобщение и тиражирование эффективного опыта,
                    современных технологий профилактической работы, повышение профессионального уровня специалистов
                    органов и учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних, в том
                    числе на базе специальных учебно-воспитательных учреждений закрытого и открытого типа (п.7)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_пункт_8_fdnAr5E.pdf">Аналитическая
                    информация по проведению мероприятии по популяризации и информированию населения о
                    возможностях получения экстренной психологической помощи, организация деятельности групп мобильной
                    помощи специалистами органов и учреждений системы профилактики безнадзорности и правонарушений
                    несовершеннолетних субъектов Российской Федерации в 2022 году (пункт 8)</a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационно-аналитические_материалы_пункт_15_DU43IGl.pdf">Информационно-аналитические
                    материалы по обеспечению поддержки детских и молодежных социально
                    ориентированных объединений, реализующих мероприятия в сфере профилактики безнадзорности и
                    правонарушений несовершеннолетних (пункт 15)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_пункт_16_7iqFRNi.pdf">Аналитическая
                    информация по разработке и реализации программ поддержки молодежных и подростковых
                    общественных организаций, волонтерского движения, программ по трудоустройству несовершеннолетних, в
                    том числе находящихся в социально опасном положении, развитию спорта и пропаганде здорового образа
                    жизни, духовно-нравственному воспитанию подрастающего поколения (пункт 16)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_пункт17_C1jw9GN.pdf">Аналитическая
                    информация по разработке и реализации комплексных социально-психологических
                    программ, направленных на реабилитацию и ресоциализацию несовершеннолетних правонарушителей, в том
                    числе состоящих на учете в учреждениях уголовно-исполнительной системы (пункт 17)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_пункт_18_9OFGnOX.pdf">Аналитическая
                    информация по разработке и реализации региональных программ по развитию системы
                    внешкольной работы с несовершеннолетними, сети учреждений дополнительного образования и организаций
                    отдыха и оздоровления детей, направленных на обеспечение занятости несовершеннолетних, в том числе
                    находящихся в трудной жизненной ситуации, а также состоящих на профилактическом учете в органах
                    внутренних дел (пункт 18)</a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационно-аналитические_материалы_пункт_19_f4UOWPQ.pdf">Информационно-аналитические
                    материалы по обеспечению методического сопровождения деятельности
                    комиссий по делам несовершеннолетних и защите их прав по координации индивидуальной профилактической
                    работы в отношении несовершеннолетних, состоящих на учете в уголовно-исполнительных инспекциях
                    (пункт 19)</a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационно-аналитичекие_материлы_пункт_24_FxohoKp.pdf">Информационно-аналитические
                    материалы по обеспечению условий для оказания психолого-педагогической
                    и медицинской помощи в субъектах Российской Федерации детям и подросткам, имеющим психологические
                    травмы, пострадавшим от насилия либо жестокого обращения или ставшим свидетелями таких
                    противоправных действий, в том числе для реализации задач расследования преступлений, связанных с
                    несовершеннолетними, различных процессуальных статусов (пункт 24)</a></p>
                <p><strong>Результаты реализации Концепции 2021 год</strong></p>
                <p><a href={respData[467]?.file}>{respData[467]?.description}</a></p>
                <p><a href={respData[468]?.file}>{respData[468]?.description}</a></p>
                <p><a href={respData[469]?.file}>{respData[469]?.description}</a></p>
                <p><a href={respData[470]?.file}>{respData[470]?.description}</a></p>
                <p><a href={respData[471]?.file}>{respData[471]?.description}</a></p>
                <p><a href={respData[472]?.file}>{respData[472]?.description}</a></p>
                <p><a href={respData[473]?.file}>{respData[473]?.description}</a></p>
                <p><a href={respData[474]?.file}>{respData[474]?.description}</a></p>
                <p><a href={respData[475]?.file}>{respData[475]?.description}</a></p>
                <p><a href={respData[476]?.file}>{respData[476]?.description}</a></p>
                <p><a href={respData[477]?.file}>{respData[477]?.description}</a></p>
                <p><a href={respData[478]?.file}>{respData[478]?.description}</a></p>
                <p><a href={respData[479]?.file}>{respData[479]?.description}</a></p>
                <p><a href={respData[480]?.file}>{respData[480]?.description}</a></p>
                <p><a href={respData[481]?.file}>{respData[481]?.description}</a></p>
                <p><a href={respData[482]?.file}>{respData[482]?.description}</a></p>
                <p><a href={respData[483]?.file}>{respData[483]?.description}</a></p>
            </div>
            <div style={{marginTop: "-57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default ImprovementEffectiveSystem