import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const ImprovementOfRegulatory = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.newHeader}>
            <h2>
                I. Совершенствование нормативно-правового регулирования в сфере<br/> профилактики безнадзорности и
                правонарушений несовершеннолетних
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>
            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>
                <p>Российская Федерация является социальным государством, что означает ведение социальной политики,
                    формирование условий, которые обеспечивали бы каждому человеку достойную жизнь, свободное развитие.
                    Дети являются будущим каждого государства, поэтому забота о формировании и развитии их личности
                    признается целью всего общества.</p>
                <p>Нормативное правовое регулирование в сфере профилактики безнадзорности и правонарушений
                    несовершеннолетних имеет особое значение для профилактики и борьбы с безнадзорностью,
                    беспризорностью, асоциальным и неправомерным поведением несовершеннолетних. Принимаются Федеральные
                    программы развития образования, концепции о модернизации образования, нормативные акты, регулирующие
                    сферу воспитания безнадзорных несовершеннолетних и иные документы.</p>
                <p>Совершенствование нормативного правового регулирования в сфере профилактики безнадзорности и
                    правонарушений несовершеннолетних предполагает:</p>
                <p>— разработку, актуализацию региональных (муниципальных) программ и планов мероприятий по
                    организации профилактики безнадзорности и правонарушений несовершеннолетних с учетом Концепции;
                </p>
                <p>— совершенствование федеральных государственных образовательных стандартов начального общего,
                    основного общего, среднего общего образования в части конкретизации требований к планируемым
                    результатам воспитания обучающихся;
                </p>
                <p>— совершенствование мониторинга системы образования и статистического учета по вопросам
                    профилактики безнадзорности и правонарушений несовершеннолетних;
                </p>
                <p>— развитие эффективной модели системы профилактики безнадзорности и правонарушений
                    несовершеннолетних.
                </p>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default ImprovementOfRegulatory