import React from "react";
import s from "./AboutUs.module.css"
import YouTube from 'react-youtube';
import graph from '../../assets/grafik1.jpg'
import {useState} from "react";
import img0 from "../../assets/AboutUs/0.jpeg"
import img1 from "../../assets/AboutUs/1.jpg"
import img2 from "../../assets/AboutUs/2.jpg"
import img3 from "../../assets/AboutUs/3.jpg"
import img4 from "../../assets/AboutUs/4.jpg"
import img5 from "../../assets/AboutUs/5.jpeg"
import img6 from "../../assets/AboutUs/6.jpeg"
import img7 from "../../assets/AboutUs/babkin_foto.jpg"
import img8 from "../../assets/AboutUs/7.jpg"

const Structure = () => {
    return <div className={s.newsPage}>
        <div className={s.newHeader}>
            <h2>Организационная структура</h2>
        </div>
        <div className={s.blocks}>
            <div className={s.block} style={{color: "white", backgroundColor: "#42506d", borderBottomColor: "#42506d"}}>
                <div>
                    <img src={img0}/>
                </div>
                <div style={{
                    fontSize: "32px",
                    fontFamily: "Proxima Nova Lt",
                    marginTop: "22px"
                }}>
                    Гусев Борис Борисович
                </div>
                <div className={s.strWork} style={{color: "white"}}>
                    Директор
                </div>
                <div className={s.strEmail}>
                    +7 (499) 444-08-06 <div>
                    доб. 100 08
                </div>
                </div>

            </div>
            <OneMan name={"Артамонова Елена Геннадьевна"} img={img1}
                    workStatus={"Заместитель директора по развитию системы профилактики девиантного поведения"}
                    email={"e.art@fcprcgov.ru"}>
                <div className={s.otdel}>
                    Отдел профилактики девиантного поведения несовершеннолетних
                    <p></p>
                    Начальник отдела: Заева Ольга Вячеславовна.
                </div>
                <ul className={s.spisok}>
                    <li>
                        Экспертно-аналитическое и организационно-методическое сопровождение обеспечение мониторинга
                        организации проведения социально-психологического тестирования обучающихся, направленного на
                        раннее выявление незаконного потребления наркотических средств и психотропных веществ, и системы
                        профилактической работы в образовательных организациях Российской Федерации.
                        <p></p>
                        Куратор направления - Заева Ольга Вячеславовна<br/>
                        <a href="mailto:spt@fcprc.ru">spt@fcprc.ru</a>,<br/>
                        <a href="tel:84994440806,10009">+7 (499) 444-08-06 доб. 100 09</a>.<p></p>
                    </li>

                </ul>
                <div className={s.otdel}>
                    Отдел сопровождения вопросов безопасности детства
                    <p></p>
                    Начальник отдела: Скорина Елена Николаевна .
                </div>
                <ul className={s.spisok}>
                    <li>
                        Экспертно-аналитическое и организационно-методическое сопровождение мониторинга реализации
                        Концепции развития системы профилактики безнадзорности и правонарушений несовершеннолетних до
                        2025 года в субъектах Российской Федерации
                        <p></p>
                        Куратор направления – Скорина Елена Николаевна
                        <br/>
                        <a href="mailto:koncepcia@fcprc.ru">koncepcia@fcprc.ru</a>,<br/>
                        <a href="tel:84994440806,10002">+7 (499) 444-08-06 доб. 100 02</a>.<p></p>
                    </li>
                    <li>
                        Экспертно-аналитическое и организационно-методическое сопровождение вопросов профилактики
                        аутодеструктивного поведения и детской безопасности
                        <p></p>
                        Куратор направления – Дубровский Роман Геннадьевич
                        <br/>
                        <a href="mailto:psy@fcprc.ru">psy@fcprc.ru</a>,<br/>
                        <a href="tel:84994440806,10003">+7 (499) 444-08-06 доб. 100 03</a>.<p></p>
                    </li>

                </ul>
            </OneMan>
            {/* <OneMan name={"Безуглова Екатерина Александровна"} img={img2}
                    workStatus={"Заместитель директора-руководитель Федерального ресурсного центра психолого-медико-педагогических комиссий"}
                    email={"bezuglova.e@fcprcgov.ru"}>
                <div className={s.otdel}>
                    Федеральный ресурсный центр психолого-медико-педагогической комиссии
                </div>
                <ul className={s.spisok}>
                    <li>
                        Организационно-методическое сопровождение деятельности психолого-медико-педагогических комиссий
                        и центров психолого-педагогической, медицинской и социальной помощи, функционирующих на
                        территории Российской Федерации, а также консультирование всех участников образовательного
                        процесса по вопросам воспитания, обучения и психолого-педагогического сопровождения обучающихся
                        с ограниченными возможностями здоровья и с инвалидностью.
                        <p></p>
                        Куратор направления - Безуглова Екатерина Александровна
                        <div>
                            <a href="mailto:info@pmpkrf.ru">info@pmpkrf.ru</a>,<br/>
                            <a href="tel:84994440806,10007">+7 (499) 444-08-06 доб. 100 07</a>.
                        </div>
                    </li>
                </ul>
            </OneMan>*/}
            <OneMan name={"Лашкул Марина Валерьевна"} img={img3}
                    workStatus={"Заместитель директора по вопросам опеки и попечительства"}
                    email={"lashkul.m@fcprcgov.ru"}>
                <div className={s.otdel}>
                    Отдел международного сотрудничества
                </div>
                <ul className={s.spisok}>
                    <li>
                        Организационно-методическое сопровождение обязательств Российской Федерации по реализации
                        Конвенции о гражданско-правовых аспектах международного похищения детей от 25 октября 1980 г.,
                        Конвенции о юрисдикции, применимом праве, признании, исполнении и сотрудничестве в отношении
                        родительской ответственности и мер по защите детей от 19 октября 1996 г., Конвенции ООН о правах
                        ребенка, включая проведение мероприятий информационно-методического характера <p>

                    </p>
                        <div>
                            Начальник отдела - Улитина Анна Владимировна
                            <div className={s.spisok}>
                                <a href="mailto:ulitina.anna@fcprc.ru"> ulitina.anna@fcprc.ru</a>,<br/>
                                <a href="tel:84994440806,10014">+7 (499) 444-08-06 доб. 100 14</a>
                            </div>
                        </div>
                        <br/>
                        <div className={s.spisok}>
                            Аналитик - Самойлова Анна Александровна
                            <div>
                                <a href="mailto:a.samoylova@fcprcgov.ru">a.samoylova@fcprcgov.ru</a>,<br/>
                                <a href="tel:74994440806,10001">+7 (499) 444-08-06 доб. 100 01</a>
                            </div>
                        </div>
                    </li>

                </ul>
                <div className={s.otdel}>
                    <div className={s.spisok}>
                        Федеральный ресурсный центр в сфере защиты прав детей-сирот и детей, оставшихся без попечения
                        родителей<br/>
                        <a href="mailto:opeca@fcprc.ru">opeca@fcprc.ru</a><br/>
                        <a href="tel:84994440806,10005">+7 (499) 444-08-06 доб. 100 05</a>
                    </div>

                </div>
                <ul className={s.spisok}>
                    <li>
                        Экспертное, аналитическое, организационно-методическое, информационно-техническое обеспечение
                        деятельности по вопросам опеки и попечительства в отношении несовершеннолетних граждан и
                        деятельности организаций для детей-сирот и детей, оставшихся без попечения родителей
                    </li>
                    <li>
                        Осуществление деятельности, направленной на защиту жилищных прав детей-сирот и детей, оставшихся
                        без попечения родителей, лиц из числа детей-сирот и детей, оставшихся без попечения родителей
                    </li>
                    <li>
                        Организация и проведение Всероссийских мероприятий в сфере опеки попечительства в отношении
                        несовершеннолетних граждан и защиты прав детей-сирот и детей, оставшихся без попечения родителей
                    </li>
                </ul>
                <div className={s.otdel}>
                    Руководитель Федерального ресурсного центра в сфере
                    защиты прав детей-сирот и детей, оставшихся без
                    попечения родителей
                </div>
                <ul>
                    <li>
                        <div className={s.spisok}>
                            <a href="mailto:yarovikova.o@fcprcgov.ru">yarovikova.o@fcprcgov.ru</a>
                        </div>
                        Яровикова Ольга Анатольевна<br/>

                    </li>
                </ul>
            </OneMan>

            <OneMan name={"Ковалев Илья Николаевич"} img={img5}
                    workStatus={"Заместитель директора-руководитель Центра поддержки информационно-образовательных ресурсов и администрирования"}
                    email={"kovalev.i@fcprcgov.ru"}>
                <div className={s.otdel}>

                    <ul className={s.spisok}>
                        <li>
                            Организационно-техническое, информационно-технологическое, аналитическое и экспертное
                            сопровождение работ и оказание услуг, предусмотренных государственным заданием Учреждения.


                            <p></p>
                        </li>
                        <li>
                            Информационное и техническое сопровождение информационных ресурсов и систем, формирование и
                            ведение которых осуществляется Учреждением, включая обеспечение информационной безопасности
                            и безопасности информационных ресурсов.

                            <p></p>
                        </li>
                        <li>
                            Организационное, ресурсное и информационно-технологическое обеспечение образовательной
                            деятельности и управление системой образования.

                            <p></p>
                        </li>
                        <li>Обеспечение реализации мероприятий по профилактике и противодействию коррупции в Учреждении.

                            <p></p>
                        </li>
                        <li>
                            Организация работы по сбору, хранению, обработке и защите персональных данных в Учреждении.
                            <p></p></li>
                        <li>
                            Осуществление работы административно – хозяйственной деятельности учреждения.

                            <p></p></li>

                    </ul>
                </div>
            </OneMan>
            <OneMan name={"Майоров Валерий Сергеевич"} img={img6}
                    workStatus={"Заместитель директора-руководитель Федерального центра развития программ социализации подростков"}
                    email={"vmayorov@fcprcgov.ru"}>
                <div className={s.otdel}>
                    <div className={s.otdel}>
                        Отдел реализации проектов
                    </div>
                    <ul className={s.spisok}>
                        <li>
                            Методическое обеспечение работы сети подростковых центров в субъектах Российской Федерации
                            <p></p>
                            Куратор направления – Ирина Юрьевна Халина<br/>
                            <a href="mailto:i.khalina@fcprcgov.ru">i.khalina@fcprcgov.ru</a>
                            <p></p>
                        </li>
                        <li>
                            Методическое обеспечение работы с детьми, находящимися в конфликте с законом, попавшими под
                            влияние деструктивных субкультур
                            <p></p>
                        </li>
                        <li>
                            Содействие интеграции в социум детей, переживших опыт боевых действий
                            <p></p>
                        </li>
                        <li>
                            Методическое обеспечение работы с детьми, находящимися в конфликте с законом, попавшими под
                            влияние деструктивных субкультур
                            <p></p>
                            Куратор направления – Наталья Леонидовна Городиская<br/>
                            <a href="mailto:n.gorodiskaya@fcprcgov.ru">n.gorodiskaya@fcprcgov.ru</a>
                            <p></p>
                        </li>
                        <li>
                            Развитие социальной и психолого-педагогической работы в сети Интернет
                            <p></p>
                        </li>
                    </ul>
                    <div className={s.otdel}>
                        Организационный отдел
                    </div>
                    <ul className={s.spisok}>
                        <li>
                            Организационно-техническое, координационное, аналитическое и консультационное сопровождение
                            работ, предусмотренных государственным заданием
                            <br/>
                            <p></p>
                            Начальник отдела – Винокуров Алексей Алексеевич<br/>
                            <a href="mailto:vinokurov@fcprcgov.ru">vinokurov@fcprcgov.ru</a>
                            <p></p>
                        </li>
                    </ul>
                    <div className={s.otdel}>
                        Отдел исследований
                    </div>
                    <ul className={s.spisok}>
                        <li>
                            Проведения мониторинговых исследований в сфере работы с подростками
                            <br/>
                            <p></p>
                            Начальник отдела – Подушкина Татьяна Геннадьевна<br/>
                            <a href="mailto:t.podushkina@fcprcgov.ru">t.podushkina@fcprcgov.ru</a>
                            <p></p>
                        </li>
                    </ul>
                    <div className={s.otdel}>
                        Отдел информационного обеспечения
                    </div>
                    <ul className={s.spisok}>
                        <li>
                            Информационное сопровождение работ федерального центра по развитию программ социализации
                            подростков
                        </li>
                        <li>
                            Обеспечение консультационного и экспертного сопровождения развития программ социализации
                            подростков
                        </li>
                        <li>
                            Редактирование выпускаемой методической литературы, информационных и нормативных материалов
                        </li>
                        <li>
                            Информатизация пространства и освещение деятельности по вопросам развития программ
                            социализации подростков
                            <br/>
                            <p></p>
                            Начальник отдела – Брязгина Александра Игоревна<br/>
                            <a href="mailto:bryazgina.a@fcprcgov.ru">bryazgina.a@fcprcgov.ru</a>
                            <p></p>
                        </li>
                    </ul>
                </div>
            </OneMan>
            {/*<OneMan name={"Бабкин Олег Александрович"} img={img7}*/}
            {/*        workStatus={"Заместитель директора — руководитель Федерального ресурсного центра Комиссий по делам несовершеннолетних и защите их прав"}*/}
            {/*        email={"babkin@fcprcgov.ru"}>*/}
            {/*    <div className={s.otdel}>*/}
            {/*        <div className={s.otdel}>*/}
            {/*            Отдел организационно-методического обеспечения деятельности комиссий по делам несовершеннолетних*/}
            {/*            и защите их прав*/}
            {/*        </div>*/}
            {/*        <ul className={s.spisok}>*/}
            {/*            <li>*/}
            {/*                Начальник отдела – Зенина Елена Сергеевна<br/>*/}
            {/*                <p></p>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                Консультационное сопровождение деятельности комиссий по делам несовершеннолетних и защите их*/}
            {/*                прав;*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                Анализ и обобщение практики применения органами и учреждениями системы профилактики*/}
            {/*                безнадзорности и правонарушений несовершеннолетних новых технологий, форм и методов работы с*/}
            {/*                несовершеннолетними по профилактике безнадзорности и правонарушений несовершеннолетних в*/}
            {/*                субъектах Российской Федерации;*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                Содействие межведомственному взаимодействию, профессиональному сотрудничеству в сфере*/}
            {/*                профилактики безнадзорности и правонарушений несовершеннолетних.*/}
            {/*                <p></p>*/}
            {/*                Куратор направления – Зенина Елена Сергеевна<br/>*/}
            {/*                <a href="mailto:zenina-es@edu.gov.ru">zenina-es@edu.gov.ru</a>,*/}
            {/*                <br/>*/}
            {/*                +7 (495) 587-01-10 доб. 3049.*/}
            {/*                <p></p>*/}
            {/*            </li>*/}
            {/*        </ul>*/}

            {/*        <div className={s.otdel}>*/}
            {/*            Отдел медиации*/}
            {/*        </div>*/}
            {/*        <ul className={s.spisok}>*/}
            {/*            <li>*/}
            {/*                Начальник отдела: Козлов Анатолий Андреевич*/}
            {/*                <br/>*/}
            {/*                <p></p>*/}
            {/*                Экспертно-аналитическое и организационно-методическое сопровождение развития служб медиации*/}
            {/*                и примирения в образовательных организациях*/}
            {/*                <p></p>*/}
            {/*                Куратор направления - Козлов Анатолий Андреевич<br/>*/}
            {/*                <a href="mailto:mediacia@fcprc.ru">mediacia@fcprc.ru</a>*/}
            {/*                <br/>*/}
            {/*                +7 (499) 444-08-06 доб. 100 11*/}
            {/*                <p></p>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</OneMan>*/}
            {/* <OneMan name={"Васильева Юлия Анатольевна"} img={img8}
                    workStatus={"Заместитель директора по взаимодействию в отдельных субъектах Российской Федерации"}
                    email={"yu.vasileva@fcprcgov.ru"}>
                <div className={s.otdel}>

                    <ul className={s.spisok}>
                        <li>
                            обеспечение развития системы профилактики безнадзорности и правонарушений несовершеннолетних
                            в Донецкой Народной Республике, Луганской Народной Республике, Запорожской и Херсонской
                            областях;
                            <p></p>
                        </li>
                        <li>
                            осуществление координации межведомственного взаимодействия органов и учреждений системы
                            профилактики безнадзорности и правонарушений несовершеннолетних, расположенных
                            в Донецкой Народной Республике, Луганской Народной Республике, Запорожской и Херсонской
                            областях;
                            <p></p>
                        </li>
                        <li>
                            осуществление организации работ по взаимодействию с социально ориентированными
                            некоммерческими организациями, детскими и молодежными общественными объединениями,
                            социальными учреждениями и фондами, иными учреждениями и организациями, расположенными на
                            территориях Донецкой Народной Республики, Луганской Народной Республики, Запорожской и
                            Херсонской областей, включая подготовку и реализацию соглашений о сотрудничестве и
                            взаимодействии по направлению своей деятельности;
                            <p></p>
                        </li>
                    </ul>
                </div>
            </OneMan> */}
        </div>

    </div>
}
const OneMan = ({
                    name, color, desc, workStatus, img, email, children
                }) => {
    const [active, SetActive] = useState(false)
    return <div className={s.block}>
        <div>
            <img src={img}/>
        </div>
        <div className={s.strName}>
            {name}
        </div>
        <div className={s.strWork}>
            {workStatus}
        </div>
        <div className={s.strEmail}>
            <a href={`mailto:${email}`}>
                {email}
            </a>
        </div>
        {active ? <div>
            {children}
            <div className={s.active} onClick={() => {
                SetActive(false)
            }}>Скрыть описание
            </div>
        </div> : <div className={s.active} onClick={() => {
            SetActive(true)
        }}>Подробнее</div>}
    </div>
}

export default Structure