import React, { useEffect } from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/hotline.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import ImgPopup from "../Popup/PopupImg"
import {Formik, Field, Form} from "formik";
import axios from "axios";


const hrefsArr1 = [
    {text: "Регламентирующие документы", to: "/regDocks"},
    {text: "Методическое сопровождение", to: "/met"},
    {text: "Часто задаваемые вопросы", to: "/sptfaq"},
]
const Hotline1 = ({url, header, children, style = {}}) => {
    const [active, setActive] = useState(false)
    const [politics, setPolitics] = useState(false)
    let data = new FormData();
		data.append('email', 'name');

		let config = {
  			method: 'get',
  			maxBodyLength: Infinity,
  			url: 'https://fcprc.ru/api/check',
  			headers: { 
    			
  			},
  			data : data
};
    useEffect(() => {
        axios.request(config)
    })
    return <>
        <div className={s.page}>
            <div className={s.content}>
                <h2 style={{fontWeight: "normal", fontSize: "30px", color: "#3a4463"}}>
                    Горячая линия по вопросам проведения социально-психологического тестирования обучающихся
                </h2>
                <div>
                    <img src={PageImg}/>
                </div>
                <div><p className={s.hotlineHref}>
                    В целях информационно-методического сопровождения вопросов проведения социально-психологического
                    тестирования обучающихся (далее тестирование), Минпросвещения России открыта «Горячая линия»<br/>

                    <a href="https://fcprc.ru/wp-content/uploads/2019/06/Pismo-Minobrnauki-Rossii-O-rabote-goryachei-linii-07-6269.pdf">Письмо
                        Минобрнауки России — О работе горячей линии 07-6269 .pdf</a></p>

                    <p style={{fontSize: "28px", fontFamily: "Segoe UI", fontWeight: "bolder"}}>Горячая линия
                        адресована</p>
                    <ul>
                        <li>специалистам органов государственной власти и местного самоуправления</li>
                        <li>педагогическим работникам</li>
                        <li>профильным общественным организациям</li>
                        <li>родительской общественности</li>
                        <li>обучающимся</li>
                    </ul>


                    <p>Обратившиеся смогут получить консультацию по широкому кругу вопросов, возникающих на всех этапах
                        тестирования.<br/>
                        Горячая линия включает возможность получения оперативной информации и методической поддержки в
                        режиме онлайн на данной специализированной странице.</p><div className={s.hotlineHref}>
                        <input type="checkbox" onClick={() => {
                            politics? setPolitics(false): setPolitics(true)
                        }}/>
                        Я даю согласие согласие на обработку <a href="https://fcprc.ru/media/media/behavior/20-1-29-04-2021_CIi14fS.pdf">персональных данных</a>
                    </div>

                    <p style={{fontSize: "28px", fontFamily: "Segoe UI", fontWeight: "bolder"}}>Режим работы</p>
                    <p>В онлайн режиме Горячая линия функционирует круглосуточно</p>

                    <br/>
                    {politics ? <div  onClick={() => {  setActive(true) }} style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "#c97171",

                        width: "230px",
                        marginBottom: "70px",
                        cursor: "pointer", 
                        borderRadius:"10px"
                    }}>
                        Задать вопрос на горячую линию
                    </div> : <div   style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "pink",
                        width: "230px",
                        marginBottom: "70px",
                        
                        borderRadius:"10px"
                    }}>
                        Задать вопрос на горячую линию
                    </div>}
                    <ImgPopup active={active} setActive={setActive}>
                        <div className={s.form}>
                            <div>
                                <h1>
                                    Форма запроса на Горячую Линию
                                </h1>
                            </div>
                            <Formik
                                initialValues={{email: '',}}

                                onSubmit={(values, {setSubmitting}) => {
                                   
                                        axios.post("https://fcprc.ru/api/hotline", {'subject': values.subject, 
                                     'question': values.question, 'category': values.category, 'email': values.email, 
                                     'theme': values.theme},  
                                     {      xsrfCookieName: 'csrftoken',
                                     xsrfHeaderName: 'X-CSRFTOKEN',
                                 })
                                        setSubmitting(false);
                                        setActive(false)
                                }}
                            >
                                {({errors, touched, isSubmitting}) => (
                                    <Form>

                                        <div>
                                            <div>
                                                <label fore="subject">СУБЪЕКТ РФ: *
                                                </label>
                                            </div>
                                            <Field type="subject" name="subject" placeholder="Субъект Рф"/>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <label fore="email">EMAIL *
                                                    </label>
                                                </div>
                                                <Field type="email" name="email"
                                                       placeholder="E-mail для обратной связи"/>
                                            </div>
                                            <div>

                                                <div>
                                                    <label fore="category">Категория респондента</label>
                                                </div>
                                                <Field component="select" name="category" placeholder="Выберите">
                                                    <option>Выберите</option>
                                                    <option>Специалист органов государственной власти и местного
                                                        самоуправления
                                                    </option>
                                                    <option>Специалист региональных (муниципальных) КДНиЗП</option>
                                                    <option>Руководитель образовательной организации</option>
                                                    <option>Педагог, педагог-психолог, классный руководитель</option>
                                                    <option>Родитель(законный представитель)</option>
                                                    <option>Обучающийся</option>
                                                    <option>Иные категории</option>
                                                </Field>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="theme">ТЕМА ВОПРОСА *</label>
                                            </div>
                                            <Field component="select" name="theme" placeholder="Выберите"
                                                   className={s.chooseTheme}>
                                                <option>Выберите</option>
                                                <option value="Правовые основания проведения тестирования"> Правовые основания проведения тестирования</option>
                                                <option value="Процедура проведения тестирования">Процедура проведения тестирования
                                                </option>
                                                <option value="Методическое сопровождение проведения тестирования">Методическое сопровождение проведения тестирования
                                                </option>
                                            </Field>
                                        </div>

                                        <div>
                                            <div>
                                                <label fore="question">ЗАДАЙТЕ ВАШ ВОПРОС *</label>
                                            </div>
                                            <Field component="textarea" type="text" name="question" placeholder="..."/>
                                        </div>
                                        <div className={s.send}>
                                        <button>Отправить</button>
                                    </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </ImgPopup>
                </div>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArr1}/>

            </div>
        </div>
    </>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}


export default Hotline1