import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLineSUVU} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"


const hrefsArr = [
    {text: "Новости", to: "/spec-suvu/news-suvu/"},
    {text: "Нормативное регулирование деятельности СУВУ", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {
        text: "Информационно-методические материалы",
        to: "/spec-suvu/informatsionno-analiticheskie-materialy/"
    },
    {text: "Всероссийские мероприятия", to: "/spec-suvu/meropriyatiya/"},
    {text: "Люди. События. Факты.", to: "/spec-suvu/ludi-fakti-sobitiya/"},
]
const FilesSuvuNorm = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/suvu").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Нормативное регулирование деятельности СУВУ
                </h2>
                <h3 style={{
                    fontWeight: "bold",
                    color: "#005090",
                    textAlign: "left",
                    maxWidth: "1100px",
                    margin: "40px auto",
                    paddingRight: "95px",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "22px"
                }}>
                    Федеральное законодательство
                </h3>
                <FileLeftLineSUVU text={respData[0]?.description} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[1]?.description} refers={respData[1]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[2]?.description} refers={respData[2]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[3]?.description} refers={respData[3]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[4]?.description} refers={respData[4]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[5]?.description} refers={respData[5]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[6]?.description} refers={respData[6]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[7]?.description} refers={respData[7]?.file} ico={ico}/>
                <h3 style={{
                    fontWeight: "bold",
                    color: "#005090",
                    textAlign: "left",
                    maxWidth: "1100px",
                    margin: "40px auto",
                    paddingRight: "95px",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "22px"
                }}>
                    Государственные программы, Стратегии, Концепции
                </h3>
                <FileLeftLineSUVU text={respData[8]?.description} refers={respData[8]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[9]?.description} refers={respData[9]?.file} ico={ico}/>
                <h3 style={{
                    fontWeight: "bold",
                    color: "#005090",
                    textAlign: "left",
                    maxWidth: "1100px",
                    margin: "40px auto",
                    paddingRight: "95px",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "22px"
                }}>
                    Приказы, письма Минпросвещения России
                </h3>
                <FileLeftLineSUVU text={respData[10]?.description} refers={respData[10]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[11]?.description} refers={respData[11]?.file} ico={ico}/>
                <FileLeftLineSUVU text={respData[12]?.description} refers={respData[12]?.file} ico={ico}/>
                <h3 style={{
                    fontWeight: "bold",
                    color: "#005090",
                    textAlign: "left",
                    maxWidth: "1100px",
                    margin: "40px auto",
                    paddingRight: "95px",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "22px"
                }}>
                    Иные регламентирующие документы
                </h3>
                <FileLeftLineSUVU text={respData[13]?.description} refers={respData[13]?.file} ico={ico}/>

            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesSuvuNorm