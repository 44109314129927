import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/CompetitionForTheSelection2022.jpg"

const CompetitionForTheSelection2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги Всероссийского конкурса по отбору ресурсных площадок программ социализации подростков
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>20 декабря 2022 г. состоялось заседание экспертного совета Всероссийского конкурса по отбору ресурсных площадок
                программ социализации подростков.</p>
            <p>Конкурс проходил с 1 по 20 декабря 2022 года по инициативе Центра защиты прав и интересов детей совместно
                с Общественной палатой РФ, при поддержке Министерства просвещения РФ. В нем приняли участие более 20
                регионов Российской Федерации.</p>
            <p>Основной задачей конкурса было создание сообщества профессионалов, реализующих программы, направленные на
                социализацию подростков в возрасте от 10 до 18 лет, и формирование эффективной результативной практики в
                обозначенной сфере.
                По итогам заседания экспертного совета, в состав которого вошли специалисты психолого-педагогического
                профиля, статус ресурсных площадок Федерального центра развития программ социализации подростков
                получили 19 участников конкурса:</p>
            <p>— ГБУ Архангельской области общего типа «Архангельский социальный центр помощи семье и детям»,
                руководитель — Ирина Звягина;<br/>
                — МБУДО «Белогорье», руководитель — Анна Маматова;</p>
            <p>— МУДО г. Владимира «Детский оздоровительно-образовательный (социально-педагогический) центр»,
                руководитель — Светлана Кислова;</p>
            <p>— ГУ «Забайкальский краевой Центр психолого-педагогической, медицинской и
                социальной помощи «Семья», руководитель — Наталья Тонышева;</p>
            <p>— ГАУДПО Ивановской области «Университет непрерывного образования и инноваций» (кафедра педагогики и
                психологии), руководитель — Елена Юферова;</p>
            <p>— МАУДО Ангарского городского округа «Молодежный центр «Перспектива», руководитель — Андрей Денисов;<br/>
                — Общественная организация Иркутской области «Противодействие социально-негативным явлениям»,
                руководитель — София Канавина;</p>
            <p>— Иркутское региональное отделение Молодежной общероссийской общественной организации «Российские
                Студенческие Отряды», руководитель — Ольга Баранова;<br/>
                — ГБУ Калужской области «Калужский областной центр социальной помощи семье и детям «Надежда»,
                руководитель — Эдуард Струков;<br/>
                — Красноярский региональный фонд «Красноярье без сирот», руководитель — Руслан Мокроусов;<br/>
                — ГОАУСОН «Мончегонский комплексный центр социального обслуживания
                населения», руководитель — Альбина Сидоренко;</p>
            <p>— ГБУСОН Ненецкого автономного округа «Комплексный центр социального
                обслуживания», руководитель — Наталья Устинова;<br/>
                — МБУ г. Новосибирска «Городской центр психолого-педагогической поддержки молодежи «Родник»,
                руководитель — Ангелина Штельвах;<br/>
                — БУ Орловской области «Кризисный центр помощи женщинам и детям «Орловский», руководитель — Сергей
                Филимонов;</p>
            <p>— МУДО «Центр психолого-педагогической, медицинской и социальной помощи» (Республика Коми), руководитель
                — Татьяна Балыгина;<br/>
                — ГБУ Республиканский Центр психолого-медико-социального сопровождения «Сайзырал» (Республика Тыва),
                руководитель — Альбина Монгуш;<br/>
                — Фонд поддержки образовательных инициатив и социальных проектов «Дельта-план» (Республика Хакасия),
                руководитель — Юлия Суркова;<br/>
                — МБУДО «Центр детско-юношеский «Созвездие» (Свердловская область), руководитель — Елена Анисимова;<br/>
                — БУ Ханты-Мансийского автономного округа – Югры «Сургутский районный центр социальной помощи семье и
                детям», руководитель — Елена Черкашина.</p><br/>
            <p><a style={{
                fontStyle: "Italic",
                fontSize: "19px"
            }} href="https://fcprc.ru/media/media/behavior/Приказ__103_от_30.12.2022.pdf">
                Приказ № 103 от 30.12.2022</a></p>
        </div>
        <ToNews/>
    </div>
}


export default CompetitionForTheSelection2022;