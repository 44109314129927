import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News090124.png"

const News090124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги Всероссийского конкурса методических материалов «Здоровье и безопасность: методические
            ресурсы»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В 2023 году Министерством просвещения Российской Федерации впервые был проведён Всероссийский конкурс
            методических материалов по профилактике употребления наркотических средств, психотропных и иных
            психоактивных веществ, формированию культуры здорового и безопасного образа жизни среди обучающихся
            «Здоровье и безопасность: методические ресурсы». Участниками конкурса стали педагогические работники или
            педагогические коллективы общеобразовательных и профессиональных образовательных организаций.
            <p></p>
            Всероссийский конкурс был направлен на повышение уровня методического обеспечения деятельности
            образовательных организаций, создание новых и эффективных практик в сфере профилактики употребления
            наркотических средств, психотропных и иных психоактивных веществ, формирование культуры здорового и
            безопасного образа жизни среди обучающихся, развитие инновационных подходов к организации воспитательной и
            профилактической деятельности в образовательной организации, обновление методического инструментария
            профилактической деятельности, повышение воспитательного потенциала образовательных организаций.
            <p></p>
            Конкурс проводился по двум номинациям:<br/>
            Номинация 1 – «Дополнительная общеразвивающая программа по профилактике употребления наркотических средств,
            психотропных и иных психоактивных веществ, формированию культуры здорового и безопасного образа жизни среди
            обучающихся».<br/>
            Номинация 2 – «Методическая разработка по профилактике употребления наркотических средств, психотропных и
            иных психоактивных веществ, формированию культуры здорового и безопасного образа жизни среди обучающихся».
            <p></p>
            Всероссийский конкурс проводился в два заочных этапа: региональный и федеральный.
            <p></p>
            В состав Жюри Всероссийского конкурса были включены эксперты Института изучения детства, семьи и воспитания,
            Московского государственного психолого-педагогического университета, Уральского государственного
            педагогического университета, Федерального института оценки качества образования, представители Центра
            защиты прав и интересов детей и Минпросвещения России.
            <p></p>
            В региональном этапе Всероссийского конкурса приняли участие более 1050 специалистов из более чем 700
            образовательных организаций из 56 субъектов Российской Федерации, включая Донецкую и Луганскую народные
            республики, Запорожскую и Херсонскую области.
            На федеральном этапе Жюри рассмотрело 85 работ – победителей региональных этапов Всероссийского конкурса.
            <p></p>
            В результате подведения итогов федерального этапа Всероссийского конкурса членами Жюри Конкурса, в состав
            которого вошло 10 человек, было определено 7 работ, занявших призовые места. Участники, подготовившие
            указанные работы, получили дипломы Победителей и Призеров Конкурса.
            <p></p>
            С результатами Всероссийского конкурса можно ознакомиться по <a
            href="https://fcprc.ru/media/media/mediacia/Результаты_Конкурса.pdf" style={{color: "blue", fontWeight: "600"}}>ссылке</a>.
            Среди победителей и призеров Всероссийского конкурса – педагогические работники образовательных организаций
            из Брянской, Воронежской, Нижегородская, Омской, Пензенской и Челябинская областей, города Санкт-Петербург.
            <p></p>
            С информацией об этом и остальных всероссийских конкурсах, проводимых при участии Центра защиты прав и
            интересов детей, можно ознакомиться на <a href="/vserosconc" style={{color: "blue", fontWeight: "600"}}>странице Всероссийских конкурсов</a>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News090124;