import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News1212242.jpg"

const News1212242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            17 декабря 2024 года стартует Всероссийский научно-методический практикум
            по актуальным вопросам профилактики девиантного поведения
            несовершеннолетних
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В рамках исполнения пункта 26 плана мероприятий на 2021–2025 годы
            пореализации Концепции развития системы профилактики безнадзорности
            иправонарушений несовершеннолетних на период до 2025 года, утвержденного
            распоряжением Правительства Российской Федерации от 18 марта 2021 г. № 656-р, <b>с 17 декабря 2024 г. по 31
            января 2025 г.</b> Минпросвещения России проводит <b>Всероссийский научно-методический практикум по актуальным вопросам
            профилактики девиантного поведения несовершеннолетних</b>.
            <p></p>
            <b>Цель Практикума</b> – содействие развитию системы профилактической
            деятельности образовательных организаций, включая выявление иустранение
            причин и условий, способствующих правонарушениям и антиобщественным
            действиям несовершеннолетних, раннее предупреждение и коррекцию девиантного
            поведения детей и подростков с учетом актуальных социальных рисков на основе
            современных научно-практических методов и ресурсов.
            <p></p>
            <b>Участники Практикума (целевая группа)</b> – представители органов и учреждений
            системы профилактики безнадзорности и правонарушений несовершеннолетних
            субъектов Российской Федерации; образовательных, научных, общественных
            организаций.
            <p></p>
            <b>Организатор Практикума</b> – ФГБУ «Центр защиты прав и интересов детей».
            <p></p>
            Практикум проводится в заочном формате.
            <p></p>
            Для участия в работе Практикума необходимо пройти регистрацию.
            <p></p>
            <b style={{color: "red"}}>ВНИМАНИЕ!</b><br/>
            <b>Регистрация на Практикум откроется на главной странице сайта Центра:</b><br/>
            <a href="https://fcprc.ru/" style={{color: "blue"}}>https://fcprc.ru/</a><br/>
            <b>17 декабря 2024 г. в 10:00 (по московскому времени).</b>
            <p></p>
            По итогам участия в Практикуме (ознакомление с презентационными
            и методическими материалами, просмотр видеоматериалов) предусмотрено
            получение <b>электронного сертификата</b>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News1212242;