import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/VMT.jpg";
import YouTube from "react-youtube";

const VMT = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Всероссийские мероприятия Центра по формированию ценностей безопасного и здорового образа
            жизни
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                На вебинаре «Всероссийские мероприятия Центра по формированию ценностей безопасного и здорового образа
                жизни», состоявшимся в рамках Московского международного салона образования – 2020, заместитель
                директора ФГБУ «Центр защиты прав и интересов детей», кандидат психологических наук Артамонова Елена
                Геннадьевна и аналитик ФГБУ «Центр защиты прав и интересов детей», социальный педагог, педагог-психолог,
                кандидат педагогических наук Заева Ольга Вячеславовна сделали обзор всероссийских мероприятий:
                Всероссийский опрос молодежи о ВИЧ, Всероссийский опрос родителей о ПАВ, Всероссийский конкурс
                социальной рекламы «Стиль жизни здоровье!», Всероссийский конкурс профессионального мастерства
                «Педагог-психолог России». Символичным завершением вебинара стал гимн психолога, сопровождающий видео
                ролик о проведении конкурса «Педагог-психолог России-2019». Слушателями вебинара стали 960 участников из
                63 регионов Российской Федерации.
            </p>
            <YouTube style={{textAlign: "center"}} videoId="7iAYmnrb7jw"/>
            <p></p>
        </div>
    </div>
}


export default VMT