import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/ChildDefence2021.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import YouTube from "react-youtube";


const Section20211 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Секция 1 «Безопасность детства. Социальные практики помощи ребенку и его семье»
                </h2>
            </div>
            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><a href="https://fcprc.ru/media/media/behavior/atabieva.pdf">Атабиева М.М.</a></p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/volkova.pdf">Волкова Л.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/voroshilova.pdf">Ворошилова Ю.И.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/gim.pdf">Гимаджиева О.С.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/guselnikova.pdf">Гусельникова А.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/zagaynov.pdf">Загайнов А.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/ivanova.pdf">Иванова И.П.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/komarov.pdf">Комаров В.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/kopovoy.pdf">Коповой А.С.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/makeeva.pdf">Макеева Н.Ю.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/spiridonova.pdf">
                        Спиридонова И.А.
                    </a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/tepljakova.pdf">Теплякова Е.Ю.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/truhina-tarasova.pdf">Трухина Ю.В., Тарасова Н.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/coy.pdf">Цой С.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/shereshkova.pdf">Шерешкова Е.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/jakovleva.pdf">Яковлева Н.Ф.</a>
                </p>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>

    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default Section20211