import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News291124.jpg"

const News291124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинары для родителей обучающихся специальных учебно-воспитательных
            учреждений в 2024 году
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            26 и 27 ноября 2024 года отдел медиации Центра защиты прав и интересов
            детей провел вебинары для родителей (законных представителей)
            обучающихся специальных учебно-воспитательных учреждений,
            подведомственных Минпросвещения России и регионального подчинения по
            теме «Медиативные технологии как способ договориться с ребенком».
            <p></p>
            Структура вебинаров была построена таким образом, чтобы слушатели могли
            составить для себя структурированное представление о конфликте, его
            сущности, причинах возникновения и его последствиях. Медиативные
            технологии органично встраиваются в повседневную жизнь семьи и
            достаточно просты в освоении.
            <p></p>
            Слушатели познакомились с медиативным подходом, принципами и
            техниками медиации, а также возможностями их применения во
            взаимоотношениях с ребенком для выстраивания уважительного и
            дружественного общения в семье.
            <p></p>
            Центр защиты прав и интересов детей проводит данные вебинары для
            родителей третий год, и родители, а также законные представители
            обучающихся СУВУ принимают в них участие по собственному желанию.
            Ведущий вебинаров: аналитик отдела медиации Центра защиты
            прав и интересов детей – Тюльканов Сергей Леонидович.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News291124;