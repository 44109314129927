import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News181024_1.jpeg"
import img2 from "../../assets/News/News181024_2.jpeg"
import img3 from "../../assets/News/News181024_3.jpeg"
import img4 from "../../assets/News/News181024_4.jpeg"
import img5 from "../../assets/News/News181024_5.jpeg"
import img6 from "../../assets/News/News181024_6.jpeg"
import img7 from "../../assets/News/News181024_7.jpeg"
import img8 from "../../assets/News/News181024_8.jpeg"
import img9 from "../../assets/News/News181024_9.jpeg"

const News181024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Публичные лекции по медиации и семейным отношениям от Общества «Знание» в Москве
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Российское общество «Знание» совместно с Национальным исследовательским университетом «Высшая школа
            экономики» в рамках проекта «Медиация: благополучие ребенка в семье и школе» проведут публичные лекции,
            посвященные конфликтам и детской агрессии.
            <p></p>
            Три опытных эксперта в диалоговом формате поделятся ценными практическими инструментами, которые будут
            полезны как родителям, так и профильным специалистам, чтобы лучше понимать, что происходит с детьми и как с
            ними эффективно выстроить общение.
            <p></p>
            Участие бесплатное, но требуется регистрация.

            <p></p>
            <b>Лекция «Обратная сторона конфликта: как уберечь ребенка»</b><br/>

            24 октября 2024 г. с 19:20 до 21:35 по адресу Москва, Покровский бульвар, профессорский клуб Высшей школы
            экономики.
            <br/>
            Регистрация: https://znanierussia.ru/events/obratnaya-storona-konflikta-kak-uberech-rebenka-115280
            <br/>
            Конфликт – неотъемлемая часть жизни человека и важная часть социализации детей. Но он всегда сопряжен с
            тайнами, скрытностью, и мы можем даже не догадываться о том, что переживает ребенок.
            <br/>
            На лекции вы сможете погрузиться в разные стороны конфликта, узнать о том, как именно он влияет на ребенка и
            отработать приемы, с помощью которых детям можно помочь.
            <br/>
            <b>1. Как разумно говорить в конфликте: Модель «четыре уха»?</b>
            <br/>
            Почему разногласий и недовольства становится больше, когда мы пытаемся говорить о них? Как выстроить
            доверительные отношения с детьми и взрослыми родственниками?
            <br/>
            Лектор: Алексей Покровский – профессиональный медиатор, переговорщик, тренер и супервизор медиаторов,
            ведущий преподаватель Всероссийского Государственного Университета Юстиции (РПА Минюста России), медиатор в
            специализированной сфере ГБУ Москвы Мой семейный центр «Диалог».
            <br/>
            <b>2. Дети и конфликт: что происходит и как можно помочь?</b>
            <br/>
            Правда ли, что взрослые и дети по-разному переживают конфликты? Как взрослый может понять, что происходит с
            ребенком в конфликтной ситуации и можем ли мы помочь детям с этим справиться?
            <br/>
            Анатолий Козлов – педагог-психолог, медиатор, лектор Российского общества «Знание», эксперт Центра медиации
            и социально-правовой помощи Москвы, член Ассоциации профессиональных медиаторов «Единство».
            <br/>
            <b>3. Роль семьи, когда ребенок в конфликте.</b>
            <br/>
            На чем строится доверие в семье? Как семья может помочь ребенку, если у него конфликт?
            <br/>
            Нина Волонтэй – международный семейный медиатор, семейный психолог, медиатор в специализированной сфере
            Центра медиации и социально-правовой помощи Москвы.
            <p></p>

            <strong>Лекция «Детская агрессия: почему и что делать?»</strong>
            <br/>
            29 октября 2024 г. с 19:00 до 21:15 по адресу Москва, Покровский бульвар, профессорский клуб Высшей школы
            экономики.
            <br/>
            Регистрация: https://znanierussia.ru/events/detskaya-agressiya-pochemu-i-chto-delat-115285
            <br/>
            Детский и подростковый возраст – это период, когда человек только начинает осваивать нормы общения. В этот
            момент эмоциональный интеллект и самоконтроль ещё находятся на стадии формирования. Ребенок может быть
            жесток к сверстникам и взрослым.
            <br/>
            Тема детской агрессивности неразрывно связана с её последствиями, такими как травля и буллинг. Какие у нас
            есть инструменты для помощи детям в этих сложных ситуациях.

            <br/>
            <b>1. Угроза травли для ребенка, механизмы реагирования.</b>
            <br/>
            Что такое травля и как государство помогает детям и семьям в таких ситуациях?
            <br/>
            Ярославская Ольга – Уполномоченный по правам ребенка в городе Москве.
            <br/>
            <b>2. «Ребенок в ситуации травли! Что делать?»</b>
            <br/>
            Как понять, что Ваш ребенок стал участником школьной травли? Круг сообщества – технология восстановительного
            подхода к решению данной проблемы в образовательной организации.
            <br/>
            Елена Женодарова – педагог-психолог высшей категории, медиатор, ведущий восстановительных программ, член
            Всероссийской ассоциации восстановительной медиации.
            <br/>
            <b>3. Медиативные инструменты семейной поддержки.</b>
            <br/>
            Какими способами семья может помочь ребенку, пережившему или переживающему травлю? Особенности семейного
            диалога.
            <br/>
            Ирина Соломахина – профессиональный медиатор, системный семейный психолог, ведущий школы приемных родителей,
            супервизор в специализированной сфере медиации Центра Медиации и социально-правовой помощи Москвы.
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <div>
            <img src={img7}/>
        </div>
        <div>
            <img src={img8}/>
        </div>
        <div>
            <img src={img9}/>
        </div>
        <ToNews/>
    </div>
}


export default News181024;