import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News231023.jpg"

const News231023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему «О межведомственном взаимодействии в сфере профилактики безнадзорности и правонарушений
            несовершеннолетних комиссий по делам несовершеннолетних и защите их прав и органов по делам молодежи»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <p>
            20 октября ФГБУ «Центр защиты прав и интересов детей» был организован вебинар на тему «О межведомственном
            взаимодействии в сфере профилактики
            безнадзорности и правонарушений несовершеннолетних комиссий по делам несовершеннолетних и защите их прав и
            органов по делам молодежи» при поддержке Росмолодежи и ФГБОУ ВО «Саратовская государственная юридическая
            академия».
        </p>
        <p>
            В вебинаре приняли участие ответственные секретари комиссий по делам несовершеннолетних и защите их прав
            субъектов Российской Федерации, специалисты, обеспечивающие деятельность КДН и ЗП, руководители и
            специалисты органов исполнительной власти субъектов Российской Федерации, реализующих молодежную политику.

        </p>
        <p>
            В рамках вебинара были рассмотрены вопросы правового регулирования деятельности по профилактике
            безнадзорности и правонарушений несовершеннолетних, организации общей и индивидуальной профилактической
            работы с
            несовершеннолетними органами и учреждениями, реализующими молодежную политику в субъектах Российской
            Федерации,
            особенности их взаимодействия с комиссиями по делам несовершеннолетних и защите их прав.

            Также были рассмотрены вопросы реализации комплекса мер по профилактике социально-негативных явлений в
            детской и молодежной среде на 2023-2025 годы.
        </p>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p className={s.blueButtonMediaciaBase}>
                <a href="https://fcprc.ru/media/media/behavior/Программа_вебинара.pdf">
                    Программа вебинара
                </a>
            </p>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News231023;