import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2811232.jpg"
import img2 from "../../assets/News/News2811232_2.jpg"
import img3 from "../../assets/News/News2811232_3.jpg"
import img4 from "../../assets/News/News2811232_4.jpg"
import img5 from "../../assets/News/News2811232_5.jpg"
import img6 from "../../assets/News/News2811232_6.jpg"

const News2811232 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Продлевается набор на ПЛАТНОЕ ОБУЧЕНИЕ
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <strong>По медиации и восстановительным технологиям</strong>
            <p></p>
            Если у вас есть желание и необходимость понимать и разрешать конфликты мирным путем, выстраивать добрые и
            доверительные отношения с учениками и их родителями, а также организовать в школе службу медиации
            (примирения), то этот курс для вас!
            <p></p>
            Наш Центр приглашает на программу повышения квалификации «Медиативно-восстановительные технологии в
            образовательной организации», 36 академических часов.
            <p></p>
            <strong>Записаться:<br/>
                <a href="/education/registration">https://fcprc.ru/education/registration</a></strong>
            <p></p>
            <strong>Период обучения:</strong> 28 ноября - 25 декабря 2023 года.
            <p></p>
            <strong>Формат обучения:</strong> онлайн занятия. Все занятия будут проводиться с личным участием
            преподавателя.
            <p></p>
            После прохождения обучения, вы получите удостоверение о повышение квалификации установленного образца.
            <p></p>
            <strong>Преподаватель: Анатолий Андреевич Козлов</strong>, медиатор, педагог-психолог, сотрудник отдела
            медиации ФГБУ «Центр
            защиты прав и интересов детей», член Ассоциации профессиональных медиаторов «Единство».
            <p></p>
            Эта программа для вас, если вы желаете ближе познакомиться с медиативно-восстановительным подходом и
            узнать об особенностях его применения в работе с несовершеннолетними и в условиях образовательной
            организации.
            <p></p>
            <strong>После прохождения обучения вы сможете:</strong>
            - разбираться в основах медиативно-восстановительного подхода и конфликтологического знания;<br/>
            - проводить медиативно-восстановительную беседу между обучающимися, коллегами, родителями;<br/>
            - использовать медиативные и восстановительные технологии, такие как: «Петля понимания», «Активное
            слушанье», «Рефрейминг»;<br/>
            - понимать возможности создания и развития службы медиации или примирения.
            <p></p>
            Программа включает в себя преподавательский опыт сотрудников отдела медиации ФГБУ «Центр защиты прав и
            интересов детей», а также опыт в области консультативной, экспертной и организационно-методической работы по
            развитию системы служб медиации и примирения в Российской Федерации.
            <p></p>
            <strong>Стоимость обучения:</strong> 10 000 рублей.
            <p></p>
            <strong><a href="https://clck.ru/36Hr6M">Подробнее о курсе</a></strong>
            <div>
                <img src={img2}/>
            </div>
            <div>
                <img src={img3}/>
            </div>
            <div>
                <img src={img4}/>
            </div>
            <div>
                <img src={img5}/>
            </div>
            <div>
                <img src={img6}/>
            </div>
        </div>
        <ToNews/>
    </div>
}


export default News2811232;