import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import adobe from "../../../assets/Behavior/adobe.png"
import presen from "../../../assets/Behavior/presen.jpg"


const KurchenkoDrugs = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Курченко Е. В. Профилактика наркомании среди подростков посредством формирования системы духовно —
                    нравственных ориентиров
                </h2>
            </div>

            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p><strong>// Электронный журнал «Общество. Культура. Наука. Образование». 2014. Вып. 3.</strong></p>
            </div>
            <div style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    <div style={{textAlign: "center"}}><strong>ПРОФИЛАКТИКА НАРКОМАНИИ СРЕДИ ПОДРОСТКОВ
                        ПОСРЕДСТВОМ ФОРМИРОВАНИЯ СИСТЕМЫ ДУХОВНО — НРАВСТВЕННЫХ ОРИЕНТИРОВ</strong></div>
                    <br/>
                    <strong>Е.В. Курченко,</strong> студентка 3 курса<br/>
                    ФГБОУ ВПО «Новосибирский государственный
                    педагогический университет»
                    (Институт физико-математического и
                    информационно-экономического образования)<br/>
                    Проблема наркомании на сегодняшний день весьма актуальна в условиях современного общества.
                    Подростки, которые испытывают нехватку эмоционального тепла в процессе общения с родными и друзьями
                    начинают компенсировать это общение в случайных компаниях, где нередким является употребление
                    алкоголя и наркотиков. Большая часть из них сталкивается с данной зависимостью очень быстро, еще во
                    время получения образования в учебном заведении.<br/>
                </p>
            </div>
            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p>Наркозависимость — это когда употребляющий наркотики человек, постоянно думает о следующей дозе,
                    стремится получить ее. Начало зависимости — для получения приятных ощущений. Дальнейший процесс
                    развития зависимости — для избавления от дискомфорта, который испытывает зависимый человек [1]. Дети
                    и подростки являются наименее защищенными перед лицом наркомании. Поэтому профилактика наркомании
                    среди подростков в школах и других учебных заведениях имеет огромную важность для борьбы с эпидемией
                    наркомании. Профилактика наркомании — это социальный метод предупреждения возникновения зависимости
                    [3].</p>
                <p>В «Концепции профилактики злоупотребления психоактивными веществами в образовательной среде»,
                    утвержденной приказом Минобразования Р Ф № 619 от 28 февраля 2000 г. определены три основные модели
                    профилактики:<br/>
                    1. Медицинская модель — информирование учащихся о негативных последствиях приема ПАВ на физическое и
                    психическое здоровье.<br/>
                    2. Образовательная модель — направлена на обеспечение детей и молодежи полной информацией о проблеме
                    наркомании и обеспечение свободы выбора при максимальной информированности.<br/>
                    3. Психосоциальная модель — своей главной целью утверждает необходимость развития определенных
                    психологических навыков в противостоянии групповому давлению, в решении конфликтной ситуации, в
                    умении сделать правильный выбор в ситуации предложения наркотиков [2].</p>
                <p>Профилактика наркозависимости делится на три взаимосвязанных направления: первичную, вторичную и
                    третичную профилактику. Под первичной профилактикой понимается комплекс мер, направленных на
                    предотвращение потребления психоактивных веществ (ПАВ). Первичная профилактика является наиболее
                    массовой и неспецифичной. В ее задачи входит не столько предупреждение потребления ПАВ, сколько
                    формирование здорового образа жизни, адаптивных форм поведения, снижение влияния факторов риска.
                    Основные задачи первичной профилактики это: совершенствование поведенческих стратегий, увеличение
                    потенциала личностных качеств, формирование позитивной, устойчивой «Я-концепции», повышение
                    эффективности функционирования социально-поддерживающих сетей, развитие внутреннего самоконтроля.
                    Вторичная профилактика у детей, подростков и молодежи включает в себя медицинские и
                    социально-психологические мероприятия, направленные на детей и подростков с поведением риска, как —
                    то аддиктивное поведение, уходы из дома, другие формы девиантного или асоциального поведения. Цель
                    вторичной профилактики — профилактика развития заболевания (зависимости), психологическая и
                    социальная адаптация путем формирования мотивации на полный отказ от ПАВ, формирования мотивации на
                    изменение дезадаптивного поведения, формированания социально-поддерживающего поведения. Третичная
                    профилактика наркозависимости среди подростков и молодежи является преимущественно
                    медико-социальной, индивидуальной и направленной на предупреждение сформированного заболевания в
                    более тяжелую форму стойкой дезадаптации. Целью третичной профилактики является максимально
                    возможное увеличение сроков ремиссии [2].</p>
                <p>Есть ряд требований, выполнение которых помогает сделать профилактику более эффективной и избежать
                    риска обратного эффекта, когда подростки начинают испытывать интерес к запретному плоду наркомании:
                    информация должна подаваться в позитивном ключе без ощущения безысходности; негативная часть
                    информация должна касаться непосредственно трагических последствий употребления наркотиков и
                    алкоголя; публикации должны иметь четкие выводы и рекомендации; заниматься подготовкой материалов и
                    проведением мероприятий профилактического характера должны только специалисты: психологи, наркологи,
                    соцработники [3]. В настоящее время, проблема духовности ушла на последнее место, а лидирующие
                    позиции заняли материальные интересы. Духовность — это высший уровень самосознания человека, тогда,
                    когда преобладающими в жизни являются духовно-нравственные и интеллектуальные позиции и интересы.
                    Человек, который в процессе всей жизни ищет смысл своего существования, своего предназначения в
                    мире, стремится к самоопределению, является духовно развитым.</p>
                <p>Авторским коллективом [3]. разработана программа первичной профилактики ВИЧ/СПИДа и рискованного
                    поведения для детей старшего подросткового возраста «ЛадьяЯ (В ладу с собой)». Её авторы считают,
                    что развитие духовной сферы особенно важно в подростковом возрасте, когда ведущим фактором
                    социализации является самоопределение, которое проявляется в желании сформировать определенную
                    смысловую систему представлений о мире и о себе самом. Формирование такой смысловой системы
                    невозможно без нахождения ответа на вопросы о смысле жизни и о системе нравственных ориентиров,
                    организующих поступки и поведение. Следует отметить, что проводя профилактические беседы с
                    подростками, нужно делать акцент на духовно — нравственных ценностях, давая понять, что обладая ими,
                    их духовный рост будет высок, а, следовательно и склонность к аддиктивному поведению будет не в
                    приоритете. Предлагаемая программа направлена на профилактику наркомании и рискованного поведения
                    для детей старшего подросткового возраста, но ее информативность так же позволяет проводить по
                    данной программе и беседы (конференции, семинары, круглые столы) по пропаганде здорового образа
                    жизни.</p>
                <p>Цель программы — первичная профилактика наркомании среди подростков посредством формирования системы
                    духовно-нравственных ориентиров. Программа реализуется в ходе решения ряда задач:<br/>
                    Содержательные задачи:<br/>
                    1. Формирование представлений об этических ценностях; создание условий для интериоризации этих
                    представлений; обеспечение ассимиляции предлагаемых ценностей нравственным самосознанием подростков;
                    развитие стремления опираться на них при осуществлении жизненных выборов. Важно показать подростку,
                    что эмоциональное состояние человека находится в лучшем положении тогда, когда он утвержден в
                    предпочтении определенных ценностей. Нравственный выбор представляет собой основополагающее явление
                    духовной жизни личности, так же при котором идет определение системы ориентиров — этических
                    принципов. Эти принципы не врожденные, а приобретённые, они усваиваются и становятся частью жизни в
                    процессе личностного роста.<br/>
                    2. Информирование подростков о ВИЧ-инфекции и сопряженных с ней проблемах, развитие представлений об
                    основных источниках риска ВИЧ-инфицирования.<br/>
                    3. Формирование психологической компетентности, которая является важным ресурсом
                    духовно-нравственного роста.<br/>
                    Организационные задачи. Для положительной результативности, для улучшения понимания данной проблемы
                    важно вовлечение в процесс не только самих подростков, но и их ближайшее окружение — родителей,
                    друзей, одноклассников, педагогов [3]. Проводя профилактику наркомании на основе данных целей и
                    задач, представленных программой идет формирование положительной «Я — концепции», полное
                    информирование подростков по данной проблеме, ее последствиях, развитие духовной сферы
                    личности.<br/>
                    Мотивационные задачи:<br/>
                    1. Блок, раскрывающий понятия «Я-концепции», общения со сверстниками и противоположным полом, любви,
                    дружбы, полоролевой идентификации, а также понятия семьи, рода и культуры с точки зрения современной
                    психологии и системы духовно-нравственных ценностей;<br/>
                    2. Блок, касающийся жизненных кризисов и их преодоления, а также эмоционального стресса. В нем
                    раскрываются понятия здоровья и болезни, дается информация о ВИЧ/СПИДе, наркомании, о зависимости от
                    психоактивных веществ и формируются нравственные представления об отношении к больным людям и о
                    толерантности;<br/>
                    3. Блок, формирующий нравственные категории — такие, как потери и надежда, добро и зло, ценности и
                    цели, смысл, свобода, выбор.</p>
                <p>Таким образом, духовно — нравственный подход в предупреждении зависимости в подростковой среде,
                    формирование понятий о здоровом образе жизни очень важны в наше время, как и представлено в
                    «Концепции духовно — нравственного развития и воспитания личности гражданина России». Но, к
                    сожалению, подобные проекты и программы в настоящее время могут быть реализованы лишь во внешкольное
                    время. Профилактика наркомании будет эффективна тогда, когда государство будет учитывать все факторы
                    в процессе обучения и воспитания, чтобы профилактические проекты были регулярным явлением, а не
                    имели характер «вспышки», то есть возникновения в критические для государства, общества, подростков
                    ситуации.</p>
                <p><strong>СПИСОК ЛИТЕРАТУРЫ:</strong><br/>
                    1. Короленко Ц. П., Донских Т. А. Семь путей к катастрофе. Новосибирск: Наука, 1990.<br/>
                    2. Приказ Минобразования Р Ф № 619 «О концепции профилактики злоупотребления психоактивными
                    веществами в образовательной среде» / base.consultant.ru/cons/cgi/online (дата обращения 02.03.2014)<br/>
                    3. Сирота Н. А., Воробьева Т. В., Ялтонская А. В., Рыдалевская Е. Е., Яцышин С. М., Микляева А. В.,
                    Кудрявцева Н. В. Программа первичной профилактики ВИЧ / СПИДа и рискованного поведения для детей
                    старшего подросткового возраста «ЛадьЯ — „В ладу с собой“». Москва: Российский Круглый Стол,
                    2012.</p>
            </div>
            <div style={{fontSize: "17px", color: "#212529", fontWeight: "550"}} className={s.concept}>
                <p><strong>Курченко Е.В.</strong> Профилактика наркомании среди подростков посредством формирования
                    системы духовно — нравственных ориентиров // Электронный журнал «Общество. Культура. Наука.
                    Образование». 2014. Вып. 3.</p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>


    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default KurchenkoDrugs