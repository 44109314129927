import React from "react";
import ico from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import Falldown, {Falldown2, FileLi, HrefLi} from "../../common/FalldownList/Falldown";
import {NavLink} from "react-router-dom";
import {DownOutlined, ArrowRightOutlined} from "@ant-design/icons"
import {HrefsBeh1, PinkButton} from "../PageInfo/PageInfo"
import {hrefsArr} from "../../common/FilePage/FilesNPD"

const hrefsArr1 = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const FilesKDNVich = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Методические материалы по профилактике ВИЧ/СПИДа
                </h2>
                <Falldown
                    header={"Методические рекомендации для педагогических работников образовательных организаций по вопросам профилактики ВИЧ-инфекции"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://fcprc.ru/media/media/mediacia/Методические_рекомендации_по_вопросам_профилактики_ВИЧ-инфекции_ZYkhRJr.pdf">
                            Методические рекомендации для педагогических работников «Организация работы по вопросам профилактики ВИЧ-инфекции» (письмо Минпросвещения России от 1 марта 2024 года № ДГ-377/07)
                        </a>
                        <p></p>
                    </p>
                </Falldown>
                <Falldown
                    header={"Мероприятия в онлайн-формате, направленные на профилактику распространения ВИЧ-инфекции среди обучающихся, в 2023 году"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://disk.yandex.ru/i/k0MP5h4R3LLryg">
                            Всероссийский вебинар для педагогических работников образовательных организаций «Деятельность образовательных организаций по профилактике распространения ВИЧ-инфекции среди обучающихся»
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Презентация_Заевой.pdf">
                            Презентация Заевой О. В.
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Презентация_Кирьяновой.pdf">
                            Презентация Кирьяновой О. Н.
                        </a>
                        <p></p>
                    </p>
                </Falldown>
                <Falldown
                    header={"Мероприятия в онлайн-формате, направленные на профилактику распространения ВИЧ-инфекции среди обучающихся, в 2022 году"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://youtu.be/zsQiLWoNSjA" target="_blank">
                            Всероссийский вебинар для педагогических работников образовательных организаций
                            «Деятельность образовательных организаций по профилактике распространения ВИЧ-инфекции среди
                            обучающихся»
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Презентация_Заевой_О._В._вебинар.pdf">
                            Презентация Заевой О.В. (вебинар)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Презентация_Патран_А_.В.pdf">
                            Презентация Патран А.В.
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Презентация_Кирьяновой_О._Н..pdf">
                            Презентация Кирьяновой О.Н.
                        </a>
                        <p></p>
                        <a href="https://youtu.be/purliqknJBQ" target="_blank">
                            Всероссийское родительское собрание «Профилактика распространения ВИЧ-инфекции и
                            формирование ответственного и безопасного поведения детей, подростков и молодежи»
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Презентация_Заевой_О_В._собрание.pdf">
                            Презентация Заевой О.В. (собрание)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Презентация_Токаревой_Е._Д..pdf">
                            Презентация Токаревой Е. Д.
                        </a>
                        <p></p>
                    </p>
                </Falldown>
                <Falldown
                    header={"Материалы вебинара в рамках проведения Всероссийской акции \"СТОП ВИЧ/СПИД\" в 2021 году"}
                    style={{maxWidth: "700px"}}>
                    <FileLi
                        text={"Лучшие практики мероприятий в сфере профилактики суицида среди несовершеннолетних.pdf (0.31 Мб)"}/>
                    <p>
                        <a href="https://www.youtube.com/watch?v=tYMm0O7DcVw" target="_blank">
                            Запись вебинара
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-5.pdf">
                            Программа вебинара
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-6.pdf">
                            Презентация Ю.А. Костыряченко
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-7.pdf">
                            Презентация О.В. Заевой
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-8.pdf">
                            Презентация Т.А. Епояна
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-9.pdf">
                            Презентация Л.Ф. Кихтенко
                        </a>
                        <p></p>
                    </p>
                </Falldown>
                <Falldown
                    header={"Материалы вебинаров в рамках участия во Всероссийской акции «СТОП ВИЧ/СПИД» в 2020 году"}
                    style={{maxWidth: "700px"}}>
                    <Falldown2 header={"Вебинар для родителей по вопросам профилактики распространения ВИЧ-инфекции"}
                               style={{maxWidth: "700px"}}>
                        <HrefLi text="Запись вебинара" href="https://youtu.be/7Qkn17cgGqo?si=wf18o480jiAZxBaE"/>
                        <FileLi text="Программа вебинара" href="https://fcprc.ru/media/media/behavior/Программа_вебинара_28.11.2020.pdf"/>
                        <FileLi text="Презентация Токаревой Е.Д." href="https://fcprc.ru/media/media/behavior/Презентация_Токаревой_Е.Д..pdf"/>
                        <FileLi text="Презентация Гальцовой П.С." href="https://fcprc.ru/media/media/behavior/Презентация_Гальцовой_П.С..pdf"/>
                    </Falldown2>
                    <Falldown2 header={"Вебинар для педагогических работников образовательных организаций по вопросам организация работы по профилактике распространения ВИЧ-инфекции"}
                               style={{maxWidth: "700px"}}>
                        <HrefLi text="Запись вебинара" href="https://youtu.be/0nfx2jLwI_o?si=F-HFS1Np4YmnqnIl"/>
                        <FileLi text="Программа вебинара" href="https://fcprc.ru/media/media/behavior/Программа_вебинара_30.11.2020.pdf"/>
                        <FileLi text="Презентация Заевой О.В." href="https://fcprc.ru/media/media/behavior/30112020.pdf"/>
                        <FileLi text="Презентация Кирьяновой О.Н." href="https://fcprc.ru/media/media/behavior/Prezenattsiya-Kiryanova-O.N..pdf"/>
                        <FileLi text="Презентация Муратовой Е.Е." href="https://fcprc.ru/media/media/behavior/ПереходныйВозрастМуратова.pdf"/>
                    </Falldown2>
                </Falldown>
                <Falldown
                    header={"Практическое руководство для руководителей и педагогических работников образовательных организаций по предотвращению дискриминации обучающихся и работников образовательных организаций, живущих с ВИЧ (2019 год)"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://fcprc.ru/media/media/behavior/Prakticheskoe-rukovodstvo-po-predotvrashheniyu-diskriminatsii-zhivushhih-s-VICH.pdf">
                            Практическое руководство по предотвращению дискриминации живущих с ВИЧ
                        </a>
                        <p></p>
                        <a href="/webinars/vserossijskij-vebinar-dlya-pedagogov-zamestitelej-rukovoditelej-obrazovatelnyh-organizatsij-po-metodicheskim-voprosam-kompleksnogo-podhoda-k-formirovaniyu-kultury-zdorovogo-obraza-zhizni-u-detej-i-pod">
                            Материалы Всероссийского вебинара для педагогов, заместителей руководителей образовательных
                            организаций по методическим вопросам комплексного подхода к формированию культуры здорового
                            образа жизни у детей и подростков, вопросам предотвращения дискриминации обучающихся и
                            работников образовательных организаций, живущих с ВИЧ (26 ноября 2019 г.)
                        </a>
                    </p>
                </Falldown>
                <Falldown
                    header={"Методические рекомендации для педагогических работников по подготовке и проведению родительского собрания «НЕЗРИМЫЕ УГРОЗЫ МИФЫ И РЕАЛЬНОСТЬ», приуроченного ко Всемирному дню борьбы со СПИДом (2018 год)"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://fcprc.ru/media/media/behavior/2.-Metodicheskie-rekomendatsii-roditelskoe-sobranie_VICH-2018.pdf">
                            Методические рекомендации родительское собрание_ВИЧ 2018.pdf (17.7 Мб)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/PamyatkaVICH.pdf">
                            Памятка для родителей ВИЧ СПИД 2018.pdf (0.22 Мб)
                        </a>
                    </p>
                </Falldown>
                <Falldown
                    header={"Методические рекомендации по организации и проведению Всероссийского открытого интернет-урока «День единых действий по информированию детей молодежи против ВИЧ/СПИДа «ЗНАНИЕ – ОТВЕТСТВЕННОСТЬ – ЗДОРОВЬЕ» (2017 год)"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://fcprc.ru/media/media/behavior/3.-Metodicheskie-rekomendatsii_Aktsiya-STOP-VICH-SPID-2017.pdf">
                            Методические материалы _Акция СТОП ВИЧ СПИД.pdf (0.71 Мб)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/3.1.pdf">
                            Сценарий интернет-урока_Акция СТОП ВИЧ СПИД 2017.pdf (1.18 Мб)
                        </a>
                    </p>
                </Falldown>
                <Falldown
                    header={"Программы повышения квалификации по вопросам социально-психологической поддержки лиц, зараженных ВИЧ, а также сопровождения семей, в которых проживает ребенок с ВИЧ. (2016 год)"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://fcprc.ru/media/media/behavior/obr-modul.pdf">
                            Образовательный модуль «Поддержка ВИЧ-инфицированных».pdf (0.8 Мб)
                        </a>
                    </p>
                </Falldown>
                <Falldown
                    header={"Программа реализации мероприятий первичной профилактики ВИЧ-инфекции в образовательной среде"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://fcprc.ru/media/media/behavior/Programma-realizatsii-meropriyatii-pervichnoi-profilaktiki-vich-infektsii_vigwCSb.pdf">
                            Программа реализации мероприятий первичной профилактики вич-инфекции в образовательной
                            среде.pdf (1 Мб)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Rezultaty-aprobatsii-programmy-profilaktiki-VICH-infektsii-v-obshheobrazo_4ZymNfL.pdf">
                            Результаты апробации программы профилактики ВИЧ-инфекции в общеобразовательных учреждениях
                            РФ.pdf (0.14 Мб)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Retsenziya.pdf">
                            Рецензия на практические рекомендации.pdf (1.32 Мб)
                        </a>
                    </p>
                </Falldown>
                <Falldown
                    header={"Архив научно-методических материалов по профилактике ВИЧ/СПИДа"}
                    style={{maxWidth: "700px"}}>
                    <p>
                        <a href="https://fcprc.ru/media/media/behavior/Informatsionnoe-posobie-dlya-sotrudnikov-doshkolnyh-i-obshheobrazovatelny_jds0rAd.pdf">
                            Информационное пособие для сотрудников дошкольных и общеобразовательных учреждений «Дети со
                            знаком плюс».pdf (0.72 Мб)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Metodicheskie-rekomendatsii-po-organizatsii-i-provedeniyu-Vserosiii-skoi.pdf">
                            Методические рекомендации по организации и проведению Всеросиийской акции, приуроченной к
                            Всемирном дню борьбы со СПИДом.pdf (0.2 Мб)
                        </a>
                        <p></p>
                        <a href="https://fcprc.ru/media/media/behavior/Programma-profilaktiki-VICH-infektsii-v-obshheobrazovatelnyh.pdf">
                            Программа профилактики ВИЧ-инфекции в общеобразовательных учреждениях РФ.pdf (0.89 Мб)
                        </a>
                        <p></p>
                        <a href="/responsible-behavior/monitoring-federalnyh-i-regionalnyh-programm">
                            Мониторинг федеральных и региональных программ
                        </a>
                        <p></p>
                        <a href="/responsible-behavior/hudozhestvennyj-film-vyshe-neba">
                            Художественный фильм «Выше неба»
                        </a>
                        <p></p>
                        <a href="/responsible-behavior/soloveva-n-v-sotsialno-psihologicheskoe-soprovozhdenie-semej-vospityvayushhih-vich-infitsirovannyh-detej">
                            Соловьева Н.В. Социально-психологическое сопровождение семей, воспитывающих
                            ВИЧ-инфицированных детей
                        </a>
                        <p></p>
                        <a href="/responsible-behavior/belozerova-m-v-rol-nekommercheskih-organizatsij-v-profilaktike-vich-infektsii">
                            Белозерова М. В. Роль некоммерческих организаций в профилактике ВИЧ-инфекции
                        </a>
                        <p></p>
                    </p>
                </Falldown>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="https://fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}


const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}

const WhiteButton = ({text, stylesheet, refs = "/"}) => {
    return <div className={s.whitebutton}>
        <a href={refs}>

            {text}
        </a>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
export default FilesKDNVich