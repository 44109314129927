import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";

const DoNotCrossLine = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Состоялась презентация документального фильма «Не пересечь черту» о роли значимого взрослого в жизни
            подростков
        </h2>
        <div>
          
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>2023 год объявлен президентом Годом наставника и педагога в России.</p>
            <p>Фонд защиты детей при поддержке Минпросвещения России снял документальный фильм о жизненных историях
                подростков, преступивших черту, и о педагогах, наставниках, которые помогли оглянуться назад, осмыслить
                пройденное, построить успешную траекторию своей жизни.</p>
            <p>Фильм рассказывает о практике системы российского образования,
                об организациях, людях, которые приходят на помощь, когда уже трудно поверить, что можно остановиться и
                все изменить.</p>
            <p>Сюжет фильма раскрывает особенности организации воспитательной работы
                с несовершеннолетними с девиантным поведением, рассказывает о становлении личности детей, формировании
                их жизненной позиции, понимании своего места
                в жизни настоящей и будущей. Сценарий предусматривает раскрытие содержания
                с двух позиций: ребенка и взрослого.</p>
            <p>География героев фильма от Хабаровского края до Республики Адыгея.</p>
            <p>Съемочными площадками стали специальное учебно-воспитательное учреждение открытого типа «Уральское
                подворье» (Пермский край), Хабаровский краевой центр психолого-педагогической, медицинской и социальной
                помощи (Хабаровский край), Майкопское специальное учебно-воспитательное учреждение закрытого типа
                (Республика Адыгея), социальный проект «Трудный театр. Премьера» (Ростовская область).</p>
            <p><strong><a href="https://pressria.ru/20230323/954383458.html">Презентация состоялась в МИА «Россия сегодня» и на
                дискуссионной площадке Всероссийского форума «Я – наставник».</a></strong></p>
            <p>Полную версию фильма можно посмотреть <strong><a href="https://disk.yandex.ru/i/RDoNJN5drAwFag">по ссылке</a></strong>.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default DoNotCrossLine;