import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News191023.jpg"
import img1 from "../../assets/News/News191023_1.jpg"
import img2 from "../../assets/News/News191023_2.jpg"
import img3 from "../../assets/News/News191023_3.jpg"
import img4 from "../../assets/News/News191023_4.jpg"
import img5 from "../../assets/News/News191023_5.jpg"
import img6 from "../../assets/News/News191023_6.jpg"

const News191023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Тематическая смена «Юный медиатор» в ВДЦ «Орленок» в 2023 году
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            С 12 по 20 сентября 2023 года по поручению Министерства просвещения Российской Федерации сотрудники отдела
            медиации федерального государственного бюджетного учреждения «Центр защиты прав и интересов детей» при
            содействии Международного союза детских общественных объединений «Союз пионерских организаций – Федерация
            детских организаций» провели тематическую смену «Юный медиатор» на базе лагеря «Комсомольский»
            Всероссийского детского центра «Орленок».

            <p></p>
            На смену приехали активисты и лидеры детских общественных объединений, занимающиеся общественно полезной
            деятельностью из 19 регионов России: Республика Адыгея, Республика Северная Осетия-Алания, Удмуртская
            Республика, Краснодарский край, Астраханская область, Белгородская область, Брянская область, Волгоградская
            область, Воронежская область, Иркутская область, Костромская область, Курская область, Липецкая область,
            Оренбургская область, Орловская область, Ростовская область, Саратовская область, Ульяновская область, город
            Москва.
            <p></p>

            Сто шестьдесят шесть девчонок и мальчишек от 12 до 17 лет прошли программу «Юный медиатор».

            <p></p>
            Программа включала в себя занятия по медиации, на которых участники познакомились с понятием «конфликта»,
            причинами его возникновения, последствиями, стратегиями поведения в конфликтной ситуации, научились
            корректировать негативное эмоциональное состояние и прояснять интересы собеседника.
            <p></p>

            В формате групповой дискуссии подростки сформулировали важность принципов медиации и пришли к выводу, что,
            опираясь на такие принципы можно лучше понимать других людей, выстраивать интересное общение, сохранить
            дружбу.
            <p></p>

            Формат интерактивных занятий позволил участникам побыть как в роли стороны конфликта, так и в роли
            медиатора, помогающего сторонам прийти к оптимальному и взаимовыгодному решению.

            <p></p>
            Все участники смены получили актуальные знания бесконфликтного общения и практический опыт взаимодействия с
            педагогами и сверстниками. По возвращению домой юные медиаторы смогут принять участие в работе служб
            медиации и примирения в своих школах, участвовать в просветительских мероприятиях медиативной
            направленности, способствовать продвижению культуры конструктивного общения и гуманистических ценностей в
            образовательных организациях, в семье, в нашем обществе в целом.
            <p></p>

            Каждому участнику были вручены сертификаты о прохождении программы.
            <p></p>

            Благодаря совместной работе с сотрудниками лагеря «Комсомольский» в лице директора – Коверды Владимира
            Сергеевича, а также с вожатыми и организаторами тематической смены «Детская дипломатия дружбы» в лице её
            руководителя – Краснова Дмитрия Анатольевича наша смена «Юный медиатор» прошла успешно и вызвала большой
            интерес у участников.

            <p></p>
            Ведущие смены – сотрудники ФГБУ «Центр защиты прав и интересов детей»: начальник отдела медиации Марина
            Викторовна Быкова и аналитик II категории отдела медиации Анатолий Андреевич Козлов.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <ToNews/>
    </div>
}


export default News191023;