import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News2102022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            «ВыборПрофи» — проект по профессиональному самоопределению детей, воспитывающихся в замещающих семьях
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Автономная некоммерческая организация Центр развития социальных проектов (АНО ЦРПС) реализует
                социально-образовательный проект «ВыборПрофи», направленный на повышение образовательного уровня
                детей-сирот и детей, оставшихся без попечения родителей, воспитывающихся в замещающих семьях, и
                подготовке к поступлению в образовательные организации среднего профессионального и высшего
                образования.</p>
            <p>Срок реализации проекта – до 31 мая 2022 г.</p>
            <p>
                В рамках проекта предусмотрены дополнительные дистанционные занятия по общеобразовательным предметам с
                целью повышения уровня успеваемости детей и их подготовки к сдаче ОГЭ и ЕГЭ, мероприятия, направленные
                на построение осознанного образовательного и профессионального маршрута.
            </p>
            <p>
                В настоящий момент проводится дополнительный набор участников из числа детей-сирот и детей, оставшихся
                без попечения родителей, учащихся 8-11 классов, находящихся на воспитании в замещающих семьях, а также
                их законных представителей, для участия в мероприятиях проекта.
            </p>
            <p>
                Информация о проекте размещена на сайте АНО ЦРПС <a href="https://www.centrrsp.ru">https://www.centrrsp.ru</a> в разделе социально-образовательного проекта «ВыборПрофи».
            </p>
            <p>
                Вопросы об участии в проекте возможно направлять на адрес электронной почты: <a href="mailto:ekaterina@centrrsp.ru">ekaterina@centrrsp.ru</a>.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News2102022;