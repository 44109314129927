import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const ChildDefence2022 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Всероссийская научно-практическая конференция по актуальным проблемам профилактики девиантного
                    поведения
                    несовершеннолетних «На защите детства: стратегии, практики, ресурсы»

                </h2>

                <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                    <p>В соответствии с пунктом 26 Плана мероприятий на 2021–2025 годы
                        по реализации Концепции развития системы профилактики безнадзорности
                        и правонарушений несовершеннолетних на период до 2025 года (далее – План мероприятий),
                        утвержденного
                        распоряжением Правительства Российской Федерации от 18 марта 2021 г.
                        № 656-р, Министерство просвещения Российской Федерации, заинтересованные органы исполнительной
                        власти, органы исполнительной власти субъектов Российской Федерации обеспечивают организацию и
                        проведение совещаний, конференций, семинаров, круглых столов, дискуссионных площадок для
                        специалистов учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних
                        по
                        актуальным вопросам профилактики девиантного поведения несовершеннолетних.</p>
                    <p></p>
                    <p>В 2022 году Минпросвещения России проводит Всероссийскую научно-практическую конференцию по
                        актуальным проблемам профилактики девиантного поведения несовершеннолетних «На защите детства:
                        стратегии, практики, ресурсы» (далее – Конференция), цель которой – обсуждение эффективных
                        механизмов выявления, предупреждения и устранения причин и условий, обуславливающих девиантное
                        поведение несовершеннолетних, с учетом современных исследований и успешной практики
                        профилактической
                        работы.</p>
                    <p>
                        Оператор Конференции – ФГБУ «Центр защиты прав и интересов детей».
                    </p>

                    <p>
                        В Конференции запланировано участие представителей Администрации Президента Российской
                        Федерации,
                        аппарата Уполномоченного при Президенте Российской Федерации
                        по правам ребенка; заинтересованных федеральных органов власти; органов исполнительной власти
                        субъектов Российской Федерации, осуществляющих государственное управление
                        в сфере образования, органов и учреждений системы профилактики безнадзорности
                        и правонарушений несовершеннолетних субъектов Российской Федерации; научных, общественных
                        организаций.
                    </p>

                    <p>
                        В 2022 году проведение Конференции приурочено к Международному дню защиты детей
                        и запланировано на 31 мая – 1 июня в смешанном формате: дистанционном (пленарная сессия,
                        панельная
                        дискуссия, фокус-сессия, онлайн-марафон мастер-классов, видеомарафон передового опыта
                        профилактики)
                        и заочном (публикация в электронном сборнике, доступ
                        к материалам Конференции).
                    </p>
                </p>
                <p><a className={s.concepcA}
                      href="https://fcprc.ru/media/media/behavior/PROGRAMMA-KONFERENTSII.pdf">Программа
                    Конференции</a></p>
                <p><a className={s.concepcA}
                      href="https://disk.yandex.ru/d/eA1nkjBJMS9njw">Материалы
                    Конференции</a></p>
                <p><a className={s.concepcA}
                      href="https://fcprc.ru/media/media/behavior/Sbornik-konferentsii_Na-zashhite-detstva-2022.pdf">Сборник
                    материалов по итогам Конференции</a></p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>


        </div>
    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
    {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default ChildDefence2022