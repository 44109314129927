import React, { useEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import "./index.css"
import i1 from "../../assets/Viktorina/1-1.png"
import i2 from "../../assets/Viktorina/2-1.png"
import i3 from "../../assets/Viktorina/3-1.png"
import i4 from "../../assets/Viktorina/4-1.png"
import i5 from "../../assets/Viktorina/5-1.png"
import i6 from "../../assets/Viktorina/6-1.png"
import i7 from "../../assets/Viktorina/7-1.png"
import i8 from "../../assets/Viktorina/8-1.png"
import i9 from "../../assets/Viktorina/9-1.png"
import i10 from "../../assets/Viktorina/10-1.png"
import i11 from "../../assets/Viktorina/11-1.png"
import i12 from "../../assets/Viktorina/12-1.png"
import i13 from "../../assets/Viktorina/13-1.png"
import i14 from "../../assets/Viktorina/14-1.png"
import i15 from "../../assets/Viktorina/15-1.png"
import i16 from "../../assets/Viktorina/16-1.png"
import i17 from "../../assets/Viktorina/17-1.png"
import i18 from "../../assets/Viktorina/18-1.png"
import i19 from "../../assets/Viktorina/19-1.png"
import i20 from "../../assets/Viktorina/20-1.png"
import i21 from "../../assets/Viktorina/21-1.png"
import i22 from "../../assets/Viktorina/22-1.png"
import i23 from "../../assets/Viktorina/23-1.png"
import i24 from "../../assets/Viktorina/24-1.png"
import i25 from "../../assets/Viktorina/25-1.png"
import i26 from "../../assets/Viktorina/26-1.png"
import i27 from "../../assets/Viktorina/27-1.png"
import i28 from "../../assets/Viktorina/28-1.png"
import i29 from "../../assets/Viktorina/29-1.png"
import i30 from "../../assets/Viktorina/30-2.png"
import i31 from "../../assets/Viktorina/31-1.png"
import i32 from "../../assets/Viktorina/32-1.png"
import i33 from "../../assets/Viktorina/33-1.png"
import i34 from "../../assets/Viktorina/34-1.png"
import i35 from "../../assets/Viktorina/35-1.png"
import i36 from "../../assets/Viktorina/36-1.png"
import i37 from "../../assets/Viktorina/37-1.png"
import i38 from "../../assets/Viktorina/38-1.png"
import i39 from "../../assets/Viktorina/39-1.png"
import i40 from "../../assets/Viktorina/40-1.png"
import i41 from "../../assets/Viktorina/41-2.png"
import i42 from "../../assets/Viktorina/42-1.png"
import i43 from "../../assets/Viktorina/43-1.png"
import i44 from "../../assets/Viktorina/44-1.png"
import i45 from "../../assets/Viktorina/45-2.png"
import i46 from "../../assets/Viktorina/46-2.png"
import i47 from "../../assets/Viktorina/47-1.png"
import i48 from "../../assets/Viktorina/48-1.png"
import i49 from "../../assets/Viktorina/49-1.png"
import i50 from "../../assets/Viktorina/50-2.png"
import rob from "../../assets/Viktorina/robot3.png"
import axios from "axios";
import {Formik, Field, Form} from "formik";
import s from "../common/PageInfo/PageInfo.module.css"
import ImgPopup from "../common/Popup/PopupImg"


const Concurs = ({isConcurs, setPageConcurs}) => {
    const [active, setActive] = useState(0)
    useEffect(() => {
        setPageConcurs(false)
    })
    return<div>
		<h1 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1300px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Комикс по финансовой грамотности
			<p></p>
			После прочтение комикса ты сможешь пройти тест и получить награду!
        </h1>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}} onClick={() => {
            setActive(active+1)
        }}  className="block">

        <SimpleImageSlider images={[i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, i11, i12, i13, i14, i15, i16,i17,i18,i19,i20,i21,i22,i23,i24,i25,i26,i27,i28,i29,i30,i31,i32,i33,i34,i35,i36,i37,i38,i39,i40,,i41,i42,i43, i44, i45, i46, i47, i48, i49, i50]} 
        width={"60vw"}
        height={"85vh"} style={{textAlign:"center", margin:"50px auto"}} showBullets={true}
        showNavs={true}/>
        </div>
        <div>
            {
                (active <= 50)?<></>:<App/>
            }
           
        </div>
    </div>
}

export function App() {
	const questions = [
		{
			questionText: 'В течение какого срока можно поменять пиджак, не подошедшей по фасону, цвету, размеру и т.д. ?',
			answerOptions: [
				{ answerText: '20 дней', isCorrect: false },
				{ answerText: '14  дней', isCorrect: true },
				{ answerText: '10 дней', isCorrect: false },
				{ answerText: '7 дней', isCorrect: false },
			],
		},
		{
			questionText: 'Что выгоднее покупать?',
			answerOptions: [
				{ answerText: 'Молоко в бутылке объёмом 1 л стоимостью 60 рублей', isCorrect: true },
				{ answerText: 'Молоко в бутылке объёмом 900 мл стоимостью 55 рублей', isCorrect: false },
				{ answerText: 'Молоко в бутылке объёмом 800 мл стоимостью 50 рублей', isCorrect: false },
			],
		},
		{
			questionText: 'В какое учреждение люди обращаются за займом денег?',
			answerOptions: [
				{ answerText: 'налоговая служба', isCorrect: false },
				{ answerText: 'предприятие', isCorrect: false },
				{ answerText: 'суд', isCorrect: false },
				{ answerText: 'банк', isCorrect: true },
			],
		},
		{
			questionText: 'Банковская карта – это…',
			answerOptions: [
				{ answerText: 'дисконтная карта', isCorrect: false },
				{ answerText: 'карта, по которой можно найти офис банка', isCorrect: false },
				{ answerText: 'карта с личной финансовой информацией клиента банка', isCorrect: false },
				{ answerText: 'карта, дающая возможность пользоваться банковским счетом', isCorrect: true },
			],
		},
		{
			questionText: 'Что из перечисленного НЕ является способом защиты от интернет-мошенников?',
			answerOptions: [
				{ answerText: 'никогда и никому не сообщать пароли', isCorrect: false },
				{ answerText: 'сообщать пароли только сотрудникам банка', isCorrect: true },
				{ answerText: 'при поиске удаленной работы не реагировать на просьбы оплаты каких-либо регистрационных взносов', isCorrect: false },
				{ answerText: 'не открывать сайты платежных систем по ссылке (например, в письмах)', isCorrect: false },
			],
		},
		{
			questionText: 'Что относится к коммунальным платежам?',
			answerOptions: [
				{ answerText: 'оплата компьютерных игр', isCorrect: false },
				{ answerText: 'плата за холодную и горячую воду', isCorrect: true },
				{ answerText: 'оплата развлечений и досуга', isCorrect: false },
				{ answerText: 'оплата продуктов питания                ', isCorrect: false },
			],
		},
		{
			questionText: 'При каком уровне дохода в месяц Вам нужно начинать планирование своего бюджета?',
			answerOptions: [
				{ answerText: 'от 15 000 до 30 000 рублей в месяц', isCorrect: false },
				{ answerText: 'независимо от уровня дохода', isCorrect: true },
				{ answerText: 'более 100 000 рублей в месяц', isCorrect: false },
			],
		},
		{
			questionText: 'Что можно отнести к регулярным источникам дохода?',
			answerOptions: [
				{ answerText: 'выигрыш в лотерею', isCorrect: false },
				{ answerText: 'получение кредита', isCorrect: false },
				{ answerText: 'доходы от сдачи в аренду квартиры', isCorrect: true },
				{ answerText: 'заработная плата на временных местах работы', isCorrect: false },
			],
		},
		{
			questionText: 'На вашем банковском счете лежит 100 000 руб. и банк ежегодно начисляет 10% на остаток по счету. Сколько денег будет на вашем счете спустя 2 года при условии, что все это время вы не снимали деньги со своего счета?',
			answerOptions: [
				{ answerText: '101 000', isCorrect: false },
				{ answerText: '121 000', isCorrect: true },
				{ answerText: '110 000', isCorrect: false },
				{ answerText: '200 000', isCorrect: false },
			],
		},
		{
			questionText: 'Что такое заработная плата?',
			answerOptions: [
				{ answerText: 'добавка к социальной пенсии', isCorrect: false },
				{ answerText: 'вознаграждение за труд в зависимости от квалификации работника, сложности, количества, качества и условий выполняемой работы', isCorrect: true },
				{ answerText: 'вознаграждение за то, что пришел на работу и провел там определенное время', isCorrect: false },
    			],
		},
	
	];

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
    const [active, setActive] = useState(false)
    const [data, setData] = useState("")
	const [isConcursStart, setStartConcurs] = useState(false)

	const handleAnswerOptionClick =  (isCorrect) => {
		if (isCorrect) {
			setScore(score + 1);
			
		}

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
        let data = new FormData();
		data.append('email', 'name');

		let config = {
  			method: 'post',
  			maxBodyLength: Infinity,
  			url: 'https://fcprc.ru/api/victory',
  			headers: { 
    			
  			},
  			data : data
};


if(score+1 === questions.length){
	axios.get('https://fcprc.ru/api/concurs').then(res => {
		if(res.data.length <= 20){
			setActive(true)
		}
	})
	
	
}
}
};

	return (<>
		<h1 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1300px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Пришло время проверить свои знания!
			<p></p>
			
        </h1>
		<div className='blockVic'>
		<div>
			<img src={rob}/>
		</div>
		{!isConcursStart 
		? 
		<div className='question-section'>
			<div style={{color:"white", backgroundColor:"#252d4a", padding:"10px", borderRadius:"15px"}}>

			Привет! Меня зовут Р22 и хочу проверить твои знания.
Готов ли ты ответить на все вопросы моей викторины и стань Чемпионом?
			</div>
			<button className="button-app" onClick={() => setStartConcurs(true)}> Начать</button>
		</div> 
		: <div className='apps'>
			{showScore ? (
				<div className='score-section'>
					Вы набрали {score} из {questions.length}
                    <div>
                    <div>
                    <button  className="button-app"><a href="https://fcprc.ru/media/media/behavior/Сертификат_участника_викторины_OodwsiL.pdf">Сертификат участника</a> </button> 
                    </div>
                    </div>
				</div>
			) : (
				<>
					<div className='question-section'>
						<div className='question-count'>
							<span>Вопрос {currentQuestion + 1}</span>/{questions.length}
						</div>
						<div className='question-text'>{questions[currentQuestion].questionText}</div>
					</div>
					<div className='answer-section'>
						{questions[currentQuestion].answerOptions.map((answerOption) => (
							<button className="button-app" onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}>{answerOption.answerText}</button>
							))}
					</div>
				</>
			)}
            <ImgPopup active={active} setActive={setActive}>
            <div className={s.form}>
                            <div>
                                <h1>
                                    Форма для связи с победителем
                                </h1>
                            </div>
                            <Formik
                                initialValues={{email: '',}}
								
                                onSubmit={(values, {setSubmitting}) => {
									setTimeout(() => {
										debugger
										axios.post('https://fcprc.ru/api/concurs', {'имя': values.имя,'email':"p.chuvikin@fcprcgov.ru", 'телефон': values.телефон,'ФИО': values.имя,},  
										{      xsrfCookieName: 'csrftoken',
										xsrfHeaderName: 'X-CSRFTOKEN',
									})
										setActive(false)
                                    }, 400);
                                }}
								>
                                {({errors, touched, isSubmitting}) => (
									<Form>
                                    <div>
                                        <div>
                                            <label fore="subject">ФИО
                                            </label>
                                        </div>
                                        <Field type="имя" name="имя" placeholder="ФИО"/>
                                    </div>
                                    
                                    <div>
                                        <div>
                                            <label fore="subject">Телефон
                                            </label>
                                        </div>
                                        <Field type="телефон" name="телефон" placeholder="телефон"/>
                                    </div>
                                    Перед отправкой убедитесь в правильности заполнения полей, иначе мы не сможем с вами связаться
                                    <div className={s.send}>
                                        <button>Отправить</button>
                                    </div>
                                    </Form>)}
                                    </Formik>
                                    </div>
            </ImgPopup>
		</div>
		}
		
										</div>
										</>
	);
}

export default Concurs