import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News170522.jpg"

const News170522 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            С 1 июня в России начнут работу летние профориентационные школы
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В рамках реализации федерального проекта «Успех каждого ребенка» Национального проекта «Образование» в
                летний период в 42 регионах Российской Федерации на базе 73 образовательных организаций, в том числе
                организаций оздоровления и отдыха, откроются каникулярные профориентационные школы для более чем 10 000
                детей. Участниками профориентационных образовательных программ станут: дети, проявившие выдающиеся
                способности, дети с ОВЗ и дети-инвалиды, дети с низкими образовательными результатами и дети находящиеся
                в социально опасной или трудной жизненной ситуации.</p>
            <p>Целью проведения таких школ является ранняя профессиональная ориентация и профессиональное
                самоопределение обучающихся и их включение в профессиональные пробы на базе индустриальных и социальных
                партнеров при непосредственном участии носителей профессии. На старте участников школ ожидает
                психолого-педагогическое профориентационное тестирование для определения наиболее подходящей сферы
                применения способностей и раскрытия потенциала.</p>
            <p>Основными темами профориентационных программ станут самые современные направления развития
                промышленности, медицины, IT-технологий, креативных индустрий, педагогики и гуманитарной сферы.</p>
            <p>За весенний каникулярный период Минпросвещением России и ФГБУК «ВЦХТ» (федеральным оператором) были
                организованы и проведены уже 19 профориентационных школ с участием 4 358 обучающихся. Партнерами смен
                стали ПАО «Камаз», госкорпорация «Роскосмос», ведущие ВУЗы России, АНО «Артмедиаобразование», Ассоциация
                развития финансовой грамотности, Банк России и другие организации.</p>
            <p>Предлагаем вам ознакомиться в видеоматериалами со смен: <a
                href="https://www.youtube.com/watch?v=Ltg3YZSLALU">https://www.youtube.com/watch?v=Ltg3YZSLALU</a></p>
            <p>СПРАВОЧНО:</p>
            <p>Материалы лучших образовательных практик собраны в Цифровом реестре, размещенном на сайте федерального
                оператора по адресу <a href="https://praktiki.vcht.center/">https://praktiki.vcht.center/</a>. Все
                отчеты о проведенных сменах содержатся на портале
                <a href="https://praktiki.vcht.center/">http://www.profrf.ru/</a>.</p>
            <p>Каникулярные профориентационные школы проводятся в рамках государственной поддержки реализации лучших
                образовательных практик по обновлению содержания и технологий дополнительного образования.</p>
            <p>Для экспертно-методического, информационно-аналитического и организационно-технического обеспечения
                проведения каникулярных профориентационных школ федеральным оператором ведется системная работа.</p>
            <p>По всем возникающим вопросам можно обратиться по электронной почте tolkachevaa@vcht.center</p>
            <p>11 мая прошел вебинар из цикла «Методическая среда ВЦХТ» по теме «Современные подходы, технологии, формы
                сопровождения профессионального самоопределения обучающихся в каникулярный период», участниками которого
                стали более 600 образовательных организаций из 163 муниципальных образований России. Слушатели были
                приглашены к сотрудничеству для включения реализуемых ими профориентационных практик в проект по
                проведению каникулярных профориентационных школ.</p>
            <p>Запись вебинара доступна по ссылке: <a
                href="https://vk.com/video-50260207_456243999">https://vk.com/video-50260207_456243999</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News170522;