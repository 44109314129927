import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News25062023.jpg"

const News25062023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Стратегическая сессия “Наставничество в России как вызов времени”
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p><strong>Дата проведения: 25 мая 2023<br/>

                Место проведения: Государственный университет просвещения<br/>

                Организатор: Государственный университет просвещения при поддержке Минпросвещения России<br/>

                Количество участников: 90</strong></p>
            <p>Открывая Год педагога и наставника 2 марта в Машуке, Президент России Владимир Путин объявил: “Вопросы
                обучения, наставничества – это всегда обращение к будущему. Опираясь на ваши знания и опыт, на традиции
                отечественной педагогики и, безусловно, используя передовые технологии, мы продолжим формирование
                суверенной системы образования – это очень важная вещь.”</p>
            <p>Для создания самодостаточной системы наставничества предстоит объединить усилия и представителей
                общественности, а также государственных органов власти, компаний - всех, кто формирует повестку в сфере
                наставничества по своему направлению.</p>
            <p>Государственный университет просвещения при поддержке Минпросвещения России организует мероприятие, на
                котором все участники смогут поделиться своим опытом и представлениями о том, как дол жно выглядеть
                будущее развития института наставничества в России.</p>
            <p>Для системного развития института наставничества в России необходимо выстроить единое понятийное поле,
                создать нормативно-правовое регулирование и сформировать понятные методы стимулирования наставничества.
                Для этого сегодня прорабатывается ряд стратегических инициатив: правотворческие инициативы в сфере
                наставничества, профессиональный стандарт “Наставник” и создание Ассоциации Наставников.</p>
            <p>Стратегическая Сессия стала ключевым событием в развитии института наставничества России и будет
                ориентирована на разработку реальных предложений, которые будут учтены в инициативах Государственной
                думы, Министерств, крупнейших корпораций и ведущих общественных организаций.</p>
            <p>Сессия будет состоять из общей установочной сессии и работы в группах по трём направлениям: “Наставник на
                рабочем месте”, “Наставник в образовании”, “Наставник в социальной сфере”. Участие во встрече принимают
                представители государственных, общественных и корпоративного секторов.</p>
            <p>Мероприятие собрало больше 90 участников.</p>
            <p>В рамках стратегической сессии будут обсуждаться вопросы стратегического развития института
                наставничества в России, разрабатываться предложения к Профстандарту «Наставник» и правотворческие
                инициативы. <strong>Спикеры:</strong><br/>

                <strong>· Виктор Сергеевич Неумывакин</strong>, директор Департамента государственной политики в сфере среднего
                профессионального образования и профессионального обучения Министерства просвещения Российской
                Федерации.<br/>

                <strong>· Любовь Николаевна Духанина</strong>, Председателя Совета по профессиональным квалификациям в сфере образования.<br/>

                <strong>· Ирина Александровна Кокоева</strong>, И.о. ректора Федерального государственного бюджетного образовательного
                учреждения высшего образования «Государственный университет просвещения» </p>
        </div>
        <ToNews/>
    </div>
}


export default News25062023;