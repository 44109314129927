import React from "react";
import ico from "../../../assets/img/link.png"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import { useState, useEffect } from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"

const hrefsArr = [
    {text:"Нормативно-правовые документы", input:[{text:"Федеральные законы", to:"/spec-materials-category/federalnye-zakony"}, {text:"Стратегии", to:"/spec-materials-category/strategii"}, {text:"Концепции", to:"/spec-materials-category/kontseptsii"}, {text:"Иные регламентирующие документы", to:"files-addect-behavior"}], to:"/spec-materials-category/normativno-pravovye-dokumenty"},
    {text:"Материалы для специалистов", input:[{text:"Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации", to:""}, 
    {text:"Методические материалы по профилактике аддиктивного поведения", to:"/"}, {text:"Статьи и публикации", to:""}], to:""},
    {text:"Материалы для родителей", to:"/spec-materials-category/informatsionnye-materialy-dlya-roditelej"},
    {text:"Материалы для подростков и молодёжи", to:"",
    input:[{text:"Информационные материалы для обучающихся", to:""}, {text:"Видеоролики, направленные на профилактику зависимого поведения обучающихся и формирование здорового образа жизни", to:""}]},
    {text:"Эффективная региональная политика",input:[{text:"Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации", to:""}, 
    {text:"Школьное телевидение как форма отражения и трансляции социальных инициатив", to:"/school-behavior"}], to:""},
    {text:"Мероприятия", to:""},
    {text:"Полезная информация", to:"/spec-materials-category/poleznye-ssylki-goryachie-linii"},
]
const BehSchool = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    },[])
    return<>
    <h1 style={{fontWeight: "normal", marginTop:"50px", color: "#3a4663"}}>
    Школьное телевидение как форма отражения и трансляции социальных инициатив
        </h1>
    <div className={s.filesLeftPage}>
        <div>
            <FileLeftLine text={"Школьное телевидение гимназии 1409"} refers="https://wifmedia.com/" ico={ico}/>
            <FileLeftLine text={"Школьное телевидение школы № 92 г. Волгограда"} refers="http://92tv.ru/" ico={ico}/>
            
        </div>
        <div>
            <HrefsBeh1 hrefsArr={hrefsArr}/>
        </div>
    </div>
    </>
}
export default BehSchool