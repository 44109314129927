import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News310522.png"

const News310522 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Открывается набор в магистратуру по профилю «Опека и попечительство в отношении несовершеннолетних»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p><strong>6 июня в 18:00</strong> состоится онлайн-презентация единственной в России программы
                магистратуры <strong>«Опека и
                    попечительство в отношении несовершеннолетних»</strong> факультета «Психология образования» ФГБОУ ВО
                «Московский
                государственный психолого-педагогический университет».</p>
            <p>В рамках мероприятия Вероника Нисоновна Ослон, руководитель программы, кандидат психологических наук,
                доцент, профессор кафедры «Возрастная психология имени профессора Л.Ф. Обуховой» факультета «Психология
                образования» ФГБОУ ВО МГППУ, расскажет о целях, задачах, содержании магистерской программы, условиях
                обучения.</p>
            <p>Подробнее о программе (<a
                href="https://fcprc.ru/media/media/behavior/магистратура_-_Опека_и_попечительство_в_отношении_несовершеннолетних.pdf">скачать</a>)
            </p>
            <p>Презентация будет проходить в формате вебинара. </p>
        </div>
        <ToNews/>
    </div>
}


export default News310522;