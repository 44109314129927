import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/Plen2020.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import img1 from  "../../../assets/News/Ploshad2.jpg"
import img2 from  "../../../assets/News/Ploshad2_2.jpg"
import img3 from  "../../../assets/News/Ploshad2_3.jpg"
import img4 from  "../../../assets/News/Ploshad2_4.jpg"
import img5 from  "../../../assets/News/Ploshad2_5.jpg"
import img6 from  "../../../assets/News/Ploshad2_6.jpg"
import file1 from "../../../assets/Files/Ploshad1_1.pdf"
import file2 from "../../../assets/Files/Arefeva-L.G..pptx"
import file3 from "../../../assets/Files/Ploshad1_3.pdf"
import file4 from "../../../assets/Files/Ploshad1_4.pdf"
import file5 from "../../../assets/Files/Konovalov-A.YU..pptx"
import file6 from "../../../assets/Files/Popova-I.N..pptx"
import file7 from "../../../assets/Files/Trunov-D.V..pptx"
import YouTube from "react-youtube";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const Ploshad2 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Площадка № 2 VII Всероссийского совещание КДН и ЗП
                </h2>
            </div>


            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><strong>
                    Дискуссионная площадка № 2
                    «Мониторинг региональных систем профилактики безнадзорности и правонарушений несовершеннолетних.
                    Обеспечение межведомственного взаимодействия при формировании ведомственных «профилактических
                    учетов»
                </strong></p>
                <p><strong>Модератор:</strong></p>
                <p>
                    Ильгова Екатерина Владимировна, ректор федерального государственного бюджетного образовательного
                    учреждения высшего образования «Саратовская государственная юридическая академия»
                </p>
                <p><strong>Сомодератор:</strong></p>
                <p>
                    Спивак Александр Михайлович, председатель правления Фонда «Национальный фонд защиты детей от
                    жестокого обращения»
                </p>
                <p><strong>Выступающие:</strong></p>
                <p>
                    Кузнецова Ирина Олеговна, доцент кафедры истории, политологии и социологии федерального
                    государственного бюджетного образовательного учреждения высшего образования «Саратовская
                    государственная юридическая академия».
                </p>
                <p>
                    Зайкова Светлана Николаевна, руководитель центра научно-методического обеспечения системы
                    профилактики безнадзорности и правонарушений несовершеннолетних федерального государственного
                    бюджетного образовательного учреждения высшего образования «Саратовская государственная юридическая
                    академия»
                </p>
                <p>
                    <strong>«Использование автоматизированных информационных систем в системе профилактики
                        безнадзорности и правонарушений несовершеннолетних: современное состояние и концепция
                        развития» </strong>
                </p>
                <p>
                    <strong>Спивак Александр Михайлович, председатель правления Фонда «Национальный фонд защиты детей от
                        жестокого обращения»</strong><br/>
                    <strong>«Система профилактики: основные векторы инновационного развития»</strong><br/>
                    Береговая Елена Николаевна, заведующий отделом по обеспечению деятельности комиссии по делам
                    несовершеннолетних и защите их прав при Правительстве Ставропольского края аппарата Правительства
                    Ставропольского края<br/>
                    <strong>«Опыт Ставропольского края по организации мониторинга деятельности органов и учреждений
                        системы профилактики безнадзорности и правонарушений несовершеннолетних по направлениям работы в
                        соответствии с положениями Федерального закона от 24 июня 1999 г. № 120-ФЗ «Об основах системы
                        профилактики безнадзорности и правонарушений несовершеннолетних»</strong>
                </p>
                <p>
                    Иванова Надежда Анатольевна, директор проектов направления «Молодые профессионалы» автономной
                    некоммерческой организации автономной некоммерческой организации «Агентство стратегических инициатив
                    по продвижению новых проектов»<br/>
                    <strong>«Конкурс лучших региональных и муниципальных управленческих практик – итоги. Эффективность и
                        результативность социальных практик в сфере детства»</strong>
                </p>
                <p>
                    Соколова Наталия Викторовна, Уполномоченный по правам ребенка в Псковской области
                    <strong>«Результаты проведенного совместно с несовершеннолетними исследования на тему:
                        «Эффективность
                        профилактики правонарушений в Псковской области глазами несовершеннолетних»</strong>
                </p>
                <p>
                    Крачковская Наталья Владимировна, начальник отдела Администрации Курской области по обеспечению
                    деятельности комиссии по делам несовершеннолетних и защите их прав, ответственный секретарь комиссии
                    по делам несовершеннолетних и защите их прав Администрации Курской области
                    <strong>«Об организации мониторинга эффективности деятельности комиссий по делам несовершеннолетних
                        и защите
                        их прав в Курской области»</strong>
                </p>
                <p>
                    Сипачева Ольга Николаевна, руководитель группы разработки и сопровождения программного обеспечения
                    ООО «Находка-АИС»
                    <strong>«Автоматизированные региональные информационные системы в системе профилактики
                        безнадзорности и
                        правонарушений несовершеннолетних»</strong>
                </p>
                <p>
                    Марков Максим Анатольевич, эксперт аппарата комиссии по делам несовершеннолетних и защите их прав
                    Ямало-Ненецкого автономного округа аппарата Губернатора Ямало-Ненецкого автономного округа
                    <strong>«Региональный банк данных семей и несовершеннолетних «группы особого внимания» как ключевой механизм
                        организации и проведения индивидуальной профилактической работы в Ямало-Ненецком автономном округе»</strong>
                </p>
                <YouTube id="WZjybiqLMW8"/>
                <YouTube id="5X9Ecl9NeOk"/>
                <div>
                    <img src={img1}/>
                </div>
                <div>
                    <img src={img2}/>
                </div>
                <div>
                    <img src={img3}/>
                </div>
                <div>
                    <img src={img4}/>
                </div>
                <div>
                    <img src={img5}/>
                </div>
                <div>
                    <img src={img6}/>
                </div>
                <p><a href={file1}>Амосов В.А.</a></p>
                <p><a href={file2}>Арефьева Л.Г.</a></p>
                <p><a href={file3}>Ванданова Э.Л.</a></p>
                <p><a href={file4}>Ильина Е.Г.</a></p>
                <p><a href={file5}>Коновалов А.Ю.</a></p>
                <p><a href={file6}>Попов И.Н.</a></p>
                <p><a href={file7}>Трунов Д.В.</a></p>

                Файлы в процессе добавления...
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default Ploshad2