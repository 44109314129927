import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/Mediacia/EightRules.jpg"
import YouTube from "react-youtube";

const EightRules = ({children, header}) => {
    const [newsData, setNews] = useState([])


    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: 8 правил безопасного общения с подростками: как родителям реагировать на «трудное»
            поведение ребенка
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.mediacia}>
            <p>
                13 апреля 2020 г. прошел вебинар по актуальной для родителей теме: «8 правил безопасного общения с
                подростками: как родителям реагировать на «трудное» поведение ребенка».
            </p>
            <p>
                Ведущая вебинара — аналитик Центра, кандидат педагогических наук Горнова Татьяна Сергеевна.
            </p>
            <p>
                В мероприятии приняли участие 1039 человек из 62 регионов Российской Федерации, а также Украины,
                Нидерландов, Германии и США.
            </p>
            <p>
                На вебинаре были раскрыты основные сложности, которые испытывают родители подростков. Родителям даны
                рекомендации о том, как сделать подростковый возраст безопасным для родителей и самих подростков, как
                правильно реагировать на поведение взрослеющего ребенка. В завершении вебинара родители получили ответы
                на интересующие вопросы.
            </p>
        </div>
        <div style={{margin: "0 auto"}}>
            <YouTube videoId="4kh_HfVmYoc"/>
        </div>
    </div>
}


export default EightRules