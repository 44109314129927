import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/CommissionsOnJuvenileAffairs.jpg"

const CommissionsOnJuvenileAffairs = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийское совещание по деятельности комиссий по делам несовершеннолетних.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Всероссийское совещание по деятельности комиссий по делам несовершеннолетних объединило свыше 1000
                человек из 89 регионов России.</p>
            <p>В Москве в очно-дистанционном формате состоялось IX Всероссийское совещание по вопросу организации
                деятельности комиссий по делам несовершеннолетних и защите их прав на тему «Современная профилактика
                безнадзорности и правонарушений несовершеннолетних: актуальные вызовы и системные решения». Мероприятие
                организовано Минпросвещения России. В его работе приняли участие свыше 1000 представителей из 89
                субъектов России, из них более 80 человек – в очном формате.</p>
            <p>К участникам Всероссийского совещания обратился первый заместитель Министра просвещения Российской
                Федерации Александр Бугаев, который поздравил всех с Днем Конституции России и рассказал о системе
                профилактики безнадзорности и правонарушений несовершеннолетних в стране.</p>
            <p>«Из общего числа субъектов системы профилактики в рамках Всероссийского совещания от комиссий
                регионального и местного уровня принимают участие 53 председателя комиссий, 134 заместителя
                председателя, 450 ответственных секретарей комиссий, 32 сотрудника аппаратов комиссий. Их деятельность
                нацелена на решение актуальных вопросов, затрагивающих судьбы детей», – сообщил Александр Бугаев.</p>
            <p>Первый заместитель Министра просвещения России отметил, что комиссии по делам несовершеннолетних и защите
                их прав несут миссию по обеспечению эффективной координации профилактической работы. По его словам, в
                последние годы сделано очень много в этом направлении, но большой фронт работы еще предстоит
                выполнить.</p>
            <p>Александр Бугаев подчеркнул, что необходимо выстраивать единые подходы, на которые должны ориентироваться
                специалисты сферы профилактики безнадзорности и правонарушений среди несовершеннолетних. Это касается
                организации индивидуальной работы по сопровождению семей, оказавшихся в трудной ситуации, общей
                профилактической работы в целом.</p>
            <p>Первый заместитель Министра просвещения заявил, что на протяжении последнего времени Правительственной
                комиссией по делам несовершеннолетних и защите их прав уделяется большое внимание вопросам повышения
                квалификации представителей комиссий. Для этого выстраивается централизованная работа в этом
                направлении.</p>
            <p>В рамках Всероссийского совещания рассмотрены вопросы координации межведомственного взаимодействия
                органов и учреждений по профилактике потребления детьми наркотических средств, новых потенциально
                опасных психоактивных веществ или одурманивающих веществ, а также деятельности органа опеки и
                попечительства как субъекта профилактики социального сиротства. Участники обсудили организацию работы
                комиссий по делам несовершеннолетних и защите их прав с учетом современных тенденций.</p>
            <p>Наиболее эффективные решения и предложения по совершенствованию межведомственного взаимодействия органов
                и учреждений системы профилактики правонарушений, сформированные участниками Всероссийского совещания,
                внесены в итоговую резолюцию. Документ станет практическим ориентиром для специалистов, работающих в
                сфере защиты прав несовершеннолетних, представителей комиссий субъектов России, федеральных и
                региональных органов исполнительной власти, общественных организаций и экспертного сообщества.</p>
        </div>
        <ToNews/>
    </div>
}


export default CommissionsOnJuvenileAffairs;