import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News280422.jpg"

const News280422 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Ответственные за Десятилетие детства в субъектах Российской Федерации получили практические навыки
            информационной работы
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>21 апреля 2022 г. Центр защиты прав и интересов детей совместно с Минпросвещения России провели
                методический вебинар «Популяризация мероприятий Десятилетия детства: проблемы, механизмы, практики».</p>
            <p>Модераторами мероприятия выступили Фальковская Лариса Павловна, директор Департамента государственной
                политики в сфере защиты прав детей Минпросвещения России, и Винокуров Алексей Алексеевич, заместитель
                директора Центра защиты прав и интересов детей.
                В своем выступлении Фальковская Л.П. отметила важность получения качественной и достоверной информации в
                современном мире. Также в вебинаре приняли участие представители пресс-службы Минпросвещения России,
                радиостанции «Детское Радио», Коммуникационного агентства АГТ, органов исполнительной власти Иркутской и
                Костромской областей.</p>
            <p>Участники мероприятия из всех 85 субъектов Российской Федерации получили практические рекомендации по
                адаптации новостных сообщений для детской аудитории, узнали об особенностях проведения эффективной
                информационной кампании на федеральном и региональном уровнях, а также познакомились с актуальными
                практиками, инструментами и механизмами информационного сопровождения Десятилетия детства.</p>
            <p>2018-2027 годы в Российской Федерации объявлены Десятилетием детства</p>
        </div>
        <ToNews/>
    </div>
}


export default News280422;