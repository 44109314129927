import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News201023.jpg"
import img2 from "../../assets/News/News201023_2.PNG"
import img3 from "../../assets/News/News201023_3.png"

const News201023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Открыт набор на курсы повышения квалификации
        </h2>
        <div style={{maxWidth: "100%"}}>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                Уважаемые коллеги!
            </p>
            <p>
                Федеральное государственное бюджетное учреждение «Центр защиты прав и интересов детей» Министерства просвещения Российской
                Федерации открывает курсы повышения квалификации для специалистов в сфере защиты прав и интересов детей по вопросам опеки
                и попечительства, медиации, помощи обучающимся с ограниченными возможностями здоровья и инвалидностью, профилактики девиантного
                поведения обучающихся.
            </p>
            <p>
                Разработчики и преподаватели программ курсов повышения квалификации - ведущие специалисты, члены экспертных групп
                по соответствующим направлениям Центра защиты прав и интересов детей и Минпросвещения России.
            </p>
            <p>
                Получить подробную информацию о проведении курсов и условиях обучения, записаться или направить запрос на обучение можно на портале
                дополнительного профессионального образования Центра: <a style={{fontSize: "22px", fontWeight: "bold"}} href="https://dpo.fcprc.ru/">dpo.fcprc.ru</a></p>
        </div>
        <div style={{width: "100%"}}>
            <div style={{margin: "0 auto", width: "250px", maxWidth: "100%"}}>
                <img src={img3} style={{margin: "0 auto", width: "100%"}}/>
            </div>

        </div>
        <div style={{maxWidth: "100%"}}>
            <img src={img2}/>
        </div>
    </div>
}


export default News201023;