import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News240524.png"

const News240524 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Стартовал Всероссийский конкурс социальной рекламы «Стиль жизни — здоровье! 2024
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Объявлен Всероссийский конкурс социальной рекламы в области формирования культуры здорового и безопасного
            образа жизни «Стиль жизни — здоровье! 2024», который проводится с целью повышения эффективности формирования
            и распространения культуры здорового и безопасного образа жизни среди детей и подростков.
            <p></p>
            В конкурсе могут принять участие обучающиеся (или коллективы обучающихся) в возрасте от 8 до 18 лет из
            образовательных организаций субъектов Российской Федерации.
            <p></p>
            Участники могут демонстрировать свои навыки и таланты в двух номинациях:
            <ol>
                <li>Социальный видеоролик по пропаганде здорового и безопасного образа жизни, профилактике зависимого
                    поведения обучающихся.</li>
                <li>Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни, профилактике
                    зависимого поведения обучающихся (буклет, плакат).</li>
            </ol>
            Региональные операторы Конкурса, назначенные органами исполнительной власти субъектов Российской Федерации,
            осуществляют проведение регионального этапа Конкурса.
            <p></p>
            Более подробно с информацией о проведении Конкурса можно ознакомиться на <a style={{color: "blue"}} href="https://2024.social.edu-contests.ru/">официальном сайте Конкурса</a>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News240524;