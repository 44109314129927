import React from "react";
import ico from "../../../assets/fileicored.svg"
import ico2 from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import {hrefsArr, PinkButton} from "./FilesNPD";
import {FileLeftLineKDN} from "./FilesKDNNews";

const FilesArticlesPublication = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })
    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Статьи и публикации
                </h2>
                <FileLeftLineKDN
                    text="Психолого-педагогические аспекты формирования ценности здоровья, культуры здорового и безопасного образа жизни в системе образования: Сборник научно-методических материалов"
                    refers="/responsible-behavior/psihologo-pedagogicheskie-aspekty-formirovaniya-tsennosti-zdorovya-kultury-zdorovogo-i-bezopasnogo-obraza-zhizni-v-sisteme-obrazovaniya-sbornik-nauchno-metodicheskih-materialov"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Культура и воспитание подростков в современном мире: Коллективная монография /Под ред. В. А. Березиной. — М.: Агентство «Мегаполис», 2015. — 488"
                    refers="/responsible-behavior/kultura-i-vospitanie-podrostkov-v-sovremennom-mire-kollektivnaya-monografiya-pod-red-v-a-berezinoj-m-agentstvo-megapolis-2015-488"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Наука для образования: Коллективная монография / Сост. и науч. ред. Н. Ю. Синягина, Е. Г. Артамонова. М.: АНО «ЦНПРО», 2015. — 252 с., издана при финансовой поддержке Федерального агентства по печати и массовым коммуникациям в рамках ФЦП Культура России"
                    refers="/responsible-behavior/nauka-dlya-obrazovaniya-kollektivnaya-monografiya-sost-i-nauch-red-n-yu-sinyagina-e-g-artamonova-m-ano-tsnpro-2015-252-s-izdana-pri-finansovoj-podderzhke-federalnogo-agentstva-po-pechati-i"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Райфшнайдер Т.Ю. Социально-психологическая поддержка подростков, находящихся в трудной жизненной ситуации. — М.: АНО «ЦНПРО», 2011. — 168с."
                    refers="/responsible-behavior/rajfshnajder-t-yu-sotsialno-psihologicheskaya-podderzhka-podrostkov-nahodyashhihsya-v-trudnoj-zhiznennoj-situatsii-m-ano-tsnpro-2011-168s"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Артамонова Е. Г. Региональный опыт деятельности педагогов-психологов образовательных организаций по раннему выявлению и профилактике аддиктивного и девиантного поведения несовершеннолетних."
                    refers="/responsible-behavior/artamonova-e-g-regionalnyj-opyt-deyatelnosti-pedagogov-psihologov-obrazovatelnyh-organizatsij-po-rannemu-vyyavleniyu-i-profilaktike-addiktivnogo-i-deviantnogo-povedeniya-nesovershennoletnih"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Курченко Е. В. Профилактика наркомании среди подростков посредством формирования системы духовно — нравственных ориентиров"
                    refers="/responsible-behavior/kurchenko-e-v-profilaktika-narkomanii-sredi-podrostkov-posredstvom-formirovaniya-sistemy-duhovno-nravstvennyh-orientirov"
                    ico={ico2}/>
                <FileLeftLineKDN text={respData[232]?.description} refers={respData[232]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[233]?.description} refers={respData[233]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[234]?.description} refers={respData[234]?.file} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}
export default FilesArticlesPublication