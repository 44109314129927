import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News200723.jpg"

const News200723 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар для сотрудников СУВУ «Альтернативные методы предупреждения и разрешения конфликтов в специальных
            учебно-воспитательных учреждениях»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>17, 19 и 22 мая 2023 года прошли ознакомительные вебинары по вопросам использования медиативного и
                восстановительного подхода для сотрудников всех 16 специальных учебно-воспитательных учреждений,
                подведомственных Минпросвщения России (СУВУ) под названием: «Альтернативные методы предупреждения и
                разрешения конфликтов в специальных учебно-воспитательных учреждениях»
            </p>
            <p> Вебинары были направлены на просвещения сотрудников СУВУ по вопросам альтернативных способов
                урегулирования
                и профилактики конфликтов и споров, а также на тех сотрудников, которые планируют войти в состав служб
                медиации.</p>
            <p>
                Ведущие вебинара рассмотрели с участниками базовые понятия и технологии медиативного подхода. Подробно
                были разобраны ситуации межструктурного взаимодействия для урегулирования и профилактики конфликтов
                внутри учреждения.
            </p>
            <p>
                Ведущие вебинаров: аналитик II категории отдела медиации Центра защиты прав и интересов детей – Козлов
                Анатолий Андреевич и аналитик отдела медиации Тюльканов Сергей Леонидович.</p>
        </div>
        <ToNews/>
    </div>
}


export default News200723;