import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/WorkOrganizationVICH.jpeg";
import YouTube from "react-youtube";

const WorkOrganizationVICH = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар для педагогических работников образовательных организаций по вопросам организация работы по профилактике распространения ВИЧ-инфекции
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                30 ноября 2020 г. в рамках вебинара для педагогических работников образовательных организаций по теме:
                «Организация работы по профилактике распространения ВИЧ-инфекции и формирование культуры здорового
                образа жизни у детей и подростков» Лариса Павловна Фальковская, директор Департамента государственной
                политики в сфере защиты прав детей Министерства просвещения Российской Федерации, кандидат
                психологических наук, освещала вопросы государственной политики в области профилактики распространения
                ВИЧ-инфекции в системе образования, построение системной профилактической работы. Аналитик 1 категории
                ФГБУ «Центр защиты прав и интересов детей», кандидат педагогических наук Ольга Вячеславовна Заева
                выступила с докладом о методическом сопровождении деятельности образовательных организаций в области
                профилактики распространения ВИЧ-инфекции и провела обзор специализированного раздела сайта ФГБУ «Центр
                защиты прав и интересов детей», акцентировав внимание на проведении онлайн-опроса молодёжи по
                определению уровня компетенции в области профилактики распространения ВИЧ-инфекции.
            </p>
            <p>
                Директор НКО Благотворительный Фонд «Дети плюс» Ольга Николаевна Кирьянова представила тему «Организация
                взаимодействия педагогов образовательных организаций с обучающимися с ВИЧ: результаты опроса учителей».
                С докладом «Переходный возраст как уникальный ресурс для создания гармоничных взаимообогощающих
                отношений между родителями и детьми» выступила координатор проекта «Семьеведение» Регионального
                общественного движения поддержки и развития семьи «Семья» в Республике Башкортостан Елена Евгеньевна
                Муратова.
            </p>
            <p>
                Для участия в вебинаре зарегистрировались 10 520 человек из 73 субъектов Российской Федерации. Все они
                получили материалы вебинара и сертификат участника. Приняли участие в вебинаре 1 908 человек, и ещё 2
                000 человек смотрели трансляцию на официальном сайте Всероссийской акции «СТОП ВИЧ/СПИД» Фонда
                социально-культурных инициатив.
            </p>
            <div className={s.blueButtonMediaciaBase} style={{maxWidth: "300px"}}>
                <a href="https://fcprc.ru/media/media/behavior/programma-vebinara_30.11.2020.pdf">Программа
                    материала</a>
            </div>
            <p></p>
            <div style={{margin: "0 auto"}}>
                <YouTube style={{textAlign: "center"}} videoId="0nfx2jLwI_o"/>
            </div>
            <p></p>
            <div className={s.blueButtonMediaciaBase} style={{maxWidth: "300px"}}>
                <a href="https://fcprc.ru/media/media/behavior/30112020.pdf">Презентация Заевой
                    О.В.</a>
            </div>
            <p></p>
            <div className={s.blueButtonMediaciaBase} style={{maxWidth: "300px"}}>
                <a href="https://fcprc.ru/media/media/behavior/Prezenattsiya-Kiryanova-O.N..pdf">Презентация
                    Кирьяновой О.Н.</a>
            </div>
            <p></p>
            <div className={s.blueButtonMediaciaBase} style={{maxWidth: "300px"}}>
                <a href="https://fcprc.ru/media/media/behavior/ПереходныйВозрастМуратова.pdf">Презентация
                    Муратовой Е.Е.</a>
            </div>
            <p></p>
        </div>
    </div>
}


export default WorkOrganizationVICH