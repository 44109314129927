import React from "react";
import ico from "../../../assets/fileicored.svg"
import ico2 from "../../../assets/link.ico"
import s from "./PageFiles.module.css"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, PinkButton, WhiteButton} from "../PageInfo/PageInfo"
import {hrefsArr} from "./FilesNPD";
import {FileLeftLineKDN} from "./FilesKDNNews";

const FilesExperts = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])

    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Материалы для специалистов
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации
                </h1>
                <FileLeftLineKDN
                    text={respData[585]?.description} refers={respData[585]?.file}
                    ico={ico}/>
                <FileLeftLineKDN text={respData[100]?.description} refers={respData[100]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[214]?.description} refers={respData[214]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[16]?.description} refers={respData[16]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[216]?.description} refers={respData[216]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[217]?.description} refers={respData[217]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[218]?.description} refers={respData[218]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[219]?.description} refers={respData[219]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[220]?.description} refers={respData[220]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[221]?.description} refers={respData[221]?.file} ico={ico}/>
                <FileLeftLineKDN
                    text={"«Информационный буллинг. Совместные действия педагога-психолога и учителя в ситуации конфликта в классе» (Свердловская область)"}
                    refers={"https://www.youtube.com/watch?v=4zxZ8mkiH88"} ico={ico2}/>
                <FileLeftLineKDN text={respData[222]?.description} refers={respData[222]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[223]?.description} refers={respData[223]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[224]?.description} refers={respData[224]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[225]?.description} refers={respData[225]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[226]?.description} refers={respData[226]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[227]?.description} refers={respData[227]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[228]?.description} refers={respData[228]?.file} ico={ico}/>

                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Методические материалы по профилактике аддиктивного поведения
                </h1>
                <FileLeftLineKDN
                    text="Методические рекомендации «Организация межведомственного взаимодействия при реализации мероприятий по раннему выявлению незаконного потребления наркотических средств и психотропных веществ» (письмо Минпросвещения России от 28 октября 2024 г. № АБ-3169/07)"
                    refers="https://fcprc.ru/media/media/behavior/Методические_рек.pdf"
                    ico={ico}/>
                <FileLeftLineKDN
                    text="Методические рекомендации «Организация деятельности образовательных организаций, направленной на профилактику употребления психоактивных веществ, пропаганду здорового образа жизни среди обучающихся (на основе результатов социально-психологического тестирования) (письмо Минпросвещения России от 8 октября 2024 г. № 07−4851)"
                    refers="https://fcprc.ru/media/media/behavior/Метод_рекомендации_межвед.pdf"
                    ico={ico}/>
                <FileLeftLineKDN
                    text="Памятка по созданию информационно-просветительского контента, призванного содействовать реализации антинаркотической политики и повышению уровня осведомленности граждан о рисках, связанных с потреблением наркотиков (разработана МРГ ГАК во исполнение подпункта 1.2.2 протокола заседания ГАК № 52 от 5 июля 2023 г.)"
                    refers="https://fcprc.ru/media/media/behavior/Pamiatka_egvrhcZ.pdf"
                    ico={ico}/>
                <FileLeftLineKDN
                    text={respData[590]?.description} refers={respData[590]?.file}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={respData[583]?.description} refers={respData[583]?.file}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={respData[584]?.description} refers={respData[584]?.file}
                    ico={ico}/>
                <FileLeftLineKDN
                    text="Памятка для педагогов «Простые правила: профилактика употребления табака и иной никотиносодержащей продукции среди обучающихся»"
                    refers="https://fcprc.ru/media/media/behavior/Prostie_pravila.pdf" ico={ico}/>
                <FileLeftLineKDN
                    text={respData[519]?.description} refers={respData[519]?.file}
                    ico={ico}/>
                <FileLeftLineKDN text={respData[229]?.description} refers={respData[229]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[230]?.description} refers={respData[230]?.file} ico={ico}/>

                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Статьи и публикации
                </h1>
                <FileLeftLineKDN
                    text="Психолого-педагогические аспекты формирования ценности здоровья, культуры здорового и безопасного образа жизни в системе образования: Сборник научно-методических материалов"
                    refers="/responsible-behavior/psihologo-pedagogicheskie-aspekty-formirovaniya-tsennosti-zdorovya-kultury-zdorovogo-i-bezopasnogo-obraza-zhizni-v-sisteme-obrazovaniya-sbornik-nauchno-metodicheskih-materialov"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Культура и воспитание подростков в современном мире: Коллективная монография /Под ред. В. А. Березиной. — М.: Агентство «Мегаполис», 2015. — 488"
                    refers="/responsible-behavior/kultura-i-vospitanie-podrostkov-v-sovremennom-mire-kollektivnaya-monografiya-pod-red-v-a-berezinoj-m-agentstvo-megapolis-2015-488"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Наука для образования: Коллективная монография / Сост. и науч. ред. Н. Ю. Синягина, Е. Г. Артамонова. М.: АНО «ЦНПРО», 2015. — 252 с., издана при финансовой поддержке Федерального агентства по печати и массовым коммуникациям в рамках ФЦП Культура России"
                    refers="/responsible-behavior/nauka-dlya-obrazovaniya-kollektivnaya-monografiya-sost-i-nauch-red-n-yu-sinyagina-e-g-artamonova-m-ano-tsnpro-2015-252-s-izdana-pri-finansovoj-podderzhke-federalnogo-agentstva-po-pechati-i"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Райфшнайдер Т.Ю. Социально-психологическая поддержка подростков, находящихся в трудной жизненной ситуации. — М.: АНО «ЦНПРО», 2011. — 168с."
                    refers="/responsible-behavior/rajfshnajder-t-yu-sotsialno-psihologicheskaya-podderzhka-podrostkov-nahodyashhihsya-v-trudnoj-zhiznennoj-situatsii-m-ano-tsnpro-2011-168s"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Артамонова Е. Г. Региональный опыт деятельности педагогов-психологов образовательных организаций по раннему выявлению и профилактике аддиктивного и девиантного поведения несовершеннолетних."
                    refers="/responsible-behavior/artamonova-e-g-regionalnyj-opyt-deyatelnosti-pedagogov-psihologov-obrazovatelnyh-organizatsij-po-rannemu-vyyavleniyu-i-profilaktike-addiktivnogo-i-deviantnogo-povedeniya-nesovershennoletnih"
                    ico={ico2}/>
                <FileLeftLineKDN
                    text="Курченко Е. В. Профилактика наркомании среди подростков посредством формирования системы духовно — нравственных ориентиров"
                    refers="/responsible-behavior/kurchenko-e-v-profilaktika-narkomanii-sredi-podrostkov-posredstvom-formirovaniya-sistemy-duhovno-nravstvennyh-orientirov"
                    ico={ico2}/>
                <FileLeftLineKDN text={respData[232]?.description} refers={respData[232]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[233]?.description} refers={respData[233]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[234]?.description} refers={respData[234]?.file} ico={ico}/>
            </div>

            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}

export default FilesExperts
