import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"
import {NavLink} from "react-router-dom";

const News260724 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            В июле-августе 2024 г. пройдут вебинары для специалистов организаций для детей-сирот и детей, оставшихся без
            попечения родителей
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            ФГБУ «Центр защиты прав и интересов детей» информирует о проведении вебинаров для специалистов организаций
            для детей-сирот и детей, оставшихся без попечения родителей, по актуальным вопросам.
            <p></p>
            Для участия в вебинарах также приглашаются представители исполнительных органов субъектов Российской
            Федерации, осуществляющих государственное управление в сфере опеки и попечительства в отношении
            несовершеннолетних граждан, специалисты органов опеки и попечительства.
            <p></p>
            Вебинары организуются на цифровой платформе «Линк Вебинары».
            <p></p>
            Для участия в каждом вебинаре требуется предварительная <NavLink style={{color: "red", fontWeight: "bold"}} to="/news/260724/reg">регистрация по
            соответствующей ссылке к теме
            вебинара.</NavLink>
            <p></p>
            Участие в вебинарах бесплатное с выдачей соответствующих свидетельств.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News260724;