import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News200624_1.jpg"
import img2 from "../../assets/News/News200624_2.jpg"
import img3 from "../../assets/News/News200624_3.jpg"

const News200624 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Ежегодная практическая конференция «Медиация как практика преобразования семейных отношений» 18 июня 2024
            года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В день медиации состоялась ежегодная практическая конференция по медиации в социальной сфере «Медиация как
            практика преобразования семейных отношений».
            <p></p>
            Мероприятие организовано Департаментом труда и социальной защиты населения г. Москвы, ГБУ города Москвы «Мой
            семейный центр «Преображение», ГАУ Институт дополнительного профессионального образования работников
            социальной сферы Департамента труда и социальной защиты населения города Москвы.
            <p></p>
            Конференция проводилась с целью обмена практическим опытом в применении семейной медиации, обсуждения
            имеющихся проблем и перспектив развития межведомственного и межпрофессионального взаимодействия, в ней
            приняли участие эксперты из разных областей: медиаторы, психологи, юристы, представители соцзащиты и
            образования.
            <p></p>
            В работе дискуссионной площадки «Дети в медиации. Возможности применения медиативного подхода в
            детско-родительских отношениях» приняла участие аналитик II категории отдела медиации Центра защиты прав и
            интересов детей Жодзишская Лидия Николаевна с темой «Право и способность ребёнка участвовать в семейном
            диалоге».
            <p></p>
            В своём выступлении Лидия Николаевна отметила важность учёта мнения ребёнка в принятии семейных решений и
            повышения осведомлённости родителей о медиативных технологиях.
            <p></p>
            В 2024 году между Центром защиты прав и интересов детей и ГАУ ИДПО ДТСЗН было заключено соглашение о
            сотрудничестве, направленном на развитие совместных научных, образовательных программ и проектов в интересах
            детей и семей.
            <p></p>
            На конференции были вручены дипломы за достижения в области медиации.
            <p></p>
            В номинации «Лучшая школа юных медиаторов» награждено ГБУ «Мой семейный центр «Ориентир»
            <p></p>
            В номинации «Лучшая школа семейной медиации» награждено ГБУ «Мой семейный центр «Планета семьи»
            <p></p>
            В номинация «Лучшая служба медиации» награждена служба медиации ГБУ «Мой семейный центр «Диалог»
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News200624;