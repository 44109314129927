import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/Mediacia/websIs.png"

const WebsIsolation = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Специфика преодоления стресса в условиях самоизоляции
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.mediacia}>
            <p>
                27 апреля 2020 года в рамках работы Московского международного салона образования – 2020 состоялся
                вебинар, посвященный вопросам переживания стрессовой ситуации в период пандемии. Ведущий вебинара,
                Мелентьева Ольга Станиславовна, педагог-психолог, аналитик ФГБУ «Центр защиты прав и интересов детей»,
                поделилась технологиями преодоления стресса. Рассказала о том, как найти баланс в отношениях с близкими
                в условиях самоизоляции; как и где найти ресурсы для того, чтобы пережить стрессовую ситуацию сегодня,
                как не бороться с собой в условиях пандемии и продолжать жить.
            </p>

            <p>
                Ссылка на вебинар: <a href="https://www.youtube.com/watch?v=2u86x7vXD6o">https://www.youtube.com/watch?v=2u86x7vXD6o</a>
            </p>
        </div>
    </div>
}


export default WebsIsolation