import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";

const ISNPRVP5 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.newHeader}>
            <h2>
                V. Управление реализацией Концепции
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>
            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>

                <p>Ответственными за реализацию Концепции являются Министерство просвещения Российской Федерации,
                    Министерство науки и высшего образования Российской Федерации, Министерство внутренних дел
                    Российской Федерации, Федеральная служба исполнения наказаний, Министерство здравоохранения
                    Российской Федерации, Министерство труда и социальной защиты Российской Федерации, Федеральное
                    агентство по делам молодежи, Фонд поддержки детей, находящихся в трудной жизненной ситуации, и
                    органы исполнительной власти субъектов Российской Федерации.</p>
                <p>Эффективность и степень достижения ожидаемых результатов Концепции оценивается на основе данных
                    постоянного мониторинга, проводимого Федеральным государственным бюджетным научным учреждением
                    «Центр защиты прав и интересов детей».</p>
                <p>Реализация Концепции осуществляется заинтересованными федеральными органами исполнительной власти и
                    органами исполнительной власти субъектов Российской Федерации.</p>
                <p><strong>Результаты реализации Концепции за 2017 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Fajl-2.pdf"> Аналитическая информация об организации и
                    обеспечении мониторинга реализации Концепции за 2017 год (п. 34).pdf ( 0.4 Мб)</a>
                </p>
                <p><a href="https://fcprc.ru/media/media/behavior/Fajl-1.pdf">Аналитическая информация об организации и обеспечении мониторинга эффективности реализации
                    региональных программ по профилактике безнадзорности и правонарушений несовершеннолетних и планов их
                    реализации за 2017 год (п. 35).pdf ( 0.2 Мб)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Proekt-doklada_Kontseptsiya-ot-30.03.2018.pdf">Проект доклада о реализации Концепции и плана мероприятий на 2017-2020 годы по реализации
                    Концепции в 2017 году (п. 36) .pdf ( 3.3 Мб)</a></p>
                <p><strong>Результаты реализации Концепции за 2018 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya-ob-organizatsii-i-obespecontseptsii-za-2018-_NSQOypa.pdf"> Аналитическая информация об организации и
                    обеспечении мониторинга реализации Концепции за 2018 год (п. 34) .pdf</a>
                </p>
                <p><a href="https://fcprc.ru/media/media/behavior/ppp35.pdf"> Аналитическая информация об организации и обеспечении мониторинга эффективности
                    реализации региональных программ по профилактике безнадзорности и правонарушений несовершеннолетних
                    и планов их реализации за 2018 год (п. 35) .pdf (0.2 Мб)</a></p>
                <p><strong>Результаты реализации Концепции за 2019 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/OTCHET_Kontseptsiya_2019.pdf">Аналитическая информация об организации и обеспечении мониторинга реализации Концепции за
                    2019 год (п. 34).pdf</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya-i-obespechenii-monitoringa-effektivnosti-rea_fX2TduR.pdf">Аналитическая информация об организации и обеспечении мониторинга эффективности
                    реализации региональных программ по профилактике безнадзорности и правонарушений несовершеннолетних
                    и планов их реализации за 2019 год (п. 35).pdf</a></p>
                <p><strong>Результаты реализации Концепции за 2020 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya-po-organizatsiyu-imonitoringa-realizatsii-Ko_FxYycoT.pdf">Аналитическая информация по организации и обеспечению мониторинга реализации Концепции пункт 34.pdf</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya-ob-organizatsii-i-ob.pdf">Аналитическая информация по организацию и обеспечению мониторинга эффективности реализации программ по профилактике пункт 35.pdf</a></p>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default ISNPRVP5