import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News14092022.jpg"

const News14092022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            15 сентября 2022 года стартует Всероссийский конкурс программ социализации подростков
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>15 сентября 2022 года стартует Всероссийский конкурс программ социализации подростков, реализуемых на
                базе подростковых клубов/центров/объединений в субъектах Российской Федерации.
                Конкурс проводится в рамках государственного задания на выполнение работы по методическому обеспечению
                образовательной деятельности федеральным государственным бюджетным учреждением
                «Центр защиты прав и интересов детей» (далее – Центр) в целях поддержки программ, направленных на
                социализацию подростков в возрасте от 10 до 18 лет на 2022 год.</p>
            <p>Учредителем Конкурса является Министерство просвещения Российской Федерации совместно с Общественной
                Палатой Российской Федерации. Организатором Конкурса является Центр.</p>
            <p>Ознакомиться с Положением о конкурсе можно по <a
                href="https://fcprc.ru/media/media/behavior/863-Polozhenie-o-Vserossijskom-konkurse-programm-sotsializatsii-podrostkov..pdf">ссылке</a>
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News14092022;