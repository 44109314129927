import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/UniMediator.jpg"
import img2 from "../../assets/News/UniMediator2.jpg"

const UniMediator = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Тематическая смена «Юный медиатор» в ВДЦ «Орлёнок» в 2022 год
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>По поручению Министерства просвещения Российской Федерации <strong>с 25 по 29 апреля 2022 года</strong> специалисты отдела
                медиации Федерального государственного бюджетного учреждения «Центр защиты прав и интересов детей» в
                рамках выполнения государственного задания на 2022 год при содействии Международного союза детских
                общественных объединений «Союз пионерских организаций – Федерация детских организаций» провели
                тематическую смену <strong>«Юный медиатор»</strong> на базе лагеря «Стремительный» Всероссийского детского центра
                «Орлёнок».</p>
            <p>Юным медиатором стал <strong>121 подросток</strong> в возрасте <strong>от 12 до 17 лет</strong>.</p>
            <p>На смену приехали активисты и лидеры детских общественных объединений, занимающиеся общественно полезной
                деятельностью из <strong>12 регионов России</strong>: Республика Башкортостан, Удмуртская Республика, Краснодарский край,
                Брянская область, Волгоградская область, Воронежская область, Костромская область, Липецкая область,
                Орловская область, Ростовская область, Самарская область, Саратовская область.</p>
            <p>
                Смена включала в себя занятия по медиации, на которых участники познакомились с понятием «конфликт»,
                причинами его возникновения, последствиями, стратегиями поведения в конфликтной ситуации, научились
                корректировать негативное эмоциональное состояние и прояснять интересы собеседника.
            </p>
            <p>
                В формате групповой дискуссии подростки сформулировали важность принципов медиации и пришли к выводу,
                что, опираясь на такие принципы, можно лучше понимать других людей, выстраивать конструктивное общение,
                сохранять товарищеские отношения.
            </p>
            <p>
                Формат интерактивных занятий позволил участникам побыть как в роли стороны конфликта, так и в роли
                медиатора, помогающего сторонам прийти к оптимальному и взаимовыгодному решению.
            </p>
            <p>
                Все участники смены получили актуальные знания бесконфликтного общения и практический опыт
                взаимодействия с педагогами и сверстниками. По возвращению домой активные подростки смогут принять
                участие в работе служб медиации и примирения в своих школах, просветительских мероприятиях медиативной
                направленности, способствовать продвижению культуры конструктивного общения и гуманистических ценностей
                в образовательных организациях, в семье, в обществе в целом.
            </p>
            <p>
                Подростки получили блокноты, ручки и папки с символикой Минпросвещения России и Центра защиты прав и
                интересов детей, а также каждому участнику были вручены сертификаты о прохождении программы и
                тематические значки.
            </p>
            <p>
                Ведущие смены – сотрудники ФГБУ «Центр защиты прав и интересов детей»: начальник отдела медиации Марина
                Викторовна Быкова и аналитик отдела медиации Анатолий Андреевич Козлов.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default UniMediator;