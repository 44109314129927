import React from "react";
import ico from "../../../assets/fileicored.svg"
import arr from "../../../assets/arrow.svg"
import link from "../../../assets/link.ico"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";
import {FileLeftLineKDN, HrefsKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Мероприятоия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"]},
    {text: "Контакты"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Сведения по форме ФСН №1-КДН"},
    {text: "Новости/Анонс", input: ["2021 год"]},
]
const FilesKDNActQ = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>


        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Актуальные интернет-ресурсы по вопросам профилактики и справочники
                </h2>
                <FileLeftLineKDN
                    text={"Форум Комиссий по делам несовершеннолетних и защите их прав"}
                    refers={"http://komissy.ru/forum/"} ico={link}/>
                <FileLeftLineKDN
                    text={"Научно-исследовательский центр профилактики правонарушений несовершеннолетних при Саратовской государственной юридической академии"}
                    refers={"http://kdnrus.ru/"} ico={link}/>
                <FileLeftLineKDN
                    text={"Федеральный центр «Профилактика» Института изучения детства, семьи и воспитания"}
                    refers={"https://институтвоспитания.рф/profilaktika/"} ico={link}/>
                <FileLeftLineKDN
                    text={"Общероссийское общественно-государственное движение детей и молодежи «Движение Первых»"}
                    refers={"https://будьвдвижении.рф/"} ico={link}/>
                <FileLeftLineKDN text={"Общественный центр «Судебно-правовая реформа»"} refers={"http://sprc.ru/"}
                                 ico={link}/>
                <FileLeftLineKDN text={"Раздел «Медиация» ФГБУ «Центр защиты прав и интересов детей»"}
                                 refers={"/mediacia"} ico={link}/>
                <FileLeftLineKDN text={"Фонд поддержки детей, находящихся в трудной жизненной ситуации"}
                                 refers={"http://fond-detyam.ru/"}
                                 ico={link}/>
                <FileLeftLineKDN text={"Портал «Я – Родитель»"} refers={"http://www.ya-roditel.ru/"} ico={link}/>

                <h2>
                    СТАТИСТИЧЕСКИЕ ДАННЫЕ
                </h2>
                <FileLeftLineKDN text={"Открытые данные Министерства внутренних дел Российской Федерации"}
                                 refers={"https://мвд.рф/opendata"} ico={link}/>
                <FileLeftLineKDN text={"РОССТАТ: семья, материнство и детство "}
                                 refers={"https://rosstat.gov.ru/folder/13807"} ico={link}/>
                <FileLeftLineKDN
                    text={"Сводные статистические сведения о состоянии судимости в России (данные Судебного департамента при Верховном Суде РФ)"}
                    refers={"http://www.cdep.ru/?id=79"} ico={link}/>
                <FileLeftLineKDN text={"Портал правовой статистики Генеральной прокуратуры РФ"}
                                 refers={"http://crimestat.ru/regions_chart_total"} ico={link}/>
                <h2>
                    СПРАВОЧНИКИ
                </h2>
                <FileLeftLineKDN text={respData[78]?.description} refers={respData[78]?.file} ico={ico}/>

            </div>
            <div style={{marginTop: "-3px"}}>
                <HrefsKDN hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </>
}
export default FilesKDNActQ