import React from "react";
import s from "./Falldown.module.css"
import "./Falldown.css"
import {DownOutlined} from "@ant-design/icons"
import {useState} from "react";
import Falldown from "../common/FalldownList/Falldown";

const Directions = () => {
    return <div className={s.newHeader}>

        <div className={s.fd}>
            <h2>
                Ключевые направления
            </h2>
            <Falldown header={"2020"}>
                <li>Организационно-методическое сопровождение развития системы профилактики девиантного поведения
                    несовершеннолетних, включающее:
                    <ul className={s.inner}>
                        <li>экспертно-аналитическое и организационно-методическое сопровождение деятельности СУВУ;</li>
                        <li>экспертно-аналитическое и организационно-методическое обеспечение деятельности КДНиЗП;</li>
                        <li>экспертно-аналитическое и организационно-методическое сопровождение мониторинга реализации
                            Концепции развития системы профилактики безнадзорности и правонарушений несовершеннолетних в
                            субъектах Российской Федерации (в 2019/2020 учебном году);
                        </li>
                        <li>экспертно-аналитическое и организационно-методическое сопровождение вопросов профилактики
                            аутодеструктивного поведения и детской безопасности;
                        </li>
                        <li>экспертно-аналитическое и организационно-методическое сопровождение обеспечение мониторинга
                            организации проведения социально-психологического тестирования обучающихся, направленного на
                            раннее выявление незаконного потребления наркотических средств и психотропных веществ, и
                            системы профилактической работы в образовательных организациях Российской Федерации;
                        </li>
                        <li>организацию и проведение Всероссийского конкурса профессионального мастерства
                            «Педагог-психолог России – 2020».
                        </li>
                    </ul>
                </li>
                <li>Методическое сопровождение деятельности в сфере защиты прав детей-сирот и детей, оставшихся без
                    попечения родителей, включающее:
                    <ul className={s.inner}>
                        <li>организацию и проведение VIII Всероссийского Съезда директоров организаций для детей-сирот и
                            детей, оставшихся без попечения родителей и Всероссийского совещания руководителей органов
                            опеки и попечительства;
                        </li>
                        <li>Всероссийского совещания по вопросам подготовки лиц, желающих принять ребенка на воспитание
                            в семью, социально-психологического обследования таких лиц, сопровождения замещающих семей;
                        </li>
                        <li>мониторинг и оценку соответствия организаций для детей-сирот и детей, оставшихся без
                            попечения родителей, требованиям постановления Правительства Российской Федерации от 24 мая
                            2014 г. № 481;
                        </li>
                        <li>мониторинг жизненной ситуации лиц из числа детей-сирот и детей, оставшихся без попечения
                            родителей, в том числе выпускников организаций для детей-сирот и детей, оставшихся без
                            попечения родителей;
                        </li>
                        <li>мониторинг подготовки лиц, желающих принять ребенка на воспитание в семью,
                            социально-психологического обследования таких лиц, сопровождения замещающих семей и мер их
                            поддержки;
                        </li>
                        <li>разработку комплекта методических материалов в целях повышения компетентности специалистов
                            организаций для детей-сирот, детей, оставшихся без попечения родителей, органов опеки и
                            попечительства, специалистов служб сопровождения замещающих семей и постинтернатного
                            сопровождения;
                        </li>
                        <li>экспертно-аналитическое, организационно-методическое и ресурсное обеспечение деятельности по
                            вопросам опеки и попечительства, организаций для детей-сирот и детей, оставшихся без
                            попечения родителей;
                        </li>
                        <li>экспертно-методическое сопровождение защиты имущественных прав детей-сирот, детей,
                            оставшихся без попечения родителей, и лиц из их числа.
                        </li>
                    </ul>
                </li>
                <li>Организационно-методическое сопровождение деятельности центров психолого-педагогической, медицинской
                    и социальной помощи субъектов Российской Федерации, включая обеспечение деятельности Федерального
                    ресурсного центра по организационно – методическому сопровождению деятельности
                    психолого-медико-педагогических комиссий субъектов Российской Федерации.
                </li>
                <li>Осуществление деятельности методического центра для обобщения и тиражирования опыта реализации
                    мероприятий, проводимых в рамках Десятилетия детства.
                </li>
                <li>Организационно-методическое сопровождение системы защиты прав детей, физического и психического
                    благополучия личности, включающее:
                    <ul className={s.inner}>
                        <li>организационно-методическое сопровождение субъектов Российской Федерации по вопросам
                            обучения на дому (в том числе с использованием дистанционных образовательных технологий)
                            обучающихся из числа обучающихся с ОВЗ и инвалидностью, находящихся на длительном лечении;
                        </li>
                        <li>организацию и проведение IV Всероссийского конкурса «Школа – территория здоровья»;</li>
                        <li>организацию и проведение VII Всероссийского конкурса «Лучшая инклюзивная школа России»;</li>
                        <li>научно-экспертное исследование вопросов защиты детей от сексуальной эксплуатации и
                            сексуального насилия;
                        </li>
                        <li>методическое обеспечение правового регулирования вопросов защиты прав ребенка в рамках
                            Конвенции ООН о правах ребенка.
                        </li>
                    </ul>
                </li>
            </Falldown>
        </div>
        <div className={s.fd}>
            <Falldown header={"2019"}>
                <li>Обеспечение деятельности Федерального ресурсного центра по развитию системы комплексного
                    сопровождения детей с ограниченными возможностями здоровья и инвалидностью.
                </li>
                <li>Организация и проведение III Всероссийского конкурса «Школа – территория здоровья».</li>
                <li>В целях формирования методических рекомендаций по системе функционирования психологических служб в
                    общеобразовательных организациях на основе апробации по созданию условий для психологического
                    сопровождения обучающихся общеобразовательных организаций не менее чем в 5 субъектах Российской
                    Федерации.
                </li>
                <li>В целях поддержки образования для детей с ограниченными возможностями здоровья. Обновление
                    материально-технической базы в организациях, осуществляющих образовательную деятельность
                    исключительно по адаптированным основным общеобразовательным программам.
                </li>
                <li>Осуществление деятельности Федерального ресурсного центра психолого-медико-педагогической
                    комиссии.
                </li>
                <li>Организационно-методическое и экспертно-аналитическое сопровождение всероссийских массовых
                    мероприятий для педагогов-психологов, социальных педагогов на 2019 год.
                </li>
                <li>Создание Федерального ресурсного центра по развитию системы комплексного сопровождения детей с
                    ограниченными возможностями здоровья и инвалидностью.
                </li>
                <li>Методическое обеспечение правового регулирования вопросов защиты прав ребенка в рамках Конвенции о
                    правах ребенка ООН.
                </li>
                <li>Научно-экспертное исследование вопросов защиты детей от сексуальной эксплуатации и сексуального
                    насилия.
                </li>
                <li>Экспертно-аналитическое, организационно-методическое и ресурсное обеспечение деятельности по
                    вопросам опеки и попечительства, организаций для детей-сирот и детей, оставшихся без попечения
                    родителей.
                </li>
                <li>Экспертно-аналитическое и методическое сопровождение мониторинга реализации Концепции развития
                    системы профилактики правонарушений несовершеннолетних в субъектах Российской Федерации.
                </li>
                <li>Экспертно-аналитическое, организационно-методическое и ресурсное обеспечение вопросов образования и
                    сопровождения детей, находящихся в специальных учебно-воспитательных учреждениях.
                </li>
                <li>Экспертно-методическое сопровождение защиты имущественных прав детей-сирот, детей, оставшихся без
                    попечения родителей, и лиц из их числа.
                </li>
                <li>Мониторинг жизненной ситуации лиц из числа детей-сирот и детей, оставшихся без попечения родителей,
                    в том числе выпускников организаций для детей-сирот и детей, оставшихся без попечения родителей.
                </li>
                <li>Организационно-методическое сопровождение реализации плана основных мероприятий в 2019 году,
                    проводимых в рамках Десятилетия детства.
                </li>
                <li>Проведение Всероссийского конкурса профессионального мастерства «Педагог-психолог России – 2019».
                </li>
                <li>Мониторинг и оценка соответствия организаций для детей-сирот и детей, оставшихся без попечения
                    родителей, требованиям постановления Правительства Российской Федерации от 24 мая 2014 г. № 481.
                </li>
                <li>Организация и проведение VII всероссийского Съезда директоров организаций для детей-сирот и детей,
                    оставшихся без попечения родителей, и Всероссийского совещания руководителей органов опеки и
                    попечительства.
                </li>
                <li>Разработка примерных адаптированных основных общеобразовательных программ для обучающихся с ОВЗ и
                    инвалидностью 5-6 классов.
                </li>
                <li>Научно-методическое обеспечение мониторинга организации проведения социально-психологического
                    тестирования обучающихся, направленного на раннее выявление незаконного потребления наркотических
                    средств и психотропных веществ, и системы профилактической работы в образовательных организациях
                    Российской Федерации.
                </li>
                <li>Научно-методическое обеспечение эффективности системы профилактики аутодеструктивного поведения
                    несовершеннолетних и вопросов детской безопасности.
                </li>
            </Falldown>
        </div>
        <div className={s.fd}>
            <Falldown header={"2018"}>
                <li>Осуществление деятельности федерального ресурсного центра психолого-медико-педагогической комиссии
                    по созданию условий для комплексного сопровождения детей с ограниченными возможностями здоровья и
                    инвалидностью.
                </li>
                <li>Мониторинг проведения социально-психологического тестирования обучающихся, направленного на раннее
                    выявление незаконного потребления наркотических средств и психотропных веществ в образовательных
                    организациях Российской Федерации.
                </li>
                <li>Научно-аналитическое обеспечение вопросов детской безопасности и совершенствования системы
                    профилактики аутодеструктивного поведения несовершеннолетних.
                </li>
                <li>Научно-экспертное и методическое обеспечение вопросов образования, сопровождения и поддержки детей,
                    нуждающихся в заботе государства, включая защиту их прав и интересов.
                </li>
                <li>Научно-аналитическое обеспечение мониторинга реализации Концепции профилактики безнадзорности и
                    правонарушений несовершеннолетних.
                </li>
                <li>Научно-методическое, наглядно-интерактивное обеспечение взаимодействия общеобразовательных
                    организаций с родителями (законными представителями) несовершеннолетних в рамках профилактики
                    асоциального поведения.
                </li>
                <li>Выполнение работы по организации общественно-значимых мероприятий в сфере образования, науки и
                    молодежной политики в целях организационно-технического, координационного и информационного
                    сопровождения реализации федеральной целевой программы «Повышение безопасности дорожного движения
                    в 2013 — 2020 годы».
                </li>
                <li>Научно-аналитическое сопровождение процедур обеспечения жилыми помещениями детей-сирот, детей,
                    оставшихся без попечения родителей, лиц из числа детей-сирот и детей, оставшихся без попечения
                    родителей.
                </li>
                <li>Разработка комплексного научно-методического обеспечения мониторинга деятельности центральных и
                    территориальных психолого-медико-педагогических комиссий в субъектах Российской Федерации и его
                    проведение.
                </li>
                <li>Организация и проведение II Всероссийского конкурса среди специальных (коррекционных) школ
                    «Школа-территория здоровья».
                </li>
            </Falldown>
        </div>
        <div className={s.fd}>
            <Falldown header={"2017"}>
                <li>Научно-экспертное и организационно-методическое сопровождение мероприятий по вопросам помощи и
                    поддержки детей, нуждающихся в услугах и заботе государства, включая защиту их прав и интересов.
                </li>
                <li>Разработка и апробация комплексного проекта ранней профилактики девиантного поведения
                    несовершеннолетних в общеобразовательных организациях и механизмов оказания помощи
                    несовершеннолетним воспитанникам специальных учебно-воспитательных учреждений и освобождающимся из
                    мест лишения свободы.
                </li>
                <li>Мониторинги состояния работы по профилактике аддиктивного и суицидального поведения детей и
                    подростков в образовательных организациях Российской Федерации.
                </li>
                <li>Разработка типовой модели деятельности педагогов-психологов образовательных организаций по раннему
                    выявлению и профилактике аддиктивного и девиантного поведения несовершеннолетних.
                </li>
                <li>Экспертно-методическое и научное сопровождение совершенствования процедур обеспечения жилыми
                    помещениями детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа в рамках
                    Комплекса мер по предоставлению жилья детям-сиротам, детям, оставшимся без попечения родителей, и
                    лицам из их числа на 2015-2017 годы, в соответствии с приказом Минобрнауки России от 5 августа 2015
                    г. № 806.
                </li>
                <li>Разработка наглядно-методических материалов для обучающихся и их родителей в рамках профилактики
                    аддиктивного поведения несовершеннолетних.
                </li>
            </Falldown>
        </div>
    </div>
}
//
// const Falldown = ({url, header, children, style = {}}) => {
//
//     const [active, setActive] = useState(false)
//     return <div className={s.fall} style={style}>
//         <div className={s.block} onClick={() => {
//             active ? setActive(false) : setActive(true)
//         }} onBlur={() => {
//             setActive(false)
//         }}>
//             <div style={{width: "700px", marginTop: "40px"}}>
//                 <div className={s.blockHead}>
//                     {header}
//                 </div>
//                 <span className={active ? s.downarrow : s.arrow}> <DownOutlined
//                     style={{fontSize: '28px', color: '#fff'}}/> </span></div>
//
//             <div className={s.ico} style={{backgroundImage: `none`, zIndex: 3, opacity: 1}}>
//
//             </div>
//         </div>
//         <nav className={active ? s.active : s.info}>
//             <ul style={{}}>
//                 {children}
//             </ul>
//         </nav>
//     </div>
//
// }

export default Directions