import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/vserosConfPEPSOS.png"

const ConferenceOfPEPSOS = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийская конференция по вопросам психолого-педагогического сопровождения обучающихся
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{
            fontFamily: "Proxima Nova Lt",
            textAlign: "center"
        }}>
            Уважаемые коллеги!
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Приглашаем Вас принять участие во <strong>Всероссийской конференции по вопросам психолого-педагогического
                сопровождения обучающихся</strong> для руководителей и специалистов органов исполнительной власти в сфере
                образования, руководителей и специалистов центров психолого-педагогической, медицинской и социальной
                помощи, психолого-медико-педагогических комиссий, психолого-педагогических консилиумов,
                психолого-педагогических служб образовательных организаций <strong>«Актуальные вопросы
                    психолого-педагогического
                    сопровождения обучающихся», которая состоится 14 – 15 ноября 2022г. в формате
                    онлайн-трансляции.</strong></p>
            <p><strong>Онлайн-трансляция: цифровая платформа: www.webinar.ru</strong></p>
            <p>Участие в мероприятии бесплатное.</p>
            <p>Начало Всероссийской конференции — 9:00 (по московскому времени).</p>
            <p>Для участия в мероприятии <strong>необходимо предварительно заполнить регистрационную форму:</strong></p>
            <br/>
        </div>
        <div style={{
            fontFamily: "Proxima Nova Lt",
            textAlign: "center",
            fontWeight: "bold"
        }}>
            14 ноября 2022г.
        </div>
        <div style={{
            fontFamily: "Proxima Nova Lt",
            fontWeight: "bold"
        }}>
            <p>Дискуссионная площадка 2 (09.30 – 13.30) «Современные аспекты деятельности центров
                психолого-педагогической помощи»<br/>
                <a href="https://events.webinar.ru/41646479/577899503">https://events.webinar.ru/41646479/758738497</a>
            </p>
            <p>
                Дискуссионная площадка 1 (12.00 – 16.00) «Психолого-педагогическое сопровождение обучающихся в
                образовательных организациях: доступность, взаимодействие, преемственность, адаптация, направления
                развития»<br/>
                <a href="https://events.webinar.ru/41646479/1688452361">https://events.webinar.ru/41646479/1688452361</a>
            </p>
            <p>
                Дискуссионная площадка 3 (12.00 – 16.00) «Актуальные проблемы обучения длительно болеющих детей и детей
                с ограниченными возможностями здоровья на дому и в условиях медицинских организаций»<br/>
                <a href="https://events.webinar.ru/41646479/643886450">https://events.webinar.ru/41646479/643886450</a>
            </p>
        </div>
        <div style={{
            fontFamily: "Proxima Nova Lt",
            textAlign: "center",
            fontWeight: "bold"
        }}>
            15 ноября 2022г.
        </div>
        <div style={{
            fontFamily: "Proxima Nova Lt",
            fontWeight: "bold"
        }}>
            <p>Дискуссионная площадка 2 (09.30 – 13.30) «Современные аспекты деятельности центров
                психолого-педагогической помощи»<br/>
                <a href="https://events.webinar.ru/41646479/577899503">https://events.webinar.ru/41646479/577899503</a>
            </p>
            <p>
                Дискуссионная площадка 4 (13.30 – 17.30) «Психолого-медико-педагогическое обследование обучающихся:
                качество, эффективность, подходы к оценке результатов»<br/>
                <a href="https://events.webinar.ru/41646479/2087433739">https://events.webinar.ru/41646479/1688452361</a>
            </p>
            <p>Программа мероприятия</p>
        </div>
        <ToNews/>
    </div>
}


export default ConferenceOfPEPSOS;