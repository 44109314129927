import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";
import {FileLeftLineKDN} from "./FilesKDNNews";


const FPF1819 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    2018/19 учебный год
                </h2>
                <FileLeftLineKDN text={respData[271]?.description} refers={respData[271]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[272]?.description} refers={respData[272]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[273]?.description} refers={respData[273]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[274]?.description} refers={respData[274]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[275]?.description} refers={respData[275]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[276]?.description} refers={respData[276]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[277]?.description} refers={respData[277]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[278]?.description} refers={respData[278]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[279]?.description} refers={respData[279]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[280]?.description} refers={respData[280]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[281]?.description} refers={respData[281]?.file} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default FPF1819