import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import img from "../../assets/News/VICH26111111.png"
import YouTube from "react-youtube";

const VICH2611 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Всероссийский вебинар для педагогов, заместителей руководителей образовательных организаций
            по методическим вопросам комплексного подхода к формированию культуры здорового образа жизни у детей и
            подростков, вопросам предотвращения дискриминации обучающихся и работников образовательных организаций,
            живущих с ВИЧ (26 ноября 2019 г.)
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.mediacia}>
            <p>
                26 ноября 2019 года, в рамках ежегодной Всероссийской акции «СТОП ВИЧ/СПИД», приуроченной к Всемирному
                дню борьбы со СПИДом (1 декабря), состоялся вебинар для педагогов, заместителей руководителей
                образовательных организаций по методическим вопросам комплексного подхода к формированию культуры
                здорового образа жизни у детей и подростков, вопросам предотвращения дискриминации обучающихся и
                работников образовательных организаций, живущих с ВИЧ.
            </p>
            <p>
                В вебинаре приняли участие 3253 специалиста из 67 субъектов Российской Федерации.
            </p>
            <p>
                На вебинаре рассматривались вопросы комплексного подхода к формированию культуры здорового образа жизни
                у детей и подростков. Фальковская Лариса Павловна, заместитель директора департамента государственной
                политики в сфере защиты прав детей Министерства просвещения Российской Федерации, кандидат
                психологических наук, освещала вопросы государственной политики в сфере ВИЧ-профилактики, построению
                системной профилактической работы. Обсуждались меры по предотвращению дискриминации обучающихся и
                работников образовательных организаций, живущих с ВИЧ, в части исполнения действующего законодательства.
            </p>
            <p>
                Аналитик ФГБНУ «Центр защиты прав и интересов детей», кандидат педагогических наук, Заева Ольга
                Вячеславовна отдельное внимание уделила организации информационно-просветительской работы в
                образовательных организациях с педагогами, обучающимися и их родителями. Определила круг
                административно-управленческих мер по предотвращению стигматизации и профилактике дискриминации
                ВИЧ-инфицированных. Специалисты регионов были также проинформированы о научно-методических материалах,
                подготовленных специалистами ФГБНУ «Центр защиты прав и интересов детей», в помощь педагогам, психологам
                образовательных организаций для организации профилактической работы в сфере Вич-профилактики с
                обучающимися и их родителями, по формированию культуры безопасного и здорового образа жизни.
            </p>
            <p>
                Директор Благотворительного Фонда «Помощь детям, затронутым эпидемией ВИЧ-инфекции «Дети плюс»,
                Кирьянова Ольга Николаевна, говорила о создании условий для беспрепятственной социализации и взросления
                детей, рожденных с ВИЧ – инфекцией и опыте работы Фонда по сопровождению семей с ВИЧ-инфицированными
                родителями и семей, воспитывающих детей с ВИЧ.
            </p>
            <p>
                <a href="https://fcprc.ru/media/media/behavior/Zaeva-O.V.-Profilaktika-diskriminatsii-VICH-infitsirovannyh.pdf">Заева
                    О.В. Профилактика дискриминации ВИЧ-инфицированных</a>
            </p>
            <p>
                <a href="https://fcprc.ru/media/media/behavior/Kiryanova-O.N.-Fond-Deti-plyus.pdf">Кирьянова О.Н. Фонд Дети плюс</a>
            </p>
            <p>
                Также в ходе конференции освещался опыт четырех проектов по восстановительному правосудию с осужденными
                в центрах временного содержания, в колониях Орловской области и Алтайского края, с обучающимися в
                специальных учебно-воспитательных учреждениях (СУВУ).
            </p>
            <p>
                <video src="https://fcprc.ru/media/media/behavior/Istoriya-Mashi-v-shkole-720p_30fps_H264-192kbit_AAC.mp4" width="1000" height="600" controls>
                </video>
            </p>
            <p>
                <YouTube videoId="28_bD_WQie0" opts={opts}/>
            </p>
        </div>
    </div>
}
const opts = {
    height: '600',
    width: '1000',

  };


export default VICH2611