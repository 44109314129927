import React from "react";
import Falldown from "../common/FalldownList/Falldown";
import img21 from "../../assets/img/1.png"
import s from "./Behavior.module.css"
import {NavLink} from "react-router-dom";
import ico2 from "../../assets/Behavior/edu2.png"
import ico3 from "../../assets/Behavior/edu3.png"
import doc from "../../assets/img/doc.svg"
import {ArrowRightOutlined} from "@ant-design/icons"


const Webinar = () => {
    return <>

            <div className={s.newHeader}>
                <h2>
                    Вебинары
                </h2>

            <div className={s.fall}>

                <div style={{marginTop: "30px"}}>
                    <div className={s.block}>
                        <div className={s.blockHead} style={{width: "64vw", maxWidth: "800px"}}>
                            <div className={s.text}>
                                <img src={doc}/>
                                Вебинары, мастер-классы

                            </div>
                            <div className={s.button} style={{textDecoration: "none"}}>
                                <div>
                                    <NavLink to="/webs">
                                        Перейти <ArrowRightOutlined style={{color: "red", width: "7px"}}/>
                                    </NavLink>
                                </div>
                            </div>

                        </div>
                        <div className={s.ico} style={{backgroundImage: `url(${ico2})`, zIndex: 3, opacity: 0.7}}>

                        </div>
                    </div>
                </div>
                <div style={{marginTop: "30px"}}>
                    <div className={s.block}>
                        <div className={s.blockHead} style={{width: "64vw", maxWidth: "800px"}}>
                            <div className={s.text}>
                                <img src={doc}/>
                                Услуга помощи родителям
                            </div>
                            <div className={s.button} style={{textDecoration: "none"}}>
                                <div>
                                    <NavLink to="/psycho">
                                        Перейти <ArrowRightOutlined style={{color: "red", width: "7px"}}/>
                                    </NavLink>
                                </div>
                            </div>

                        </div>
                        <div className={s.ico} style={{backgroundImage: `url(${ico3})`, zIndex: 3, opacity: 0.7}}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}


export default Webinar