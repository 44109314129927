import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SupportMediationServices2023.jpg"
import img2 from "../../assets/News/SupportMediationServicesMarch2023_2.jpg"
import img3 from "../../assets/News/SupportMediationServicesMarch2023_3.jpg"

const SupportMediationServicesMarch2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в марте
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                В 2023 году отдел медиации Центра защиты прав и интересов детей продолжил работу по сопровождению работы
                служб медиации специальных учебно-воспитательных учреждений, подведомственных Минпросвещения России.</p>
            <p>Мероприятия прошли 15 – 16 марта с Ишимбайским СУВУ, Неманским СУВУ, Орловским СУВУ и Омским СУВУ, а
                также и 30 – 31 марта – с Санкт-Петербургским СУВУ, Астраханским СУВУ, Калтанским СУВУ и Ишимбайским
                СУВУ в дистанционном формате.</p>
            <p>В рамках сопровождения для сотрудников служб были проведены консультации, на которых обсуждались планы на
                2023 год и текущее состояние службы. В практической части встречи проходил обмен профессиональным опытом
                и коллективный разбор нескольких сложных ситуаций из практики СУВУ.</p>
            <p>С обучающимися на интерактивных занятиях были проведены деловые игры, направленные на формирование
                навыков ведения переговоров в ситуации неопределенности. Структура занятий была выстроена таким образом,
                чтобы создать условия для общения обучающихся разных полов.</p>
            <p>Встречи проходили в дружеской и уважительной атмосфере, обучающиеся и сотрудники положительно оценили
                интерактивный формат занятий.</p>
            <p>Мероприятия проводили: начальник отдела медиации Центра защиты прав и интересов детей – Быкова Марина
                Викторовна и аналитик II категории отдела медиации – Козлов Анатолий Андреевич.
            </p>
            <div>
                <img src={img2}/>
            </div>
            <div>
                <img src={img3}/>
            </div>
        </div>
        <ToNews/>
    </div>
}


export default SupportMediationServicesMarch2023;