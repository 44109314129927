import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import logo from "../../../assets/Directions/2_1.svg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import img0 from "../../../assets/SUVU/Slid0.png"
import img1 from "../../../assets/SUVU/Slid1.png"
import img2 from "../../../assets/SUVU/Slid2.png"
import img3 from "../../../assets/SUVU/Slid3.png"
import img4 from "../../../assets/SUVU/Slid4.png"
import img5 from "../../../assets/SUVU/Slid5.png"
import img6 from "../../../assets/SUVU/Slid6.png"
import SimpleImageSlider from "react-simple-image-slider";
import {hrefsArrKDN} from "../FilePage/FilesKDNGos";


const PageInfoKDNMain = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.SUVU}>
        <div className={s.newHeader}>
            <h2 style={{fontWeight: "normal", display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                <div style={{width: "100%"}}>
                    <div style={{margin: "0 auto", width: "110px"}}>
                        <img src={logo} style={{height: "115px", width: "110px"}}/>
                    </div>
                </div>

                <div style={{
                    fontSize: "30px",
                    fontFamily: "Proxima Nova Lt",
                    color: "#3A4663",
                    textAlign: "center"
                }}>Федеральный ресурсный центр комиссий по делам несовершеннолетних<br/>и защите их прав (ФРЦ КДНиЗП)</div>
            </h2>
        </div>
        <div className={s.page}>
            <div className={s.content}>
                <p style={{
                    fontSize: "18px",
                    color: "#5e5a5a",
                    fontFamily: 'Proxima Nova Lt',
                    fontWeight: 300,
                    lineHeight: 1.5
                }}>
                    <div class="row">
                        <SimpleImageSlider images={[img0, img1, img2, img3, img4, img5, img6]} width={780}
                                           height={439} showBullets={true}
                                           showNavs={true}/>
                    </div>
                </p>
            </div>
            <div>
                <Hrefs/>
                <p></p>
            </div>
        </div>
    </div>


}

const Hrefs = ({hrefs, to}) => {
    return <div className={s.hrefs}>
        <ul>
            {hrefsArrKDN.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <NavLink to={to}>
            <li style={{position: "relative", marginRight: "20px"}}>
                <div style={{marginRight: "20px"}}>
                    <img src={docIco}/>

                    {text}

                    {input ? <span
                            style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                            onClick={() => {
                                isActive ? SetActive(false) : SetActive(true)
                            }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                        : <></>}
                </div>
            </li>
        </NavLink>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>

}
const PinkButton = ({text, stylesheet, refs, type}) => {
    return <div className={s.pinkbutton}>
        {type === "a" ? <a href={refs}>{text}</a> : <NavLink to={refs}>{text}</NavLink>}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

const BlackButton = ({text, stylesheet}) => {
    return <div className={s.blackbutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default PageInfoKDNMain