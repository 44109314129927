import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/ExpandChildPart.jpg"
import {PlusOutlined} from "@ant-design/icons";

const ExpandChildPart = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Расширение механизмов участия детей и подростков в принятии решений, затрагивающих их
            интересы, учета их мнения по вопросам в сфере детства на федеральном, региональном и муниципальном уровнях
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div>
            <p><a href="https://events.webinar.ru/19466565/9203383">Регистрация<PlusOutlined style={{paddingLeft: "5px"}}/></a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/DD-Programma-vebinara-2021-09-29-4.pdf">Программа</a></p>
            <p>
                7 октября 2021 г. в 10:00 Мск. Минпросвещения России совместно с ФГБУ «Центр защиты прав и интересов
                детей» проводит методический вебинар «Расширение механизмов участия детей и подростков в принятии
                решений, затрагивающих их интересы, учета их мнения по вопросам в сфере детства на федеральном,
                региональном и муниципальном уровнях».
            </p>
            <p>
                Вебинар продолжает цикл мероприятий по реализации Десятилетия детства.
                В данном вебинаре примут участие представители федеральных органов исполнительной власти, органов
                исполнительной власти субъектов Российской Федерации и общественных организаций.
            </p>
            <p>
                Спикеры вебинара:
            </p>
            <p>
                Фальковская Лариса Павловна – директор Департамента государственной политики в сфере защиты прав детей
                Минпросвещения России.
            </p>
            <p>
                Семья Галина Владимировна — член Координационного совета при Правительстве Российской Федерации по
                реализации в Российской Федерации Десятилетия детства, профессор ФГБОУ ВО «Московский государственный
                психолого-педагогический университет».
            </p>
            <p>
                Представители органов исполнительной власти, а также обучающиеся общеобразовательных организаций
                Новосибирской и Свердловской областей, которые представят лучшие региональные практики и наработки по
                вопросам, связанным с расширением механизмов участия детей и подростков в принятии решений,
                затрагивающих их интересы, учета их мнения по вопросам в сфере детства.
            </p>
            <p>
                Мероприятие пройдет в онлайн-формате.
            </p>
            <p>
                Ссылка на регистрацию: <a href="https://events.webinar.ru/19466565/9203383">https://events.webinar.ru/19466565/9203383</a>.
            </p>
        </div>
    </div>
}


export default ExpandChildPart