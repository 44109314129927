import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News120324.jpg"
import img1 from "../../assets/News/News120324_1.jpg"
import img2 from "../../assets/News/News120324_2.jpg"
import img3 from "../../assets/News/News120324_3.jpg"
import img4 from "../../assets/News/News120324_4.jpg"
import img5 from "../../assets/News/News120324_5.jpg"

const News120324 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Продолжается набор на курс повышения квалификации специалистов органов опеки и попечительства.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Образовательная программа: <strong>Рассмотрение споров родителей, связанных с воспитанием детей, включая
            споры с участием иностранного элемента, 36 академических часов.</strong>
            <p></p>
            Период обучения:<br/>
            <strong>с 15 апреля по 30 апреля 2024 г.</strong>
            <p></p>
            После обучения и успешного прохождения итоговой аттестации вы получите удостоверение о повышении
            квалификации.<br/>
            Стоимость обучения: <strong>15 000 рублей.</strong><br/>
            Описание программы: <a href="https://dpo.fcprc.ru/opecacourse1">https://dpo.fcprc.ru/opecacourse1</a>
            <p></p>
            <a href="https://dpo.fcprc.ru/registration">ПОДАТЬ ЗАЯВКУ НА ОБУЧЕНИЕ</a>
            <p></p>
            Направить свой запрос на обучение специалистов по другим интересующим вас темам можно на электронную
            почту <a href="mailto:dpo@fcprcgov.ru">dpo@fcprcgov.ru</a>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <ToNews/>
    </div>
}


export default News120324;