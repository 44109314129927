import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News271123.png"
import img2 from "../../assets/News/News271123_2.png"
import img3 from "../../assets/News/News271123_3.png"
import img4 from "../../assets/News/News271123_4.png"
import img5 from "../../assets/News/News271123_5.jpeg"

const News271123 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Научно-практический семинар «Проблемы буллинга
            в современном образовании: состояние и пути решения»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            21 ноября 2023 года в Российской академии образования (РАО) прошел научно-практический семинар по проблеме
            буллинга и его профилактики. Семинар был организован в рамках деятельности отделения психологии и возрастной
            физиологии Российской академии образования и Научного совета РАО по проблемам профилактики агрессии и
            деструктивного поведения учащихся.

            <p></p>
            Различные аспекты феноменологии и предупреждения буллинга – травли в детско-подростковой среде осветили
            ведущие ученые, обозначив свои разработки и пути решения вопроса:
            <p></p>

            <strong>Сергей Борисович Малых</strong>, академик РАО, академик-секретарь отделения психологии и возрастной
            физиологии РАО,
            член Президиума РАО, профессор, доктор психологических наук;
            <p></p>

            <strong>Артур Александрович Реан</strong>, академик РАО, член бюро отделения психологии и возрастной
            физиологии, доктор
            психологических наук, профессор, директор Центра социализации, семьи и профилактики асоциального поведения
            ФГБОУ ВО «Московский педагогический государственный университет» (МПГУ), председатель Научного совета РАО по
            проблемам профилактики агрессии и деструктивного поведения учащихся;
            <p></p>

            <strong>Владимир Самуилович Собкин</strong>, академик РАО, руководитель Центра социологии образования
            Института управления
            образованием РАО, руководитель информационно-аналитического центра РАО, профессор, доктор психологических
            наук;
            <p></p>

            <strong>Иван Александрович Коновалов</strong>, кандидат психологических наук, ученый секретарь Научного
            совета РАО по
            проблемам профилактики агрессии и деструктивного поведения учащихся, аналитик Центра социализации, семьи и
            профилактики асоциального поведения МПГУ;
            <p></p>

            <strong>Алексей Андреевич Ставцев</strong>, кандидат психологических наук, ученый секретарь
            Научно-координационного совета
            РАО по вопросам семьи и детства, аналитик Центра социализации, семьи и профилактики асоциального поведения
            МПГУ;
            <p></p>

            <strong>Татьяна Николаевна Тихомирова</strong>, научный руководитель Федерального ресурсного центра в
            системе высшего
            образования РАО, академик РАО, доктор психологических наук;
            <p></p>

            <strong>Сергей Николаевич Ениколопов</strong>, руководитель отдела медицинской психологии ФГБНУ «Научного
            центра психического
            здоровья», кандидат психологических наук;
            <p></p>

            <strong>Вячеслав Лазаревич Цветков</strong>, начальник кафедры юридической психологии Учебно-научного
            комплекса психологии
            служебной деятельности Московского университета Министерства внутренних дел Российской Федерации им. В.Я.
            Кикотя, доктор психологических наук, кандидат юридических наук, профессор;
            <p></p>

            <strong>Виктория Владимировна Вахнина</strong>, руководитель Отдела психологического обеспечения
            прокурорской деятельности
            Университета Прокуратуры РФ, доктор психологических наук, профессор, старший советник юстиции;
            <p></p>

            В работе семинара приняли участие <strong>Борис Борисович Гусев</strong>, директор ФГБУ «Центр защиты прав и
            интересов
            детей», кандидат педагогических наук и <strong>Елена Геннадьевна Артамонова</strong>, заместитель директора
            Центра по
            развитию системы профилактики девиантного поведения, кандидат психологических наук.

            <p></p>
            Борис Борисович поделился опытом работы в качестве директора Спецшколы №1 г. Москвы для детей с девиантным и
            общественно опасным поведением и рассказал о специфике работы Центра защиты прав и интересов детей, отметив,
            что за последние два года значительно выросло число обращений, связанных с буллингом в образовательных
            организациях, в том числе буллингом со стороны учителей.
            <p></p>
            Подробнее о мероприятии: <strong><a
            href="http://mpgu.su/novosti/sovremennom-obrazovanii-sostojanie/">http://mpgu.su/novosti/sovremennom-obrazovanii-sostojanie/</a></strong>
            <p></p>
            <div>
                <img src={img2}/>
            </div>
            <div>
                <img src={img3}/>
            </div>
            <div>
                <img src={img4}/>
            </div>
            <div>
                <img src={img5}/>
            </div>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News271123;