import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News250521 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ в мае
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В мае в рамках сопровождения служб медиации в специальных учебно-воспитательных учреждениях медиаторы Центра
            защиты прав и интересов детей на площадке Минпросвещения РФ провели серию из трёх просветительских
            вебинаров, направленных на приобретение и актуализацию знаний сотрудников об альтернативных методах
            предупреждения и разрешения конфликтных ситуаций в СУВУ.
            <p></p>
            В процессе занятий слушатели узнали о разнице традиционных и альтернативных методов разрешения споров,
            познакомились с базовыми техниками медиации, потренировали некоторые из них, попробовали себя в роли
            нейтральной стороны в предложенных учебных кейсах.
            <p></p>
            Формат групповой дискуссии в малых группах позволил участникам не только ближе узнать своих коллег из других
            СУВУ, но и сравнить, и зачастую скорректировать свои позиции по обсуждаемым вопросам.
            <p></p>
            Занятия прошли в атмосфере сотрудничества и взаимопонимания, большинство участников выразили
            заинтересованность в проведении семинаров медиативной направленности в дальнейшем.
            <p></p>
            Всего в мероприятиях приняли участие 64 сотрудника из 17 СУВУ, подведомственных Минпросвещения России.
        </div>
        <ToNews/>
    </div>
}


export default News250521;