import React from "react";
import ico from "../../../assets/fileicored.svg"
import ico1 from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const FilesAllRusCon = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Всероссийские конференции
                </h2>
                <Falldown header="2023 год" style={{maxWidth: "700px"}}>
                    <FileLeftLine
                        text={"Всероссийская научно-практическая конференция по актуальным вопросам профилактики девиантного поведения несовершеннолетних «Безопасная образовательная среда: стратегии, практики, ресурсы»"}
                        refers={"/conception/2023"} ico={ico1} styles={{maxWidth: "600px"}}/>
                </Falldown>
                <Falldown header="2022 год" style={{maxWidth: "700px"}}>
                    <FileLeftLine
                        text={"Всероссийская научно-практическая конференция по актуальным проблемам профилактики девиантного поведения несовершеннолетних «На защите детства: стратегии, практики, ресурсы»"}
                        refers={"/conception/2022"} ico={ico1} styles={{maxWidth: "600px"}}/>
                </Falldown>
                <Falldown header="2021 год" style={{maxWidth: "700px"}}>
                    <FileLeftLine
                        text={"Всероссийская научно-практическая конференция «Актуальные проблемы профилактики девиантного поведения несовершеннолетних», 2021 год"}
                        refers={"/conception/2021"} ico={ico1} styles={{maxWidth: "600px"}}/>
                    <FileLeftLine text={"Пленарная сессия"} refers={"/conception/plenarnaya-sessiya"}
                                  ico={ico1}
                                  styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"Панельная дискуссия «Психологическое благополучие как основа безопасности детей и профилактики подростковой девиации»"}
                        refers={"/conception/panelnaya-diskussiya-psihologicheskoe-blagopoluchie-kak-osnova-bezopasnosti-detej-i-profilaktiki-podrostkovoj-deviatsii"}
                        ico={ico1}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"Секция 1 «Безопасность детства. Социальные практики помощи ребенку и его семье»"}
                        refers={"/conception/sektsiya-1-bezopasnost-detstva-sotsialnye-praktiki-pomoshhi-rebenku-i-ego-seme"}
                        ico={ico1}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"Секция 2 «Личность и социум. Профилактика асоциального поведения детей и подростков»"}
                        refers={"/conception/sektsiya-2-lichnost-i-sotsium-profilaktika-asotsialnogo-povedeniya-detej-i-podrostkov"}
                        ico={ico1}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine text={"Секция 3 «Ценность здоровья. Позитивная профилактика зависимого поведения»"}
                                  refers={"/conception/sektsiya-3-tsennost-zdorovya-pozitivnaya-profilaktika-zavisimogo-povedeniya"}
                                  ico={ico1}
                                  styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"Секция 4 «Медиативные практики. Пространство конструктивного взаимодействия»"}
                        refers={"/conception/sektsiya-4-mediativnye-praktiki-prostranstvo-konstruktivnogo-vzaimodejstviya/"}
                        ico={ico1}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine text={"Открытый марафон мастер-классов"}
                                  refers={"/conception/otkrytyj-marafon-master-klassov/"} ico={ico1}
                                  styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"Актуальные проблемы профилактики девиантного поведения несовершеннолетних: сборник материалов Всероссийской научно-практической конференции"}
                        refers={"https://fcprc.ru/media/media/behavior/Sbornik-Konferentsii-profilaktiki_2021_fzq1Wrp.pdf"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}/>
                </Falldown>
                <Falldown header="2020 год" style={{maxWidth: "700px"}}>
                    <FileLeftLine
                        text={"Всероссийская научно-практическая конференция «Актуальные проблемы профилактики девиантного поведения несовершеннолетних», 2020 год"}
                        refers={"/conception/2020/"} ico={ico1} styles={{maxWidth: "600px"}}/>
                </Falldown>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default FilesAllRusCon