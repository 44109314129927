import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import img from "../../assets/News/SuvuGraph1.jpg"
import img2 from "../../assets/News/SuvuGraph2.jpg"
import img3 from "../../assets/News/SuvuGraph3.jpg"

const SuvuGraph = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Министерством просвещения Российской Федерации утвержден график проведения подведомственными специальными
            учебно-воспитательными учреждениями (далее – СУВУ) общественно значимых мероприятий в сфере образования,
            науки и молодежной политики в 2021 году
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Обучающимся в СУВУ предложено принять участие во Всероссийской ассамблее инициатив юношества и во
                Всероссийской олимпиаде по общеобразовательным предметам, педагогическим работникам – во Всероссийском
                конкурсе профессионального мастерства «Педагог года – 2021».</p>
        </div>
        <div>
            <img src={img}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Ознакомиться с положениями об организации и проведении упомянутых мероприятий можно здесь:</p>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Polozhenie-Assambleya-2021.pdf">Положение - Ассамблея 2021</a>
        </div>
        <br/>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Polozhenie-Olimpiada-2021-.pdf">Положение - Олимпиада 2021</a>
        </div>
        <br/>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Polozhenie-Pedagog-goda-2021.pdf">Положение - Педагог года 2021</a>
        </div>
        <p></p>
    </div>
}


export default SuvuGraph;