import React from "react";
import ico1 from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const LentaRegPrac = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Лента региональных практик
                </h2>
                <Falldown header="Лента региональных практик – 2022" style={{maxWidth: "700px"}}>
                    <FileLeftLine
                        text={"Лента региональных практик – 2022"}
                        refers={"https://disk.yandex.ru/i/FhXWYU31Cydnxw"} ico={ico1} styles={{maxWidth: "600px"}}/>
                </Falldown>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default LentaRegPrac