import React from "react";
import s from "./News.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import arr from "../../assets/arrow.svg"

const NewsBlock = ({children, img, header}) => {
    const [newsData, setNews] = useState([])
    useEffect(() => {

        axios.all(
            [
                axios.get("https://fcprc.ru/api/newsold"),
                axios.get("https://fcprc.ru/api/main")
            ]
        )
            .then(axios.spread((obj1, obj2) => {
                let data1 = obj1.data.reverse()
                let data2 = obj2.data.reverse()

                setNews(data2.concat(data1))
            }))
    }, [])
    return <div className={s.newsPage}>
        <div className={s.newHeader}>
            <h2>
                Новости
            </h2>
        </div>
        <div className={s.newsList}>
            {
                newsData.map(el =>
                    <NewsSquare src={el.img} header={el.Header} text={el.text} date={el.date} refs={el.ref}/>
                )
            }
        </div>

    </div>
}

const NewsSquare = ({src, header, text, date, refs = "/"}) => {
    return <div className={s.one}>
        <div className={s.iconNews}>
            <a href={refs}>
                <img src={src}/>
            </a>
        </div>
        <div style={{overflow: "hidden", height: "43px", marginTop: "15px"}}>
            <a href={refs}>
                {header}
            </a>
        </div>
        <div style={{
            color: "#212529",
            fontSize: "14px",
            fontFamily: "Segoe UI",
            fontWeight: "300",
            marginTop: "15px",
            marginBottom: "25px"
        }}>
            {date}
        </div>
        <div style={{overflow: "hidden", height: "87px"}}>
            {text}
        </div>
        <a href={refs}>
            <button className={s.subBtn}>
                Читать далее <img src={arr} style={{width: "20px", fontSize: "20px"}}/>
            </button>
        </a>
        <p></p>
    </div>
}


export default NewsBlock