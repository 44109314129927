import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News29052023.jpg"

const News29052023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            31 мая – Всемирный день без табака.<br/>
            Всероссийское родительское собрание по вопросам профилактики употребления табака и иной никотинсодержащей
            продукции среди детей, подростков и молодежи «Здоровье и безопасность: простые правила»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Каждый год 31 мая по всему миру отмечается <strong>Всемирный день без табака</strong>, который был
                учрежден в 1988 году
                Всемирной организацией здравоохранения. Ежегодная кампания служит поводом для широкого информирования о
                губительных последствиях употребления табака и пассивного курения, в целях содействия сокращению
                употребления табака и иной никотинсодержащей продукции в любой форме.</p>
            <p>В России действует Федеральный закон от 23 февраля 2013 г. № 15-ФЗ «Об охране здоровья граждан от
                воздействия окружающего табачного дыма и последствий потребления табака». В 2023 году в целях усиления
                заботы об охране здоровья несовершеннолетних были внесены поправки в законодательство в сфере оборота
                никотинсодержащей продукции. Изменения касаются ужесточения правил продажи несовершеннолетним
                никотинсодержащей продукции, никотинсодержащей и безникотиновой жидкостей, электронных систем доставки
                никотина, устройств для нагревания табака и иных приборов, которые используются для получения
                никотинсодержащего или безникотинового аэрозоля, пара, вдыхаемых потребителем.</p>
            <p>Центром защиты прав и интересов детей подготовлено Всероссийское родительское собрание «Здоровье и
                безопасность: простые правила» в целях профилактики употребления табака и иной никотинсодержащей
                продукции в детско-молодежной среде и предотвращения возможных рисков и угроз здоровью.</p>
            <p>Собрание проводилось в формате тематического интервью-беседы с профильными экспертами и охватывало
                медицинский, юридический и педагогический аспекты профилактики употребления никотинсодержащей продукции.<br/>
                В ходе беседы были затронуты следующие темы:<br/>
                • Мифы и реальность о потреблении никотинсодержащей продукции среди детей и подростков – медицинский
                аспект раскрыла медицинский психолог Центра профилактики зависимого поведения (филиала) ГБУЗ «Московский
                научно-практический центр наркологии Департамента здравоохранения города Москвы» <strong>Дарья Сергеевна
                    Катюрина.</strong><br/>
                • Ответственность и меры противодействия распространению табакокурения и потребления иной
                никотинсодержащей продукции – юридический аспект осветила начальник отдела профилактики девиантного
                поведения несовершеннолетних ФГБУ «Центр защиты прав и интересов детей», кандидат педагогических
                наук <strong>Ольга Вячеславовна Заева.</strong><br/>
                • Вопросы профилактики табакокурения в школе и семье – педагогический аспект рассмотрела заместитель
                директора по научной работе ФГБНУ «Институт изучения вопросов детства, семьи и воспитания», кандидат
                технических наук <strong>Оксана Александровна Шестакова.</strong><br/>
            </p>
            <p>Собрания провела заместитель директора по развитию системы профилактики девиантного поведения ФГБУ «Центр
                защиты прав и интересов детей», кандидат психологических наук <strong>Елена Геннадьевна
                    Артамонова.</strong></p>
            <div style={{textAlign: "center"}}>
                <p>Приглашаем к просмотру Всероссийского родительского собрания<br/>
                    «Здоровье и безопасность: простые правила» родителей, педагогов,<br/>
                    всех неравнодушных к теме сохранения здоровья подрастающего поколения.<br/></p>
            </div>
            <div style={{textAlign: "center"}}>
                <p>Каждый ребенок имеет право на здоровое и счастливое детство.<br/>

                    Совместные действия родителей, педагогов при поддержке государства<br/>

                    помогут создать безопасную среду для роста и развития наших детей!<br/></p>
            </div>
            <div style={{textAlign: "center"}}>
                <p><strong> ЗАПИСЬ ВСЕРОССИЙСКОГО РОДИТЕЛЬСКОГО СОБРАНИЯ «ЗДОРОВЬЕ И БЕЗОПАСНОСТЬ: ПРОСТЫЕ ПРАВИЛА»</strong></p>
            </div>

            <div style={{textAlign: "center", margin: "0 auto"}}>
                <iframe src="https://vk.com/video_ext.php?oid=-182917553&id=456239031&hash=c7e709cb22f0e1c9&hd=2"
                        width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                        frameBorder="0" allowFullScreen></iframe>
            </div>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News29052023;