import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/CosmonauticsDay2023.jpg"

const CosmonauticsDay2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Поздравление Министра просвещения с Днём космонавтики
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Дорогие друзья!</p>
            <p>Поздравляю ветеранов и работников космической отрасли, их учителей и наставников, всех жителей нашей
                страны с Днем космонавтики!
            </p>
            <p>Наши учёные, конструкторы и космонавты открыли эру освоения космоса. Юрий Гагарин, Валентина Терешкова,
                Алексей Леонов – их имена по праву вписаны в мировую историю космонавтики.</p>
            <p>В Год педагога и наставника особую благодарность хотел бы выразить тем, кто готовит и обучает будущих
                исследователей, учёных, испытателей, тех, кто вносит неоценимый вклад в научно-технологическое развитие
                страны. Именно наставники, передавая свои знания, опыт и веру в себя, воспитывают героев нашего времени,
                вдохновляют юное поколение на новые свершения.
                Сегодня важно прививать интерес к науке и высокотехнологичным производствам. В нашей стране есть все
                возможности для профессиональной реализации в различных передовых индустриях, в том числе и в
                космической. Начинать свой путь к выбранной специальности нужно еще в школе, уделяя внимание астрономии,
                физике, математике и другим точным наукам.</p>
            <p>Всем школьникам и студентам, кто стремится связать свою жизнь с космической отраслью, их учителям и
                наставникам желаю уверенности, усердия и терпения в достижении самых амбициозных целей и задач. Желаю
                быть первыми в своей профессии!</p>
            <p>Министр просвещения Сергей Кравцов</p>
        </div>
        <ToNews/>
    </div>
}


export default CosmonauticsDay2023;