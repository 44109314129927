import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News030424.JPG"
import img1 from "../../assets/News/News030424_1.jpg"
import img2 from "../../assets/News/News030424_2.jpg"
import img3 from "../../assets/News/News030424_3.jpg"
import img4 from "../../assets/News/News030424_4.jpg"
import img5 from "../../assets/News/News030424_5.jpg"

import SimpleImageSlider from "react-simple-image-slider";

const News030424 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            28-29 марта 2024 г. в Москве прошел XII Всероссийский Съезд руководителей организаций для детей-сирот и
            детей, оставшихся без попечения родителей
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            28-29 марта 2024 г. Минпросвещения России проведен XII Всероссийский Съезд руководителей организаций для
            детей-сирот и детей, оставшихся
            без попечения родителей.
            <p></p>
            Мероприятие прошло на площадке Международной выставки-форума «Россия», в главном лектории для
            просветительских мероприятий Российского общества «Знание» - генерального партнера выставки-форума.
            <p></p>
            В Съезде приняли участие более 300 человек, включая в том числе делегатов из 82 субъектов Российской
            Федерации (руководители и специалисты организаций
            для детей-сирот, представители исполнительных органов субъектов Российской Федерации, осуществляющих
            государственное управление в сфере опеки
            и попечительства в отношении несовершеннолетних граждан), представителей федеральных органов исполнительной
            власти, высшей школы, Всероссийской общественной организации «Содружество выпускников детских домов «Дети
            всей страны».
            <p></p>
            <SimpleImageSlider images={[img1, img2, img3, img4]} width={1000} height={600}
                               showBullets={true}
                               autoPlay={true}
                               autoPlayDelay={5}
                               showNavs={true}/>
            <p></p>
            В Год семьи ключевой темой съезда стала популяризация государственной политики в сфере защиты семьи,
            создание условий для восстановления родительских прав, сохранение родственных и дружеских связей ребенка,
            находящегося в организации для детей-сирот.
            <p></p>
            В ходе работы Съезда обсуждены вопросы, касающиеся перспектив развития организаций для детей-сирот,
            различных этапов нахождения детей в этих организациях, развития творческих способностей воспитанников,
            организации воспитательной работы.
            <p></p>
            <strong><a href="https://disk.yandex.ru/d/GG2_R5csNAOdkA">Материалы XII Всероссийского Съезда руководителей
                организаций для детей-сирот и детей, оставшихся без
                попечения родителей.</a></strong>
            <p></p>
            <div>
                <img src={img5}/>
            </div>
            В рамках Съезда проведено торжественное открытие <u><a
            href="https://на-стороне-ребенка.рф/%d0%be-%d0%ba%d0%be%d0%bd%d0%ba%d1%83%d1%80%d1%81%d0%b5/">Всероссийского
            конкурса в сфере профессиональной
            деятельности специалистов органов опеки и попечительства в отношении несовершеннолетних граждан «На стороне
            ребенка».</a></u>
            <p></p>
            Его организация предусмотрена Комплексом мер по подготовке и повышению квалификации специалистов органов
            опеки и попечительства в отношении несовершеннолетних граждан на 2021-2024 годы, утвержденным Заместителем
            Председателя Правительства Российской Федерации Т.А. Голиковой. Впервые на федеральном уровне конкурс среди
            специалистов органов опеки и попечительства был проведен в 2022 году.
            <p></p>
            На Съезде присутствовали члены экспертной комиссии конкурса. Галина Владимировна Семья, доктор
            психологических наук, профессор Московского государственного психолого-педагогический университета и Алла
            Зауровна Дзугаева, директор ГБУ города Москвы Ресурсный центр по вопросам опеки и попечительства
            «Содействие» Департамента труда и социальной защиты населения города Москвы рассказали об опыте проведения
            первого конкурса и ожиданиях конкурса 2024 года, который стартовал в феврале текущего года.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News030424;