import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";
import {FileLeftLineKDN} from "./FilesKDNNews";


const FPF2021 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    2021 год
                </h2>
                <FileLeftLineKDN text={respData[266]?.description} refers={respData[266]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[265]?.description} refers={respData[265]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[262]?.description} refers={respData[262]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[261]?.description} refers={respData[261]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[263]?.description} refers={respData[263]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[264]?.description} refers={respData[264]?.file} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default FPF2021