import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/OSRPRV.jpg"
import YouTube from "react-youtube";

const OSRPRV = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «Организация системной работы по профилактике раннего вовлечения в незаконное потребление
            наркотических средств и психотропных веществ обучающихся образовательных организаций в субъектах Российской
            Федерации»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                8 июня 2021 года состоялся вебинар по теме «Организация системной работы по профилактике раннего
                вовлечения в незаконное потребление наркотических средств и психотропных веществ обучающихся
                образовательных организаций в субъектах Российской Федерации».
            </p>
            <p>
                В вебинаре приняли участие специалисты органов исполнительной власти субъектов Российской Федерации,
                осуществляющих государственное управление в сфере образования, и региональные операторы, ответственные
                за организацию и проведение социально-психологического тестирования обучающихся образовательных
                организаций. Всего в вебинаре приняли участие 587 человек из 82 субъектов Российской Федерации.
            </p>
            <p>
                Костыряченко Юлия Александровна, заместитель директора Департамента государственной политики в сфере
                защиты прав детей Министерства просвещения Российской Федерации, поприветствовала участников вебинара и
                осветила в своём выступлении нормативное правовое обеспечение организации профилактической работы в
                образовательных организациях в субъектах Российской Федерации.
            </p>
            <p>
                Заева Ольга Вячеславовна, начальник отдела организационно-методического сопровождения развития системы
                профилактики девиантного поведения несовершеннолетних ФГБУ «Центр защиты прав и интересов детей»,
                подготовила сообщение, раскрывшее особенности проведения мониторинга организации профилактической работы
                в образовательных организациях субъектов Российской Федерации, направленной на раннее выявление
                незаконного потребления наркотических средств и психотропных веществ среди обучающихся, в 2020/21
                учебном году. В своём выступлении Ольга Вячеславовна также анонсировала проведение онлайн-опроса
                педагогов, направленного на самоисследование уровня их информированности и характера субъектного
                отношения к деятельности в области профилактики распространения ВИЧ-инфекции среди обучающихся. Опрос
                проходит на портале Центра <a href="опрос-педагогов-о-вич.рф">опрос-педагогов-о-вич.рф</a>.
            </p>
            <p>Смирнова Марина Васильевна, заместитель директора ГБУ ДО Нижегородской области «Центр
                психолого-педагогической, медицинской и социальной помощи», выступила с докладом на тему «Комплексный
                подход к профилактике аддиктивного поведения обучающихся в системе образования Нижегородской
                области».</p>
            <p>
                Опытом организации профилактической работы по итогам социально-психологического тестирования обучающихся
                образовательных организаций в Омской области поделилась Орлова Екатерина Витальевна, директор БУ Омской
                области «Центр психолого-медико-социального сопровождения».
            </p>
            <p>
                Алагуев Михаил Викторович, аналитик ФГБУ «Центр защиты прав и интересов детей», осветил итоги работы
                специализированной страницы антинаркотического содержания на сайте Центра с октября 2020 года и
                представил обзор материалов, размещенных на специализированной странице «Ответственное поведение –
                ресурс здоровья».
            </p>
            <p>
                Все материалы вебинара, включая его запись, размещены в личном кабинете регионального оператора СПТ.
            </p>
            <YouTube style={{textAlign: "center"}} videoId="FnTrAEsbcIU"/>
            <p></p>
        </div>
    </div>
}


export default OSRPRV