import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/Saratov105.jpg"
import img1 from "../../assets/News/Saratov105_1.jpg"
import img2 from "../../assets/News/Saratov105_2.jpg"

const Zased30032023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            30 марта 2023 года состоялось заочное заседание Правительственной комиссии
            по делам несовершеннолетних и защите их прав
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>30 марта 2023 года в рамках заседания Правительственной комиссии по делам несовершеннолетних и защите их
                прав, проведённого в заочном формате, рассмотрен плановый вопрос– «Правовое просвещение и правовое
                информирование
                в деятельности органов и учреждений системы профилактики безнадзорности и правонарушений
                несовершеннолетних как неотъемлемая часть профилактики антиобщественного
                и противоправного поведения несовершеннолетних
                и защиты их прав».
            </p>
            <p>Приняты решения, в том числе: о разработке единых подходов по формированию целостной системы правового
                просвещения и правового информирования в образовательных организациях на всех уровнях образования
                независимо от типа указанных организаций, с учетом отечественных практик правопросветительской работы;
                об организации работы по правовому просвещению и правовому информированию несовершеннолетних в Донецкой
                и Луганской Народных Республиках, Запорожской и Херсонской областях.
            </p>
            <p>Кроме того, предложено при организации Всероссийского молодежного образовательного форума «Территория
                смыслов» в 2023 году рассмотреть вопрос реализации в рамках тематических смен правозащитного направления
                с включением в его работу молодых правозащитников и волонтеров из субъектов Российской Федерации.
            </p>
            <p className={s.newsA}>
                <a href="https://fcprc.ru/media/media/behavior/протокол__1_от_30.03.2023_г._1.pdf">Протокол заочного
                    заседания Правительственной комиссии по делам несовершеннолетних и защите
                    их прав от 30 марта 2023 г. №1</a>
            </p>
        </div>
    </div>
}


export default Zased30032023;