import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/LifeStyle2021.jpg"
import YouTube from "react-youtube";

const LifeStyle2021 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «О проведении Всероссийского конкурса социальной рекламы «Стиль жизни – здоровье! 2021»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div>
            <p>
                21 мая 2021 года состоялся вебинар, посвященный проведению Всероссийского конкурса социальной рекламы
                «Стиль жизни – здоровье! 2021». На вебинаре рассматривались вопросы организации и проведения конкурса в
                субъектах Российской Федерации и порядок участия в нем.
            </p>
            <p>
                Хабибуллин Ильнур Исхакович, начальник отдела развития профилактики асоциального поведения
                несовершеннолетних Департамента государственной политики в сфере защиты прав детей Минпросвещения
                России, выступил с приветственным словом к участникам вебинара.
            </p>
            <p>
                Заева Ольга Вячеславовна, начальник отдела организационно-методического сопровождения развития системы
                профилактики девиантного поведения несовершеннолетних ФГБУ «‎Центр защиты прав и интересов детей»,
                ознакомила с порядком проведения Всероссийского конкурса социальной рекламы «Стиль жизни – здоровье!
                2021» и требованиями к подготовке конкурсных работ.
            </p>
            <p>
                Семенюк Игорь Иванович, аналитик отдела организационно-методического сопровождения развития системы
                профилактики девиантного поведения несовершеннолетних ФГБУ «‎Центр защиты прав и интересов детей»,
                рассмотрел организационные вопросы проведения федерального этапа Всероссийского конкурса «Стиль жизни –
                здоровье! 2021».
            </p>
            <p>В вебинаре приняли участие 110 слушателей из 55 субъектов Российской Федерации.</p>
            <YouTube style={{textAlign: "center", height: "100%"}} videoId="-V592cw716M"/>
            <p></p>
            <div className={s.blueButtonMediaciaBase}><a href="https://fcprc.ru/media/media/behavior/Prezentatsiya-1-Zaeva-O.V.pdf">Презентация О.В. Заевой</a></div>
            <p></p>
            <div className={s.blueButtonMediaciaBase}><a href="https://fcprc.ru/media/media/behavior/Prezentatsiya-2-Semenyuk-I.I.pdf">Презентация И.И. Семенюка</a></div>
            <br/>
        </div>
    </div>
}


export default LifeStyle2021