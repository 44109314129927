import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/OPVP.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const OPVP = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Вебинар по актуальным вопросам деятельности комиссий по делам несовершеннолетних и защите их прав для
                    ответственных секретарей комиссий субъектов Российской Федерации и специалистов, обеспечивающих
                    деятельность указанных комиссий, по теме: «О проблемных вопросах правоприменительной практики комиссий
                    по делам несовершеннолетних и защите их прав по результатам мониторинга практики организации
                    деятельности комиссий», 15 сентября 2021 г.
                </h2>
            </div>
            <div>
                <img src={logo}/>
            </div>

            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    15 сентября 2021 года в дистанционном формате на Webinar-платформе ФГБУ «Центр защиты прав и
                    интересов детей» состоялся вебинар по актуальным вопросам деятельности комиссий по делам
                    несовершеннолетних и защите их прав для ответственных секретарей комиссий по делам
                    несовершеннолетних и защите их прав субъектов Российской Федерации и специалистов, обеспечивающих
                    деятельность указанных комиссий, по теме: «О проблемных вопросах правоприменительной практики
                    комиссий по делам несовершеннолетних и защите их прав по результатам мониторинга практики
                    организации деятельности комиссий» (далее – вебинар).
                </p>
                <p>Данное мероприятие, организованное во исполнение пункта 26 плана мероприятий на 2021-2025 годы по
                    реализации Концепции развития системы профилактики безнадзорности и правонарушений
                    несовершеннолетних на период до 2025 года, утвержденного распоряжением Правительства Российской
                    Федерации от 22 марта 2017 г. № 520-р, Минпросвещения России совместно с ФГБУ «Центр защиты прав и
                    интересов детей», проводилось в целях разъяснения актуальных проблемных вопросов правоприменительной
                    практики комиссий по делам несовершеннолетних и защите их прав, возникших по результатам мониторинга
                    практики организации деятельности комиссий.</p>
                <p>В рамках мероприятия заместитель начальника отдела развития системы профилактики асоциального
                    поведения несовершеннолетних Департамента государственной политики в сфере защиты прав детей
                    Минпросвещения России А.В. Овчинникова дала разъяснения основных аспектов подготовки плана работы
                    комиссии по делам несовершеннолетних и защите их прав с учетом требований, установленных Примерным
                    положением о комиссиях по делам несовершеннолетних и защите их прав, утвержденным постановлением
                    Правительства Российской Федерации от 6 ноября 2013 г. № 995.</p>
                <p>
                    В докладе ведущего аналитика ФГБУ «Центр защиты прав и интересов детей» Н.Т. Григорьевой отражены
                    сведения об актуальных проблемных вопросах координации комиссиями по делам несовершеннолетних и
                    защите их прав деятельности органов и учреждений системы профилактики безнадзорности и
                    правонарушений несовершеннолетних.
                </p>
                <p>
                    В рамках выступлений начальника отдела по вопросам помилования, взаимодействия с федеральными
                    органами и делам несовершеннолетних аппарата Правительства Тверской области Ю.Н. Шараповой на тему:
                    «О некоторых подходах межведомственной комиссии по делам несовершеннолетних и защите их прав при
                    Правительстве Тверской области по обеспечению координации деятельности органов и учреждений системы
                    профилактики безнадзорности и правонарушений несовершеннолетних» и консультанта отдела профилактики
                    правонарушений управления региональной безопасности Администрации Губернатора Белгородской области,
                    ответственного секретаря комиссии по делам несовершеннолетних и защите их прав Белгородской области
                    Ю.С. Соколовой на тему: «Об опыте реализации проекта «Модернизация работы комиссий по делам
                    несовершеннолетних и защите их прав на территории Белгородской области». Применение медиативных
                    технологий в деятельности территориальных комиссий» представлен опыт субъектов Российской Федерации
                    по практике применения подходов к обеспечению координации деятельности органов и учреждений системы
                    профилактики безнадзорности и правонарушений несовершеннолетних.
                </p>
                <p>
                    Проблемные вопросы, связанные с организацией административного производства комиссий по делам
                    несовершеннолетних и защите их прав, раскрыты в докладе руководитель центра научно-методического
                    обеспечения системы профилактики безнадзорности и правонарушений несовершеннолетних федерального
                    государственного бюджетного образовательного учреждения высшего образования «Саратовская
                    государственная юридическая академия» С.Н. Зайковой.
                </p>
                <p>
                    Начальник отдела организационно-методического сопровождения развития системы профилактики
                    девиантного поведения несовершеннолетних федерального государственного бюджетного учреждения «Центр
                    защиты прав и интересов детей» О.В. Заева ознакомила участников вебинара с информацией о технических
                    вопросах проведения мониторинга, возникающих при организации работы в личном кабинете региональной
                    комиссии по делам несовершеннолетних и защите их прав.
                </p>
                <p><a href="https://fcprc.ru/media/media/behavior/2021-09-15-programma.doc">Программа вебинара</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/2021-09-15-otchet.docx">Отчет о проведении вебинара</a></p>
            </p>
        </div>
        <div>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default OPVP