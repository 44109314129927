import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News11042022.jpg"

const News11042022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Об итогах деятельности органов опеки и попечительства в отношении несовершеннолетних граждан за 2021 год
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>31 марта 2022 г. Министерством просвещения Российской Федерации проведено совещание в режиме
                видео-конференц-связи с руководителями органов исполнительной власти субъектов Российской Федерации,
                осуществляющих полномочия по организации деятельности по опеке и попечительству в отношении
                несовершеннолетних граждан.</p>
            <p>В рамках совещания обсуждены итоги деятельности органов опеки и попечительства в отношении
                несовершеннолетних граждан за 2021 год, а также вопросы профилактики гибели и жестокого обращения в
                отношении детей-сирот и детей, оставшихся без попечения родителей. В совещании приняли участие
                представители Комитета Государственной Думы по вопросам семьи, женщин и детей, социально ориентированных
                некоммерческих организаций.</p>
        </div>
        <ToNews/>
    </div>
}


export default News11042022;