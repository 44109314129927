import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SociolizationTeenagersNewZone1.jpg"
import img2 from "../../assets/News/SociolizationTeenagersNewZone2.jpg"

const SocializationTeenagersNewZone = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            «Лучшая инклюзивная школа России – 2022»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>На прошлой неделе руководитель проектного отдела Федерального подросткового центра Павел Азыркин прибыл в
                Мариуполь, чтобы лично осмотреть помещение, где временно будет размещен подростковый центр,
                познакомиться с руководителем центра и передать все необходимое оборудование для детей.</p>
            <p>Благодаря команде профессионалов и активности самих детей, которые помогали в поиске пространства, центр
                уже открыл свои двери, сотрудники проходят обучение, а в следующем году центр планирует переехать в
                пространство побольше.</p>
            <p>Сейчас ребята воодушевлены идеей дизайна будущего помещения — проектируют, визуализируют, чертят,
                мечтают.</p>
            <p>Также до Мариуполя довезли очередную партию гуманитарной помощи в рамках акции «Детям — в руки», которая
                проходит под эгидой Уполномоченного по правкам ребёнка. Главная задача гуманитарной миссии — обеспечить
                доставку до детей и их родителей самого необходимо, что поддержит в трудную минуту и обеспечит их жизнь
                и здоровье.</p>
            <p>Благодарим всех за участие и неравнодушие. Более восьми тысяч детей с освобожденных территорий уже получили помощь в рамках акции.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default SocializationTeenagersNewZone;