import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/StopHIVAIDS.jpg";

const StopHIVAIDS = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: 29 ноября 2021 года в рамках проведения Всероссийской акции #СТОП ВИЧ/СПИД, состоялся
            вебинар для педагогических работников по вопросам профилактики ВИЧ-инфекции среди обучающихся
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div>
            <p>
                29 ноября 2021 года в рамках проведения Всероссийской акции #СТОП ВИЧ/СПИД, приуроченной к Всемирному
                дню борьбы со СПИДом (1 декабря), состоялся вебинар по вопросам организации и проведения
                профилактической работы с обучающимися «Организационно-методическое сопровождение деятельности
                образовательных организаций по профилактике распространения ВИЧ-инфекции среди обучающихся» для
                специалистов (педагогических работники) образовательных организаций.
            </p>
            <p>
                Всероссийская акция #СТОП ВИЧ/СПИД проводится ежегодно по инициативе Фонда социально-культурных
                инициатив под патронажем С.В. Медведевой в целях повышения эффективности методов противодействия
                распространению и профилактики ВИЧ-инфекции.
            </p>
            <p>
                В вебинаре Центра защиты прав и интересов детей приняли участие 2794 человек из 66 субъектов Российской
                Федерации. 1800 участников Всероссийской акции присоединились к вебинару во время онлайн трансляции,
                организованной на портале ФСКИ.онлайн.
            </p>
            <p>
                Костыряченко Юлия Александровна, заместитель директора Департамента государственной политики в сфере
                защиты прав детей Министерства просвещения Российской Федерации, поприветствовала участников совещания и
                обозначила в своем выступлении ключевые вопросы государственной политики в области профилактики
                распространения ВИЧ-инфекции среди обучающихся образовательных организаций.
            </p>
            <p>
                Заева Ольга Вячеславовна, начальник отдела организационно-методического сопровождения развития системы
                профилактики девиантного поведения несовершеннолетних ФГБУ «Центр защиты прав и интересов детей»,
                подготовила сообщение, посвященное организационным вопросам обеспечения профилактической деятельности
                образовательных организаций.
            </p>
            <p>
                Ключевым разделом вебинара стала интерактивная сессия с участниками вебинара. Интерактивную сессию
                провели Заева Ольга Вячеславовна и Кирьянова Ольга Николаевна, директор Благотворительного фонда помощи
                детям с социально значимыми заболеваниями «Дети плюс».
            </p>
            <p>
                В основу сессии был заложен разбор результатов Всероссийского онлайн-опроса педагогов о профилактике
                распространения ВИЧ-инфекции среди обучающихся (www.опрос-педагогов-о-вич.рф), в котором на момент
                проведения вебинара уже приняли участие более 45 000 педагогов. Опрос педагогов о ВИЧ был разработан в
                рамках соглашения о сотрудничестве Центра и Благотворительного фонда «Дети плюс».
            </p>
            <p>
                Вопросы профилактика ВИЧ и консолидации общих усилий по формированию ответственного отношения к себе,
                своему здоровью и благополучию в разрезе кросс-культурного подхода осветил в своем выступлении Епоян
                Тигран Альбертович, советник по образованию и здоровью Института ЮНЕСКО по информационным технологиям в
                образовании, образованию и здоровью. Речь шла об опыте применения инструментов по профилактике ВИЧ через
                образование для здоровья и благополучия в Армении, Республике Молдове, Эстонии, Шотландии и Кыргызстане,
                а также новыми инструментами — ресурсами в помощь как самим подросткам, так и учителям.
            </p>
            <p>
                Логичным завершением вебинара стал доклад Кихтенко Любовь Федоровны, заведующий кафедрой физической
                культуры и здоровьесбережения Ставропольского краевого института развития образования, повышения
                квалификации и переподготовки работников образования на тему «Повышение квалификации педагогов в области
                профилактики распространения ВИЧ-инфекции среди обучающихся: ключевые компетенции».
            </p>
            <p>МАТЕРИАЛЫ ВЕБИНАРА:</p>
            <ul>
                <li><a href="https://youtu.be/tYMm0O7DcVw">ЗАПИСЬ ВЕБИНАРА</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-5_p3P2ro6.pdf">ПРОГРАММА ВЕБИНАРА</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-6_OBGOPyA.pdf">ПРЕЗЕНТАЦИЯ Ю.А. КОСТЫРЯЧЕНКО</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/Презентация_PowerPoint.pdf">ПРЕЗЕНТАЦИЯ О.В. ЗАЕВОЙ</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/vebinar-2021-11-29-8_CXGl8du.pdf">ПРЕЗЕНТАЦИЯ Т.А. ЕПОЯНА</a></li>
                <li><a href="https://fcprc.ru/media/media/behavior/PowerPoint_Presentation.pdf">ПРЕЗЕНТАЦИЯ Л.Ф. КИХТЕНКО</a></li>
            </ul>
        </div>
    </div>
}


export default StopHIVAIDS