import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News021224.png"

const News021224 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Стартовали командные соревнования «АртМастерс Регионы» по
            направлениям
            «Кино» и «Театр»
        </h2>
        <div style={{display:"flex", justifyContent:"center"}}>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <b>Открыт прием заявок на новый сезон масштабных командных
                соревнований «АртМастерс Регионы», которые проводятся между
                федеральными округами России в направлениях «Театр» и «Кино».</b>
            <p></p>
            В соревнованиях могут принять участие молодые представители творческих
            специальностей в возрасте от 18 до 35 лет. Новым направлением второго
            сезона стал «Театр» – таким образом количество компетенций, в которых
            пройдут индивидуальные соревнования, увеличилось до шестнадцати. В
            направление «Кино» вошли компетенции «Продюсер», «Сценарист»,
            «Оператор кино и ТВ», «Звукорежиссер кино и медиа», «Режиссер монтажа»,
            «Медиакомпозитор», «Моушн-дизайнер (CG-график)» и «Художник по
            костюмам». В направление «Театр» – «Сценограф», «Драматург», «Стейдж-
            менеджер», «Композитор», «Художник по костюмам», «Художник по гриму»,
            «Художник по свету», «Звукорежиссер театра».
            <p></p>
            Лучшие участники в каждой из компетенций в своих федеральных округах
            войдут в сборную команду региона. Команды создадут короткометражные
            фильмы и театральные постановки для широкой аудитории, раскрывающие
            традиционные ценности, самобытность и региональную специфику. Съемки
            будут проходить на базе местных кинокомпаний, а постановка театральных
            этюдов – в ведущих театрах региона.
            <p></p>
            «<i>”АртМастерс Регионы” – это уникальная площадка, которая дает
            возможность талантам продемонстрировать свои способности, обменяться
            опытом и получить признание профессионального сообщества. Министерство
            культуры Российской Федерации уже второй сезон поддерживает
            соревнования. Мы рады способствовать развитию талантливой региональной
            молодежи и созданию профессиональных работ на базе региональной
            инфраструктуры</i>», – отметила статс-секретарь – заместитель Министра
            культуры Российской Федерации, член оргкомитета «АртМастерс Регионы» <b>Жанна Алексеева</b>.
            <p></p>
            Командные соревнования пройдут в несколько этапов:
            <ul>
                <li>I этап (отборочный) – прием заявок на сайте и оценка представленных
                    участниками портфолио, по результатам которой будут определены
                    участники второго этапа (1280 человек – до 10 участников в каждой
                    компетенции в каждом федеральном округе);
                </li>
                <li>II этап (квалификационный) – выполнение практического задания, по
                    результатам которого в федеральных округах будут сформированы сборные
                    команды (16 региональных команд по 8 человек, по 1 представителю из
                    каждой компетенции);
                </li>
                <li>III этап (финальный) – производство фильмов и постановка театральных
                    этюдов. Этот этап пройдет очно в регионах.
                </li>
            </ul>
            Показ работ, определение победителей и награждение состоится в
            Москве, где объявят 48 лучших участников и 6 команд-победителей.
            <p></p>
            Победители «АртМастерс Регионы» получат возможность принять
            участие в акселераторе Президентского фонда культурных инициатив для
            получения гранта до 100 миллионов рублей на производство
            полнометражного фильма или театральной постановки, размещение работ на
            стриминговых платформах-партнерах, а также возможность включения в
            репертуар региональных театров, участие в творческих лабораториях Союза
            театральных деятелей. Победители в каждой индивидуальной компетенции
            автоматически могут пройти в квалификационный этап Национального
            открытого чемпионата творческих компетенций ArtMasters-2025, а также
            пройти стажировки в ведущих компаниях или проекты креативных индустрий.
            <p></p>
            Главным режиссером направления «Кино» в новом сезоне стал режиссер,
            сценарист, продюсер, многократный призер различных кинофестивалей <b>Николай Рыбников</b>, в направлении
            «Театр» – российский театральный режиссер, лауреат премии Президента
            Российской Федерации для молодых деятелей культуры <b>Айдар Заббаров</b>.
            <p></p>
            Экспертами «АртМастерс Регионы» выступают лидеры креативных
            индустрий – авторитетные специалисты в области кино и театра,
            представители ведущих технологических компаний, лауреаты премий
            «Золотая маска», «Золотой орел», «ТЭФИ»:
            <ul>
                <li><b>Александр Архипов</b>, главный эксперт компетенций «Сценарист» и
                    «Драматург театра», драматург, сценарист, режиссер, продюсер,
                    главный редактор кинокомпании «СТВ», член союза писателей Москвы,
                    член экспертного совета Министерства культуры Российской Федерации
                    по отбору проектов, представленных организациями кинематографии;
                </li>
                <li><b>Анастасия Нефедова</b>, главный эксперт компетенции «Художник по
                    костюмам», художник театра и кино, сценограф, художник по костюмам,
                    креативный продюсер. Руководитель направления «Ивент. Театр и
                    перформанс» в школе дизайна НИУ ВШЭ, главный художник
                    Электротеатра Станиславский, Обладатель «Золотой маски» в номинации
                    «Лучший художник по костюмам в музыкальном театре» (2016), «Лучшая
                    работа художника по костюмам» (2021);
                </li>
                <li><b>Павел Дореули</b>, главный эксперт компетенции «Звукорежиссер кино и
                    медиа», звукорежиссер, саунд-дизайнер, соучредитель студии
                    «Атмосфера»;
                </li>
                <li><b>Сергей Кальварский</b>, главный эксперт компетенции «Продюсер»,
                    российский телевизионный деятель, продюсер, театральный и
                    кинорежиссер, медиаменеджер, Академик Российского телевидения.
                    Лауреат премии «Золотой Орел» (2023) и премии ТЭФИ (2007, 2010);
                </li>
                <li><b>Мария Сергеенкова</b>, главный эксперт компетенции «Режиссер монтажа», Режиссер монтажа, режиссер,
                    сценарист и
                    креативный продюсер, член Гильдии кинорежиссеров, член Союза кинематографистов. Четырехкратный
                    лауреат
                    премии «Золотой орел» в номинации «Лучший монтаж фильма», лауреат премии Ассоциации продюсеров кино
                    и
                    телевидения АПКиТ и
                    Мумбайского международного кинофестиваля за монтаж, обладатель награды Венесуэльского международного
                    фестиваля за фильм «Подольские курсанты»;
                </li>
                <li><b>Тимур Рамазанов</b>, главный эксперт компетенции «Моушн-дизайнер»,
                    режиссер, предприниматель, сооснователь Открытого международного
                    фестиваля короткометражного и документального кино «Генезис» (GIFF);
                </li>
                <li><b>Иван Бурляев</b>, главный эксперт компетенции «Медиакомпозитор», композитор, продюсер, руководитель
                    Ассоциации киномузыки Российского музыкального союза, член правления Союза кинематографистов России,
                    член Совета директоров Российского авторского общества (РАО), руководитель Лаборатории киномузыки
                    REC;
                </li>
                <li><b>Иван Поморин</b>, главный эксперт компетенции «Оператор кино и ТВ»,
                    оператор, действующий член правления Гильдии кинооператоров России,
                    преподаватель Института кино и телевидения (ГИТР);
                </li>
                <li><b>Евгений Загот</b>, главный эксперт компетенции «Композитор театра»,
                    Композитор, дирижер, музыкальный продюсер, аранжировщик. Член
                    Союза композиторов России, обладатель национальной премии
                    «Музыкальное сердце театра» в номинации «лучшая музыка
                    (композитор)», лауреат премии «Золотая маска» в номинации «Оперетта-
                    мюзикл / работа дирижера»;
                </li>
                <li><b>Нана Абдрашитова</b>, главный эксперт компетенции «Сценограф»,
                    сценограф, художник по костюмам, архитектор выставок; номинант
                    премии «Золотая маска» в номинациях «Лучшая работа художника»,
                    «Лучшая работа художника по костюмам»; номинант на Латвийскую
                    национальную премию Spēlmaņu nakts в номинации «Лучший сценограф»;
                </li>
                <li><b>Станислав Свистунович</b>, главный эксперт компетенции «Художник по
                    свету», художник по свету, лауреат Национальной театральной премии
                    «Золотая маска» в номинации «Лучший художник по свету»;
                </li>
                <li><b>Елена Самарина</b>, главный эксперт компетенции «Художник по гриму»,
                    художник по гриму, педагог, основатель кафедры «Искусство грима» в
                    Российском институте театрального искусства – ГИТИС;
                </li>
                <li><b>Софья Кругликова</b>, главный эксперт компетенции «Звукорежиссер
                    театра», концертный и студийный звукорежиссер, саунд-продюсер,
                    композитор;
                </li>
                <li><b>Алексей Франдетти</b>, главный эксперт компетенции «Стейдж-
                    менеджер», режиссёр театра и кино, актёр, педагог; трехкратный
                    обладатель Национальной театральной премии «Золотая маска» и
                    премии «Звезда театрала».
                </li>
            </ul>
            Продюсерами соревнований выступают кинопродюсер, член совета Фонда
            поддержки регионального кинематографа Общероссийской общественной
            организации «Союз кинематографистов Российской Федерации» (ФПРК),
            генеральный директор продюсерского центра ФПРК, руководитель Форума
            регионального кинематографа «Новый вектор» <b>Дмитрий Якунин</b> (направление «Кино»), а также главный специалист Кабинета драматических
            театров Центрального Аппарата Общероссийской общественной организации
            «Союз театральных деятелей Российской Федерации (Всероссийское
            театральное общество)» <b>Мария Шишкина</b> (направление «Театр»).
            <p></p>
            Региональными партнерами выступают кинокомпания «ПУШКА»
            (Тюмень, Уральский федеральный округ), кинокомпания «Перспектива»
            (Рязань, Центральный федеральный округ), киностудия «АрхФильм»
            (Архангельск, Северо-Западный федеральный округ), Черноморская
            киностудия (Геленджик, Южный федеральный округ), киностудия
            «Башкортостан» (Уфа, Приволжский федеральный округ), кинокомпания LBL-
            PRODUCTION (Новосибирск, Сибирский федеральный округ), киностудия
            «БАЙКАЛКИНО» (Улан-Удэ, Дальневосточный федеральный округ), кинокомпания «Кавказ Фильм (Нальчик,
            Северо-Кавказский федеральный
            округ) в направлении «Кино». Свои театральные постановки команды будут
            готовить на базе Челябинского государственного академического театра
            драмы имени Наума Орлова (Челябинск, УФО), Кинешемский драматический
            театр им. Островского (Ивановская область, ЦФО), Архангельский театр
            драмы им. Ломоносова (Архангельск, СЗФО), Театральное объединение
            Республики Адыгея (Майкоп, ЮФО), Татарский государственный
            академический театр имени Галиасгара Камала (Казань, ПФО), Красноярский
            драматический театр им. Пушкина (Красноярск, СФО), Хабаровский краевой
            театр драмы (ДФО), Русский академический театр им. Евгения Вахтангова
            (Владикавказ, СКФО).
            <p></p>
            Коммуникационным партнером «АртМастерс Регионы» выступает
            медиахолдинг МАЕР.
            <p></p>
            Подать заявку на участие могут все желающие при наличии
            соответствующего компетенции портфолио до 10 декабря 2024 года на сайте <a
            style={{color: "red", fontWeight: "bold"}}
            href="https://www.region.artmasters.ru/">https://www.region.artmasters.ru/</a>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News021224;