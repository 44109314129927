import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/Directions/11_1.svg"

const FederalnyjCenter = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <div className={s.header}>
            <h2 style={{
                fontWeight: "normal",
                color: "#3a4663",
                fontFamily: "Proxima Nova Lt",
                fontSize: "30px",
                textAlign: "start"
            }}>
                Федеральный центр развития программ социализации подростков
            </h2>
        </div>
        <div style={{width: "400px"}}>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt", width: "90%", maxWidth: "1200px", margin: "0 auto"}}>
            <p><a href="https://fcprc.ru/media/media/behavior/Polozhenie.pdf">Положение о Всероссийском конкурсе по отбору ресурсных площадок программ социализации подростков</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/01452dca-ae69-4a34-9921-9043bac526de.pdf">Положение о Всероссийском конкурсе программ социализации подростков 2022</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/Отсканированное_изображение.pdf">Положение о Федеральном центре развития программ социализации подростков (ФЦ ЗПСП)</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/Informatsionnaya-spravka-Forum-Kaluga-teatry.docx">Информационная справка о Фестивале практиков социальных театров «Мастер форум»</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/Polozhenie-o-konkurse-FTS-RPSP-2023.pdf">Положение о Всероссийском конкурсе программ социализации подростков 2023</a></p>
        </div>
    </div>
}


export default FederalnyjCenter;