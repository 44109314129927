import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News1910232.jpg"
import img2 from "../../assets/News/News1910232_2.jpg"
import img3 from "../../assets/News/News1910232_3.JPG"
import img4 from "../../assets/News/News1910232_4.jpg"
import img5 from "../../assets/News/News1910232_5.jpg"


const News1910232 = ({children, header}) => {


    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сотрудники Центра приняли участие во II Национальном форуме
            «Здоровье и безопасность детей»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            18 октября 2023 года в Международном мультимедийном пресс-центре «Россия сегодня» состоялся II Национальный
            форум «Здоровье и безопасность детей», организованный Национальным центром помощи пропавшим и пострадавшим
            детям и МЧС России при поддержке ОАО «РЖД».

            <p></p>
            Центральной темой Форума стало рассмотрение вопросов по обеспечению здоровья и безопасности подрастающего
            поколения в свете Указа Президента РФ «Об утверждении Основ государственной политики по сохранению
            и укреплению традиционных российских духовно-нравственных ценностей».
            <p></p>
            В мероприятии приняли участие депутаты Государственной Думы РФ, сенаторы Совета Федерации РФ, представители
            Общественной палаты РФ, силовых ведомств, научных и общественных организаций.

            <p></p>
            В рамках Форума была организована работа круглых столов и секций по 14 тематическим направлением, в которых
            приняли участие
            более 100 ключевых экспертов по вопросам безопасности
            детства, представителей общественных организаций, органов законодательной и исполнительной власти. Участники
            тематических сессий затронули вопросы формирования нравственных ориентиров подрастающего поколения,
            военно-патриотического воспитания несовершеннолетних, цифровизации образования, профилактики зависимостей,
            защиты детей от деструктивного контента.

            <p></p>
            В двух секциях Форума «Актуальные проблемы ОГЭ, ЕГЭ и пути их решения в свете новых политических реалий» и
            «Образец для подражания: как поведение блогеров влияет на аудиторию» приняли участие сотрудники Центра –
            заместитель директора по развитию системы профилактики девиантного поведения <strong>Артамонова Елена Геннадьевна</strong> и
            начальник отдела сопровождения вопросов безопасности детства <strong>Скорина Елена Николаевна</strong>.

            <p></p>
            Информация об участниках и тематических площадках размещена на сайте Форума: <a
            href="https://forum-detstvo.ru/">https://forum-detstvo.ru/</a>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <ToNews/>
    </div>
}


export default News1910232;