import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import video from "../../../assets/Behavior/video.png"


const HigherSky = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.header}>
                <h2 style={{
                    fontWeight: "normal",
                    color: "#3a4663",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "30px",
                    textAlign: "start"
                }}>
                    Художественный фильм «Выше неба»
                </h2>
            </div>
            <div style={{justifyContent: "space-around", display: 'flex   '}}>
                <img src={video} style={{width: "80px"}}></img>
            </div>
            <div style={{justifyContent: "space-around", display: 'flex   '}}>
                <a className={s.posobie}
                   href="http://www.youtube.com/channel/UCpktwtJG5k1KRv8MPpFDGNw">Смотреть
                    видео</a>
            </div>

            <div style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>В 2013 году в Республике Беларусь на экраны вышел многосерийный художественный фильм по
                    предотвращению дискриминации людей, живущих с ВИЧ, и профилактике ВИЧ-инфекции «Выше неба».</p>
                <p>Организатор кино-проекта: Программа развития ООН. Фильм снят в сотрудничестве с Министерством
                    здравоохранения Республики Беларусь.</p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>


    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default HigherSky