import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News2306.jpg"
import img2 from "../../assets/News/News2306_2.jpg"
import img3 from "../../assets/News/News2306_3.jpg"
import img4 from "../../assets/News/News2306_4.jpg"
import img5 from "../../assets/News/News2306_5.jpg"
import img6 from "../../assets/News/News2306_6.jpg"
import img7 from "../../assets/News/News2306_7.jpg"

const News22062023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сотрудники Центра приняли участие в премьерном показе спектакля «Трудного театра»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>13 июня 2023 года в г. Москва на камерной сцене музея им. А.С. Пушкина состоялся премьерный показ спектакля «Трудного театра» по пьесе
                А.П. Чехова «Медведь».</p>
            <p>Интересно, что первая презентация проектного подросткового спектакля состоялась на фестивальной площадке грандиозного события для детей
                и юношества «Фестиваль первых» Российского движения детей
                и молодежи «Движение Первых», прошедшего 1-2 июня на ВДНХ.
                Премьере классического спектакля была посвящена атмосферная молодежная акция «Читаем Чехова. Русские дневники. Мой вальс»,</p>
            <p>
                В показе спектакля на сцене Пушкинского музея в качестве актёров
                и волонтёров приняли участие подростки, нуждающиеся в особом внимании со стороны взрослых, из г. Москвы, Твери, Ростова-на-Дону, а зал заполнили  многодетные семьи, подростки из московских Центров помощи семье
                и детям, специалисты системы профилактики безнадзорности
                и правонарушений несовершеннолетних.
            </p>
            <p>
                С приветственным словом к участникам и зрителям обратились
                Артамонова Елена Геннадьевна, заместитель директора по развитию системы профилактики девиантного поведения ФГБУ «Центр защиты прав
                и интересов детей», а также Пахомова Светлана Юрьевна, режиссер
                и куратор проекта «Трудный театр. Премьера».
            </p>
            <p>
                Показ закончился в знаменитом зимнем саду музея, где фраза чеховских героев «Пройдемте в сад!» стала восприниматься еще более реалистично.  Премьера удалась, показы чеховского «Медведя» продолжаются.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <div>
            <img src={img7}/>
        </div>
        <ToNews/>
    </div>
}


export default News22062023;