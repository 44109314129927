import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News02032022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Курсы повышения квалификации для руководителей и специалистов органов опеки и попечительства
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Автономная некоммерческая организация Центр развития социальных проектов (АНО ЦРСП) проводит курсы
                повышения квалификации для руководителей и специалистов органов опеки и попечительства по программе:
                «Практическая компетентность в сфере опеки и попечительства в отношении несовершеннолетних: учимся на
                кейсах».</p>
            <p>Сроки проведения онлайн-курсов программы:</p>
            <li>
                11 апреля – 27 апреля 2022 г.
            </li>
            <li>23 мая – 08 июня 2022 г.</li>
            <li>3 октября – 19 октября 2022 г.</li>
            <li>14 ноября – 30 ноября 2022 г.</li>
            <p>В состав преподавателей курсов входят ведущие специалисты в сфере опеки и попечительства в отношении
                несовершеннолетних граждан, защиты прав детей, психологии. Научный руководитель программы – Семья
                Г.В.</p>
            <p>Программа рассчитана на 72 академических часа, включающих онлайн лекции, самостоятельную работу и
                выполнение заданий слушателями.</p>
            <p>
                С программой онлайн-курсов можно ознакомиться в <a
                href="https://fcprc.ru/media/media/behavior/Informatsionnoe-pismo-ANO-TSRSP.pdf">письме АНО ЦРСП</a>.
            </p>
            <p>
                По вопросам, связанным с условиями участия в программе, регистрацией и оплатой, можно связаться по
                телефонам: +7 (495) 363-35-80, +7 (985) 088-56-72, а также по электронной почте: <a
                href="mailto:kursy@centrrsp.ru">kursy@centrrsp.ru</a>.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News02032022;