import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/NewProjectTheWayOfHooligan.png"

const NewProjectTheWayOfHooligan = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Новый проект Федерального центра развития программ социализации подростков «Путь хулигана» обсудили в рамках
            круглого стола
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>20 апреля состоялся круглый стол с участием экспертов в сфере профилактики правонарушений
                несовершеннолетних, посвященный вопросам межведомственного взаимодействия и межотраслевого партнерства в
                рамках организации работы с несовершеннолетними в конфликте с законом.</p>
            <p> В рамках круглого стола прошла презентация проекта «Путь хулигана» Федерального центра развития программ
                социализации подростков. Проект направлен на изучение уровней индивидуальной профилактической работы в
                отношении подростков, совершивших правонарушения в Российской Федерации.</p>
            <p>Круглый стол проходил в открытом формате: общее количество участников составило 198 специалистов из сферы
                образования, социальной и молодежной политики из 36 субъектов Российской Федерации.</p>
            <p> Проект «Путь хулигана» на площадке Центра защиты прав и интересов детей обсудили представители
                Минпросвещения России, Уполномоченного при Президенте РФ по правам ребенка, Следственного комитета РФ,
                региональных органов исполнительной власти, научного сообщества и общественных организаций.</p>
            <p>В рамках обсуждения участники мероприятия поделились дополнениями к проекту, отметили важность учета
                факторов дезадаптации, а также особенности отбора программ социализации в будущем.</p>
        </div>
        <ToNews/>
    </div>
}


export default NewProjectTheWayOfHooligan;