import React from "react";
import ico from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const FilesPublicationsBeznadzor = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Материалы по исполнению Концепции развития системы профилактики<br/> безнадзорности и
                    правонарушений
                    несовершеннолетних
                </h2>
                <Falldown header="До 2025 года" style={{maxWidth: "700px"}}>
                    <FileLeftLine
                        text={"I. Совершенствование нормативно-правового регулирования в сфере профилактики безнадзорности и правонарушений несовершеннолетних"}
                        refers={"/improvement-of-regulatory"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}
                    />
                    <FileLeftLine
                        text={"II. Развитие эффективной модели системы профилактики безнадзорности и правонарушений несовершеннолетних"}
                        refers={"/improvement-effective-system"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"III. Информационно-методическое обеспечение системы профилактики безнадзорности и правонарушений несовершеннолетних"}
                        refers={"/ims-bezn"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine text={"IV. Управление реализацией Концепции"}
                                  refers={"/managing-conc"}
                                  ico={ico}
                                  styles={{maxWidth: "600px"}}/>
                </Falldown>
                <Falldown header="До 2020 года" style={{maxWidth: "700px"}}>
                    <FileLeftLine
                        text={"I.Совершенствование нормативно-правового регулирования в сфере профилактики безнадзорности и правонарушений несовершеннолетних"}
                        refers={"/isnprvp"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"II.Развитие эффективной модели системы профилактики безнадзорности и правонарушений несовершеннолетних"}
                        refers={"/isnprvp2"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"III.Информационно - методическое обеспечение системы профилактики безнадзорности и правонарушений несовершеннолетних"}
                        refers={"/isnprvp3"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine
                        text={"IV. Развитие кадрового потенциала системы профилактики безнадзорности и правонарушений несовершеннолетних"}
                        refers={"/isnprvp4"}
                        ico={ico}
                        styles={{maxWidth: "600px"}}/>
                    <FileLeftLine text={"V. Управление реализацией Концепции"}
                                  refers={"/isnprvp5"}
                                  ico={ico}
                                  styles={{maxWidth: "600px"}}/>
                </Falldown>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default FilesPublicationsBeznadzor