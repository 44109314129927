import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/VectorsNewOpportunities.jpg"

const VectorsNewOpportunities = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            21 февраля состоится Всероссийское совещание по вопросам совершенствования деятельности специальных
            учебно-воспитательных учреждений «Векторы новых возможностей профилактики девиантного поведения обучающихся»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <ToNews/>
    </div>
}


export default VectorsNewOpportunities;