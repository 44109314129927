import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News030724.jpg"
import img1 from "../../assets/News/News030724_2.jpg"
import img2 from "../../assets/News/News030724_3.jpg"

const News030724 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Отдел медиации на Родительском дне в Щекинском СУВУ 29 июня 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Родительский день с участием сотрудников отдела медиации Центра прошел в ФГБПОУ «Щёкинское специальное
            учебно-воспитательное учреждение закрытого типа» в Тульской области.
            <p></p>
            Для участия в мероприятии прямо в учреждение приехали родители и члены семей обучающихся СУВУ. Для них была
            организована широкая программа с экскурсией по учреждению, мастер-классами и концертной программой от ребят.
            Концентр был посвящен окончанию учебного года и завершился торжественным вручением аттестатов.
            <p></p>
            Один из мастер-классов под названием «Выстраивание доверительных отношений с ребенком» был проведен
            начальником отдела медиации Центра Козловым Анатолием Андреевичем.
            <p></p>
            На встрече Анатолий Андреевич рассказал родителям обучающихся о принципах медиации как условиях
            уважительного и конструктивного общения с ребенком и познакомил их с краткой моделью медиативной беседы.
            Данная модель дает возможность создания диалога с ребенком, если с ним или у него возник конфликт, чтобы
            помочь подростку найти наиболее подходящее решение ситуации.
            <p></p>
            В течение встречи родители задавали вопросы о способах общения в напряженных ситуациях с детьми, а также о
            возможностях развития у ребят навыков общения и роли родителя в этом процессе.
            <p></p>
            Также Анатолию Андреевичу и аналитику II категории отдела медиации Центра Жодзижской Лидии Николаевне
            удалось пообщаться с юными волонтерами-медиаторами и специалистами службы медиации СУВУ, которые принимают
            участие в традиционных интерактивных занятиях и консультациях, которые отдел проводит для обучающихся из
            всех СУВУ, подведомственных Минпросвещения России.
            <p></p>
            На мероприятии также присутствовали представители правительства Тульской области и Уполномоченный по правам
            ребенка в Тульской области Кузнецова Ирина Александровна.
            <p></p>
            Центр защиты прав и интересов детей благодарен руководству Щекинского СУВУ в лице его директора Моисеенко
            Нелли Вячеславовны и заместителя директора, куратора службы медиации Сальковой Натальи Владимировны за
            особое внимание к вопросам поддержки и ресоциализации обучающихся, а также интеграции медиативных и
            восстановительных технологий в работу учреждения.
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News030724;