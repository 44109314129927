import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News120624.jpg"

const News120624 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Поздравление директора ФГБУ "Центр защиты прав и интересов детей"
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Поздравляю с Днем России!
            <p></p>
            Сегодня мы празднуем один из самых значимых дней в нашей стране - День России! Этот праздник символизирует
            единство нашего народа, его силу и мудрость, способность преодолевать трудности и достигать целей.
            <p></p>
            Каждый год мы отмечаем этот праздник с особым чувством гордости за то, что являемся гражданами такой великой
            державы, которая богата историей, культурой и талантами. Мы гордимся тем, что Россия является одной из
            ведущих стран мира, играющей важную роль в международных делах.
            <p></p>
            Наш коллектив также внес свой вклад в развитие нашей страны, и сегодня мы можем быть уверены в том, что мы
            движемся в правильном направлении. Мы продолжаем работать над реализацией наших планов и достижениями новых
            успехов.
            <p></p>
            Желаю всем вам здоровья, любви, счастья и процветания. Пусть этот праздник принесет вам только радость и
            положительные эмоции.
            <p></p>
            С Днем России!
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News120624;