import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/MediaciaFuturePers1.jpg"
import img2 from "../../assets/News/MediaciaFuturePers2.jpg"
import img3 from "../../assets/News/MediaciaFuturePers3.jpg"
import img4 from "../../assets/News/MediaciaFuturePers4.jpg"
import img5 from "../../assets/News/MediaciaFuturePers5.jpg"


const MediaciaFuturePers = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Конференция «Медиация: опыт настоящего. Перспективы будущего»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Специалисты ФГБУ «Центр защиты прав и интересов детей» приняли участие в IV международной практической
                конференции «Медиация: опыт настоящего. Перспективы будущего», проходившей 20-21 сентября 2021 года в г.
                Анапе.</p>
            <p>Традиционное мероприятие, направленное на обмен успешным практическим опытом и особенности применения
                медиации во всех сферах деятельности (семейные споры, сфера образования, бизнес, корпоративные споры,
                конфликты в области здравоохранения, ЖКХ и др.) и ставшее фактически одной из самых масштабных и весомых
                дискуссионных площадок в нашей стране, собрало более двухсот человек — представителей органов власти,
                судейского сообщества, Уполномоченных по правам человека, Уполномоченных по защите прав
                предпринимателей, Уполномоченных по правам ребенка из разных регионов России, а также юристов,
                представителей НКО, представителей профессиональных региональных объединений медиаторов России и стран
                ближнего зарубежья, экспертов и начинающих специалистов.</p>
            <p>
                Медиаторы Центра выступили с докладами в секциях «Медиативные технологии в образовательном процессе» и
                «Медиация как способ работы с семьей».
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <a href="https://fcprc.ru/media/media/behavior/med-2021-09-22-5.pdf">Программа конференции (скачать pdf).</a>
        </div>
        <ToNews/>
    </div>
}


export default MediaciaFuturePers;