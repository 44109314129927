import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/hotline2.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";


const Hotline2 = ({url, header, children, style = {}}) => {
    return <>
        <div style={{maxWidth: "1100px", margin: "auto"}}>
            <div>
                <h2 style={{fontWeight: "normal", fontFamily: "Proxima Nova Lt", fontSize: "30px", color: "#3A4663", textAlign: "left"}}>
                    Горячая линия по вопросам реализации жилищных прав детей-сирот, детей, оставшихся без попечения
                    родителей, и лиц из их числа </h2>
                <div>
                    <a href="tel:88004441806">
                        <img src={PageImg} style={{width: "100%", marginBottom: "30px"}}/>

                    </a>
                </div>
            </div>
        </div>
    </>

}


export default Hotline2