import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News210224.jpg"
import img1 from "../../assets/News/News210224_1.jpg"

const News190224 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Повышение квалификации специалистов органов опеки и попечительства, специалистов школ приемных родителей и
            служб сопровождения
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            ФГБУ «Центр защиты прав и интересов детей» (Центр) проводит набор на дистанционные курсы повышения
            квалификации по программам:
            <ul>
                <li>
                    «Споры родителей, связанные с воспитанием детей. Особенности рассмотрения споров с участием
                    иностранного
                    элемента», 36 ак. часов.
                </li>
                <li>
                    «Особенности подготовки и сопровождения опекунов или попечителей несовершеннолетних», 36 ак.
                    часов.
                </li>
            </ul>
            Преподавателями курса станут эксперты в сфере защиты прав детей, представители науки, сотрудники Центра.
            <p></p>
            После прохождения обучения при успешном прохождении итоговой аттестации вы получите удостоверение о
            повышении квалификации установленного образца.
            <p></p>
            Даты проведения курсов формируются по мере формирования учебных групп.
            <p></p>
            Заявки на участие в образовательной программе принимаются
            до 1 марта 2024 г. <a href="https://dpo.fcprc.ru/registration"><strong>на сайте Центра</strong></a>.
            <p></p>
            Направить свой запрос на обучение специалистов по другим интересующим вас темам можно по электронной
            почте: <a href="mailto:dpo@fcprcgov.ru">dpo@fcprcgov.ru</a>
            <p></p>
            <div>
                <img src={img1}/>
            </div>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News190224;