import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News061124_1.jpg"
import img2 from "../../assets/News/News061124_2.png"
import img3 from "../../assets/News/News061124_3.png"
import img4 from "../../assets/News/News061124_4.png"
import img5 from "../../assets/News/News061124_5.png"

const News061124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных
            учреждениях в октябре 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Сотрудники отдела медиации ФГБУ «Центр защиты прав и интересов детей»
            провели мероприятия по сопровождению служб медиации в специальных
            учебно-воспитательных учреждениях, подведомственных Минпросвещения
            России (СУВУ) в октябре 2024 года.
            <p></p>
            Мероприятия прошли 10-11 октября с Куртамышским СУВУ, Каргатским
            СУВУ, Калтанским СУВУ, Санкт-Петербургским СУВУ и Орловским СУВУ
            и 24-25 октября с Майкопским СУВУ, Рефтинским СУВУ, Куртамышским
            СУВУ, Орловским СУВУ, Омским СУВУ, Неманским СУВУ и Каргатским
            СУВУ.
            <p></p>
            10 и 24 октября состоялись консультации для специалистов служб медиации,
            на которых обсуждались проведенные мероприятия, достижения, актуальное
            состояние служб и планы на 2024 год.
            <p></p>
            11 и 25 октября на интерактивных занятиях для обучающихся были
            проведены деловые игры, направленные на формирование навыков ведения
            переговоров. Структура занятий была выстроена таким образом, чтобы
            создать условия для общения мальчиков и девочек из разных учреждений.
            Обучающиеся и сотрудники положительно оценили игровой формат занятий,
            отметили уважительную атмосферу встреч.
            <p></p>
            Мероприятия проводили сотрудники отдела медиации Центра защиты прав и
            интересов детей: аналитик Быкова Марина Викторовна, аналитик Тюльканов Сергей Леонидович, аналитик II
            категории Жодзишская Лидия
            Николаевна.
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <p></p>
        <ToNews/>
    </div>
}


export default News061124;