import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/TerritoryOfMeanings2022.jpg"
import img2 from "../../assets/News/TerritoryOfMeanings2022_2.jpg"

const TerritoryOfMeanings2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийский молодёжный форум «Территория смыслов» – 2022
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                С 5 июля по 5 августа 2022 года прошел <strong>Всероссийский молодёжный форум «ТЕРРИТОРИЯ СМЫСЛОВ»</strong> в
                Солнечногорском районе на территории Мастерской управления «Сенеж».
            </p>
            <p>
                Форум «Территория смыслов» – это проект Федерального агентства по делам молодежи «Росмолодежь», который
                является крупнейшей образовательной площадкой для молодежи России.
            </p>
            <p>
                Ежегодно на форуме собираются молодые профессионалы из разных сфер: учителя, управленцы, политологи,
                бизнесмены, историки и многие другие.
            </p>
            <p>
                В этом году форум включал в себя <strong>7 профильных смен-сообществ</strong>, внутри которых работали <strong>15 тематических
                клубов</strong>, суммарное количество участников всех сообществ составило порядка <strong>2000 человек</strong>.
            </p>
            <p>
                В работе одного из сообществ форума – «Управление» с 25 июля по 1 августа 2022 года приняли участие
                молодые сотрудники органов исполнительной власти и члены молодежных правительств.
            </p>
            <p>
                Программа сообщества была направлена на развитие у участников компетенций современного лидера и
                инструментов креативного управления.
            </p>
            <p>
                В работе форума принял участие аналитик II категории отдела медиации ФГБУ «Центр защиты прав и интересов
                детей» Анатолий Андреевич Козлов. А.А. Козлов выступил перед членами клуба «Детство» и познакомил их с
                понятием медиации и основами медиативного подхода в работе с несовершеннолетними, а также с
                направлениями работы Центра.
            </p>
            <p>
                Члены клуба «Детство» в рамках программы форума участвовали в образовательных и просветительских
                мероприятиях, а также разрабатывали решение профильного кейса, касающегося создания модели
                межведомственного взаимодействия в области поддержки и развития детей и молодежи в Российской Федерации.
            </p>
            <p>
                В работе форума в качестве экспертов приняли участие: председатель Государственной думы Федерального
                Собрания Российской Федерации <strong>Вячеслав Викторович Володин</strong>, руководитель Аппарата Правительства
                Российской Федерации <strong>Дмитрий Юрьевич Григоренко</strong>, президент Российской академии образования <strong>Ольга Юрьевна
                Васильева</strong> и директор Департамента государственной политики в сфере защиты прав детей Министерства
                просвещения Российской Федерации <strong>Лариса Павловна Фальковская</strong>, которая осветила тему роли некоммерческих
                организаций в профилактической работе с детьми.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default TerritoryOfMeanings2022;