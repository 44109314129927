import React from "react";
import ico from "../../../assets/fileicored.svg"
import ico1 from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";


const FilesIncome = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/aboutus").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Сведения о доходах
                </h2>
                <FileLeftLine
                    text='«Указ Президента Российской Федерации от 29 декабря 2022 г. № 968  «Об особенностях исполнения обязанностей, соблюдения ограничений  и запретов в области противодействия коррупции некоторыми категориями граждан в период проведения специальной военной операции»'
                    refers="http://pravo.gov.ru/proxy/ips/?docbody=&link_id=0&nd=603637722" ico={ico1}/>
                <FileLeftLine
                    text="Информация о среднемесячной заработной плате руководителей, их заместителей и главных бухгалтеров"
                    refers="https://fcprc.ru/media/media/behavior/для_размещения.pdf" ico={ico}/>
            </div>

        </div>
    </>
}
export default FilesIncome