import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import adobe from "../../../assets/Behavior/adobe.png"
import presen from "../../../assets/Behavior/presen.jpg"


const RegionalExp = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Артамонова Е. Г. Региональный опыт деятельности педагогов-психологов образовательных организаций по
                    раннему выявлению и профилактике аддиктивного и девиантного поведения несовершеннолетних.
                </h2>
            </div>

            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p><strong>// Электронный журнал «Общество. Культура. Наука. Образование». 2016. Вып. 2</strong></p>
            </div>
            <div style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><strong>Е.Г. Артамонова,</strong><br/>
                    кандидат психологических наук, старший научный сотрудник
                    ФГБНУ «Центр исследования проблем воспитания,
                    формирования здорового образа жизни, профилактики наркомании,
                    социально-педагогической поддержки детей и молодежи», г. Москва<br/><br/>
                    <div style={{textAlign: "center"}}><strong>Тезисы выступления на вебинаре
                    «Основные подходы и технологии ранней профилактики девиантного поведения
                    и правонарушений детей и подростков в образовательных организациях» (23 марта 2016 г.)
                        подготовлены в рамках государственного задания ФГБНУ «ЦПВиСППДМ» на 2016 г.</strong></div><br/>
                    В статье показана актуальность раннего выявления и профилактики аддиктивного и девиантного поведения
                    несовершеннолетних, где важная роль отводится образовательным организациям. Приводится первичный
                    анализ условий эффективной профилактической работы в образовательных организациях регионов РФ:
                    уровня квалифицированности кадров, полноты информационного и научно-методического обеспечения и
                    других аспектов подготовки специалистов сферы профилактики.</p>
            </div>
            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p><strong>Ключевые слова: </strong>образовательные организации, несовершеннолетние, аддиктивное и
                    девиантное поведение, раннее выявление, профилактика, региональный опыт.</p>
                <p>В последнее десятилетие обеспечение благополучного и безопасного детства стало одним из основных
                    национальных приоритетов России. Указом Президента Российской Федерации от 2 июня 2012 г. была
                    утверждена Национальная стратегия действий в интересах детей на 2012−2017 годы. Актуальные вопросы
                    защиты прав детства нашли свое отражение в Концепции долгосрочного социально-экономического развития
                    Российской Федерации на период до 2020 г., Концепции демографической политики Российской Федерации
                    на период до 2025 г., Концепции государственной семейной политики в Российской Федерации на период
                    до 2025 г. и в ряде других важнейших стратегических документов. Созданы новые государственные и
                    общественные институты; приняты дополнительные меры социальной поддержки семей с детьми.</p>
                <p>В рамках научно-исследовательской деятельности нашего Центра в 2015 г. был проведен анализ
                    эффективной практики субъектов Российской Федерации в организации и функционировании системы
                    профилактики безнадзорности и правонарушений несовершеннолетних. Были получены материалы из 75
                    субъектов РФ. Во исполнение плана п. 60 Минобрнауки России совместно с федеральными органами власти,
                    а также исполнительными органами субъектов РФ был разработан проект <strong>Концепции развития
                        системы
                        профилактики правонарушений несовершеннолетних</strong>. Проведено экспертное и
                    профессионально-общественное
                    обсуждение проекта концепции. Концепция развития системы профилактики правонарушений
                    несовершеннолетних направлена на создание условий для их успешной социализации (ресоциализации),
                    формирование у них готовности к саморазвитию, самоопределению и ответственному отношению к своей
                    жизни.</p>
                <p>Современные эффективные модели системы профилактики правонарушений несовершеннолетних основываются на
                    системно-динамической концепции с опорой на системный подход к анализу явлений, принципы
                    личностно-ориентированного, гуманистического, культурно-исторического и деятельностного подходов.
                    Методологической основой организации работы по профилактике правонарушений несовершеннолетних,
                    обучающихся в образовательных учреждениях, выступают федеральные государственные стандарты второго
                    поколения и концепция духовно-нравственного развития и воспитания личности гражданина России.</p>
                <p>Профилактическая работа должна быть направлена, прежде всего, не на решение частных, задач, а на
                    повышение уровня жизни, формирование такого окружения ребенка, которое формирует систему ценностей и
                    целей; развивает в нем социально значимые качества и демонстрирует социально-одобряемые сценарии
                    достижения целей. В этом направлении необходимы:
                    <br/>•повышение эффективности государственной поддержки семьи, в том числе укрепление института
                    семьи, возрождение и сохранение духовно-нравственных традиций семейных отношений;
                    <br/>•создание единой образовательной (воспитывающей) среды;
                    <br/>•разработка и реализация мер по раннему выявлению и профилактике девиантного поведения.</p>
                <p>В зависимости от момента начала мер осуществления профилактического воздействия необходимо
                    рассматривать три этапа предупреждения правонарушений несовершеннолетних: раннее предупреждение
                    правонарушений; непосредственное предупреждение правонарушений; предупреждение рецидива. В проекте
                    концепции сформулированы основные мероприятия, которые предполагаются к реализации на каждом этапе.
                    Важная роль принадлежит раннему предупреждению, поскольку в интересах общества желательно
                    использование таких форм, которые бы исключали сам факт совершения правонарушений.</p>
                <p>Раннее предупреждение — это создание социальной ситуации развития несовершеннолетнего в направлении
                    нормы; создание социально-психологического заслона на пути к отрицательным импульсам и устремлениям
                    в поведении несовершеннолетнего, совершения им преступлений и правонарушений.</p>
                <p>С учетом того, что значительную часть своей жизни несовершеннолетние проводят в образовательных
                    организациях, важный акцент в осуществлении раннего предупреждения преступности несовершеннолетних
                    необходимо делать на деятельности органов и организаций образования. Именно здесь можно и нужно
                    осуществлять раннее выявление и профилактику аддиктивного и девиантного поведения детей и
                    подростков.</p>
                <p>Для выстраивания модели деятельности педагогов-психологов образовательных организаций по раннему
                    выявлению и профилактике аддиктивного и девиантного поведения несовершеннолетних важна объективная
                    картина, сложившаяся на сегодняшний день в образовательных организациях регионов РФ, отражающая
                    уровень квалифицированности кадров, полноты информационного и научно-методического обеспечения и
                    другие аспекты профессиональной подготовки специалистов сферы профилактики.</p>
                <p>Проведенный в марте 2016 г. онлайн-опрос, в котором приняли участие 527 респондентов из 63 регионов
                    Российской Федерации, показал следующее:<br/>
                    <strong>•Начинать профилактическую работу</strong>, по мнению большинства специалистов, следует в
                    8−14 лет (49%).
                    Часть респондентов отметили и более ранний возраст — 4−7 лет (29%).<br/>
                    <strong>•Наиболее распространенные категории «трудных детей»</strong>, с которыми чаще всего
                    сталкиваются педагоги и
                    психологи образовательных организаций: «не выполняют требования внутреннего распорядка» (16%),
                    «мешают проведению уроков/занятий» (16%), «прогуливают учебные занятия» (14%), «дерутся и издеваются
                    над другими детьми» (8%), «не могут найти „общий язык“ с окружающими» (8%), «постоянно обманывают»
                    (7%), «издеваются над слабыми» (6%), «портят имущество» (6%), «воруют» (6%), «связаны с плохой
                    компанией» (5%) и др.<br/>
                    <strong>•Причинами «неуправляемости»</strong> были названы: «борьба за самоутверждение в кругу
                    сверстников» (19%),
                    «завышенная самооценка, поощряемая родителями» (15%), «борьба за внимание взрослых, родителей»
                    (14%), «возрастной негативизм» (13%), «подражание сверстникам из «плохой компании» (10%), «низкая
                    самооценка, поощряемая родителями» (7%), «неверие в собственный успех из-за плохих взаимоотношений
                    со сверстниками» (6%) и др.<br/>
                    <strong>•Проблемами в организации профилактической работы</strong> были названы: «недостаточная
                    работа с семьей»
                    (35%), «недостаточность или полное отсутствие системной комплексной работы и поддержки,
                    межведомственного взаимодействия, малая активность социальных партнеров, общественных организаций»
                    (20%), «нехватка специалистов (ставок) социальных педагогов, психологов, тьюторов и др.» (12%),
                    «отсутствие практики, опыта, курсов повышения квалификации» (7%), «нехватка рабочего времени на
                    решение вопросов профилактики» (6%) и др.<br/>
                    <strong>•Технологиями профилактической деятельности</strong>, в ознакомлении с которыми
                    заинтересованы специалисты,
                    были названы технологии: «коррекции детско-родительских отношений» (14%), «сопровождения детей,
                    находящихся в трудной жизненной ситуации, в том числе детей из неблагополучных семей» (13%),
                    «профилактики агрессивного поведения» (12%), «использования проблемного метода case-study (метод
                    кейсов или ситуаций)» (11%), «формирования у ценности здорового образа жизни, модели безопасного
                    поведения» (9%), «медиативная технология профилактики правонарушений несовершеннолетних» (8%),
                    «интерактивная технология профилактики безнадзорности и правонарушений несовершеннолетних» (8%) и
                    др.<br/>
                    <strong>•Наличие или отсутствие в области оригинальных, эффективных методических наработок в области
                        профилактики безнадзорности и правонарушений несовершеннолетних</strong> респонденты оценили,
                    дав практически
                    равное количество утвердительных и отрицательных ответов, соответственно — 43% и 41%.</p>
                <p>Все это обуславливает актуальность начавшейся в 2016 г. научно-исследовательской работы в рамках
                    госзадания Центра по заказу Минобрнауки России по выявлению эффективных отечественных и зарубежных
                    технологий и практик и разработке <strong>Типовой модели деятельности педагогов-психологов
                        образовательных
                        организаций по раннему выявлению и профилактике аддиктивного и девиантного поведения
                        несовершеннолетних</strong>.</p>
            </div>
            <div style={{fontSize: "17px", color: "#212529", fontWeight: "550"}} className={s.concept}>
                <p><strong>Артамонова Е.Г.</strong> Региональный опыт деятельности педагогов-психологов образовательных
                    организаций по
                    раннему выявлению и профилактике аддиктивного и девиантного поведения несовершеннолетних //
                    Электронный журнал «Общество. Культура. Наука. Образование», 2016. Вып. 2.</p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>


    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default RegionalExp