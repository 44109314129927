import React from "react";
import { useState } from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Directions/6_1.svg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import logo1 from "../../../assets/SUVU/logo1.png"
import logo2 from "../../../assets/SUVU/logo2.png"
import logo3 from "../../../assets/SUVU/logo3.png"
import logo4 from "../../../assets/SUVU/logo4.png"
import file from "../../../assets/SUVU/pol.svg"
import { NavLink } from "react-router-dom";




const hrefsArr = [
    {text:"Новости", to:"/spec-suvu/normativno-pravovye-dokumenty/"},
    {text:"Нормативно-правовые акты", to:"/spec-suvu/normativno-pravovye-dokumenty/"},
    {text:"Информационно-методические материалы", to:""},
    {text:"Всероссийские мероприятия", to:""},
    {text:"Полезные ресурсы", to:""},
    {text:"Люди. События. Факты.", to:"/spec-suvu/ludi-fakti-sobitiya/"},
    {text:"Личный кабинет", to:""},
    {text:"Архив", to:"/suvuarchive"},
]
const PageArhSUVU = ({url, header, children, style={}}) => {
      return <div style={{width:"1200px", margin:"0 auto"}} >
        <h2 style={{fontWeight: "normal" }}>
            
            Архив
        </h2>
        <div className={s.page}>
        <div className={s.content}>
            
            <p style={{fontSize: "16px", color: "#5e5a5a", fontFamily: 'Proxima Nova Lt', fontWeight: 300, lineHeight: 1.5}}>
            
            Страница находится в разработке
            </p>
        </div>
        <div>
            <Hrefs />
            <WhiteButton text="Навигатор СУВУ"/>
            

        </div>
    </div></div>

   
}

const Hrefs = ({hrefs, to}) => {
    return<div className={s.hrefs} >
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>
                
            </>)}
        </ul>
              
    </div>
}
const OneLiHrefs = ({text, ul, input=null, to}) => {
    const [isActive, SetActive] = useState(false)

    return<>
    <NavLink to={to}>
    <li style={{position: "relative", marginRight:"20px" }}>
        <div style={{ marginRight:"20px" }}>
        <img src={docIco}/>
        
        {text}
        
        {input ? <span 
        style={{position: "absolute", right: "4px", padding: "3px", top:"10px" }} 
        onClick={() => {isActive ? SetActive(false) : SetActive(true)}} className={s.arrow}>     
        {!isActive ? <DownOutlined /> :<DownOutlined style={{rotate: "180deg"}}/>} </span>
        : <></>}
        </div>
    </li>
    </NavLink>
    {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to} />) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
            <li>        
                {text }
            </li>
        </NavLink> 
    
}
const PinkButton = ({text, stylesheet}) => {
    return<div className={s.pinkbutton}>
        {text}
        
        <div>
            <ArrowRightOutlined />
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return<div className={s.whitebutton} >
        {text}
        <div>
        <ArrowRightOutlined />
        </div>
    </div>
}

export default PageArhSUVU