import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News040324.jpg"
import img1 from "../../assets/News/News040324_1.jpg"

const News040324 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Круглый стол «Медиация и семейное насилие: баланс интересов» состоялся 28 февраля 2024 года с участием ФГБУ
            «Центр защиты прав и интересов детей»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Мероприятие организовано Центром медиации и социально-правовой помощи в Москве (филиал ГБУ Центр
            "Содействие") Департамента труда и социальной защиты населения г. Москвы. В заседании круглого стола приняло
            участие более 270 человек из 42 субъектов Российской Федерации.
            <p></p>
            С докладами на тему семейного насилия, профилактики данного явления и сопровождения семьи в кризисе
            выступили представители профессионального сообщества медиаторов, психологов, адвокатов, ФГБУ «Центр защиты
            прав и интересов детей», ГБУ «Кризисный центр помощи женщинам и детям», ГБУ «Мой семейный центр
            «Преображение», ГБУ «Московская служба психологической помощи населению».
            <p></p>
            Применение медиации в контексте домашнего насилия продолжает оставаться предметом острых дискуссий среди
            специалистов разного профиля, работающих с высоко конфликтными семьями как в России, так и за рубежом.
            <p></p>
            В рамках круглого стола были рассмотрены темы эффективного выявления случаев насилия со стороны партнера в
            работе с семейными парами, обратившимися за процедурой медиации, применение достоверных и надежных
            инструментов скрининга всего спектра форм и моделей поведения, связанных с насилием в семье.
            <p></p>
            С докладом <strong>«Насилие в трансграничных спорах. Практика применения медиации»</strong> в онлайн формате
            выступил
            аналитик I категории отдела международного сотрудничества Центра, международный семейный медиатор Тюльканов
            Сергей Леонидович.
            <p></p>
            Сергей Леонидович отметил важность учета культурных норм и обычаев, которые влияют на представление как
            родителей, так и общества, значимость норм и границ допустимого поведения и необходимости специальной
            подготовки медиаторов в вопросах обнаружения семейного насилия.
            <p></p>
            Медиатор обратил внимание участников круглого стола, что эмоциональное и психологическое насилие также
            являются важными факторами, влияющими на динамику развития семейного спора, и не следует ограничиваться
            работой только в отношении физического насилия и его последствий. В связи с этим такие вопросы, как,
            например, отчуждение одного из родителей от ребенка, необходимо учитывать всем лицам, занимающимся
            сопровождением семей и профилактикой домашнего насилия.
            <p></p>
            Также в ходе мероприятия выступила и поделилась опытом и результатами исследований руководитель Центра
            медиации и социально-правовой помощи в Москве (филиал ГБУ Центра «Содействие») Грачёва Ольга Евгеньевна.
            <p></p>
            Участники круглого стола согласились, что, учитывая распространенность и сложность случаев домашнего насилия
            в семье, службы медиации должны быть ориентированы на эффективные способы их обнаружения и обращения с ними.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <ToNews/>
    </div>
}


export default News040324;