import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/DecadeOfChildhood.jpg"

const DecadeOfChildhood = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: III Всероссийская конференция «Десятилетие детства: первоочередные задачи и результаты,
            вызовы, перспективы»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div>
            <p><strong>ПРЕСС-РЕЛИЗ</strong></p>
        </div>
        <div>
            <p><strong>III Всероссийская конференция<br/>
                «Десятилетие детства: первоочередные задачи и результаты, вызовы, перспективы»</strong></p>
        </div>
        <div>
            <p>
                19 ноября 2021 года Минпросвещения России проведет<br/>
                III Всероссийскую конференцию по вопросам реализации Десятилетия детства.
            </p>
            <p>
                В рамках мероприятия будут подведены предварительные итоги реализации в 2021 году Плана основных
                мероприятий, проводимых в рамках Десятилетия детства, на период до 2027 года и рассмотрят основные
                направления работы на федеральном и региональном уровнях.
            </p>
            <p>
                Участие в конференции примут члены Координационного совета при Правительстве Российской Федерации по
                проведению в Российской Федерации Десятилетия детства и действующих при нем рабочих групп, сенаторы
                Российской Федерации, Уполномоченный при Президенте Российской Федерации по правам ребенка, а также
                представители Общественной палаты Российской Федерации, федеральных и региональных органов
                исполнительной власти, некоммерческих организаций и ведущие российские эксперты.
            </p>
            <p>
                Конференция пройдет в онлайн-формате. Время проведения – с 9:30 до 15:00 (по Мск).
            </p>
            <p>Формат мероприятия включает пленарное заседание и тематические секций:</p>
            <ul>
                <li>«Здоровьесбережение с детства» (ссылка для регистрации: <br/><a
                    href="https://events.webinar.ru/19466565/9537551">https://events.webinar.ru/19466565/9537551</a>)
                    ;
                </li>
                <li>«Благополучие семей с детьми» (ссылка для регистрации: <br/><a
                    href="https://events.webinar.ru/19466565/9537607">https://events.webinar.ru/19466565/9537607</a>)
                    ;
                </li>
                <li>«Всестороннее развитие, обучение, воспитание детей» (ссылка для регистрации: <br/><a
                    href="https://events.webinar.ru/19466565/9537639">https://events.webinar.ru/19466565/9537639</a>)
                    ;
                </li>
                <li>«Инфраструктура детства» (ссылка для регистрации: <br/><a
                    href="https://events.webinar.ru/19466565/9537669">https://events.webinar.ru/19466565/9537669</a>)
                    ;
                </li>
                <li>«Качество жизни детей с ОВЗ, Детей инвалидов» (ссылка для регистрации: <br/><a
                    href="https://events.webinar.ru/19466565/9537697">https://events.webinar.ru/19466565/9537697</a>)
                    ;
                </li>
                <li>«Защита детей, оставшихся без попечения родителей» (ссылка для регистрации: <br/><a
                    href="https://events.webinar.ru/19466565/9537821">https://events.webinar.ru/19466565/9537821</a>)
                    ;
                </li>
                <li>«Безопасность детей» (ссылка для регистрации: <br/><a
                    href="https://events.webinar.ru/19466565/9537867">https://events.webinar.ru/19466565/9537867</a>)
                    ;
                </li>
            </ul>
            <p>Ссылка для регистрации на пленарное заседание: <a
                href="https://events.webinar.ru/19466565/9051057/">https://events.webinar.ru/19466565/9051057/</a></p>
            <p>По итогам конференции планируется издать электронный сборник, который будет размещен на информационном
                портале Десятилетия детства (10letie.edu.gov.ru).</p>
            <p>Регистрация на мероприятие открыта до 18 ноября 2021 г. до 00:00 часов включительно.</p>
            <p>Справочно: Организаторы конференции – Минпросвещения России, ФГБУ «Центр защиты прав и интересов
                детей».</p>
            <p>2018–2027 годы в Российской Федерации объявлены Десятилетием детства в соответствии с Указом Президента
                Российской Федерации от 29 мая 2017 года № 240.</p>
            <p>План основных мероприятий, проводимых в рамках Десятилетия детства, на период до 2027 года утвержден
                распоряжением Правительства Российской Федерации от 23 января 2021 г. № 122-р.</p>
        </div>
    </div>
}


export default DecadeOfChildhood