import React from "react";
import ico from "../../../assets/AD2/1.PNG"
import ico2 from "../../../assets/AD2/2.PNG"
import ico3 from "../../../assets/AD2/3.PNG"
import ico4 from "../../../assets/AD2/4.PNG"
import ico5 from "../../../assets/AD2/5.PNG"
import ico6 from "../../../assets/AD2/6.PNG"
import ico7 from "../../../assets/AD2/7.PNG"
import ico8 from "../../../assets/AD2/8.PNG"
import ico9 from "../../../assets/AD2/9.PNG"
import ico10 from "../../../assets/AD2/10.PNG"
import ico11 from "../../../assets/AD2/11.PNG"
import s from "./PageFiles.module.css"
import {FileLeftLineValue} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"
import ico12 from "../../../assets/AD2/12.PNG";
import ico13 from "../../../assets/AD2/13.PNG";
import ico14 from "../../../assets/AD2/14.PNG";
import ico15 from "../../../assets/AD2/15.PNG";
import ico16 from "../../../assets/AD2/16.PNG";
import ico17 from "../../../assets/AD2/17.PNG";
import ico18 from "../../../assets/AD2/18.PNG";

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {text: "Информационно-методические материалы для специалистов", to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"},
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const FilesValueInf = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.filesLeftPage}>
        <div className={s.newHeader}>
            <h2>
                Методические материалы для родителей и детей
            </h2>
            <h3 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1200px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt",
                fontSize: "24px",
            }}>Сценарии педагогических советов, родительских собраний, электронные каталоги</h3>
            <div className={s.filesLeftPage}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                    <FileLeftLineValue
                        text="Сценарий всероссийского родительского собрания «Профилактика интернет-рисков и угроз жизни детей и подростков»"
                        refers="https://fcprc.ru/media/media/behavior/Roditelskoe_sobranie_Profilaktika_internet-riskov.pdf"
                        ico={ico9}/>
                    <FileLeftLineValue
                        text="Сценарий всероссийского педагогического совета «Профилактика интернет-рисков и угроз жизни детей и подростков»"
                        refers="https://fcprc.ru/media/media/behavior/Pedsovet_Profilaktika_internet-riskov.pdf"
                        ico={ico10}/>
                    <FileLeftLineValue
                        text="Сценарий родительского собрания «Профилактика рисков аутодеструктивного поведения подростков»"
                        refers="https://fcprc.ru/media/media/behavior/Roditelskoe_sobranie_Profilaktika_autodestruktivnogo_povedeniya.pdf"
                        ico={ico7}/>
                    <FileLeftLineValue
                        text="Сценарий педагогического совета «Профилактика рисков аутодеструктивного поведения подростков»"
                        refers="https://fcprc.ru/media/media/behavior/Pedsovet_Profilaktika_autodestruktivnogo_povedeniya.pdf"
                        ico={ico8}/>
                    <FileLeftLineValue
                        text="Сценарий родительского онлайн-собрания «НЕ-ЗАВИСИМОСТЬ: подростки, привычки, здоровье»"
                        refers="https://fcprc.ru/media/media/behavior/Roditelskoe_sobranie_Ne-zavisimost.pdf"
                        ico={ico}/>
                    <FileLeftLineValue
                        text="Презентация к сценарию родительского онлайн-собрания «НЕ-ЗАВИСИМОСТЬ: подростки, привычки, здоровье»"
                        refers="https://fcprc.ru/media/media/behavior/Prezentatciya_Roditelskoe_sobranie_Ne-zavisimost.pdf"
                        ico={ico2}/>
                    <FileLeftLineValue
                        text="Сценарий родительского онлайн-собрания «Пространство соцсетей – без риска для детей»"
                        refers="https://fcprc.ru/media/media/behavior/Roditelskoe_sobranie_Prostranstvo_socsetey.pdf"
                        ico={ico3}/>
                    <FileLeftLineValue
                        text="Презентация к сценарию родительского онлайн-собрания «Пространство соцсетей – без риска для детей»"
                        refers="https://fcprc.ru/media/media/behavior/Prezentatciya_Roditelskoe_sobranie_Prostranstvo_socsetey.pdf"
                        ico={ico4}/>
                    <FileLeftLineValue
                        text="Сценарий родительского онлайн-собрания «Профилактика самоповреждающего поведения среди подростков»"
                        refers="https://fcprc.ru/media/media/behavior/Roditelskoe_sobranie_Profilaktika_samopovrejdeniya.pdf"
                        ico={ico5}/>
                    <FileLeftLineValue
                        text="Презентация к сценарию родительского онлайн-собрания «Профилактика самоповреждающего поведения среди подростков»"
                        refers="https://fcprc.ru/media/media/behavior/Prezentatciya_Roditelskoe_sobranie_Profilaktika_samopovrejdeniya.pdf"
                        ico={ico6}/>
                </div>
            </div>
            <h3 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1200px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt",
                fontSize: "24px",
            }}>Информационные материалы для родителей и обучающихся</h3>
            <div className={s.filesLeftPage}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                    <FileLeftLineValue text="Памятка для заботливых родителей"
                                       refers="https://fcprc.ru/media/media/behavior/Pamyatka_dlya_zabotlivyh_roditelei.pdf" ico={ico16}/>
                    <FileLeftLineValue
                        text="Алгоритм действий для родителей обучающихся по раннему выявлению и реагированию на деструктивное поведение несовершеннолетних, проявляющееся под воздействием информации негативного характера, распространяемой в сети «Интернет»"
                        refers="https://fcprc.ru/media/media/behavior/Algoritm_dlya_roditelei_destruktivnoe_povedenie.pdf"
                        ico={ico11}/>
                    <FileLeftLineValue text="Твоя психологическая безопасность"
                                       refers="https://fcprc.ru/media/media/behavior/Tvoya_psihologicheskaya_bezopasnost.pdf"
                                       ico={ico17}/>
                    <FileLeftLineValue text="Родителям о психологической безопасности детей и подростков"
                                       refers="https://fcprc.ru/media/media/behavior/Roditelyam_o_psihologicheskoi_bezopasnosti.pdf"
                                       ico={ico18}/>
                    <FileLeftLineValue
                        text="Универсальные советы родителям по воспитанию детей, способные предотвратить развитие рискованного поведения"
                        refers="https://fcprc.ru/media/media/behavior/Universalnie_sovety_roditelyam.pdf"
                        ico={ico12}/>
                    <FileLeftLineValue text="Как не совершать ошибок в воспитании детей?"
                                       refers="https://fcprc.ru/media/media/behavior/Kak_ne_sovershat_oshibok_v_vospitanii.pdf"
                                       ico={ico13}/>
                    <FileLeftLineValue text="Формула твоей безопасности. Памятка для подростков"
                                       refers="https://fcprc.ru/media/media/behavior/Formula_tvoei_bezopasnosti._Podrostkam.pdf"
                                       ico={ico14}/>
                    <FileLeftLineValue text="Формула безопасного детства. Рекомендации для родителей"
                                       refers="https://fcprc.ru/media/media/behavior/Formula_bezopasnogo_detstva._Roditelyam.pdf"
                                       ico={ico15}/>
                </div>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <Hrefs hrefsArr={hrefsArr}/>
        </div>
    </div>
}
export default FilesValueInf