import React from "react";
import s from "./Falldown.module.css"
import "./Falldown.css"
import {DownOutlined} from "@ant-design/icons"
import {useState, useEffect} from "react";
import axios from "axios";
import file from "../../assets/fileicored.svg"
import Falldown from "../common/FalldownList/Falldown";

const PFHD = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/PFHD").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div>
        <div className={s.newHeader}>
            <h2>
                ПФХД
            </h2>
            <Falldown header={"План финансово-хозяйственной деятельности 2022"}>
                <li>
                    <a href={respData[30]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[30]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[29]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[29]?.description}
                    </a>
                </li>
                <li>

                    <a href={respData[28]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[28]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[27]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[27]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[26]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[26]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[25]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[25]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[24]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[24]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[0]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[0]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[1]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[1]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[2]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[2]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[3]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[3]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[4]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[4]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[5]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[5]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[6]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[6]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[7]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[7]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[8]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[8]?.description}
                    </a>
                </li>
            </Falldown>

            <Falldown header={"План финансово-хозяйственной деятельности 2021"}>

                <li>
                    <a href={respData[9]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[9]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[10]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[10]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[11]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[11]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[12]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[12]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[13]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[13]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[14]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[14]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[15]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[15]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[16]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[16]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[17]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[17]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[18]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[18]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[19]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[19]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[20]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[20]?.description}
                    </a>
                </li>

            </Falldown>

            <Falldown header={"План финансово-хозяйственной деятельности 2020"}>
                <li>
                    <a href={respData[21]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[21]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[22]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[22]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[23]?.file}>
                        <img src={file} style={{height: "25px"}}/>{respData[23]?.description}
                    </a>
                </li>
            </Falldown>

        </div>
    </div>


}
export default PFHD