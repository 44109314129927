import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News17082022.jpg"

const News17082022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Обсуждение изменений в профессиональный стандарт «Специалист органа опеки и попечительства в отношении
            несовершеннолетних»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В 2022 году Совет по профессиональным квалификациям в сфере безопасности труда, социальной защиты
                и занятости населения при участии ФГБУ «Центр защиты прав и интересов детей» осуществляет
                актуализацию профессионального стандарта «Специалист органа опеки и попечительства в отношении
                несовершеннолетних», утвержденного приказом Министерства труда и социальной защиты
                Российской Федерации от 18 ноября 2013 г. № 680н.</p>
            <p>Актуализация профессионального стандарта вызвана изменениями в общероссийских классификаторах
                видов экономической деятельности, занятий, специальностей по образованию, законодательстве
                Российской Федерации в сфере опеки и попечительства.</p>
            <p>Актуализация и реализация профессионального стандарта отвечает задачам кадрового обеспечения
                в рамках совершенствования деятельности органов опеки и попечительства.</p>
            <p>В целях обсуждения предлагаемых изменений проект актуализированного профессионального стандарта
                размещен по <a
                    href="https://fcprc.ru/media/media/behavior/Proekt-professionalnogo-standarta-1.pdf">ссылке</a>.
            </p>
            <p>Замечания и предложения по доработке проекта можно направлять до 1 сентября 2022 г. на адрес
                электронной почты: <a href="mailto:opeca@fcprc.ru">opeca@fcprc.ru</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News17082022;