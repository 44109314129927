import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/MediationStaffTraining.jpg"
import img2 from "../../assets/News/MediationStaffTraining2.jpg"
import img3 from "../../assets/News/MediationStaffTraining3.jpg"


const MediationStaffTraining = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Обучение сотрудников служб медиации СУВУ
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                В рамках выполнения работ по формированию и совершенствованию профессиональных компетенций сотрудников
                СУВУ в области профилактики и коррекции девиантного поведения подростков специалистами Центра защиты
                прав и интересов детей был разработан модуль «Современные технологии социально-педагогической работы с
                несовершеннолетними: теории и практики медиативно-восстановительных технологий» для включения в
                совместно реализуемую с Институтом художественного образования и культуры РАО дополнительную
                профессиональную программу повышения квалификации «Современные технологии социально-педагогической
                работы с несовершеннолетними: теории и практики».
            </p>
            <p>
                Сорок сотрудников служб медиации СУВУ приступили к освоению модуля, который состоит из трех семинаров,
                трех деловых игр и итоговой защиты проектов. В ходе занятий обучающиеся актуализируют знания о
                проведении процедуры медиации и медиативно-восстановительной беседы, отработают медиативные техники как
                в тематических упражнениях, так и в игровом взаимодействии, а также изучат процесс планирования и
                организации систематических мероприятий с воспитанниками.
            </p>
            <p>
                Занятия проходят в дистанционном формате и продлятся три недели.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default MediationStaffTraining;