import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/CompetitionSelectionPlatforms.jpg"

const CompetitionSelectionPlatforms = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийский конкурс по отбору ресурсных площадок программ социализации подростков
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>1 декабря 2022 года стартует Всероссийский конкурс по отбору ресурсных площадок программ социализации
                подростков (далее – Конкурс). Конкурс проводится в рамках государственного задания на выполнение работы
                по методическому обеспечению образовательной деятельности федеральным государственным бюджетным
                учреждением «Центр защиты прав и интересов детей» (далее – Центр) в целях поддержки программ,
                направленных на социализацию подростков в возрасте от 10 до 18 лет на 2022 год. Учредителем Конкурса
                является ФГБУ «Центр защиты прав и интересов детей» совместно с Общественной Палатой Российской
                Федерации. Организатором Конкурса является Центр. Ознакомиться с Положением о конкурсе
                можно по <a href="https://fcprc.ru/wp-content/uploads/2022/12/Polozhenie.pdf">ссылке</a></p>
        </div>
        <ToNews/>
    </div>
}


export default CompetitionSelectionPlatforms;