import React from "react";
import s from "./Default.module.css"
import { useEffect, useState } from "react";
import axios from "axios";
import img from "../../assets/Mediacia/vseros2.jpg"

const Vseros2 = ({children, header}) => {
    const [newsData, setNews] = useState([])
    
    return(<div className={s.page}>
        <h2 style={{fontWeight: "normal", marginTop:"50px", color: "#3a4663", textAlign:"left", maxWidth:"1100px", margin:"40px auto", fontFamily:"Proxima Nova Lt"}}>
        Итоги II Всероссийского совещания школьных служб примирения и медиации
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.boldec}>
        15 ноября 2022 г. прошло II Всероссийское совещание школьных служб примирения и медиации.
<p></p>
Всероссийское совещание было организовано и проведено <span>Министерством просвещения Российской Федерации</span> совместно с <span>ФГБУ «Центр защиты прав и интересов детей»</span> на базе <span>ФГБОУ ВО «Московский государственный юридический университет имени О.Е. Кутафина (МГЮА)»</span>.
<p></p>
Во Всероссийском совещании приняли очное участие более 90 человек, дистанционно работали 562 точки подключения образовательных организаций, органов опеки и попечительства, органов исполнительной власти субъектов Российской Федерации, осуществляющих государственное управление в сфере образования, экспертных сообществ и других, заинтересованных в данной практике организаций специалистов из 86 субъектов Российской Федерации, среди них: уполномоченные по правам ребенка субъектов Российской Федерации; представители образовательных организаций, в том числе специальных учебно-воспитательных учреждений федерального и регионального подчинения; общественных, научных организаций и экспертных сообществ, а также служб медиации и примирения субъектов Российской Федерации.
<p></p>
Всероссийское совещание открыло обращение Первого заместителя Министра просвещения Российской Федерации Александра Вячеславовича Бугаева.
<p></p>
Участников Всероссийского совещания приветствовал ректор Университета имени О.Е. Кутафина (МГЮА) Виктор Владимирович Блажеев.
<p></p>
 На пленарном заседании выступили:
<p></p>
<span>Анна Юрьевна Кузнецова </span> – Заместитель Председателя Государственной Думы Федерального Собрания Российской Федерации, член Правительственной комиссии по делам несовершеннолетних и защите их прав;
<p></p>
<span>Мария Алексеевна Львова-Белова </span>– Уполномоченный при Президенте Российской Федерации по правам ребенка, член Правительственной комиссии по делам несовершеннолетних и защите их прав;
<p></p>
<span>Марина Владимировна Гордеева</span> – председатель правления Фонда поддержки детей, находящихся в трудной жизненной ситуации;
<p></p>
<span>Лариса Павловна Фальковская</span> – директор Департамента государственной политики в сфере защиты прав детей Министерства просвещения Российской Федерации, ответственный секретарь Правительственной комиссии по делам несовершеннолетних и защите их прав;
<p></p>
<span>Инга Александровна Меликян</span> – исполняющая обязанности заместителя директора Департамента развития и регулирования юридической помощи и правовых услуг Министерства юстиции Российской Федерации.
<p></p>
<span>Борис Борисович Гусев</span> – директор федерального государственного бюджетного учреждения «Центр защиты прав и интересов детей»;
<p></p>
<span>Ирина Игоревна Фришман </span>– директор научно-практического центра и заместитель председателя Международного союза детских общественных объединений «Союз пионерских организаций;
<p></p>
<span>Марина Викторовна Быкова</span> и <span>Антон Юрьевич Коновалов</span> – сотрудники отдела Медиации Центра защиты прав и интересов детей.
<p></p>
В завершение пленарного заседания выступили юные медиаторы, с обращением
к взрослым: «Мы готовы к диалогу, а вы?»
<p></p>
<span>Михаил Сергеевич Мозгов</span> – обучающийся 11 класса муниципального бюджетного образовательного учреждения «Средняя общеобразовательная школа № 103» города Воронежа;
<p></p>
<span>Амалия Евгеньевна Галиева</span> – обучающаяся 9 класса государственного бюджетного образовательного учреждения Самарской области средней общеобразовательной школы № 4, поселка городского типа Безенчук муниципального района Безенчукский Самарской области;
<p></p>
<span>Софья Андреевна Васина </span>и <span>Ярослав Максимович Ярмоленко </span>– обучающиеся 1 класса государственного бюджетного образовательного учреждения города Москвы «Школа в Некрасовке»;
<p></p>
<span>Дарья Леонидовна Язонкина</span> и <span>Степан Леонидович Язонкин</span> – обучающиеся 1 класса государственного бюджетного образовательного учреждения города Москвы «Школа № 2051».
<p></p>
Руководитель кружка юных медиаторов <span>Инна Борисовна Мусатова</span> – воспитатель и педагог дополнительного образования.
<p></p>
В рамках работы Всероссийского совещания проводились: пленарное заседание, пять тематических круглых столов, посвященных ключевым направлениям развития служб школьной медиации и школьных служб примирения, а именно:
<p></p>
<div>
«Медиативная культура и восстановительные практики в системе образования» (Круглый стол №1);
«Подготовка специалиста в области восстановительного подхода и медиации в системе образования: современные вызовы и решения» (Круглый стол №2);
«Реализация восстановительных практик и медиации как элемента профилактики правонарушений в подростковой среде» (Круглый стол №3);
«Восстановительные практики и медиация в социальной сфере: ребенок-семья-школа» (Круглый стол №4);
«Управленческие механизмы поддержки сети служб медиации и примирения» (Круглый стол №5);
секция «Марафон практик», где участники могли представить практические разработки программ, уроков, семинаров и проектов для работы с обучающимися, их родителями, сотрудниками образовательных организаций.
</div>
<p></p>
Участникам Всероссийского совещания была представлена возможность ознакомиться с опытом работы по интеграции медиативных и восстановительных технологий в образовательное пространство, представленным представителями Республик Адыгея, Башкортостан, Алтайского края, Краснодарского края, Красноярского края, Пермского края, Кемеровской, Кировской, Самарской, Свердловской, Тульской, областей, городов Москвы, Санкт-Петербурга, Севастополя.
<p></p>
По мнению участников Всероссийского совещания медиация и восстановительные технологии уже вносят ощутимый вклад в снижение общего количества и остроты конфликтов, в которые вовлекаются дети, количества и тяжести правонарушений, в том числе повторных, и их последствий для других лиц и общества в целом, уменьшение асоциальных проявлений среди детей и подростков, а также в разрешение конфликтов в социальной сфере.
<p></p>
Всероссийское совещание проведено во исполнение пункта 8 Межведомственного плана комплексных мероприятий по реализации Концепции развития сети служб медиации в целях реализации восстановительного правосудия в отношении детей, в том числе совершивших общественно опасные деяния, но не достигших возраста, с которого наступает уголовная ответственность в Российской Федерации, до 2025 года, утвержденного протоколом заседания Правительственной комиссии по делам несовершеннолетних и защите их прав от 25 сентября 2019 г. № 23.
<p></p>
С материалами Всероссийского совещания можно ознакомиться на странице Всероссийского совещания
        </div>
    </div>)
}




export default Vseros2