import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News10622.jpg"
import YouTube from "react-youtube";

const News10622 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Растем с финансами
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Ко Дню защиты детей «Центр защиты прав и интересов детей» подготовил для несовершеннолетних серию
                видеоуроков, посвященных финансовой грамотности.></p>
            <p>Благодаря экспертам Банка России и Департамента финансов города Москвы каждый из видеосюжетов помогает
                несовершеннолетним ориентироваться в многогранном и интересном, но иногда опасном, современном мире
                финансов.</p>
            <p>Эксперты представляют порядок открытия и использования банковских карт для несовершеннолетних, способы
                защиты персональных данных детей и платежных данных в социальных сетях, включая безопасность финансовых
                операций в Интернете. Кроме того, зрители узнают о порядке открытия детских вкладов и особенностях
                совершения любых операций c ними. Финансово грамотного человека отличает и разумное финансовом
                поведении: умение контролировать себя, обдумывать заранее каждую покупку, составлять их список, критично
                относиться к уловкам маркетологов, следить за новостями. Немаловажным является и соблюдение условий
                кибербезопасности, понимание уловок мошенников, способов им противостоять и, конечно, использование
                антивирусных программ.</p>
            <p>Наиболее подробную информацию можно найти на портале Банка России <a href="http://finkult.info/">«Финансовая культура Банка России».</a></p>
            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt"
            }}>
                Разумное финансовое поведение
            </h2>
            <div>
                <YouTube videoId="iReW2bCDsGw" />
            </div>
            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt"
            }}>
                Банковская карта
            </h2>
            <div>
                <YouTube videoId="S_jGy55J8to" />
            </div>
            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt"
            }}>
                Вклады
            </h2>
            <div>
                <YouTube videoId="cyOv8Oie9C8" />
            </div>
            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt"
            }}>
                Фишинг
            </h2>
            <div>
                <YouTube videoId="PYZkihhkRE0" />
            </div>
            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt"
            }}>
                Безопасность в соцсетях
            </h2>
            <div>
                <YouTube videoId="MdpSFUdiJE0" />
            </div>
        </div>
        <ToNews/>
    </div>
}


export default News10622;