import React from 'react'
import s from "./PageFiles.module.css"

export const FileLeftLine = ({text, refers, ico, styles={}}) => {
    return <div className={s.fileleftlineRed} style={styles}>
        <img src={ico}/>
        <div style={{
            margin: "20px"
        }}>
            <a href={refers} target="_blank">
                {text}
            </a>
        </div>
    </div>
}
export const FileLeftLineValue = ({text, refers, ico}) => {
    return <div className={s.fileleftlineRedL}>
        <img src={ico}/>
        <div style={{
            margin: "20px"
        }}>
            <a href={refers} target="_blank">
                {text}
            </a>
        </div>
    </div>
}
export const FileLeftLineSUVU = ({text, refers, ico}) => {
    return <div className={s.fileleftlineBlue}>
        <img src={ico}/>
        <a href={refers} target="_blank">
            {text}
        </a>
    </div>
}

export const FileLeftLineSUVUWithDate = ({date, text, refers, ico}) => {
    return <div className={s.fileleftlineBlue}>
        <img src={ico}/>
        <a href={refers} target="_blank">
            <div style={{fontSize: "14px"}}>{date}</div>
            <p/>
            {text}
        </a>
    </div>
}

export const FileLeftLineSUVUWithDateAndFilesOrRefs = ({date, text, refers, ico, icoFile, fileRefers, fileName}) => {
    return <>
        <div className={s.fileleftlineBlue}>
            <div>
                <p style={{height: "25px"}}></p>
                <img src={ico}/>
                <p></p>
                <img src={icoFile}/>
            </div>
            <a href={refers} target="_blank">
                <div style={{fontSize: "14px"}}>{date}</div>
                <p/>
                {text}
                <div>

                    <a href={fileRefers} target="_blank">
                        <p/>
                        {fileName}
                    </a>
                </div>
            </a>
        </div>
    </>
}