import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/SSPSmolensk.jpg";
import YouTube from "react-youtube";

const SSPSmolensk = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Социально значимые проекты Смоленской области, реализуемые в рамках плана основных
            мероприятий, проводимых в рамках Десятилетия детства
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div>
            <p>
                В рамках цикла вебинаров «Региональные практики реализации Десятилетия детства: опыт и перспективы» 30
                июня 2021 г. состоялся вебинар «Социально значимые проекты Смоленской области, реализуемые в рамках
                плана основных мероприятий, проводимых в рамках Десятилетия детства».
            </p>
            <p>
                В вебинаре приняли участие представители органов исполнительной власти субъектов Российской Федерации,
                осуществляющих координацию реализации Десятилетия детства, а также профильные некоммерческие
                организации.
            </p>
            <p>
                Обсуждались особенности функционирования сложившейся в Смоленской области системы мер поддержки и защиты
                интересов семей с детьми, материнства, отцовства, профилактики социального сиротства.
            </p>
            <p>
                Общее количество участников – 994 человека из 77 регионов России.
            </p>
            <YouTube videoId="u8lj8GY2wbs"/>
            <p>Презентации:</p>
            <p><a href="https://fcprc.ru/media/media/behavior/savenkov1.pptx">Презентация 1</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/savenkov2.pptx">Презентация 2</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/savenkov3.pptx">Презентация 3</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/savenkov4.pptx">Презентация 4</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/savenkov5.pdf">Программа вебинара (скачать).</a></p>
        </div>
    </div>
}


export default SSPSmolensk