import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News180324.jpeg"
import {NavLink} from "react-router-dom";

const News180324 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Итоги Всероссийского опроса «Молодежь современной России:
            мой взгляд на риски и возможности»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Подведены итоги <strong>Всероссийского опроса «Молодежь современной России: мой взгляд на риски и
            возможности»</strong>.
            <p></p>
            Опрос проводился в период с <strong>21 декабря 2023 года по 31 января 2024 года</strong> в рамках реализации
            мероприятий по
            исполнению поручений заместителя Председателя Правительства Российской Федерации, председателя
            Правительственной комиссии по делам несовершеннолетних и защите их прав Т.А. Голиковой, согласно пункту 10
            раздела 1 протокола заседания Правительственной комиссии от 7 июля 2023 г. № 2 (анализ эффективности
            реализуемых концепций, комплексов мер, планов мероприятий по профилактике безнадзорности и правонарушений
            несовершеннолетних, суицидов, социально негативных явлений в детской и молодежной среде, иных документов,
            утвержденных Правительством Российской Федерации и Правительственной комиссией с целью внесения при
            необходимости предложении в Правительство Российской Федерации по их актуализации, унификации подходов в
            сфере профилактики).
            <p></p>

            В рамках анализа эффективности документов в сфере профилактики в качестве дополнительного инструментария
            оценки был применен социологический опрос трех целевых групп в субъектах Российской Федерации об уровне
            «удовлетворенности», субъективной оценке социальной ситуации, изменениях в сфере защиты детства в своем
            месте проживания – в сопоставлении актуального состояния с ситуацией глубиной 5 лет – Всероссийский опрос
            «Молодежь современной России: мой взгляд на риски и возможности», разработанный специалистами Центра защиты
            прав и интересов детей.
            <p></p>

            <strong>Всероссийский опрос охватывал 4 сферы:</strong>
            <ul>

                <li>
                    Психологическая устойчивость, социально-эмоциональное благополучие и стабильность.
                </li>
                <li>
                    Ценность здоровья, значимость привычек ЗОЖ, непринятие зависимого поведения.
                </li>
                <li>
                    Защищенность, резистентность к агрессии, правонарушениям, негативу.
                </li>
                <li>
                    Осознанность, ответственное поведение, гражданское самосознание, социальная активность.
                </li>
            </ul>

            В опросе приняли участие представители целевых групп из всех субъектов Российской Федерации:
            <p></p>

            <strong>232 725</strong> представителей обучающейся молодежи<br/>

            <strong>66 205 родителей</strong> (законных представителей)<br/>

            <strong>35 470 специалистов</strong>, работающих с молодежью<br/>

            <p></p>
            Суммарный охват Всероссийского опроса составил 334 400 человек.<br/>
            <p></p>

            <a href="http://молодежь-современной-россии.рф/"><strong>Итоги Всероссийского опроса: </strong>«Молодежь
                современной России: мой взгляд на риски и
                возможности».</a><br/>
            {/*<a href="https://fcprc.ru/media/media/behavior/МОЛОДЕЖЬ_РОССИИ_ОПРОС-2024._ОТЧЕТ_1.pdf"> Аналитический*/}
            {/*    отчет о результатах всероссийского опроса об изменениях в подростково-молодежной среде</a>*/}
            <p></p>

        </div>
        <ToNews/>
    </div>
}


export default News180324;