import s from "./Mediacia.module.css"
import React, {useEffect} from "react";
import {Category, Filter, Presentation} from "./VideoMediacia";
import logo from "../../assets/img/library_logo.png";
import {usePageTracking} from "../../hooks/usePageTracking";

const VideoMediaciaParents = () => {
    usePageTracking();
    return (
        <div>


            <div className={s.main}>
                <div className={s.logo}>
                    <img src={logo} alt=""/>
                </div>
                <p>
                    <strong>
                        Представляем Библиотеку Практик Медиатора от Центра защиты прав и интересов детей!
                    </strong>
                </p>
                <p>
                    Это ценный ресурс для всех, кто интересуется медиативными и
                    восстановительными технологиями в разрешении конфликтов и выстраивании конструктивных
                    взаимоотношений.
                </p>
                <p>
                    На данной странице собрана важная информация и полезные материалы на темы медиативных и
                    восстановительных технологий, разрешения конфликтов и ненасильственного общения в виде записей
                    вебинаров, тренингов, мастер-классов и семинаров от экспертов и практиков со всей страны.
                </p>
                <p>
                    Предлагаем Вам познакомиться с опытом других специалистов и различными подходами к урегулированию
                    споров. Осваивайте эффективные коммуникационные стратегии построения доверительного общения и
                    развивайте навыки восстановления отношений между участниками конфликта.
                </p>
                <p>
                    Представленные материалы не только познакомят Вас с основами медиативного и восстановительного
                    подхода, но и будут способствовать развитию и углублению практических знаний и навыков в данной
                    тематике.
                </p>
                Информация будет полезна для:
                <ul>
                    <li>
                        родителей и законных представителей несовершеннолетних
                    </li>
                    <li>
                        специалистов по работе с детьми и подростками (учителя, сотрудники образовательных организаций,
                        педагоги-психологи, советники директоров по воспитательной работе, сотрудники комиссии по делам
                        несовершеннолетних и защите их прав, специалисты органов опеки и попечительства, специалисты
                        органов
                        молодёжной политики, члены детских и молодёжных организаций и объединений)
                    </li>
                    <li>
                        специалистов и координаторов служб медиации и примирения
                    </li>
                </ul>
                Вы можете выбрать интересующую Вас категорию:
                <p></p>
                <Filter filterArray={filterArray}/>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Тренинг</span><br/>Медиативная беседа: разрешение споров
                            между взрослым и подростком
                        </div>
                        <p></p>
                        <p>
                            Знакомство с особенностями медиативного подхода в общении с подростками.

                            Анализ структуры медиативной беседы, которую можно применять в работе и личном общении.

                            Разбор практического кейса по предложенной структуре.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Тренинг проведен в рамках форума «РосПодрос» по инициативе
                                Уполномоченного при Президенте
                                Российской Федерации по правам ребенка. Марии Львовой-Беловой. Организатор – АНО «Центр
                                развития
                                социальных проектов» в рамках Национального проекта «Образование» при поддержке
                                Министерства
                                просвещения Российской Федерации</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239442&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Тренинг_Медиативная_беседа_разрешение_споров_между_взрослым_и_подростком__HTuXj5O.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            12 барьеров в общении с ребенком
                        </div>
                        <p></p>
                        <p>
                            Рассмотрены отношения дети-родители, разобраны двенадцать барьеров общения. Поиск
                            конструктивного пути реагирования и выстраивания собственного поведения с целью сохранения
                            доверительных и добрых отношений с ребенком.
                        </p>
                        <p>
                            <strong>Спикеры: </strong>Быкова Марина Викторовна — профессиональный медиатор,
                            тренер-преподаватель медиации, аналитик ФГБУ «Центр защиты прав и интересов детей»,
                            сертифицированный медиатор Gollege Of Mediators UK (Коллегии медиаторов Великобритании),
                            старший преподаватель МГППУ
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239050&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Быкова_М.В._12_барьеров_в_общении_с_ребенком.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>Как реагировать на детскую грубость:
                            инструменты медиатора
                        </div>
                        <p></p>
                        <p>
                            Участники вебинара узнают о причинах подобного поведения и целях негативных высказываний.

                            Проанализируют конструктивные и ненасильственные способы реагирования на детскую грубость на
                            основе медиативных техник. Услышат о способах профилактики грубого и агрессивного поведения
                            у детей и подростков.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239463&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Как_реагировать_на_детскую_грубость_Козлов_А.А..pdf"/>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Медиативные технологии для родителей
                        </div>
                        <p></p>
                        <p>
                            Рассмотрены условия, принципы и технологии медиации. Конструктивная коммуникация. Опытом из
                            личной практики Марины Быковой.
                        </p>
                        <p>
                            <strong>Спикеры: </strong>Быкова Марина Викторовна — профессиональный медиатор,
                            тренер-преподаватель медиации, аналитик ФГБУ «Центр защиты прав и интересов детей»,
                            сертифицированный медиатор Gollege Of Mediators UK (Коллегии медиаторов Великобритании),
                            старший преподаватель МГППУ
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239052&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Возможности и риски семейной медиации по спорам о детях
                        </div>
                        <p></p>
                        <p>
                            Освещены тонкости процедуры семейной медиации и работы медиатора. Принципы и преимущества
                            медиации. Что нужно знать, чтобы правильно выбрать медиатора.
                        </p>
                        <p>
                            <strong>Спикеры: </strong>Быкова Марина Викторовна — профессиональный медиатор,
                            тренер-преподаватель медиации, аналитик ФГБУ «Центр защиты прав и интересов детей»,
                            сертифицированный медиатор Gollege Of Mediators UK (Коллегии медиаторов Великобритании),
                            старший преподаватель МГППУ
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239058&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Быкова_М.В._Возможности_и_риски_семейной_медиации_по_спорам_о_детях_1.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Как правильно задавать вопросы ребёнку
                        </div>
                        <p></p>
                        <p>
                            Рассмотрена структура открытого вопроса, в том числе с позиции медиативного подхода.

                            Особенности задавания вопросов ребенку.

                            Разобраны ситуации, когда ребенок отказывается отвечать или отвечает грубо.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239443&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Как_правильно_задавать_вопросы_ребенку_Козлов_А.А..pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Медиация как технология регулирования межпоколенных конфликтов
                        </div>
                        <p></p>
                        <p>
                            Рассмотрено понятие межпоколенного конфликта, особенности его возникновения. Специфика
                            применения процедуры медиации как с точки зрения организации процесса переговоров, так и с
                            точки зрения проведения.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Пегашова Станислава Олеговна – начальник Центра медиации СПб ГБУ
                            «Городской центр социальных программ и профилактики асоциальных явлений среди молодежи
                            «Контакт» (г. Санкт-Петербург)
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар организован АНО «Центр развития социальных проектов» в
                                рамках лектория «Подростковая среда» проекта «РосПодрос» в рамках Национального проекта
                                «Образование» при поддержке Министерства
                                просвещения Российской Федерации и Уполномоченного при Президенте Российской Федерации
                                по правам ребенка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239062&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Медиация_как_технология_регулирования_межпоколенных_конфликтов.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Медиативная беседа с ребёнком
                        </div>
                        <p></p>
                        <p>
                            Знакомство с особенностями медиативного подхода в общении с детьми.

                            Разбор структуры медиативной беседы, которую можно применять в работе и личном общении.

                            Возможность узнать тонкости выстраивания диалога с ребенком в ситуации конфликта.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239464&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Медиативная_беседа_Козлов_А.А..pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Возможность медиации для построения правил совместной жизни, или как договариваться, чтобы
                            все были довольны
                        </div>
                        <p></p>
                        <p>
                            Что означает «договариваться» и как понять, что «все довольны»? Рассмотрена процедура
                            медиации как подход к достижению договоренностей. Какие инструменты технологии можно
                            применять при разрешении спора, не будучи медиатором.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Пегашова Станислава Олеговна – начальник Центра медиации СПб ГБУ
                            «Городской центр социальных программ и профилактики асоциальных явлений среди молодежи
                            «Контакт» (г. Санкт-Петербург)
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар организован АНО «Центр развития социальных проектов» в
                                рамках лектория «Подростковая среда» проекта «РосПодрос» в рамках Национального проекта
                                «Образование» при поддержке Министерства
                                просвещения Российской Федерации и Уполномоченного при Президенте Российской Федерации
                                по правам ребенка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239063&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Конфликты в учебных заведениях
                        </div>
                        <p></p>
                        <p>
                            Какими бывают конфликты в учебных заведениях и способы их разрешения.

                        </p>
                        <p>
                            <strong>Спикеры: </strong>Бриль Михаил Сергеевич – начальник Службы Медиации ГЦСП "КОНТАКТ",
                            практикующий медиатор<p></p>
                            Пегашова
                            Станислава Олеговна – начальник Центра медиации СПб ГБУ «Городской центр социальных программ
                            и профилактики асоциальных явлений среди молодежи «Контакт» (г. Санкт-Петербург)
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведен СПб ГБУ «Городской центр социальных программ
                                и профилактики асоциальных явлений среди молодежи «КОНТАКТ»
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239068&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Конструктивное общение с техникой Я-сообщение
                        </div>
                        <p></p>
                        <p>
                            Участники познакомятся со структурой техники ненасильственного общения «Я-сообщение».

                            Возможности применения данной техники в общении со взрослыми и детьми.

                            Образовательные программы Центра защиты прав и интересов детей и их особенности.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведен отделом медиации ФГБУ «Центр защиты прав и
                                интересов детей»</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239461&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                                allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Конструктивное_общение_с_техникой_Я-сообщение_Козлов_А.А..pdf"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Мастер-класс</span><br/>
                            Общение с ребёнком без конфликтов: медиативный подход
                        </div>
                        <p></p>
                        <p>
                            Способы создания безопасной и доверительной среды для общения.

                            Как медиативным путем узнать об интересах ребенка и какие есть инструменты определения
                            потребностей родителей.

                            Как поможет техника общения «Я-сообщение»
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Мастер-класс проводился в рамках Всероссийского форума
                                приёмных семей на площадке Усыновите.ру.

                                Проект реализуется при использовании гранта Президента Российской Федерации,
                                предоставленного Фондом президентских грантов</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239466&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Мастер-класс_общение_с_ребенком_без_конфликтов_Козлов_А.А..pdf"/>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Возможности медиации для построения правил совместной жизни, или как договариваться, чтобы
                            все были довольны
                        </div>
                        <p></p>
                        <p>
                            Рассмотрена формы разрешения конфликтов и стратегии поведения в конфликтных ситуациях.
                            Процесс медиативной процедуры.

                        </p>
                        <p>
                            <strong>Спикер: </strong>Сизова Наталья Сергеевна – начальник отдела разрешения
                            межличностных конфликтных ситуаций Центра медиации СПб ГБУ «Городской центр социальных
                            программ и профилактики асоциальных явлений среди молодежи «Контакт» (г. Санкт-Петербург)

                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар организован АНО «Центр развития социальных проектов» в
                                рамках лектория «Подростковая среда» проекта «РосПодрос» в рамках Национального проекта
                                «Образование» при поддержке Министерства просвещения Российской Федерации и
                                Уполномоченного при Президенте Российской Федерации по правам ребенка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239061&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Возможности_медиации_для_построения_правил_совместной_жизни_или.pdf"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Выпуск программы «Час с психологом»</span><br/>
                            Службы примирения
                        </div>
                        <p></p>
                        <p>
                            В передаче приняли участие руководитель PR-службы Департамента образования и науки города
                            Москвы Наталья Александровна Цымбаленко, руководитель городской службы примирения ГППЦ
                            Департамента образования и науки города Москвы Антон Юрьевич Коновалов и директор школы №
                            1285 Ярослава Дмитриевна Алпатова
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239081&hd=2"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Онлайн-семинар</span><br/>
                            Использование медиативных технологий в деятельности специальных учебно-воспитательных
                            учреждений для восстановления детско-родительских отношений
                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Козлов А.А., Махнева О.П., Спесивов Н.В., Василевская В.В.,
                            Салькова Н.В., Заманова С.М.
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Проведено Фондом поддержки детей, находящихся в трудной
                                жизненной ситуации
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy"
                                src="https://vk.com/video_ext.php?oid=-182917553&id=456239079&hd=2&autoplay=1"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="https://fond-detyam.ru/biblioteka/materialy-i-seminary/17836/"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VideoMediaciaParents


const filterArray = [
    {
        title: "Вся библиотека",
        url: "/video_mediacia",
        background: "rgba(241, 95, 72, 0.7)",
    },
    {
        title: "Специалистам по работе с детьми и подростками",
        url: "/video_mediacia/children",
        background: "rgba(246, 165, 74, 0.7)"
    },
    {
        title: "Специалистам служб медиации и примирения",
        url: "/video_mediacia/piece",
        background: "rgba(166, 205, 236, 0.7)"
    }
]