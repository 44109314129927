import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News140922.jpg"

const News140922 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Научно-методическое обеспечение единого подхода внедрения социальных (в том числе образовательных) практик с
            доказанной эффективностью в части реализации мероприятий Десятилетия детства
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>15 сентября 2022 г. в 10:00 мск. Минпросвещения России и ФГБУ «Центр защиты прав и интересов детей»
                совместно с ФГБОУ ВО «Московский государственный психолого-педагогический университет» проводят вебинар
                «Научно-методическое обеспечение единого подхода внедрения социальных (в том числе образовательных)
                практик с доказанной эффективностью в части реализации мероприятий Десятилетия детства» (программа
                прилагается).</p>
            <p>Вебинар продолжает цикл мероприятий по реализации Десятилетия детства</p>
            <p>К участию в вебинаре приглашаются специалисты органов исполнительной власти субъектов Российской
                Федерации, государственных и некоммерческих организаций, фондов и иных организаций, участвующих
                разработке, отборе и тиражировании социальных и образовательных практик в сфере детства.</p>
            <p><a href="https://events.webinar.ru/41646463/1988225524">Для регистрации перейдите по ссылке</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/Programma-1.pdf">Скачать программу вебинара</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News140922;