import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SummingUpResultsOTSOTC.jpg"

const SummingUpResultsOTSOTC = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            О подведении итогов первого Всероссийского конкурса в сфере профессиональной деятельности специалистов
            органов опеки и попечительства в отношении несовершеннолетних граждан «На стороне ребенка» 2022 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>29 ноября 2022 г. объявлены победители и лауреаты Всероссийского конкурса в сфере профессиональной
                деятельности специалистов органов опеки и попечительства в отношении несовершеннолетних граждан «На
                стороне ребенка»</p>
            <p>По результатам конкурсных испытаний Экспертной комиссией Конкурса определены 15 победителей и 24 лауреата
                в пяти номинациях среди специалистов органов опеки и попечительства:</p>
            <p>«Право ребенка на семью»</p>
            <p>«Защита прав детей в суде»</p>
            <p>«Помощник замещающей семьи»</p>
            <p>«Успешный старт»</p>
            <p>«На стороне ребенка»</p>
            <p>А также 9 победителей и 5 лауреатов в трех номинациях среди органов опеки и попечительства:</p>
            <p>«Эффективное взаимодействие с негосударственными некоммерческими организациями»</p>
            <p>«Лидер информационных технологий»</p>
            <p>«Лидер открытости обществу»</p>
            <p>Награждение победителей Конкурса состоится 9 декабря 2022 г. на торжественной церемонии закрытия
                Конкурса, проведение которой планируется в рамках Всероссийского совещания руководителей органов опеки и
                попечительства.</p>
            <p>Списки победителей и лауреатов Конкурса размещены на сайте Конкурса в разделе Объявления</p>
        </div>
        <ToNews/>
    </div>
}


export default SummingUpResultsOTSOTC;