import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News080524.jpg"
import img1 from "../../assets/News/News080524_1.jpg"
import img2 from "../../assets/News/News080524_2.jpg"

const BestInclusiveSchool2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в апреле 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В 2024 году отдел медиации Центра защиты прав и интересов детей продолжил работу по сопровождению работы
            служб медиации специальных учебно-воспитательных учреждений, подведомственных Минпросвещения России.
            <p></p>
            Мероприятия прошли 19 апреля в дистанционном формате с Ишимбайским СУВУ, Неманским СУВУ, Каргатским СУВУ,
            Майкопским СУВУ и Калтанским СУВУ, а также 26 апреля с Рефтинским СУВУ, Щекинским СУВУ и Себежским СУВУ.
            Также к этим мероприятиям с интересом присоединились обучающиеся и сотрудники из других СУВУ.
            <p></p>
            На встрече 19 апреля юные медиаторы Ишимбайского и Майкопского СУВУ выступили соведущими занятия, рассказав
            про принципы добровольности, ответственности, нейтральности и равноправия в медиации и вовлекая в дискуссию
            других ребят.
            <p></p>
            26 апреля занятие прошло тоже в смешанном формате. На этот раз обучающиеся также были в роли соведущих.
            Ребята из Рефтинского СУВУ продемонстрировали снятый и смонтированный ими видеоролик о принципе
            конфиденциальности в медиации, а обучающиеся Щекинского СУВУ рассказали о принципах взаимоуважения и
            сотрудничества.
            <p></p>
            Обучающиеся смогли на встречах обсудить особенности принципов медиации, применить на практике медиативный
            подход, проанализировать несколько кейсов, предложенных педагогами и высказать свою точку зрения по поводу
            выхода из смоделированных ситуаций.
            <p></p>
            Представители разных СУВУ продемонстрировали уважительное отношение друг к другу, отмечали удачные моменты в
            выступлении товарищей. Структура занятий позволила успешно провести встречи для обучающихся разных полов.
            <p></p>
            19 и 25 апреля в рамках сопровождения сотрудников служб медиации отделом медиации Центра защиты прав и
            интересов детей были проведены консультации, на которых обсуждались планы на 2024 год и текущее состояние
            служб. Педагоги обменивались профессиональным опытом из практики СУВУ.
            <p></p>
            Мероприятия проводили: начальник отдела медиации Центра защиты прав и интересов детей – Козлов Анатолий
            Андреевич, аналитик категории отдела медиации – Быкова Марина Викторовна и аналитик II категории отдела
            медиации – Жодзишская Лидия Николаевна.
            <p></p>
            <a style={{fontWeight: "bold"}}
               href="https://fcprc.ru/media/media/behavior/Программа_вебинаров_СУВУ_апрель-август_2024.docx"
               target="_blank">ПРОГРАММА ВЕБИНАРОВ </a>
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default BestInclusiveSchool2022;