import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/ETSCDTV.jpg"

const ETSCDTV = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийское родительское собрание
            «Обеспечение безопасности детей при перевозке в транспортных средствах»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Вниманию родителей!</p>
            <p>26 апреля 2023 года состоится Всероссийское родительское собрание <strong>«Обеспечение безопасности детей
                при перевозке в транспортных средствах»</strong>.</p>
            <p>Всероссийское родительское собрание проводит ГУОБДД МВД России
                при поддержке Минпросвещения России в целях повышения эффективности реализации мероприятий, направленных
                на повышение уровня правосознания и культуры поведения участников дорожного движения и привития детям
                навыков безопасного поведения на дорогах.</p>
            <p>Трансляция родительского собрания в онлайн-формате будет организована 26 апреля 2023 г. с 12:00 до 13:30
                (мск) на канале «Госавтоинспекция МВД России» видеохостинга RUTUBE по ссылке
                <a href="https://rutube.ru/channel/13806034/">https://rutube.ru/channel/13806034/</a>
                (видеозапись размещена на указанном канале и доступна для просмотра).</p>
            <p>В Родительском собрании примут участие представители федеральных органов исполнительной власти,
                общественных и иных организаций, осуществляющих деятельность в сфере обеспечения безопасности дорожного
                движения. </p>
        </div>
        <ToNews/>
    </div>
}


export default ETSCDTV;