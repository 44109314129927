import React from "react";
import ico4 from "../../../assets/AD1/4.PNG"
import ico5 from "../../../assets/AD1/5.PNG"
import ico6 from "../../../assets/AD1/6.PNG"
import ico7 from "../../../assets/AD1/7.PNG"
import s from "./PageFiles.module.css"
import {FileLeftLineValue} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const Value_Met4 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.filesLeftPage}>
        <div className={s.newHeader}>
            <h2>
                Профилактика рискованного, девиантного поведения в детско-подростковой среде
            </h2>

            <div className={s.filesLeftPage}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                    <FileLeftLineValue text={respData[172]?.description} refers={respData[172]?.file} ico={ico6}/>
                    <FileLeftLineValue text={respData[173]?.description} refers={respData[173]?.file} ico={ico7}/>
                    <FileLeftLineValue text={respData[174]?.description} refers={respData[174]?.file} ico={ico5}/>
                    <FileLeftLineValue text={respData[175]?.description} refers={respData[175]?.file} ico={ico4}/>
                </div>
            </div>
        </div>
        <div>
            <div style={{marginTop: "72px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </div>
}
export default Value_Met4