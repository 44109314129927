import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News201124.jpg"

const News201124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Межгосударственный слет юных инспекторов движения, 2024 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В Анапе с 5 по 18 ноября 2024 года был проведен <b>Межгосударственный слет юных инспекторов движения</b> на
            базе ФГБОУ
            «Всероссийский детский центр «Смена».
            <p></p>
            Слёт проводится ежегодно <b>Министерством просвещения России и
            МВД России</b> в рамках проекта <b>«Безопасность дорожного движения»</b> нацпроекта <b>«Безопасные
            качественные
            дороги»</b>.
            <p></p>
            Директор ФГБУ «Центр защиты прав и интересов детей» Борис
            Борисович Гусев вошел в состав Оргкомитета Слета.
            <p></p>
            16 ноября на церемонии закрытия Слета Борис Борисович зачитал
            приветствие министра просвещения Российской Федерации <b>Сергея
                Сергеевича Кравцова</b>.
            <p></p>
            В своем выступлении <b>Борис Борисович Гусев</b> подчеркнул
            незаменимый вклад Слета в повышение эффективности деятельности по
            воспитанию законопослушных участников дорожного движения и
            укрепление сотрудничества со странами Содружества Независимых
            Государств в развитии движения юных инспекторов движения и
            профилактику детского дорожно-транспортного травматизма, а также
            принял участие в награждении победителей.
            <p></p>
            В этом году в Слете принимали участие более 160 активистов юных
            инспекторов движения в возрасте 10–13 лет из 36 регионов России и 4 стран
            ближнего зарубежья – Абхазии, Белоруссии, Киргизии, Южной Осетии.
            Две недели ребята активно участвовали в мероприятиях – мастер-
            классах, интерактивных занятиях, лекциях, тренингах и тестировании по
            правилам дорожной безопасности.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News201124;