import {Action, applyMiddleware, combineReducers, compose} from "redux";
import { legacy_createStore as createStore} from 'redux'
import thunkMiddleware, {ThunkAction} from "redux-thunk"
import blankReducer from "./Blank"

let RootReducer = combineReducers({
    BlankPage: blankReducer
    }
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(RootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));
window.__store__ = store;

export default store