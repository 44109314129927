import { Form, Formik, Field } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import s from "./Finder.module.css"
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import {SearchOutlined, EyeOutlined} from "@ant-design/icons"

const Finder = ({active, setActive, find, setFind}) => {
    const [word, SetWord] = useState("")

    return<span className={s.head}>
        Поиск
    </span>
}
export const Search = ({find, setFind}) => {
    const [newsData, setNews] = useState([])
    const [newsFind, setNewsFind] = useState([])
    let navigate = useNavigate();
    const routeChange = () =>{
        debugger
        let path = `/find`;
        navigate(path);
      }
    useEffect(() => {
        axios.get("http://80.87.197.48:8666/api/main").then(
            res => {
                let data = res.data.reverse()
                setNews(res.data)
                
            }
        )
    }, [])
const onFindEl = ({word}) => {
        newsData.forEach(el => {
            const inputLetters = word
            const newsLetters = el.description.split(" ")
            newsLetters.forEach(els => {
                if(els === word){
                    const arr = []
                    arr.push(el)
                    debugger
                    setNewsFind(arr)
                    setFind(arr)
                }
            })
        }
            )
    }

    return<div className={s.search}>
        <form>
            <input placeholder="Поиск"/>
        </form>
<button type="submit"  className={s.subBtn} onClick={routeChange}>
              <SearchOutlined />
           </button>
    </div>
}
export default Finder