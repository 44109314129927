import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News10032022.jpg"

const News10032022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Тематические вебинары по вопросам, связанным с совместным нахождением с ребенком из организации для
            детей-сирот и детей, оставшихся без попечения родителей, при оказании ребенку медицинской помощи в
            стационарных условиях
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>28 февраля 2022 г. и 1 марта 2022 г. прошли тематические вебинары по актуальным вопросам, связанным с
                организацией и осуществлением совместного нахождения с ребенком из организации для детей-сирот и детей,
                оставшихся без попечения родителей, при оказании медицинской помощи в стационарных условиях.</p>
            <p>Вебинары организованы ФГБУ «Центр защиты прав и интересов детей» при участии Санкт-Петербургского
                регионального общественного движения помощи детям, оставшимся без попечения родителей, «Петербургские
                родители».</p>
            <p>
                Тематика вопросов, обсуждаемых в ходе вебинаров, касалась цели, задач, содержания и механизмов
                совместного нахождения с ребенком.
            </p>
            <p>
                Участники вебинаров ознакомились с практикой, сложившейся в Республике Башкортостан и Республике Коми,
                Пермском и Ставропольском краях, Калининградской области, городах федерального значения Санкт-Петербург
                и Севастополь.
            </p>
            <p>
                О волонтерской деятельности при организации совместного нахождения с ребенком рассказала Альшанская
                Елена Леонидовна, президент Благотворительного фонда «Волонтеры в помощь детям-сиротам».
            </p>
            <p>
                В мероприятиях приняли участие представители Минпросвещения России, Минтруда России, Минздрава России,
                органов исполнительной власти субъектов Российской Федерации, осуществляющих государственное управление
                в сфере опеки и попечительства в отношении несовершеннолетних граждан, негосударственных некоммерческих
                организаций, специалисты организаций для детей-сирот и детей, оставшихся без попечения родителей.
            </p>
            <p>
                Видеозапись вебинаров и презентации спикеров доступны по ссылкам:
            </p>
            <p>
                <a href="https://frcopeca.ru/webinars/vebinar-po-voprosam-svjazannym-s-organizaciej-i-osushhestvleniem-sovmestnogo-nahozhdenija-s-rebenkom-iz-organizacii-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-pri-okazanii-medic-2/">https://frcopeca.ru/webinars/vebinar-po-voprosam-svjazannym-s-organizaciej-i-osushhestvleniem-sovmestnogo-nahozhdenija-s-rebenkom-iz-organizacii-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-pri-okazanii-medic-2/;</a>
            </p>
            <p>
                <a href="https://frcopeca.ru/webinars/vebinar-po-voprosam-svjazannym-s-organizaciej-i-osushhestvleniem-sovmestnogo-nahozhdenija-s-rebenkom-iz-organizacii-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-pri-okazanii-medicin/">https://frcopeca.ru/webinars/vebinar-po-voprosam-svjazannym-s-organizaciej-i-osushhestvleniem-sovmestnogo-nahozhdenija-s-rebenkom-iz-organizacii-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-pri-okazanii-medicin/</a>
            </p>
            <p>Ответы на вопросы участников вебинаров будут размещены дополнительно по указанным выше ссылкам.</p>
        </div>
        <ToNews/>
    </div>
}


export default News10032022;