import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/WorkOrganizationVICH.jpeg";
import YouTube from "react-youtube";

const TerritoryWithout = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «Территория онлайн – без стресса»
        </h2>
        <div>
            <p>
                1 июня 2021 года в рамках онлайн-марафона «Растим детей ‒ растем с детьми», состоялся вебинар,
                посвященный вопросам современной жизни в онлайн-пространстве и необходимости выработки новых стратегий
                поведения в нем. Ведущий вебинара, Мелентьева Ольга Станиславовна, педагог-психолог, аналитик ФГБУ
                «Центр защиты прав и интересов детей», в рамках своего выступления рассмотрела стрессовые факторы
                интернет-среды, воздействующие на людей, и возможные пути их устранения.
            </p>
            <p>
                Ссылка на вебинар: <a href="https://www.youtube.com/watch?v=t1P3z5jwj_U">https://www.youtube.com/watch?v=t1P3z5jwj_U</a>
            </p>
            <p></p>
        </div>
    </div>
}


export default TerritoryWithout