import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News230424.jpg"
import img1 from "../../assets/News/News230424_1.jpg"

const News230424 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Нижегородский областной форум руководителей служб медиации (примирения) 18 апреля 2024 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            18 апреля 2024 года состоялся областной форум педагогов руководителей служб медиации (примирения) в
            Нижегородской области.
            <p></p>
            Форум проводился с целью поддержки и развития профессионального сообщества специалистов медиативных и
            восстановительных практик в системе образования Нижегородской области.
            <p></p>
            В нем приняли участие: Министр образования и науки Нижегородской области Пучков Михаил Юрьевич,
            Уполномоченный по правам ребенка в Нижегородской области Ушакова Маргарита Юрьевна, руководители и педагоги
            образовательных учреждений.
            <p></p>
            В рамках форума проводились мастер-классы, баркемп и форсайт-сессия на тему разрешения конфликтов.
            <p></p>
            Начальник отдела медиации ФГБУ «Центр защиты прав и интересов детей» Козлов Анатолий Андреевич выступил в
            пленарной части форума с темой «Место медиации в решении школьных конфликтов: педагоги, дети, родители,
            администрация», отметив важность взаимодействия семьи и школы. Потому что именно стратегия сотрудничества
            помогает выстроить конструктивный диалог и соблюсти интересы всех участников образовательных отношений.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <ToNews/>
    </div>
}


export default News230424;