import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/ChildDay2023.jpeg"
import img2 from "../../assets/News/ChildDay2.gif"

const ChildDay2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Поздравление директора
            ФГБУ «Центр защиты прав и интересов детей» с Днем защиты детей
        </h2>
        <div>
            <img src={img2}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>1 июня — Международный день защиты детей</p>
            <p>Детство — самое счастливое и беззаботное время!
                Это пора, о которой, вырастая, вспоминают с теплой радостью в сердце.
                К сожалению, иногда на детские годы выпадают сложные жизненные ситуации.
                Но мы, взрослые, должны приложить все усилия, чтобы сегодняшние дети росли, чувствуя поддержку и заботу.
                Чтобы детство запомнилось добрым и счастливым, и мир был открыт детям навстречу!
            </p>
        </div>
        <div>
            <img src={img}/>
        </div>
        <ToNews/>
    </div>
}


export default ChildDay2023;