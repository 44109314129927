import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, PinkButton, WhiteButton} from "../PageInfo/PageInfo"
import {hrefsArr} from "./FilesNPD";
import {FileLeftLineKDN} from "./FilesKDNNews";

const MASRRF = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })
    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации
                </h2>
                <FileLeftLineKDN text={respData[587]?.description} refers={respData[587]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[588]?.description} refers={respData[588]?.file} ico={ico}/>
                <FileLeftLineKDN
                    text={"Областной фестиваль волонтёрских объединений, направленных на пропаганду идей здорового жизненного стиля «Мы выбираем жизнь!» (Нижегородская область)"}
                    refers={"https://выбираемжизнь52.рф/"} ico={ico}/>
                <FileLeftLineKDN text={"Центр профилактики асоциальных явлений «Мой выбор» (Рязанская область)"}
                                 refers={"http://dmrzn.ru/index/0-113"} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="http://fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}
export default MASRRF