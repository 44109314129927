import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";
import {FileLeftLineKDN, HrefsKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const FilesKDNDev = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Методологические материалы
                </h2>
                <div>
                    <FileLeftLineKDN
                        text="Рекомендации по организации исполнительными органами субъектов Российской Федерации дополнительных мероприятий по привлечению несовершеннолетних в возрасте от 10 до 14 лет, состоящих на различных видах профилактического учета в органах и учреждениях системы профилактики безнадзорности и правонарушений несовершеннолетних, в деятельность Общероссийского общественно-государственного движения детей и молодежи «Движение первых»"
                        refers="https://fcprc.ru/media/media/behavior/Prilozhieniie_viersiia_9.pdf" ico={ico}/>
                    <FileLeftLineKDN text={respData[29]?.description} refers={respData[29]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[30]?.description} refers={respData[30]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[31]?.description} refers={respData[31]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[32]?.description} refers={respData[32]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[33]?.description} refers={respData[33]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[34]?.description} refers={respData[34]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[35]?.description} refers={respData[35]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[36]?.description} refers={respData[36]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[37]?.description} refers={respData[37]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[38]?.description} refers={respData[38]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[39]?.description} refers={respData[39]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[40]?.description} refers={respData[40]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[41]?.description} refers={respData[41]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[42]?.description} refers={respData[42]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[43]?.description} refers={respData[43]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[44]?.description} refers={respData[44]?.file} ico={ico}/>

                </div>
            </div>
            <div style={{marginTop: "-3px"}}>
                <HrefsKDN hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </>
}
export default FilesKDNDev