import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News010324.jpg"
import img1 from "../../assets/News/News010324_1.jpg"
import img2 from "../../assets/News/News010324_2.jpg"

const News010324 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Презентация мониторинга «Практическое применение процедуры медиации при разрешении семейных споров в
            субъектах Российской Федерации» 29 февраля 2024 г.
        </h2>
        <div>
            <img src={img1}/>
        </div>

        <div style={{fontFamily: "Proxima Nova Lt"}}>
            29 февраля 2024 года в Общественной палате Российской Федерации прошла общественная презентация мониторинга
            «Практическое применение процедуры медиации при разрешении семейных споров в субъектах Российской
            Федерации».
            <p></p>
            Мероприятие проводилось Аппаратом Уполномоченного при Президенте Российской Федерации по правам ребёнка
            Львовой-Беловой М.А. при участии представителей профессионального сообщества медиаторов, Уполномоченных по
            правам ребёнка субъектов Российской Федерации, Министерства Юстиции Российской Федерации.

            <p></p>
            В рамках проведения мониторинга рассматривалась информация, полученная Аппаратом Уполномоченного от 61
            субъекта Российской Федерации.
            <p></p>
            Результаты мониторинга включают в себя данные о применении примирительных процедур при разрешении семейных
            споров по определению места жительства ребенка и порядка общения с отдельно проживающим родителем на
            досудебной, судебной, а также стадии исполнительного производства.

            <p></p>
            В мероприятии приняли участие сотрудники отдела медиации Центра защиты прав и интересов детей: начальник
            отдела Анатолий Андреевич Козлов, аналитик II категории Лидия Николаевна Жодзишская, аналитик отдела Марина
            Викторовна Быкова.

            <p></p>
            Анатолий Андреевич и Марина Викторовна высказались в рамках экспертного обсуждения концепции и результатов
            проведенного мониторинга.
            <p></p>

            Козлов А.А. вынес предложение по созданию межведомственной рабочей группы для совершенствования мониторинга
            с целью проведения его в будущем на постоянной основе. Он также затронул вопросы необходимости
            систематизации просветительской работы в отношении детей и их родителей (законных представителей), которая
            бы раскрывала для них возможности медиации и восстановительного подхода при разрешении споров. Еще одним
            тезисом было обозначение важности обучения и несовершеннолетних и других членов семьи медиативным и
            восстановительным технологиям для профилактики конфликтов и улучшения внутрисемейных отношений.
            <p></p>

            Быкова М.В. как сертифицированный семейный медиатор отметила основные проблемы, связанные с проведением
            процедур медиации в спорах с участием несовершеннолетних, отдельно отметив специфику приглашения сторон в
            процедуру медиации.
            <p></p>

            Также в рамках мероприятия выступили и поделились своим опытом:
            <ul>
                <li>Директор АНО «Уральский центр медиации» Махнёва Ольга Павловна</li>

                <li>Президент ассоциации профессиональных медиаторов «Паритет» Дубинина Юлия Александровна</li>

                <li>Председатель Комитета Московской торгово-промышленной палаты по развитию медиации Черячукина
                    Евгения
                </li>
                Александровна

                <li>Руководитель Центра медиации и социально-правовой помощи в Москве. (филиал ГБУ Центр "Содействие")
                    Грачёва Ольга Евгеньевна</li>

                <li>Президент Ассоциации профессиональных медиаторов Алтайского края Ионина Елизавета Ивановна</li>

                <li>Уполномоченный по правам ребёнка в Орловской области Домогатский Константин Игоревич</li>
            </ul>

            Было отмечено, что на данный момент вопросами применения процедуры медиации в спорах с участием
            несовершеннолетних в субъектах Российской Федерации занимаются разные органы и организации. Необходимо
            продумать действия по аккумулированию действующего опыта применения медиации в России.
            <p></p>

            На сайте нашего Центра вы можете найти:
            <ul>
               <li><a href="https://clck.ru/398yct">Дополнительную общеразвивающую программу интерактивных занятий для детей, подростков и молодежи по
                   вопросам ненасильственных методов разрешения споров и конфликтов «Курс юного переговорщика»</a></li>


                <li><a href="https://clck.ru/398ycJ">Дополнительную общеразвивающую программу интерактивных занятий для детей, подростков и молодежи «Юный
                медиатор».</a></li>

                    <li><a href="https://clck.ru/398yiQ">Реестр некоммерческих организаций, применяющих восстановительные, а также медиативные технологии в
                отношении несовершеннолетних</a></li>
            </ul>
        </div>
        <div>
            <img src={img}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News010324;