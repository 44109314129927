import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/Saratov105.jpg"
import img1 from "../../assets/News/Saratov105_1.jpg"
import img2 from "../../assets/News/Saratov105_2.jpg"

const Saratov105 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            В Саратове состоялась межрегиональная научно-практическая конференция, посвященная 105-летию комиссий по
            делам несовершеннолетних и защите их прав
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p> Директор ФГБУ «Центр защиты прав и интересов детей» Б.Б. Гусев, руководитель федерального ресурсного
                центра комиссий по делам несовершеннолетних и защите их прав Е.С. Зенина приняли участие в
                межрегиональной научно-практической конференции, посвященной
                105-летию комиссий по делам несовершеннолетних и защите их прав, состоявшейся 23 – 24 марта 2023 года.
                Организатором межрегиональной научно-практической конференции выступила ФГБОУ ВО «Саратовская
                государственная юридическая академия».
                </p>
            <p>Указанное мероприятие объединило разных представителей системы профилактики безнадзорности и
                правонарушений несовершеннолетних, научного сообщества, прокуратуры Саратовской области для обсуждения
                вопросов в том числе совершенствования нормативного правового регулирования деятельности.</p>
            <p>В научно-практической конференции приняли участие в очном
                и заочном форматах: заместители председателей и ответственные секретари комиссий по делам
                несовершеннолетних и защите их прав Приволжского федерального округа, представителей иных субъектов
                системы профилактики безнадзорности и правонарушений несовершеннолетних муниципальных образований
                Саратовской области; ответственные секретари комиссия по делам несовершеннолетних и защите их прав
                Алтайского, Краснодарского и Ставропольского края, Пензенской и Ярославской областей, Ханты-Мансийского
                автономного округа – Югра.</p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
    </div>
}


export default Saratov105;