import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News310520222.jpg"

const News310520222 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Лица из числа детей-сирот, принимавшие участие в СВО, имеют преимущественное право на обеспечение жилыми помещениями
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Внесены изменения в статью 8 Федерального закона от 21 декабря 1996 г.
                № 159-ФЗ «О дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без
                попечения родителей», позволяющие лицам из числа детей-сирот и детей, оставшихся без попечения
                родителей, принимавшим участие в специальной военной операции на территориях Украины, Донецкой Народной
                Республики, Луганской Народной Республики, Запорожской области и Херсонской области, иметь
                преимущественное право на предоставление им жилых помещений перед другими лицами, включенными в список,
                подлежащих обеспечению жилыми помещениями. </p>
        </div>
        <ToNews/>
    </div>
}


export default News310520222;