import React from "react";
import ico1 from "../../../assets/AD1/2.1.PNG"
import ico2 from "../../../assets/AD1/2.2.PNG"
import ico3 from "../../../assets/AD1/2.3.PNG"
import ico4 from "../../../assets/AD1/2.4.PNG"
import ico5 from "../../../assets/AD1/2.5.PNG"
import ico6 from "../../../assets/AD1/2.6.1.png"
import ico7 from "../../../assets/AD1/2.7.png"
import ico9 from "../../../assets/AD1/2.9.PNG"

import s from "./PageFiles.module.css"
import {FileLeftLineValue} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"


const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const Value_Met2 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.filesLeftPage}>
        <div className={s.newHeader}>
            <h2>
                Профилактика преступных деяний против половой неприкосновенности несовершеннолетних
            </h2>
            <div className={s.filesLeftPage}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                    <FileLeftLineValue
                        text="Методические рекомендации «Выявление признаков сексуальной эксплуатации ребенка и признаков совершения сексуального насилия в отношении него, а также обеспечение психолого-педагогического сопровождения»"
                        refers="https://fcprc.ru/media/media/mediacia/МР_Выявление_признаков_СЭ.pdf" ico={ico9}/>
                    <FileLeftLineValue
                        text="Методические рекомендации «Выявление признаков сексуальной эксплуатации и совершения сексуального насилия в отношении несовершеннолетнего на основе анализа существующих методик и рекомендуемых к применению на базе образовательных организаций»"
                        refers="https://fcprc.ru/media/media/behavior/Выявление_признаков_сексуальной_эксплуатации_и_совершения_сексуального_на_dqxmAh1.pdf"
                        ico={ico7}/>
                    <FileLeftLineValue text={respData[582]?.description} refers={respData[582]?.file} ico={ico6}/>
                    <FileLeftLineValue text={respData[577]?.description} refers={respData[577]?.file} ico={ico1}/>
                    <FileLeftLineValue text={respData[577]?.description} refers={respData[577]?.file} ico={ico1}/>
                    <FileLeftLineValue text={respData[577]?.description} refers={respData[577]?.file} ico={ico1}/>
                    <FileLeftLineValue text={respData[578]?.description} refers={respData[578]?.file} ico={ico2}/>
                    <FileLeftLineValue text={respData[579]?.description} refers={respData[579]?.file} ico={ico3}/>
                    <FileLeftLineValue text={respData[580]?.description} refers={respData[580]?.file} ico={ico4}/>
                    <FileLeftLineValue text={respData[581]?.description} refers={respData[581]?.file} ico={ico5}/>


                </div>
            </div>
        </div>
        <div>
            <div style={{marginTop: "72px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </div>
}
export default Value_Met2