import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/KDNIZP2020.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import file1 from "../../../assets/Files/KDNIZP2020-pr.pdf"
import file2 from "../../../assets/Files/KDNIZP2020-lif.pdf"
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const KDNIZP2020 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Итоги VII Всероссийского совещания КДН и ЗП
                </h2>
            </div>

            <div>
                <img src={logo}/>
            </div>

            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    О проведении VII Всероссийского совещания по вопросу организации деятельности комиссий по делам
                    несовершеннолетних и защите их прав по теме: «Инновационный подход к развитию системы профилактики
                    безнадзорности и правонарушений несовершеннолетних»
                </p>
                <p>С 5 по 6 ноября 2020 г. в дистанционном формате состоялось VII Всероссийское совещание по вопросу
                    организации деятельности комиссий по делам несовершеннолетних и защите их прав на тему:
                    «Инновационный подход к развитию системы профилактики безнадзорности и правонарушений
                    несовершеннолетних» (далее – Всероссийское совещание).</p>
                <p>Организаторами данного мероприятия выступили Министерство просвещения Российской Федерации совместно
                    с ФГБУ «Центр защиты прав и интересов детей» при участии ФГБОУ ВО «Саратовская государственная
                    юридическая академия».</p>
                <p>
                    Онлайн-формат Всероссийского совещания в этом году еще более расширил его географию и круг
                    участников: в мероприятии приняли участие более 500 человек из 85 субъектов Российской Федерации, в
                    числе которых члены Правительственной комиссии по делам несовершеннолетних и защите их прав,
                    представители федеральных органов власти, региональных и территориальных (муниципальных) комиссий по
                    делам несовершеннолетних и защите их прав (далее – комиссии), иных органов и учреждений системы
                    профилактики безнадзорности и правонарушений несовершеннолетних (далее – система профилактики),
                    общественных организаций и научного сообщества, председатели региональных отделений общероссийской
                    общественно-государственной детско-юношеской организации «Российское движение школьников» (далее –
                    РДШ).
                </p>
                <p>
                    На пленарном заседании Всероссийского совещания обсуждались пути реализации приоритетных направлений
                    государственной политики в сфере профилактики безнадзорности и правонарушений несовершеннолетних,
                    ключевые проблемы и эффективные практики, способствующие обновлению формата превентивной
                    деятельности органов и учреждений системы профилактики с учетом рисков и вызовов современной среды.
                </p>
                <p>
                    В развитие ключевых тезисов выступлений планарного заседания, в целях выработки векторных
                    предложений по совершенствованию системы профилактики работа на дискуссионных площадках была
                    направлена на изучение: вопросов применения технологий проектной деятельности в сфере профилактики
                    асоциального поведения несовершеннолетних; регионального опыта по развитию системы профилактики,
                    предупреждению деструктивного поведения несовершеннолетних, декриминализации подростковой среды;
                    системы организации мониторинга в сфере профилактики безнадзорности и правонарушений
                    несовершеннолетних путем применения критериев оценки эффективности деятельности системы
                    профилактики; принципов межведомственного взаимодействия при формировании ведомственных
                    «профилактических учетов» органов и учреждений системы профилактики в современном контексте с
                    применением информационных технологий.
                </p>
                <p>
                    Опыт профилактической деятельности был представлен на Всероссийском совещании представителями
                    Алтайского, Ставропольского и Красноярского краев, Иркутской, Костромской, Курской, Курганской,
                    Липецкой, Московской, Нижегородской, Новосибирской, Оренбургской, Псковской, Ростовской,
                    Свердловской, Тамбовской и Тульской областей, Ямало-Ненецкого автономного округа, Севастополя,
                    представителями РДШ.
                </p>
                <p>
                    В целях обеспечения возможностей приобретения новых компетенций лицами, входящими в составы
                    комиссий, мастер-классы во второй день Всероссийского совещания были посвящены темам ранней
                    профилактики проблемного поведения детей, диагностики причин асоциального поведения
                    несовершеннолетних, применения информационных технологий и систем в деятельности комиссий,
                    предупреждения негативных явлений в подростковой среде через социальную рекламу.
                </p>
                <p>
                    Реализация выработанных участниками мероприятия по итогам Всероссийского совещания рекомендаций
                    будет способствовать обновлению формата превентивной деятельности органов и учреждений системы
                    профилактики, в том числе комиссий, внедрению в профилактическую работу инновационных методов и
                    технологий, подтвердивших свою эффективность, а также развитию межведомственного взаимодействия в
                    сфере защите детства.
                </p>
                <p><a href={file1}>Программа вебинара</a></p>
                <p><a href={file2}>Отчет о проведении
                    вебинара</a></p>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default KDNIZP2020