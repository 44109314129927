import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/FirstTeenagers360.jpg"

const FirstTeenagers360 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Состоялся I межрегиональный форум «Подростки 360»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>28-29 июня 2022 года в городе Санкт-Петербурге прошел I межрегиональный форум «Подростки 360»,
                посвященный межведомственному взаимодействию органов власти и заинтересованных структур по вопросам
                создания сети подростковых центров в субъектах Российской Федерации, формированию сообщества
                специалистов, обеспечивающих социализацию и поддержку подростков, созданию методической базы по работе с
                данной категорией, усилению работы с несовершеннолетними «группы риска» и состоящими на профилактическом
                учете.</p>
            <p>Организатором форума выступила автономная некоммерческая организация «Центр развития социальных проектов»
                в лице ее генерального директора – Попова Армена Гагиковича.</p>
            <p>Важными задачами Форума были: описание проблемного поля, создание карты возможностей, поддержки, развития
                и самореализации подростков.</p>
            <p>В работе Форума приняли участие уполномоченные по правам ребенка в субъектах Российской Федерации,
                представители профильных федеральных и региональных министерств и ведомств, общественных и молодежных
                организаций, члены Детского общественного совета при уполномоченном по правам ребенка в
                Санкт-Петербурге, специалисты-практики, работающие с подростками, в том числе с девиантным поведением.
                Всего – 150 специалистов в очном формате и более 1000 –в дистанционном.</p>
            <p>На открытии Форума выступила Уполномоченный при Президенте Российской Федерации по правам ребенка Мария
                Алексеевна Львова-Белова, а также директор Департамента государственной политики в сфере защиты прав
                детей Министерства просвещения Российской Федерации Лариса Павловна Фальковская.</p>
            <p>В работе Форума принял участие аналитик II категории отдела медиации ФГБУ «Центр защиты прав и интересов
                детей» Анатолий Андреевич Козлов, выступив с докладом на тему «Развитие конфликтной компетентности
                подростков: на основе опыта проведения поддерживающих мероприятий для сотрудников и обучающихся
                специальных учебно-воспитательных учреждений» на открытии второго дня форума, также в рамках секции
                «Безопасность и благополучие уязвимых категорий подростков» с темой: «Медиативные практики в сфере
                защиты прав и интересов детей».</p>
            <p>Участники Форума познакомились с детальностью ФГБУ «Центр защиты прав и интересов детей» и отдела
                медиации, а также узнали о возможностях применения медиативных и восстановительных технологий в работе с
                несовершеннолетними.</p>
            <p>
                В рамках форума участники посетили ряд городских и региональных организаций, занимающихся
                социально-психологической работой с детьми и молодёжью Санкт-Петербурга, в том числе государственное
                бюджетное учреждение «Городской центр социальных программ и профилактики асоциальных явлений среди
                молодежи «КОНТАКТ». На базе этого Центра успешно действует служба медиации, которая занимается
                разрешением школьных и семейных конфликтов с участием несовершеннолетних.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default FirstTeenagers360;