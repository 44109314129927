import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News120422.jpg"
import img2 from "../../assets/News/News120422_2.jpg"
import img3 from "../../assets/News/News120422_3.jpg"
const News120422 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Видеоконференция «Медиация как способ урегулирования споров»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>7 апреля 2022 г. аналитик отдела медиации ФГБУ «Центр защиты прав и интересов детей» Коновалов А.Ю.
                принял участие в видеоконференции на тему: «Медиация как способ урегулирования споров», организованной
                Управлением Министерства юстиции Российской Федерации по Московской области в рамках межведомственного
                плана комплексных мероприятий по реализации Концепции развития служб медиации в целях реализации
                восстановительного правосудия в отношении детей, в том числе совершивших опасные деяния, утвержденного
                протоколом Правительственной комиссии по делам несовершеннолетних и защите их прав от 25.09.2019 №
                23.</p>
            <p>Видеоконференция прошла с участием несовершеннолетних, содержащихся под стражей в ФКУ Можайская ВК УФСИН
                России по Московской области.</p>
            <p>В ходе видеоконференции рассматривались возможности восстановительного правосудия и медиации для
                восстановления конструктивных социальных связей осужденных и развитие их умений бесконфликтного решения
                ситуаций.</p>
            <p>Коновалов А.Ю. выступил с докладом, обозначив основные принципы восстановительного правосудия:</p>
            <p>- ответственность обидчика состоит в заглаживании причиненного жертве вреда;<br/>
                - акцент на потребностях жертвы преступления и ее исцеление в ходе восстановительной программы;<br/>
                - поддержка позитивных изменений со стороны близких и уважаемых людей.</p>
            <p>Были представлены две восстановительные программы:</p>
            <p>1. Программа «Встреча по заглаживанию вреда между обидчиком и пострадавшим», в ходе которой происходит
                встреча осужденного и пострадавшего (в случае добровольного согласия пострадавшего), и реализуются
                перечисленные выше принципы.<br/>
                2. Программа «Семейный совет», на котором происходит восстановление семейных социальных связей между
                отбывшим наказание осужденным, его родными и близкими – для профилактики правонарушений в будущем.</p>
            <p>Участие в этих программах в рамках уголовного дела не отменяет наказание (кроме дел частного обвинения),
                но может служить одним из факторов для его смягчения (например, УДО – условно-досрочное освобождение от
                отбывания наказания).</p>
            <p>Также в ходе конференции освещался опыт четырех проектов по восстановительному правосудию с осужденными в
                центрах временного содержания, в колониях Орловской области и Алтайского края, с обучающимися в
                специальных учебно-воспитательных учреждениях (СУВУ).</p>
            <p>Представленный опыт поможет развитию восстановительного правосудия и медиации при работе с несовершеннолетними в учреждениях ФСИН.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News120422;