import React from "react";
import s from "./Footer.module.css"
import ico1 from "../../assets/img/09.svg"
import ico2 from "../../assets/img/10.svg"
import ico3 from "../../assets/img/11.svg"
import {useState} from "react";
import "./style.css"
import {DownOutlined} from "@ant-design/icons"
import {ArrowRightOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {useMediaQuery} from "react-responsive";


const Footer = () => {
    const [isActive1, SetActive1] = useState(false)
    const [isActive2, SetActive2] = useState(false)
    const [isActive3, SetActive3] = useState(false)
    const isSmall = useMediaQuery({ query: "(max-width: 1200px)" });

    return <div style={{backgroundColor: "#455476"}}>
        <footer className={s.foot}>
            <div className={s.footPad}>
                <div className={s.footblocks}>
                    <div onClick={() => {
                        isActive1 ? SetActive1(false) : SetActive1(true)
                    }} className={s.ulBlock}
                         style={{}}>
                        <div style={{textDecoration: "underline"}}>
                            О центре <span>{isActive1 ? <DownOutlined/> :
                            <DownOutlined style={{rotate: "180deg"}}/>}</span>

                        </div>
                        {isActive1 ? <nav className={s.ALi}>
                            <ul style={{textDecoration: "none", opscity: 1}}>
                                <li>
                                    <NavLink to="/aboutUs">Информация</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/structure">Структура центра</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/maindir">Ключевые направления</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/reports">Отчеты</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/files-ustav">Уставные документы</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/gosexercise">Государственное задаание</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/pfhd">ПФХД</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/openshops">Открытые закупки</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/files-income">Сведения о доходах</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/files-anticor">Противодействие коррупции</NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/media/media/behavior/Доклад_об_антимонопольном_комплаенсе_в_ФГБУ_Центр_защиты_прав_и_интересов_x6rfilT.pdf">Антимонопольное
                                        законодательство</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/media/media/behavior/Приказ__38_от_29.05.2023.pdf">Политика обработки
                                        персональных данных</NavLink>
                                </li>
                                <li>
                                    <a href="/news">Новости</a>
                                </li>
                                <li>
                                    <NavLink to="/contacts">Контакты</NavLink>
                                </li>
                            </ul>
                        </nav> : <></>}
                    </div>
                    <div onClick={() => {
                        isActive2 ? SetActive2(false) : SetActive2(true)
                    }} className={s.ulBlock}
                    >
                        <div style={{
                            textDecoration: "underline",
                            fontSize: "20px",
                            fontFamily: "Proxima Nova Rg",
                            opacity: 0.7
                        }}>

                            Ключевые проекты <span>{isActive2 ? <DownOutlined/> :
                            <DownOutlined style={{rotate: "180deg"}}/>}</span>
                        </div>
                        {isActive2 ? <nav className={s.ALi}>
                            <ul>
                                <li>
                                    <a href="https://10let.edu.gov.ru/">10-летие детства</a>
                                </li>
                                <li>
                                    <a href="#">Национальный проект «Образование»</a>
                                </li>
                                <li>
                                    <a href="/profilaktika-deviantnogo-povedeniya/">Профилактика девиантного
                                        поведения</a>
                                </li>
                                <li>
                                    <a href="https://pmpkrf.ru/">ФРЦ ПМПК</a>
                                </li>
                                <li>
                                    <a href="https://ovzrf.ru/">ФРЦ ОВЗ</a>
                                </li>
                                <li>
                                    <a href="https://frcopeca.ru/">ФРЦ в сфере защиты прав детей, оставшихся без
                                        попечения родителей</a>
                                </li>
                                <li>
                                    <a href="/vserosconc">Всероссийские конкурсы</a>
                                </li>
                                <li>
                                    <a href="/mediacia">Медиация</a>
                                </li>
                                <li>
                                    <a href="/suvu">ФРЦ СУВУ</a>
                                </li>
                                <li>
                                    <a href="/federalnyj-tsentr-razvitiya-programm-sotsializatsii-podrostkov">ФЦ
                                        РПСП</a>
                                </li>
                            </ul>
                        </nav> : <></>}
                    </div>
                    <div onClick={() => {
                        isActive3 ? SetActive3(false) : SetActive3(true)
                    }} className={s.ulBlock}>
                        <div style={{
                            textDecoration: "underline",
                            fontSize: "20px",
                            fontFamily: "Proxima Nova Rg",
                            opacity: 0.7
                        }}>

                            Мероприятия <span>{isActive3 ? <DownOutlined/> :
                            <DownOutlined style={{rotate: "180deg"}}/>}</span>
                        </div>
                        {isActive3 ? <nav className={s.ALi}>
                            <ul>
                                <li>
                                    <a href="/news/01062023">Международный день защиты детей</a>
                                </li>
                                <li>
                                    <a href="/news/best-inclusive-school-2022">26-28 октября состоится федеральный этап
                                        IX Всероссийского конкурса «Лучшая инклюзивная школа России – 2022».</a>
                                </li>

                            </ul>
                        </nav> : <></>}
                    </div>
                    <div className={s.navigation}>
                        <ul>
                            <li><span><img src={ico1} className={s.fill}/></span><a
                                href="https://yandex.ru/maps/?text=115093%2C+%D0%B3.+%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0%2C+%D1%83%D0%BB.+%D0%9B%D1%8E%D1%81%D0%B8%D0%BD%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F%2C+%D0%B4.+51"
                                rel="nofollow" target="_blank">115093, г. Москва, ул. Люсиновская, д. 51</a>
                            </li>
                            <li><span><img src={ico2}></img></span><a href="mailto:fcprc@yandex.ru"
                                                                      class="href">fcprc@yandex.ru</a>
                            </li>
                            <li><span><img src={ico3}></img></span><a href="tel:+74994440806,10008" class="href">+7
                                (499) 444-08-06, <span>доб. 100 08</span></a>
                            </li>
                        </ul>
                    </div>
                    <div>
                    </div>
                    <div className={s.allmer}>
                        <a href="/meropr">

                            Все мероприятия <ArrowRightOutlined style={{color: "red"}}/>
                        </a>
                    </div>
                </div>
                <div style={{paddingBottom: "10px"}}>
                    {!isSmall && <p style={{
                        fontWeight: "normal",
                        color: "#3a4663",
                        textAlign: "left",
                        maxWidth: "1100px",
                        margin: "15px auto",
                        fontFamily: "Proxima Nova Lt",
                        fontSize: "12px"
                    }}>
                        <a target="_blank" rel="nofollow" href="https://edu.gov.ru" style={{fontSize: "12px"}}>МИНИСТЕРСТВО
                            ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</a>
                    </p>}
                    {isSmall && <p style={{
                        fontWeight: "normal",
                        color: "#3a4663",
                        textAlign: "center",
                        maxWidth: "1100px",
                        margin: "15px auto",
                        fontFamily: "Proxima Nova Lt",
                        fontSize: "12px"
                    }}>
                        <a target="_blank" rel="nofollow" href="https://edu.gov.ru" style={{fontSize: "12px"}}>МИНИСТЕРСТВО
                            ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</a>
                    </p>}

                    <div className={s.bottom}>

                        <span style={{color: "#fff", opacity: 0.6, overflow: "revert"}}>© ФГБУ "Центр защиты прав и интересов детей", 2024</span>
                        <a target="_blank" rel="noopener"
                           href="https://fcprc.ru/media/media/behavior/Приказ__39_от_29.05.2023.pdf">Политика
                            конфиденциальности</a>
                    </div>
                </div>
            </div>

        </footer>
    </div>
}

export default Footer