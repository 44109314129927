import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News01042022.jpg"

const News01042022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            ОБЪЯСНЯЕМ.РФ
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt", fontSize: "30px", fontWeight: "bold", textAlign: "center"}}>
            <p><a style={{fontSize: "50px", fontWeight: "bold", textAlign: "center"}} href="ОБЪЯСНЯЕМ.РФ">ОБЪЯСНЯЕМ.РФ</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News01042022;