import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SuvuSeptember1.jpg"
import img2 from "../../assets/News/SuvuSeptember2.jpg"

const SuvuSeptember = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ в сентябре-октябре
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В сентябре и октябре текущего года специалистами ФГБУ «Центр защиты прав и интересов детей» проведены
                мероприятия по сопровождению служб медиации Астраханского, Известковского, Каргатского, Мончегорского,
                Неманского, Омского, Рефтинского и Себежского специальных учебно-воспитательных учреждений.</p>
            <p>В рамках консультаций специалисты служб медиации обменялись опытом работы, организации занятий для
                обучающихся, а также разобрали и разыграли ситуацию, в которой применили медиативный подход в разговоре
                с ребенком и помогли ему разобраться в сложной ситуации.</p>
            <p>Интерактивные занятия для несовершеннолетних были посвящены теме восприятия и понимания другого. Ребята
                анализировали презентационный материал, работали в группах, учились составлять и говорить «Я-сообщение»
                в предложенных ситуациях.</p>
            <p>Деятельность по сопровождению служб медиации специальных учебно-воспитательных учреждений продолжается.</p>
        </div>
        <div>
            <img src={img}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default SuvuSeptember;