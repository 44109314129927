import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/HumanitarianMission.jpg"

const HumanitarianMission = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Гуманитарная миссия на новых территориях
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Федеральный центр развития программ социализации подростков, по поручению Уполномоченного при Президенте
                Российской Федерации по правам ребёнка Марии Львовой-Беловой, вместе с гуманитарной миссией омбудсмена
                «Детям в руки» побывал в городах Луганской и Донецкой народных республик.</p>
            <p>Первым городом для посещения стал Краснодон, где находится коррекционный дом-интернат для детей с
                особенностью развития. Привезли оборудование для детской комнаты, игры, телевизор, стиральную машину.
                Так же в Луганске была передана гуманитарная помощь 25 семьям — продукты питания, лекарства, средства
                гигиены, детские игрушки.</p>
            <p>Мария Алексеевна Львова-Белова:</p>
            <p>«Каждая гуманитарная поездка на Донбасс для меня особенная. Эта не стала исключением. Взрослым людям
                тяжело пережить самое страшное — боль, потерю близких, ежедневный страх за себя и родных, а детям это
                сделать ещё сложнее. Стараешься им помочь, поддержать добрым словом, обнять, чтобы поверили: все
                обязательно будет хорошо!</p>
            <p>Один из способов помочь — создать безопасные для детей и подростков пространства. С нового года вместе с
                Минпросвещения России и Федеральным подростковым центром откроем еще больше таких пространств в рамках
                нашей сети «Подростки России» в городах Донецкой и Луганской народных республик.</p>
            <p>Первые штрихи и тона уже внесли, покрасив вместе с подростками стену будущего центра в Харцызске,
                посмотрели помещения для центров и в других городах.</p>
            <p>Особенно ценно то, что ребята воодушевлены, с горящими глазами, полны оптимизма. Вместе мы сможем
                все!»</p>
            <p>Валерий Майоров, руководитель Федерального подросткового центра:</p>
            <p>«Важно помнить, что наш центр занимается помощью подросткам, но мы не оставляем без внимания других
                детей, и поэтому по поручению Марии Алексеевны Львовой-Беловой присутствуем сегодня на этих территориях
                и помогаем в раздаче гуманитарной помощи, что тоже является частью нашей работы».</p>
        </div>
        <ToNews/>
    </div>
}


export default HumanitarianMission;