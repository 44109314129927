import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const Suvu12 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            12 мая состоялся обучающий вебинар, который дал старт мониторингу специальных условий содержания
            несовершеннолетних, безопасности деятельности учреждений
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Организация и проведение мониторинга специальных условий содержания несовершеннолетних, безопасности
                деятельности специальных учебно-воспитательных учреждений рассматривались в рамках обучающего
                вебинара.</p>
            <p>В совещании приняли участие руководители и сотрудники 17 учреждений закрытого типа, подведомственных
                Минпросвещения России, обсудили вопрос заполнения форм в личных кабинетах на сайте Центра защиты прав
                детей.</p>
            <p>В программе вебинара также раскрывались вопросы законодательного регулирования создания и
                функционирования таких условий, структура мониторинга, особенности заполнения формы опросного листа, а
                также организационные вопросы взаимодействия с сотрудниками федерального ресурсного центра
                организационно-методического сопровождения деятельности специальных учебно-воспитательных учреждений по
                обобщению и тиражированию эффективного опыта организации работы по безопасности.</p>
            <p>По итогам вебинара все ответственные специалисты зарегистрировались в своих личных кабинетах для
                работы.</p>
            <p>Результатом мониторинга станет разработка методических рекомендаций по организации и обеспечению
                специальных условий содержания несовершеннолетних, безопасности деятельности СУВУ.
            </p>
        </div>
    </div>
}


export default Suvu12;