import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News140624.png"

const News140624 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            20-21 июня 2024 г. в Москве пройдет форум для специалистов сферы детства

        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Благотворительный фонд помощи детям с социально значимыми заболеваниями «Дети плюс» 20-21 июня 2024 г.
            проведет Всероссийский онлайн форум для специалистов, помогающих детям с социально значимыми заболеваниями.
            <p></p>
            Цель Форума – обмен опытом и повышение профессиональных компетенций специалистов, работающих в сфере помощи
            детям, живущим с социально значимыми заболеваниями (ВИЧ, гепатит, туберкулез, сахарный диабет, ментальные
            нарушения).
            <p></p>
            К участию в Форуме приглашаются руководители и специалисты органов опеки и попечительства, школ приемных
            родителей, служб сопровождения замещающих семей, общественных и некоммерческих организаций, осуществляющих
            деятельность социально-психологического сопровождения детей с социально-значимыми заболеваниями.
            <p></p>
            Мероприятия форума будут проводиться в очном и дистанционном формате.
            <p></p>
            Адрес очного участия: г. Москва, 4-й Вешняковский проезд, 1к1
            <p></p>
            Регистрация для онлайн участия по ссылке: <a style={{color: "red"}}
                                                         href="https://forum.childrenplus.ru/">https://forum.childrenplus.ru/</a>
            <p></p>
            ФГБУ «Центр защиты прав и интересов детей» Минпросвещения России выступают соорганизаторами форума.
        </div>
        <ToNews/>
    </div>
}


export default News140624;