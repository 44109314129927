import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News22032022.jpg"

const News23032022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Субсидии работодателям за трудоустройство молодежи
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Правительство Российской Федерации утвердило <a
                href="https://fcprc.ru/media/media/behavior/Postanovlenie-Pravitelstva-Rossijskoj-Federatsii-398-ot-18-marta-2022-g..pdf">постановление</a> о
                государственной поддержке в 2022 году
                юридических лиц, включая некоммерческие организации, и индивидуальных предпринимателей в целях
                стимулирования занятости отдельных категорий граждан.</p>
            <p>Субсидию получат работодатели, которые возьмут к себе на работу молодых людей в возрасте до 30 лет, в том
                числе лиц с инвалидностью и ограниченными возможностями здоровья, детей-сирот, детей, оставшихся без
                попечения родителей, лиц из числа детей-сирот и детей, оставшихся без попечения родителей, лиц,
                состоящих на учете в комиссии по делам несовершеннолетних.</p>
            <p>Чтобы получить субсидию, работодателю нужно обратиться в центр занятости для подбора специалистов под
                имеющиеся вакансии. Сделать это можно дистанционно через личный кабинет на портале «Работа России».
                После этого потребуется направить заявление в Фонд социального страхования, который занимается
                распределением и выплатой субсидий. Сделать это также можно дистанционно – через систему «Соцстрах».</p>
            <p>
                Решение Правительства Российской Федерации поможет молодым людям быстрее найти работу и поддержит
                работодателей.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News23032022;