import s from "./Mobile.module.css"
import {MenuOutlined} from "@ant-design/icons";
import logo from "../../assets/SVG/Logo.svg"
import React, {useState} from "react";
import {NavLink} from "react-router-dom";

const MobileHeader = () => {
    const [active, setActive] = useState(false);

    return (
        <div>
            <header>
                <span className={s.minPros}>
                    <a href="https://edu.gov.ru/">
                        Министерство просвещения Российской Федерации
                    </a>
                </span>

                <div className={s.mobileHeader} style={active ? {borderBottomRightRadius: "0"} : {}}>
                    <div className={s.ppms}>
                        <NavLink to="/"><img src={logo} alt="FCPRC"/></NavLink>
                    </div>
                    <div onClick={() => {
                        !active ?
                            setActive(true) : setActive(false)
                    }} className={s.menu} style={active ? {borderBottomLeftRadius: "0"} : {}}>
                        <MenuOutlined/>
                    </div>
                </div>

            </header>
            {active ? <BigMenu/> : <></>}
        </div>
    )
}
export default MobileHeader

const BigMenu = () => {
    return (
        <div className={s.subMenuList}>
            <li>
                <div className={s.title}>О Центре</div>
                <li>
                    <NavLink to="/aboutUs">Информация</NavLink>
                </li>
                <li>

                    <a href="/news">Новости</a>
                </li>
                <li>
                    <a href="/structure">Структура центра</a>
                </li>
                <li>
                    <NavLink to="/maindir">Ключевые направления</NavLink>
                </li>
                <li>
                    <NavLink to="/reports">Отчеты</NavLink>
                </li>
                <li>
                    <NavLink to="/files-ustav">Документы</NavLink>
                </li>
                <li>
                    <NavLink to="/gosexercise">Государственное задание</NavLink>
                </li>
                <li>
                    <NavLink to="/openshops">Открытые закупки</NavLink>
                </li>
                <li>
                    <NavLink to="/pfhd">ПФХД</NavLink>
                </li>
                <li>
                    <NavLink to="/files-income">Сведения о доходах</NavLink>
                </li>
                <li>
                    <NavLink to="/files-anticor">Противодействие коррупции</NavLink>
                </li>
                <li>
                    <a target="_blank"
                        //href="https://fcprc.ru/media/media/behavior/Доклад_об_антимонопольном_комплаенсе_в_ФГБУ_Центр_защиты_прав_и_интересов_x6rfilT.pdf">Антимонопольное
                       href="https://fcprc.ru/antimonopolnoe-zakonodatelstvo">Антимонопольное
                        законодательство</a>
                </li>
                <li>
                    <a href="https://fcprc.ru/media/media/behavior/Приказ__39_от_29.05.2023.pdf">Политика
                        обработки персональных данных</a>
                </li>
                <li>
                    <a href="https://fcprc.ru/media/media/behavior/Приказ__38_от_29.05.2023.pdf">Положение
                        об обработке и защите персональных данных</a>
                </li>
                <li>
                    <NavLink to="/contacts">Контакты</NavLink>
                </li>
            </li>

            <li className={s.title}>
                <div style={{marginBottom: "10px"}}>
                    <NavLink to="/education">
                        Образование
                    </NavLink>
                </div>
            </li>
            <li className={s.title}>
                <div style={{marginBottom: "10px"}}>
                    <a href="https://fcprc.ru/media/media/behavior/Our_Experts.pdf">
                        Наши Эксперты
                    </a>
                </div>
            </li>
            <li className={s.title}>
                <div style={{marginBottom: "10px"}}>
                    <NavLink to="/metodicheskie-razrabotki/">
                        Методические разработки
                    </NavLink>
                </div>
            </li>
            <li className={s.title}>
                <div style={{marginBottom: "10px"}}>
                    <NavLink to="/citsendata">
                        Обращения граждан
                    </NavLink>
                </div>
            </li>
            <div className={s.title}>Горячая линия</div>
            <li>
                <NavLink style={{fontSize: "20px"}} to="/hotline1">
                    Тестирование обучающихся
                </NavLink>
            </li>
            <li>
                <a style={{fontSize: "20px"}} href="https://frcopeca.ru/goryachaya-liniya-po-voprosam-realizatsii-zhilishhnyh-prav-detej-sirot/">
                    Жилищные права
                </a>
            </li>
            <li>
                <NavLink style={{fontSize: "20px"}} to="/hotline3">
                    Тематическая лента новостей
                </NavLink>
            </li>
        </div>
    );
};