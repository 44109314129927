import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/MainMissionsDecade.png";
import YouTube from "react-youtube";

const MainMissionsDecade = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Ключевые задачи и механизмы реализации Десятилетия детства
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                17 мая 2021 г. Минпросвещения России совместно с ФГБУ «Центр защиты прав и интересов детей» проведет
                организационно-методический вебинар «Ключевые задачи и механизмы реализации Десятилетия детства».
            </p>
            <p>
                Вебинар открывает цикл мероприятий по реализации Десятилетия детства. На первом мероприятии планируется
                обсудить задачи по повышению эффективности реализации Десятилетия детства на федеральном и региональном
                уровнях, а также ключевые механизмы реализации.
            </p>
            <p>
                В мероприятии примут участие представители федеральных органов исполнительной власти и органов
                исполнительной власти субъектов Российской Федерации, обеспечивающих координацию реализации плана
                основных мероприятий, проводимых в рамках Десятилетия детства.
            </p>
            <p>Спикеры вебинара:</p>
            <ul>
                <li>Фальковская Лариса Павловна – директор Департамента государственной политики в сфере защиты прав
                    детей Минпросвещения России
                </li>
                <li>Семья Галина Владимировна – член Координационного совета при Правительстве Российской Федерации по
                    проведению в Российской Федерации Десятилетия детства, профессор ФГБОУ ВО «Московский
                    государственный психолого-педагогический университет»
                </li>
                <li>Зубарева Наталия Николаевна – заместитель Губернатора Белгородской области
                </li>
                <li>Колесникова Ксения Ивановна – и.о. директора ФГБУ «Центр защиты прав и интересов детей»
                </li>
            </ul>
            <p>Мероприятие пройдет в онлайн-формате.</p>
            <p>Ссылка на подключение: <a
                href="https://events.webinar.ru/19466565/8626243">https://events.webinar.ru/19466565/8626243</a></p>
            <div className={s.blueButtonMediaciaBase}>
                <a href="https://fcprc.ru/media/media/behavior/Prilozhenie_programma-vebinara-.pdf">Программа
                    вебинара</a>
            </div>
            <br/>
        </div>
    </div>
}


export default MainMissionsDecade