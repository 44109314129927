import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/GrandOpeningTeenageSpaces.jpg"
import img2 from "../../assets/News/GrandOpeningTeenageSpaces2.jpg"
import img3 from "../../assets/News/GrandOpeningTeenageSpaces3.jpg"
import img4 from "../../assets/News/GrandOpeningTeenageSpaces4.jpg"
import img5 from "../../assets/News/GrandOpeningTeenageSpaces5.jpg"

const GrandOpeningTeenageSpaces = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            20 декабря состоялось торжественное открытие подростковых пространств одновременно в 13 регионах России
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В рамках стратегической программы «Подростки России», реализуемой Уполномоченным при Президенте
                Российской Федерации по правам ребёнка Марией Алексеевной Львовой-Беловой и Федеральным центром развития
                программ социализаций подростков под управлением Валерия Майорова, в одно время открылось сразу 13 новых
                подростковых пространств в разных точках России. Новые подростковые пространства – победители
                Всероссийского конкурса программ социализации подростков, прошедшего осенью.</p>
            <p>Мария Алексеевна Львова-Белова лично открыла новый подростковый центр в Рязани – «Территория подростков
                “Офлайн”» и провела телемост с остальными пространствами нового формата. В ходе открытия руководитель
                Федерального подросткового центра Валерий Майоров в режиме телемоста поздравил представителей новых и
                уже действующих центров, а также подвел итоги года, рассказал о достигнутых результатах и о поставленных
                целях. В каждом из регионов страны лично присутствовали делегаты Федерального подросткового центра.</p>
            <p>Руководителям подростковых пространств представилась возможность презентовать себя, свою команду и
                поделиться планами на будущий год. Были определены основные тенденции работы, в направлении которых
                необходимо будет двигаться на протяжении всего года.</p>
            <p>В первую очередь центры ориентированы на помощь и поддержку детям, на возможность ребенку выразить себя в
                доброжелательной и безопасной атмосфере. Здесь созданы все условия для интересного времяпровождения и
                творческой реализации, все помещения оборудованы современной техникой. Кроме того, одним из важнейших
                направлений работы центров является психологическая помощь, в том числе подросткам, которые находятся в
                трудной жизненной ситуации.</p>
            <p>«Открытие такого пространства – очень приятное завершение года для рязанских детей. Впереди новогодние
                каникулы, ребята уже спланировали свой досуг в “Территории”», – прокомментировала Анжелика Евдокимова
                Уполномоченный по правам ребёнка в Рязанской области, подростковый центр «Территория подростков».</p>
            <p>Ранее уже были успешно открыты пять подростковых центров: «ЗАВОД» и «ЯДома» в Белгородской области
                (соответственно г. Валуйки и г. Белгород), «Ритм» в Республике Дагестан (г. Махачкала), «Юность» в
                Новосибирской области (г. Новосибирск) и «Двор» в Пензенской области (г. Пенза).</p>
            <p>Теперь подростковые пространства есть в республиках Чувашия (г. Новочебоксарск), Коми (г. Сыктывкар) и
                Тыва (г. Кызыл), в Ханты-Мансийском автономном округе – Югре (Сургутский район, пгт. Барсово) и Пермском
                крае (г. Чусовой), в Новосибирской (г. Новосибирск), Нижегородской (г. Семенов), Челябинской (г.
                Челябинск), Ульяновской (г. Ульяновск), Тамбовской (г. Тамбов), Рязанской (г. Рязань), Тульской (г.
                Тула) и Саратовской (г. Саратов) областях.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>

        <ToNews/>
    </div>
}


export default GrandOpeningTeenageSpaces;