import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2811233.jpg"
import img2 from "../../assets/News/News2811233_1.jpg"
import img3 from "../../assets/News/News2811233_2.jpg"
import img4 from "../../assets/News/News2811233_3.jpg"
import img5 from "../../assets/News/News2811233_4.jpg"
import img6 from "../../assets/News/News2811233_5.jpg"

const News2811233 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Межрегиональная научно-практическая конференция по вопросам профилактики безнадзорности и правонарушений
            несовершеннолетних «Точка опоры».
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <strong>22-24 ноября 2023 года</strong> в г. Вологда при поддержке правительства Вологодской области совместно с филиалом
            автономного учреждения Вологодской области «Областной центр молодежных и гражданских инициатив «Содружество»
            Центр патриотического воспитания, Регионального штаба ВВПОД «ЮНАРМИЯ» Вологодской области, Главного Штаба
            ВВПОД «ЮНАРМИЯ» прошла <strong>Межрегиональная научно-практическая конференция по вопросам профилактики
            безнадзорности и правонарушений несовершеннолетних «Точка опоры»</strong>.

            <p></p>
            Цель конференции – формирование банка лучших практик в сфере профилактики безнадзорности и правонарушений
            несовершеннолетних, а также обмен опытом специалистов в данной сфере.

            <p></p>
            В рамках конференции прошли пленарное заседание, дискуссии, практикумы, презентации успешных региональных
            практик, лекции по наиболее актуальным вопросам организации профилактической работы с несовершеннолетними,
            круглый стол «Роль наставника в работе с детьми и молодежью», деловая игра «Спасем ребенка вместе.
            Межведомственное взаимодействие в сфере профилактики
            безнадзорности и правонарушений несовершеннолетних».

            <p></p>
            В мероприятиях конференции приняли участие: Уполномоченный при Губернаторе Вологодской области по правам
            ребенка Ольга Александровна Смирнова; представители органов исполнительной власти области,
            правоохранительных органов; федеральные эксперты по вопросам детской безопасности, а также специалисты сферы
            профилактики безнадзорности и правонарушений несовершеннолетних и специалисты сферы охраны и защиты детства
            из <strong>38 субъектов Российской Федерации</strong>.

            <p></p>
            В работе конференции приняла участие начальник отдела сопровождения вопросов безопасности детства ФГБУ
            «Центр защиты прав и интересов детей» <strong>Елена Николаевна Скорина</strong>.
            <div>
                <img src={img2}/>
            </div>
            <div>
                <img src={img3}/>
            </div>
            <div>
                <img src={img4}/>
            </div>
            <div>
                <img src={img5}/>
            </div>
            <div>
                <img src={img6}/>
            </div>
            <ToNews/>
        </div>
    </div>
}


export default News2811233;