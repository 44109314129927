import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/MediatsiyaPage1.jpg"
import img2 from "../../assets/News/MediatsiyaPage2.jpg"
import img3 from "../../assets/News/MediatsiyaPage3.jpg"
import img4 from "../../assets/News/MediatsiyaPage4.jpg"
import img5 from "../../assets/News/MediatsiyaPage5.jpg"
import img6 from "../../assets/News/MediatsiyaPage6.jpg"

const MediatsiyaPage = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийское совещание школьных служб примирения и медиации
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                24 сентября состоялось Всероссийское совещание школьных служб примирения и медиации, организованное
                Министерством просвещения Российской Федерации совместно с Центром по защите прав и интересов детей и
                Московским государственным юридическим университетом им. О.Е. Кутафина (МГЮА). Мероприятие прошло в
                дистанционном формате, в нем приняли участие сотрудники служб медиации из всех субъектов Российской
                Федерации, эксперты, представители органов власти, ответственных за развитие медиации. Общие число
                участников превысило 450 человек, из которых более 70 человек выступили с сообщениями и докладами.
            </p>
            <p>
                Совещание было посвящено подведению итогов реализации Концепции развития до 2020 года сети служб
                медиации в целях реализации восстановительного правосудия в отношении детей, в том числе совершивших
                общественно опасные деяния, но не достигших возраста, с которого наступает уголовная ответственность в
                Российской Федерации, утвержденной в 2014 году Правительством Российской Федерации. Также обсуждался ход
                дальнейшей работы, предусмотренный принятым в 2019 году Планом мероприятий по реализации Концепции до
                2025 года.
            </p>
            <p>
                На пленарном заседании с приветственным словом выступили заместитель Министра просвещения Российской
                Федерации Д.Е. Грибов и Уполномоченный при Президенте Российской Федерации по правам ребенка А.Ю.
                Кузнецова. Основной доклад был сделан директором департамента государственной политики в сфере защиты
                прав детей Минпросвещения России Л.П. Фальковской. В нем были озвучены основные результаты реализации
                Концепции по развитию служб медиации: в стране создано в общей сложности более 25 тысяч служб школьной
                медиации и примирения, большинство из которых работают в образовательных организациях. В этих службах
                задействовано более 75 тысяч специалистов. Также она обозначила основные направления развития системы
                служб медиации в будущем: повышение качества подготовки специалистов в области школьной медиации и
                примирения; развитие медиации в цифровой среде; более активное вовлечение служб медиации в разрешении
                школьных конфликтов.
            </p>
            <p>
                Пять тематических круглых столов были посвящены следующим вопросам.<br/>
                1. «Проблема эффективности подготовки профессионального школьного медиатора: современное состояние,
                перспективы».<br/>
                2. «Медиация и примирение: практики применения в образовательных организациях».<br/>
                3. «Службы школьной медиации как форма институционализации современного гражданского общества».<br/>
                4. «Роль школьных служб примирения и медиации в процессах управления, профилактической работы и
                воспитания обучающихся в образовательных организациях».<br/>
                5. «Реализация восстановительных программ в образовательных организациях как элемент профилактики
                криминализации подростковой среды».
            </p>
            <p>
                В своем докладе и.о. директора Центра защиты прав и интересов детей К.И. Колесникова отметила
                преемственность Центра в отношении работы по развитию школьной медиации, которая ранее осуществлялась
                Федеральным институтом медиации. Ведущими круглых столов в мероприятии выступили специалисты отдела по
                развитию медиации и восстановительных технологий – М.В. Быкова, А.В. Зацаринный и М.А Сулева.
            </p>
            <p>
                Участники Совещания высоко оценили вклад медиативных и восстановительных технологий по гармонизации
                отношений в образовательном пространстве и профилактике криминализации подростковой среды, а также
                отметили необходимость эффективного межведомственного взаимодействия по вопросам проведения мероприятий,
                предусмотренных планом до 2025 года.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
    </div>
}


export default MediatsiyaPage;