import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News280224.jpg"

const News280224 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийское совещание по вопросам профилактической работы в образовательных организациях
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            29 февраля 2024 г. пройдет Всероссийское совещание «Система воспитания как ключевой ресурс профилактической
            работы в образовательной организации».
            <p></p>
            На площадке совещания будут рассматриваться вопросы воспитания как базовой основы профилактической работы,
            возможности использования информационных ресурсов Президентской библиотеки и проектов Всероссийского
            общества «Знание» в воспитательной деятельности и профилактике девиантного поведения, значении
            наставничества в социализации обучающихся группы риска и особенностей проектирования программ воспитания.
            <p></p>
            Совершенствованию деятельности специальных учебно-воспитательных учреждений будет посвящена очная
            фокус-сессия. С программой совещания можно ознакомиться <a
            href="https://fcprc.ru/media/media/behavior/Программа_совещания_для_писем_2024_02_21.pdf"><strong>по ссылке</strong></a>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News280224;