import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News221124.jpg"
import img2 from "../../assets/News/News221124_2.jpg"
import img3 from "../../assets/News/News221124_3.jpg"

const News221124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Круглый стол «Квесты– необходимость правового регулирования сферы»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <b>21 ноября 2024 года</b><br/>
            в <b>Совете Федерации Федерального Собрания Российской Федерации</b> прошло рабочее совещании в формате
            круглого стола по разработке проекта федерального закона <b>«О государственном регулировании деятельности по
            организации и проведению квестов»</b>
            <p></p>
            Совещание вел член Комитета Совета Федерации по науке, образованию и культуре <b>Гибатдинов Айрат
            Минерасихович</b>.
            <p></p>
            В рабочем совещании приняли участие сенаторы члены Комитета Совета Федерации по науке,
            образованию икультуре, Комитета Совета Федерации по социальной политике, Комитета Совета
            Федерации по организации парламентской деятельности, депутаты Государственной Думы,
            представители Минкультуры России, Минэкономразвития России, Минпросвещения России,
            Роспотребнадзора, экспертного сообщества.
            <p></p>
            В совещании приняла участие <b>Артамонова Елена Геннадьевна</b>, заместитель директора
            повопросам профилактики девиантного поведения ФГБУ «Центр защиты прав и интересов
            детей».
            <p></p>
            <b>На повестке</b><br/>
            В настоящее время действует ГОСТ Р 71161-2023, который устанавливает стандарты качества
            длядетских квест-комнат. Принятие обсуждаемого законопроекта призвано способствовать
            развитию единообразного подхода при выработке государственной политики в вопросах
            организации ипроведения квестов.
            <p></p>
            <b>Обсуждалось</b><br/>
            <ul>
                <li>разработка ГОСТ для взрослых квест-комнат;</li>
                <li>включение квестов в перечень ОКВЭД;</li>
                <li>требования к организаторам квеста и его работникам, а также к самому процессу организации
                    ипроведения квестов;
                </li>
                <li>закрепление решением Правительства Российской Федерации курирующего ведомства;</li>
                <li>проработка понятийного аппарата (терминов);</li>
                <li>проработка разграничений повозрастной адресованности квестов (до 18 лет и 18+);</li>
                <li>проработка требования к организаторам и ведущим квестов (отсутствие судимостей,
                    психическое здоровье и др.);
                </li>
                <li>проработка ограничений по проведению квестов (риски психическому, духовному,
                    нравственному развитию детей и др.).
                </li>
            </ul>
            <b>Решение:</b> продолжить доработку законопроекта с последующим вынесением на решение
            Правительства Российской Федерации.
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News221124;