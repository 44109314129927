import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News151124.jpg"
import img2 from "../../assets/News/News151124_2.jpg"

const News151124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сотрудники Центра защиты прав и интересов детей приняли участие в работе третьего
            Национального форума «Здоровье ибезопасность детей»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            14 ноября в Международном мультимедийном пресс-центре «Россия сегодня» состоялся третий
            Национальный форум «Здоровье и безопасность детей».
            <p></p>
            В пленарном заседании Форума приняли участие:
            <ul>
                <li>исполнительный директор Национального мониторингового центра помощи пропавшим и
                    пострадавшим детям, член Общественной палаты Российской Федерации Екатерина МИЗУЛИНА;
                </li>
                <li>первый заместитель Министра просвещения Российской Федерации Александр БУГАЕВ;</li>
                <li>заместитель председателя Комитета Совета Федерации по науке, образованию и культуре Елена
                    ПИСАРЕВА;
                </li>
                <li>помощник Министра МЧС России полковник Александр КОРОВИН;</li>
                <li>председатель Комитета Государственной Думы Российской Федерации по труду, социальной
                    политике и делам ветеранов Ярослав НИЛОВ;
                </li>
                <li>председатель Попечительского совета Национального мониторингового центра помощи
                    пропавшим и пострадавшим детям Елена МИЛЬСКАЯ.
                </li>
            </ul>
            Главная тема форума – «Семья – основа здоровья и безопасности детей».
            Одним из важнейших стал вопрос воспитания подростков и молодежи в духе традиционных
            российских духовно-нравственных, культурно-исторических и семейных ценностей.
            <p></p>
            В работе Форума в рамках тематической сессии «Воспитание подростков и молодежи вдухе
            традиционных российских духовно-нравственных, культурно-исторических исемейных
            ценностей: задачи семьи, школы, общества и государства» приняла участие заместитель директора
            Центра по развитию системы профилактики девиантного поведения Елена АРТАМОНОВА
            сдокладом «Благополучие ибезопасность детства: основы устойчивости личности, ценности
            и ориентиры».
        </div>
        <div>
            <img src={img2}/>
        </div>

        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Подробнее о Форуме: <a style={{fontWeight: "bold"}}
                                   href="https://pressria.ru/20241114/957274558.html">https://pressria.ru/20241114/957274558.html</a>
        </div>
        <p></p>
        <ToNews/>
    </div>
}


export default News151124;