import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News0406242.jpg"

const News0406242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Онлайн-семинар «Использование медиативных технологий в деятельности специальных учебно-воспитательных учреждений для восстановления детско-родительских отношений» 29 мая 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            29 мая 2024 года прошёл онлайн-семинар для работников СУВУ и членов комиссий по делам несовершеннолетних и
            защите их прав.
            <p></p>
            Мероприятие проводилось с целью совершенствования навыков использования медиативных технологий специалистами
            СУВУ в процессе социализации и ресоциализации обучающихся, в нём приняли участие более 500 специалистов из
            69 субъектов Российской Федерации.
            <p></p>
            На онлайн-семинаре были рассмотрены вопросы восстановительной медиации в работе с подростками и членами их
            семей, внедрения восстановительной медиации в СУВУ и формирования профессиональных компетенций специалистов
            служб медиации и примирения.
            <p></p>
            Директор Департамента государственной политики в сфере защиты прав детей Минпросвещения России Лариса
            Павловна Фальковская в своём обращении к участникам онлайн-семинара обозначила основные направления
            интеграции медиативного и восстановительного подходов в работу СУВУ и подчеркнула значимость проведения
            подобных мероприятий.
            <p></p>
            С докладом «О работе по ресоциализации воспитанников специальных учебно-воспитательных учреждений
            (урегулирование конфликтных ситуаций с участием обучающихся)» выступил начальник отдела медиации ФГБУ «Центр
            защиты прав и интересов детей» Козлов Анатолий Андреевич.
            <p></p>
            Также в рамках онлайн-семинара презентовали свою работу специалисты служб медиации СУВУ, подведомственных
            Минпросвещения России и член Экспертного совета по развитию служб медиации и примирения в образовательных
            организациях Махнёва Ольга Павловна.
            <p></p>
            Мероприятие организовано Фондом поддержки детей, находящихся в трудной жизненной ситуации и Саратовской
            государственной юридической академией при поддержке Минпросвещения России.
            <p></p>
            Запись онлайн-семинара: <a style={{fontWeight: "bold"}} href="https://www.youtube.com/watch?v=qFKlPLGtY1g">https://www.youtube.com/watch?v=qFKlPLGtY1g</a>
            <p></p>
            Материалы онлайн-семинара: <a style={{fontWeight: "bold"}} href="https://fond-detyam.ru/biblioteka/materialy-i-seminary/17836">https://fond-detyam.ru/biblioteka/materialy-i-seminary/17836</a>
                <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News0406242;