import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/ResultsOfSecondMSRAMS.jpg"

const ResultsOfSecondMSRAMS = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Итоги II Всероссийского совещания школьных служб примирения и медиации
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>15 ноября 2022 г. прошло II Всероссийское совещание школьных служб примирения и медиации.</p>
            <p>Всероссийское совещание было организовано и проведено <strong>Министерством просвещения Российской
                Федерации</strong> совместно с <strong>ФГБУ «Центр защиты прав и интересов детей»</strong> на
                базе <strong>ФГБОУ ВО «Московский государственный
                    юридический университет имени О.Е. Кутафина (МГЮА)»</strong>.</p>
            <p>Во Всероссийском совещании приняли <strong>очное участие</strong> более <strong>90 человек</strong>,
                дистанционно работали <strong>562 точки подключения</strong> образовательных организаций, органов опеки
                и попечительства, органов
                исполнительной власти субъектов Российской Федерации, осуществляющих государственное управление в сфере
                образования,
                экспертных сообществ и других, заинтересованных в данной практике организаций специалистов из <strong>86
                    субъектов</strong> Российской Федерации, среди них: уполномоченные по правам ребенка субъектов
                Российской
                Федерации; представители образовательных организаций, в том числе специальных учебно-воспитательных
                учреждений федерального и регионального подчинения; общественных, научных организаций и экспертных
                сообществ, а также служб медиации и примирения субъектов Российской Федерации.</p>
            <p>Всероссийское совещание открыло обращение Первого заместителя Министра просвещения Российской Федерации
                <strong>Александра Вячеславовича Бугаева</strong>.</p>
            <p>Участников Всероссийского совещания приветствовал ректор Университета имени О.Е. Кутафина (МГЮА) <strong>Виктор
                Владимирович Блажеев</strong>.</p>
            <p>На пленарном заседании выступили:</p>
            <p><strong>Анна Юрьевна Кузнецова</strong> – Заместитель Председателя Государственной Думы Федерального Собрания Российской
                Федерации, член Правительственной комиссии по делам несовершеннолетних и защите их прав;</p>
            <p><strong>Мария Алексеевна Львова-Белова</strong> – Уполномоченный при Президенте Российской Федерации по правам ребенка,
                член Правительственной комиссии по делам несовершеннолетних и защите их прав;</p>
            <p><strong>Марина Владимировна Гордеева</strong> – председатель правления Фонда поддержки детей, находящихся в трудной
                жизненной ситуации;</p>
            <p><strong>Лариса Павловна Фальковская</strong> – директор Департамента государственной политики в сфере защиты прав детей
                Министерства просвещения Российской Федерации, ответственный секретарь Правительственной комиссии по
                делам несовершеннолетних и защите их прав;</p>
            <p><strong>Инга Александровна Меликян</strong> – исполняющая обязанности заместителя директора Департамента развития и
                регулирования юридической помощи и правовых услуг Министерства юстиции Российской Федерации.</p>
            <p><strong>Борис Борисович Гусев</strong> – директор федерального государственного бюджетного учреждения «Центр защиты прав и
                интересов детей»;</p>
            <p><strong>Ирина Игоревна Фришман</strong> – директор научно-практического центра и заместитель председателя Международного
                союза детских общественных объединений «Союз пионерских организаций;</p>
            <p><strong>Марина Викторовна Быкова и Антон Юрьевич Коновалов</strong> – сотрудники отдела Медиации Центра защиты прав и
                интересов детей.</p>
            <p>В завершение пленарного заседания выступили <strong>юные медиаторы</strong>, с обращением
                к взрослым: «Мы готовы к диалогу, а вы?»</p>
            <p><strong>Михаил Сергеевич Мозгов</strong> – обучающийся 11 класса муниципального бюджетного образовательного учреждения
                «Средняя общеобразовательная школа № 103» города Воронежа;</p>
            <p><strong>Амалия Евгеньевна Галиева</strong> – обучающаяся 9 класса государственного бюджетного образовательного учреждения
                Самарской области средней общеобразовательной школы № 4, поселка городского типа Безенчук муниципального
                района Безенчукский Самарской области;</p>
            <p><strong>Софья Андреевна Васина</strong> и <strong>Ярослав Максимович Ярмоленко</strong> – обучающиеся 1 класса государственного бюджетного
                образовательного учреждения города Москвы «Школа в Некрасовке»;</p>
            <p><strong>Дарья Леонидовна Язонкина</strong> и <strong>Степан Леонидович Язонкин</strong> – обучающиеся 1 класса государственного бюджетного
                образовательного учреждения города Москвы «Школа № 2051».</p>
            <p>Руководитель кружка юных медиаторов <strong>Инна Борисовна Мусатова</strong> – воспитатель и педагог дополнительного
                образования.</p>
            <p>В рамках работы Всероссийского совещания проводились: пленарное заседание, пять тематических круглых
                столов, посвященных ключевым направлениям развития служб школьной медиации и школьных служб примирения,
                а именно:</p>
            <ul className={s.noBullet}>
                <li><strong>«Медиативная культура и восстановительные практики в системе образования» (Круглый стол
                    №1);</strong></li>
                <li><strong>«Подготовка специалиста в области восстановительного подхода и медиации в системе
                    образования:
                    современные вызовы и решения»</strong> (Круглый стол №2);
                </li>
                <li><strong>«Реализация восстановительных практик и медиации как элемента профилактики правонарушений в
                    подростковой среде»</strong> (Круглый стол №3);
                </li>
                <li><strong>«Восстановительные практики и медиация в социальной сфере:
                    ребенок-семья-школа»</strong> (Круглый стол №4);
                </li>
                <li><strong>«Управленческие механизмы поддержки сети служб медиации и примирения»</strong> (Круглый стол
                    №5);
                </li>
                <li><strong>Секция «Марафон практик»</strong>, где участники могли представить практические разработки
                    программ, уроков,
                    семинаров и проектов для работы с обучающимися, их родителями, сотрудниками образовательных
                    организаций.
                </li>
            </ul>
            <p>Участникам Всероссийского совещания была представлена возможность ознакомиться с опытом работы по
                интеграции медиативных и восстановительных технологий в образовательное пространство, представленным
                представителями <strong>Республик Адыгея, Башкортостан, Алтайского края, Краснодарского края, Красноярского
                края, Пермского края, Кемеровской, Кировской, Самарской, Свердловской, Тульской, областей, городов
                Москвы, Санкт-Петербурга, Севастополя</strong>.</p>
            <p>По мнению участников Всероссийского совещания медиация и восстановительные технологии уже вносят ощутимый
                вклад в снижение общего количества и остроты конфликтов, в которые вовлекаются дети, количества и
                тяжести правонарушений, в том числе повторных, и их последствий для других лиц и общества в целом,
                уменьшение асоциальных проявлений среди детей и подростков, а также в разрешение конфликтов в социальной
                сфере.</p>
            <p>Всероссийское совещание проведено во исполнение пункта 8 Межведомственного плана комплексных мероприятий
                по реализации Концепции развития сети служб медиации в целях реализации восстановительного правосудия в
                отношении детей, в том числе совершивших общественно опасные деяния, но не достигших возраста, с
                которого наступает уголовная ответственность в Российской Федерации, до 2025 года, утвержденного
                протоколом заседания Правительственной комиссии по делам несовершеннолетних и защите их прав от 25
                сентября 2019 г. № 23.</p>
            <p>С материалами Всероссийского совещания можно ознакомиться на странице <a
                href="https://fcprc.ru/ii-vserossijskoe-soveshhanie-shkolnyh-sluzhb-primireniya-i-mediatsii-2/">Всероссийского
                совещания</a></p>
        </div>
        <ToNews/>
    </div>
}


export default ResultsOfSecondMSRAMS;