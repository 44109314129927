import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News210524.jpg"

const News210524 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Круглый стол о разрешении семейных споров и споров о детях 16 мая 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            16 мая 2024 года в Центральном Доме адвоката в г. Москве состоялся Круглый стол, посвящённый организации
            сотрудничества адвокатов, медиаторов и психологов при оказании бесплатной юридической помощи гражданам,
            находящимся в сложной жизненной ситуации при разрешении семейных споров и спорах о детях.
            <p></p>
            Модераторами Круглого стола выступили: почётный адвокат России, медиатор, член рабочей группы Гильдии
            российских адвокатов по развитию организационно-правовых основ бесплатной юридической помощи Елена
            Валерьевна Колотильщикова и почётный адвокат России, советник президента Гильдии российских адвокатов
            Людмила Олеговна Матвиенко.
            <p></p>
            В работе Круглого стола приняли участие представители Аппарата Уполномоченного при Президенте Российской
            Федерации по правам ребёнка, Национальной ассоциации семейных медиаторов, региональной общественной
            организации «Объединение многодетных семей города Москвы», центров бесплатной юридической помощи.
            <p></p>
            С докладом о деятельности отдела медиации Центра защиты прав и интересов детей выступила аналитик II
            категории отдела Жодзишская Лидия Николаевна, рассказав про меры, которые предпринимает Центр для
            восстановления детско-родительских отношений и защиты интересов детей, а также о полезных материалах,
            размещенных на информационных ресурсах Центра. Также Лидия Николаевна отметила, что повышение
            осведомлённости о медиативных технологиях может помочь сторонам конфликта самостоятельно разрешить его,
            поэтому важно проводить просветительские и образовательные мероприятия как для родительского сообщества, так
            и для детей, подростков и молодёжи.
            <p></p>
            На Круглом столе участники обсудили значение медиации в семейных спорах, основные категории судебных споров,
            с которыми сталкиваются многодетные семьи Москвы, вопросы поддержки и сопровождения приёмных семей, оказания
            юридической помощи людям в трудной жизненной ситуации, опыт системного внедрения медиации в работу
            учреждений социальной сферы Москвы.
            <p></p>
            Также были освещены вопросы обеспечения доступности семейной медиации в России, возможности оказания
            качественной и полномасштабной помощи на безвозмездной основе адвокатами, медиаторами и психологами
            совместно по семейным спорам и делам о защите законных прав и интересов детей.
            <p></p>
            Подробнее о мероприятии можете прочитать на сайте <a style={{color: "blue"}}
            href="https://gra.ru/2024/05/17/г-б-мирзоев-открыл-круглый-стол-по-воп-2/">Гильдии российских адвокатов</a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News210524;