import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/MonitoringMediacia.jpg";
import YouTube from "react-youtube";
import file from "../../assets/Files/Materialy-vebinara.zip"

const MonitoringMediacia = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «О проведении мониторинга служб медиации и примирения на территории субъектов Российской
            Федерации»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                26 марта 2021 г. состоялся вебинар «О проведении мониторинга служб медиации и примирения на территории
                субъектов Российской Федерации», во время которого сотрудниками ФГБУ «Центр защиты прав и интересов
                детей» были озвучены цели и задачи мониторинга и подробно изложен порядок заполнения информационной
                анкеты. Во время мероприятия у всех участников была возможность задать вопросы в чате и услышать
                комментарии ведущих.
            </p>
            <p>
                Начиная с 2020 года ФГБУ «Центр защиты прав и интересов детей» по поручению Министерства просвещения
                Российской Федерации анализирует деятельностью служб медиации (примирения) в субъектах РФ с целью
                обобщения и анализа опыта практического применения медиации и технологий восстановительного правосудия в
                отношении несовершеннолетних в образовательных организациях. Исследование проводится во исполнение
                пункта 5 Межведомственного плана комплексных мероприятий по реализации Концепции развития сети служб
                медиации в целях реализации восстановительного правосудия в отношении детей, в том числе совершивших
                общественно опасные деяния, но не достигших возраста, с которого наступает уголовная ответственность в
                Российской Федерации, до 2025 года, утвержденного Правительственной комиссией по делам
                несовершеннолетних и защите их прав 25 сентября 2019 г.
            </p>
            <p>
                Важность и необходимость данных мониторинга для использования в дальнейшей работе по профилактике
                деструктивного поведения несовершеннолетних осветила в своем выступлении заместитель директора ФГБУ
                «Центр защиты прав и интересов детей» Артамонова Елена Геннадьевна. Заева Ольга Вячеславовна, начальник
                отдела организационно-методического сопровождения развития системы профилактики девиантного поведения
                несовершеннолетних, рассказала о порядке регистрации пользователей и технических вопросах заполнения
                форм мониторинга на портале Центра.
            </p>
            <p>На вебинар зарегистрировались и приняли участие 257 человек из 67 субъектов Российской Федерации</p>
            <YouTube style={{textAlign: "center"}} videoId="7o_E6_mUIjg"/>
            <p></p>
            <div className={s.blueButtonMediaciaBase}>
                <a href={file}>Материалы</a>
            </div>
            <br/>
        </div>
    </div>
}


export default MonitoringMediacia