import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News2204242.jpeg"
import img2 from "../../assets/News/News2204242_1.jpeg"

const News2204242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Обучение специалистов социальной сферы Москвы 16 апреля 2024 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            16 апреля 2024 года в государственном автономном учреждении «Институт дополнительного профессионального
            образования работников социальной сферы» Департамента труда и социальной защиты населения города Москвы
            проходило обучение специалистов социальной сферы по программе дополнительного профессионального образования
            «Медиация в социальной сфере».
            <p></p>
            В реализации программы принял участие начальник отдела медиации Центра защиты прав и интересов детей Козлов
            Анатолий Андреевич, проведя занятия по теме «Школьные службы медиации (примирения): принципы работы и
            взаимодействие».
            <p></p>
            На занятиях было рассмотрено нормативное регулирование служб в системе образования. Подробно обсуждались
            принципы медиативного и восстановительного подходов и то, как эти принципы реализуются в работе с
            обучающимися. Студентами был сформирован портрет школьного медиатора, какими качествами и навыками должен
            обладать этот специалист, почему это важно. Важной частью занятия стало рассмотрение межведомственного
            взаимодействия семейных центров и школьных служб медиации и примирения.
            <p></p>
            Подробней о школьной медиации на территории города Москвы вы можете узнать на сайте Городской службы
            примирения: <u><a href="https://gsp.gppc.ru">https://gsp.gppc.ru</a></u>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News2204242;