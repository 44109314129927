import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News21112023.jpg"

const News201123 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги Всероссийского конкурса региональных моделей системы профилактики безнадзорности и
            правонарушений несовершеннолетних в 2023 году
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Всероссийский конкурс региональных моделей системы профилактики безнадзорности и правонарушений
            несовершеннолетних (далее – Конкурс) проводится
            в 2023 году в соответствии с пунктом 30 плана мероприятий на 2021 – 2025 годы
            по реализации Концепции развития системы профилактики безнадзорности и правонарушений несовершеннолетних на
            период до 2025 года, утвержденного распоряжением Правительства Российской Федерации от 22 марта 2017 г. №
            520-р, и направлен на выявление лучших региональных практик решения задач профилактики безнадзорности и
            правонарушений несовершеннолетних, их тиражирование в субъектах Российской Федерации.
            <p></p>
            Задачей конкурса является отбор лучших региональных моделей системы профилактики для последующего
            тиражирования положительного опыта в субъектах Российской Федерации, а также привлечение внимания высших
            исполнительных органов субъектов Российской Федерации, образовательных и общественных организаций, научного
            сообщества к решению актуальных вопросов профилактики безнадзорности
            и правонарушений несовершеннолетних.
            <p></p>
            Конкурс проводился в заочной форме в период с 20 сентября по 20 ноября 2023 года. В состав Экспертного
            совета, утвержденного 20 октября 2023 г., вошли 19 экспертов, из них в качестве членов – 16 человек, а также
            председатель, заместитель председателя и секретарь совета.
            <p></p>
            На конкурс было подано 35 заявок, три заявки были отклонены в связи
            с несоответствием условиям Положения Конкурса, для оценки членам Экспертного совета было направлено 32
            комплекта материалов от 32 субъектов Российской Федерации.
            <p></p>
            Итоги Конкурса подведены в заочном формате.
            <p></p>
            Результаты оценки конкурсных материалов каждого из членов Экспертного совета суммированы и определена сумма
            баллов, набранная каждым Участником. Все оценки внесены в Сводный протокол, который был направлен всем
            членам Экспертного совета.
            <p></p>
            Согласно Положению Конкурса победителем признается Участник, набравший наибольший суммарный балл. Призерами
            Конкурса признаются Участники, занявшие второе и третье место по количеству суммарных баллов. При равенстве
            суммарных баллов победителем и (или) призером конкурса может быть признано несколько Участников.
            <p></p>
            В соответствии со Сводным протоколом Победителем Всероссийского конкурса региональных моделей системы
            профилактики безнадзорности и правонарушений несовершеннолетних признано Управление по социальной и
            демографической политике Правительства Ярославской области, набравшее 706 баллов, призерами конкурса,
            занявшие второе и третье место соответственно, признаны Участники Конкурса – Аппарат Губернатора
            Ямало-Ненецкого автономного округа (685 баллов) и Правительство Красноярского края (682 балла).
            <p></p>
            <strong><a href="/vserosconc">Узнать подробнее</a></strong>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News201123;