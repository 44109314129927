import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News100321 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ в феврале
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В феврале прошли первые двухдневные мероприятия по сопровождению служб медиации Абаканского, Астраханского и
            Известковского СУВУ, которые включали групповые консультации специалистов служб и интерактивные занятия с
            воспитанниками.
            <p></p>
            Сотрудники рассказали об актуальном состоянии служб, поговорили о разнице организации работы в открытом и
            закрытом СУВУ (в прошлом году Абаканское и Известковское перешли в закрытый тип), обменялись опытом
            конструктивного взаимодействия с другими службами и подразделениями СУВУ.
            <p></p>
            Обучающиеся проанализировали эмоциональное состояние сторон в конфликте и разобрали предложенные
            тематические кейсы, попробовав себя как в роли участников спора, так и в роли третьей нейтральной стороны.
            <p></p>
            Занятия прошли в конструктивном ключе и атмосфере доброжелательности и сотрудничества.
            <p></p>
            На март запланированы мероприятия в Ишимбайском, Рефтинском, Куртамышском, Калтанском, Каргатском, Омском,
            Орловском и Мончегорском СУВУ.
        </div>
        <ToNews/>
    </div>
}


export default News100321;