import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const ISNPRVP2 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.newHeader}>
            <h2>
                II. Развитие эффективной модели системы профилактики безнадзорности и правонарушений несовершеннолетних
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>
            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>
                <p>Развитие эффективной модели системы профилактики безнадзорности и правонарушений несовершеннолетних
                    принадлежит к числу основных задач российского государства. Актуальной является проблема поиска
                    более совершенных мер превенции преступности и борьбы с правонарушениями среди
                    несовершеннолетних.</p>
                <p>Современные эффективные модели системы профилактики безнадзорности и правонарушений
                    несовершеннолетних должны основываться на личностно ориентированном и системном подходах.
                    Профилактическая работа должна быть направлена прежде всего на укрепление института семьи,
                    возрождение и сохранение духовно-нравственных традиций семейных отношений, развитие программ
                    профилактики семейного неблагополучия, повышение уровня жизни.</p>
                <p>Меры профилактического воздействия должны предусматривать раннее предупреждение правонарушений,
                    непосредственное предупреждение правонарушений и предупреждение повторных правонарушений.</p>
                <p>Раннее предупреждение правонарушений несовершеннолетних должно обеспечивать создание благоприятных
                    условий для их социального развития, раскрытие и усиление их положительных личностных ресурсов до
                    возникновения реальной угрозы совершения ими правонарушений. Раннее предупреждение правонарушений
                    несовершеннолетних должно предполагать усиление роли института семьи, повышение эффективности
                    государственной поддержки семьи, развитие единой образовательной (воспитывающей) среды, а также меры
                    по раннему выявлению и профилактике девиантного поведения несовершеннолетних. Непосредственное
                    предупреждение правонарушений и предупреждение повторных правонарушений несовершеннолетних должно
                    включать организацию работы с несовершеннолетними, уже нарушившими предписания закона, оказание
                    помощи несовершеннолетним в позитивном изменении социальной микросреды, в которой они находятся, и
                    их самих.</p>
                <p><strong>Результаты реализации Концепции за 2017 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-p.5.pdf">Аналитическая справка
                    об обеспечении организационно-методической поддержки детских и молодежных
                    социально ориентированных объединений (п. 5)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-p.6.pdf">Аналитическая справка
                    о разработке и реализации программ поддержки молодежных и подростковых
                    общественных организаций, волонтерского движения, программ по трудоустройству несовершеннолетних,
                    развитию спорта и пропаганде здорового образа жизни, духовно-нравственному воспитанию подрастающего
                    поколения (п. 6)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-p.7.pdf">Аналитическая справка
                    о реализации региональных комплексов мер по организации продуктивной
                    социально значимой деятельности несовершеннолетних, находящихся в конфликте с законом (п. 7)</a></p>
                <p><strong>Результаты реализации Концепции за 2018 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/p9.pdf">Разработка и реализация комплексных
                    социально-психологичских программ, направленных на
                    реабилитацию и ресоциализацию несовершеннолетних правонарушителей, в том числе состоящих на учете в
                    учреждениях уголовно-исолнительной системы (п. 9)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Metodicheskie-rekomendatsii_p.-14.pdf">Методические
                    рекомендации по совершенствованию деятельности образовательных организаций по
                    формированию законопослушного поведения несовершеннолетних (п. 14)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/p15.pdf">Развитие деятельности центров
                    психолого-педагогической, медицинской и социальной помощи,
                    психолого-медико-педагогических комиссий в части профилактики безнадзорности и правонарушений
                    несовершеннолетних (п. 15)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Informatsionno-metodicheskie-materialy-p.16.pdf">Информационно-методические
                    материалы по организации правового просвещения обучающихся, родителей,
                    педагогических работников, специалистов, работающих с несовершеннолетними в субъектах Российской
                    Федерации (п. 16)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/p17.pdf">Методические рекомендации по
                    совершенствованию деятельности специальных учебно-воспитательных
                    учреждений открытого и закрытого типа, в том числе в части развития и внедрения современных
                    реабилитационных технологий (п. 17)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/p18.pdf">Методические рекомендации по привлечению
                    организаций, осуществляющих отдых и оздоровление детей,
                    для профилактики правонарушений несовершеннолетних в период каникул (п. 18)</a></p>
                <p><strong>Результаты реализации Концепции за 2019 год</strong></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-o-razrabotke-i-realizatsii-programm-podderzhk.pdf">Аналитическая
                    справка о разработке и реализации программ поддержки молодежных и подростковых
                    общественных организаций, волонтерского движения, программ по трудоустройству несовершеннолетних,
                    развития спорта и пропаганды здорового образа жизни, духовно-нравственного воспитания подрастающего
                    поколения (п. 6)</a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-o-razvitii-v-subektah-Rossii-skoi-Federatsii-.pdf">Аналитическая
                    справка о развитии в субъектах Российской Федерации учреждений системы профилактики
                    безнадзорности и правонарушений несовершеннолетних, в том числе в части создаваемых органов и
                    учреждений такой системы (п. 21)</a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-o-provedenii-vserossii-skih-soveshhanii-po-vopros.pdf">Аналитическая
                    справка о проведении всероссийских совещаний по вопросам организации и
                    обеспечения
                    деятельности комиссий по делам несовершеннолетних и защите их прав, а также о проведении совещаний,
                    конференций, семинаров и круглых столов по актуальным вопросам профилактики безнадзорности и
                    правонарушений несовершеннолетних (п. 24, п. 25)</a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-ob-organizatsii-meropriyatii-napravlennyh-na-povyshenie-.pdf">Аналитическая
                    справка об организации мероприятий, направленных на повышение
                    профессионального
                    уровня, приобретения новой квалификации руководителями и специалистами органов и учреждений системы
                    профилактики безнадзорности и правонарушений несовершеннолетних, в том числе проведение
                    межведомственных обучающих семинаров с участием профильных некоммерческих организаций (п. 30)</a>
                </p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka-ob-organizatsii.pdf">Аналитическая справка об организации и обеспечении мониторинга эффективности реализации
                    региональных программ по профилактике безнадзорности и правонарушений несовершеннолетних и планов их
                    реализации (п. 35)</a></p>
                <p><strong>Результаты реализации Концепции за 2020 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/analiticheskaya-spravka_-punkt-6.docx">Материалы по разработке и реализации программ поддержки молодежных и подростковых
                    общественных
                    организаций пункт 6</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-spravka_punkt-9.docx">Материалы по разработке и реализации комплексных социально-психологических программ,
                    направленных
                    на реабилитацию и ресоциализацию несовершеннолетних правонарушителей, в том числе стоящих на учете в
                    учреждениях уголовно-исполнительной системы пункт 9.</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/MEDIATSIYA_punkt-10.docx">Методические материалы по обеспечению организационно-методической поддержки развития служб
                    медиации пункт 10</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/KDN_punkt-11.docx">Информационно-методические материалы по обеспечению деятельности комиссий по делам
                    несовершеннолетних и защите их прав пункт 11</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Mezhved_punkt-12.docx">Методические материалы по совершенствованию межведомственного взаимодействия органов и
                    учреждений
                    системы профилактики безнадзорности и правонарушений несовершеннолетних пункт 12</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/8-04-20-Reestr-kabinetov-besplatnoj-pravovoj-pomoshhi-dlya-nesovershennol_wIjKerQ.pdf">Реестр кабинетов бесплатной правовой помощи для детей и подростков, их семей на базе
                    образовательных организаций высшего образования, осуществляющих обучение по специальности и (или)
                    направлению подготовки высшего образования «юриспруденция» (п. 22)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Reestr-resursnyh-tsentrov-p.-23.xlsx">Реестр ресурсных центров (п. 23)</a></p>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default ISNPRVP2