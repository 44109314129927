import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News26062023.jpg"

const News26062023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Под эгидой Минпросвещения прошла стратегическая сессия “Наставничество в России как вызов времени”
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p><strong>25 мая в Государственном университете просвещения прошла стратегическая сессия “Наставничество в
                России как вызов времени”. В рамках мероприятия участникам удалось обсудить ключевые вопросы, связанные
                с разработкой стратегических инициатив в сфере наставничества. Это мероприятие стало первым, где
                представители из разных сфер выступили в качестве “разработчиков” Профессионального стандарта и других
                правотворческих инициатив.</strong></p>
            <p>В обращении к участникам стратегической сессии директор Департамента государственной политики в сфере
                среднего профессионального образования и профессионального обучения Минпросвещения России <strong>Виктор
                    Неумывакин</strong> отметил, что сегодня важно перейти от внутренних обсуждений к общественным, где
                будут
                собираться представители из государственного, корпоративного и общественного секторов. А именно,
                необходимо перейти от разработок и улучшений внутренних документов, регулирующих наставничество в каждой
                конкретной организации, к систематизации и обобщению всего существующего опыта.</p>
            <p>Мероприятие собрало на своей площадке более 90 участников, включая представителей крупнейших российских
                предприятий и образовательных и общественных организаций: РЖД, Русгидро, Русагро, Роскомос, Северсталь,
                Росатом, Сбер, Татнефть, VK, Сколково, Национальное агентство развития квалификаций, Российский Союз
                Молодёжи и других.</p>
            <p>В ходе стратегической сессии были озвучены подходы к тому, как должен выглядеть институт наставничества в
                России. С одной стороны, требуется учитывать специфику наставничества в каждой из сфер, а с другой
                стороны, определить и выделить конкретику, что именно объединяет наставников с производства, в
                образовании и социальной сфере.</p>
            <p>Стратегическая сессия показала, что однозначно ответить на эти вопросы можно только через непрерывное
                взаимодействие и открытый диалог представителей государства, реального сектора экономики, образования и
                социальной сферы.</p>
            <p>Участники обозначили ключевые вопросы, связанные с разработкой Профстандарта “Наставник”.</p>
            <p>Например, необходимо уделить большее внимание проблемам определения функционала и вопросам подготовки
                наставников, а также разделить понятия “педагога” и “наставника”. Профстандарт “Наставник” должен иметь
                уровневую структуру и учитывать квалификацию наставника. При описании трудовых функций важно
                предусмотреть разработку программы наставничества.</p>
            <p>Наставничество обсуждалось не только на рабочем месте, в образовании и социальной сфере, но и в
                волонтерской сфере, которое реализуется не в рамках трудовых отношений.</p>
            <p>Все озвученные мнения и предложения разработчики учтут при подготовке Профстандарта “Наставник“.</p>
            <p>“Наставничество обсуждается уже не первый год на различных площадках. Многие компании и федеральные
                органы исполнительной власти серьезно преуспели в этом вопросе, создав стройную систему наставничества.
                В ходе сегодняшней стратегической сессии перед нами стоит практическая задача. Мы должны договориться о
                конкретных моментах, которые мы предпринимаем в подготовке документов по наставничеству с юридической и
                практической точки зрения”, — прокомментировал <strong>Виктор Неумывакин</strong>, директор Департамента
                государственной
                политики в сфере среднего профессионального образования и профессионального обучения Минпросвещения
                России.</p>
            <p>“Сегодня наш университет станет площадкой для диалога и объединения усилий по созданию будущего образа
                системы наставничества. В процессе нашей стратегической сессии мы сможем перейти от общих обсуждений
                непосредственно к разработке конкретных предложений”, — считает <strong>Ирина Кокоева</strong>,
                Исполняющая обязанности
                ректора Федерального государственного бюджетного образовательного учреждения высшего образования
                “Государственный университет просвещения”.</p>
            <p>“Наставничество как социальное явление и профессиональная технология охватывает множество сфер. Наша
                задача на сегодня — обсудить, какие задачи стоят перед системой наставничества и каким содержанием
                необходимо наполнить нормативные документы, чтобы их решить. Один из этих документов — профстандарт. Он
                легитимизирует для организации найм сотрудника на должность наставника“, — прокомментировала <strong>Любовь
                    Духанина</strong>, Председатель Совета по профессиональным квалификациям в сфере образования.</p>
            <p><strong>Организаторы</strong></p>
            <p><strong>Государственный университет просвещения </strong></p>
            <p>Государственный университет просвещения – один из старейших университетов, входит в топ-100 лучших вузов
                страны (65 место) и топ-20 – по подготовке лингвистов и переводчиков. В Университете обучается около 13
                тысяч студентов со всей России и других стран. Вуз реализует более 200 образовательных программ
                (педагогика, естественные науки, медицина, история, филология, экономика и управление, юриспруденция,
                изобразительное искусство и дизайн, физическая культура, и другие), внедряет инновационные технологии в
                учебный процесс и развивает цифровую среду.</p>
        </div>
        <ToNews/>
    </div>
}


export default News26062023;