import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import adobe from "../../../assets/Behavior/adobe.png"
import presen from "../../../assets/Behavior/presen.jpg"


const VichMonitoring = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Мониторинг федеральных и региональных программ
                </h2>
            </div>
            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <table>
                    <tr>
                        <th>Бюро ЮНЕСКО в г. Москве<br/>
                            Фонд ООН в области народонаселения
                        </th>
                    </tr>
                    <tr style={{height: "10px"}}>
                        <th></th>
                    </tr>
                    <tr>
                        <td>
                            <p>Разработано при участии Министерства образования и науки Российской Федерации и
                                Федерального государственного научного учреждения «Центр исследования проблем
                                воспитания, формирования здорового образа жизни, социально-педагогической поддержки
                                детей и молодежи»</p>
                            <p><strong>Мониторинг федеральных и региональных программ, направленных на профилактику
                                ВИЧ-инфекции
                                в системе образования.</strong> — М., 2007. — 62 с. Авторский коллектив: д.мед.н., проф.
                                В. Н.
                                Касаткин, к.п.н. И. А. Савченко, Е. И. Рассказова, Н. Г. Черкасенко, к.мед.н. А. А.
                                Гериш, М.П. Котельникова</p>
                            <p>Рецензент: д.п.н., проф. Е.Б. Евладова</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            В пособии рассматриваются результаты мониторинга федеральных и региональных программ,
                            направленных на профилактику ВИЧ-инфекции в системе образования, проведенного в 2006 году.
                        </td>
                    </tr>
                    <tr style={{height: "10px"}}>
                        <th></th>
                    </tr>
                    <tr>
                        <td style={{height: "40px"}}>
                            <a style={{fontWeight: "bold"}}
                               href="https://fcprc.ru/media/media/behavior/posobie_unesco.doc">(Читать, скачать
                                полностью)…</a> в формате MS Word
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>


    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default VichMonitoring