import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"


const PageMeshdSotr = ({url, header, children, style = {}}) => {
    return <div className={s.newHeader}>


        <h2>
            Международное сотрудничество
        </h2>

        <p style={{
            color: "#212529",
            lineHeight: "24px",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "600"
        }}>
            <div style={{
                maxWidth: "700px",
                textAlign: "left",
                color: "#3A4663",
                fontSize: "17px",
                fontFamily: "Proxima Nova Lt"
            }}>
                Цель – обеспечение международных обязательств Российской Федерации в рамках Гаагских
                конвенций 1980 г. и 1996 г., Конвенции Совета Европы о защите детей от сексуальной
                эксплуатации и сексуального насилия и Конвенции ООН о правах ребенка.
            </div>
            <div className={s.btnMeshdSotr}>
                <a href="https://edu.gov.ru/activity/main_activities/child-protection/international-conflicts">
                    Официальный сайт Центрального органа РФ
                </a>
            </div>
        </p>
        <p align="left" style={{
            alignItems: "start",
            marginTop: "30px",
            fontFamily: "Proxima Nova Lt",
            fontSize: "17px",
            lineHeight: "1.5"
        }}>
            <div class="wpb_wrapper">
                <p><span style={{color: "#455476"}}>Сопровождение деятельности Центрального органа Российской Федерации по Гаагским конвенциям 1980 г. и 1996 г.</span>
                </p>
                <ul style={{listStyleType: "circle", color: "#212529"}} className={s.LiMeshSotr}>
                    <li>сопровождение трансграничных семейных споров, находящихся на рассмотрении Центрального
                        органа Российской Федерации
                    </li>
                    <li>содействие воссоединению детей с законными представителями
                        сопровождение комиссии Минпросвещения России по рассмотрению дел в рамках гаагских
                        конвенций
                    </li>
                    <li>проведение процедур медиации по трансграничным семейным спорам</li>
                </ul>

                <p style={{marginTop: "30px"}}><span style={{color: "#455476"}}>Организация и проведение конференций, семинаров и других мероприятий по вопросам защиты прав детей</span>
                </p>
                <ul style={{listStyleType: "circle", color: "#212529"}}>
                    <li>научно-методический семинар по реализации Гаагских конвенций 1980 г. и 1996 г.</li>
                </ul>

            </div>
        </p>

    </div>


}


export default PageMeshdSotr