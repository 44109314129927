import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SupportOfMediationServicesApril2022_1.jpg"
import img2 from "../../assets/News/SupportOfMediationServicesApril2022_2.jpg"
import img3 from "../../assets/News/SupportOfMediationServicesApril2022_3.jpg"
import img4 from "../../assets/News/SupportOfMediationServicesApril2022_4.jpg"

const SupportOfMediationServicesApril2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в апреле 2022 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                Отдел медиации ФГБУ «Центр защиты прав и интересов детей» в апреле провел ряд мероприятий по
                сопровождению служб медиации в специальных учебно-воспитательных учреждениях, подведомственных
                Минпросвещения России (СУВУ). Мероприятия включали групповые консультации специалистов служб и
                интерактивные занятия с обучающимися.
            </p>
            <p>
                Занятия и консультации прошли в дистанционном формате 7 – 8 апреля (Неманское СУВУ, Щекинское СУВУ,
                Каргатское СУВУ), 14 – 15 апреля (Астраханское СУВУ, Раифское СУВУ, Рефтинское СУВУ).
            </p>
            <p>
                На консультациях с сотрудниками служб медиации СУВУ обсуждалось текущее состояние службы и планы на 2022
                год. В практической части встречи анализировалась конфликтная ситуация между учеником и учителем,
                рассматривались варианты урегулирования и профилактики подобных случаев.
            </p>
            <p>
                Интерактивные занятия с обучающимися из разных СУВУ включали в себя обсуждение причин конфликта и его
                составных частей. Участники встреч разобрали ряд тематических кейсов, предлагали свои варианты их
                решения и оценивали варианты, озвученные сверстниками. Занятия прошли в доверительной и комфортной
                атмосфере.
            </p>
            <p>
                Продолжение мероприятий запланировано на май 2022 года.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <ToNews/>
    </div>
}


export default SupportOfMediationServicesApril2022;