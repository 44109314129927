import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/Plen2020.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import file1 from "../../../assets/Files/Plen2020.pdf"
import file2 from "../../../assets/Files/Nelyubov-S.A..pptx"
import YouTube from "react-youtube";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const Plen2020 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Пленарное заседание VII Всероссийского совещания КДН и ЗП
                </h2>
            </div>

            <div>
                <img src={logo}/>
            </div>
            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><strong>
                    Пленарное заседание VII Всероссийского совещания по вопросу организации деятельности комиссий по
                    делам несовершеннолетних и защите их прав на тему: «Инновационный подход к развитию системы
                    профилактики безнадзорности и правонарушений несовершеннолетних»
                </strong></p>
                <p>Выступления:</p>
                <p>
                    <strong>Фальковская Лариса Павловна</strong>, директор Департамента государственной политики в сфере защиты прав
                    детей Минпросвещения России
                </p>
                <p><strong>Гордеева Марина Владимировна</strong>, председатель правления Фонда поддержки детей, находящихся в трудной
                    жизненной ситуации</p>
                <p>
                    <strong>Капинус Оксана Сергеевна</strong>, ректор федерального государственного казенного образовательного учреждения
                    высшего образования «Университет прокуратуры Российской Федерации»
                </p>
                <p>
                    <strong>Ильгова Екатерина Владимировна</strong>, ректор федерального государственного бюджетного образовательного
                    учреждения высшего образования «Саратовская государственная юридическая академия»</p>
                <p>
                    <strong> Реан Артур Александрович</strong>, руководитель Центра социализации, семьи и профилактики асоциального
                    поведения федерального государственного бюджетного образовательного учреждения высшего образования
                    «Московский педагогический государственный университет», академик Российской академии образования
                </p>
                <p>
                    <strong>Собкин Владимир Самуилович</strong>, руководитель Центра социологии образования федерального государственного
                    бюджетного научного учреждения «Институт управления образованием Российской академии образования»
                </p>
                <p>
                    <strong>Попова Ирина Николаевна</strong>, ведущий научный сотрудник Научно-исследовательского центра социализации и
                    персонализации образования детей Федерального института развития образования федерального
                    государственного бюджетного образовательного учреждения высшего образования «Российская академия
                    народного хозяйства и государственной службы при Президенте Российской Федерации»
                </p>
                <p>
                    <strong>Ласточкин Василий Александрович</strong>, президент Благотворительного фонда по оказанию помощи лицам,
                    отбывающим наказание в исправительных учреждениях «Забота», руководитель проекта автономной
                    некоммерческой организации «Агентство стратегических инициатив по продвижению новых проектов»
                </p>
                <p>
                    <strong>Нелюбов Сергей Александрович</strong>, заместитель Губернатора Новосибирской области, председатель комиссии
                    по делам несовершеннолетних и защите их прав на территории Новосибирской области
                </p>
                <p><a href={file1}>Презентационные материалы</a></p>
                <p><a href={file2}>Презентация Нелюбов С.А.</a></p>
            </p>
            <YouTube id="L9vBm_NvblQ"/>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default Plen2020