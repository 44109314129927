import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";

const hrefsArr = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},
]
const FilesKDNReestr = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div>
                <h1 style={{
                    fontWeight: "normal",
                    marginTop: "50px",
                    color: "#3a4663",
                    fontSize: "30px",
                    fontFamily: "Proxima Nova Lt",
                    textAlign: "left"
                }}>
                    Реестр НКО
                </h1>
                <FileLeftLine text={respData[57]?.description} refers={respData[57]?.file} ico={ico}/>
            </div>
            <div>
                <Hrefs hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </>
}
export default FilesKDNReestr