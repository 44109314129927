import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News280824.jpg"
import img2 from "../../assets/News/News280824_2.png"

const News280824 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Семинар «Реализация Гаагских конвенций 1980 г. и 1996 г.» 16 августа 2024 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            16 августа 2024 г. состоялся семинар «Реализация Гаагских конвенций 1980 г. и 1996 г.».
            <p></p>
            На семинаре были освещены вопросы реализации Гаагской конвенции
            о гражданско-правовых аспектах международного похищения детей от 25 октября 1980 г. и Гаагской конвенции о
            юрисдикции, применимом праве, признании, исполнении и сотрудничестве в отношении родительской
            ответственности и мер по защите детей от 19 октября 1996 г., а также возможностей и рисков применения
            процедуры медиации в международных семейных спорах.
            <p></p>
            Организаторами семинара выступили Минпросвещения России совместно
            с ФГБУ «Центр защиты прав и интересов детей».
            <p></p>
            В мероприятии приняли участие более 160 человек, в том числе представители судейского и научного сообществ,
            органов исполнительной власти субъектов Российской Федерации, осуществляющих управление в сфере опеки
            и попечительства в отношении несовершеннолетних граждан.
            <p></p>
            С приветственным словом и докладом «О реализации Гаагских Конвенций Центральным органом Российской Федерации
            в условиях современной международной повестки дня» выступила директор
            Департамента государственной политики в сфере защиты прав детей Минпросвещения России Фальковская Лариса
            Павловна.
            <p></p>
            Также были заслушаны доклады представителей Центрального органа Республики Сербия, Московского
            государственного юридического университета имени О.Е. Кутафина (МГЮА), Московского государственного
            психолога-педагогического университета (МГППУ), «Центра медиации и социально-правовой помощи» ГБУ города
            Москвы Ресурсного центра по вопросам опеки и попечительства «Содействие» Департамента труда и социального
            защиты населения города Москвы.
            <p></p>
            От Центра защиты прав и интересов детей приняли участие аналитик
            I категории, международный медиатор Тюльканов Сергей Леонидович
            с докладом «Особенности коммуникации родителей в трансграничных семейных спорах и возможности их учета в
            медиации» и аналитик II категории отдела медиации Жодзишская Лидия Николаевна с темой «Право
            и способность ребёнка участвовать в семейном диалоге».
            <p></p>
            При подведении итогов семинара был сделан акцент на необходимости совершенствования методик и механизмов
            проведения медиации
            в трансграничных семейных спорах и освещения системы работы медиации, разрешении семейных споров в
            досудебном порядке, задействовании в этом вопросе как можно большего количества органов, в том числе органов
            опеки
            и попечительства.
            <p></p>
            Заместитель директора Департамента государственной политики в сфере защиты прав детей Минпросвещения России
            Аккуратова Анастасия Павловна выразила готовность продолжать работу в этом направлении.
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News280824;