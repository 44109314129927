import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News08052022.jpg"

const News08052022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Поздравление Министра просвещения Российской Федерации Сергея Кравцова с Днем Победы
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Уважаемые ветераны, дорогие друзья!</p>
            <p>От всей души поздравляю вас с Днем Победы! Это священный для каждого жителя нашей страны праздник.</p>
            <p>Мы вспоминаем подвиги советских солдат, их стойкость и единение вокруг одной великой цели – спасти мир от
                нацизма. На протяжении десятилетий несокрушимый дух наших дорогих ветеранов, их павших товарищей,
                узников концлагерей, тружеников тыла служит примером подрастающим поколениям и вызывает у них
                безусловное чувство гордости. Это чувство рождается в семье и затем крепнет на школьной скамье, в
                колледжах и институтах.</p>
            <p>Наши учителя сегодня, как и их предшественники в годы испытаний, словом и делом поддерживают своих
                учеников, воспитывают ребят, уважающих и оберегающих свое прошлое, помнящих о незабвенных подвигах, о
                победах и сложном пути восстановления нашей Родины, а также, как поется в ставшей народной песне, о
                пыльных дорогах Великой Отечественной войны.</p>
            <p>При этом мы стремимся без прикрас рассказывать о том, что сейчас происходит в мире. Нет оправданий тем,
                кто пытается реанимировать националистические идеи, фактически взрастить поколения неонацистов. И наш
                долг – защитить историческую правду о Великой Отечественной войне!</p>
            <p>Дети очень чутко воспринимают простые человеческие ценности: справедливость, честность, отвагу, доброту.
                И 9 мая по их лицам отчетливо видно, как они искренне и с глубокой признательностью смотрят на наших
                ветеранов. Мы помним и гордимся каждым воином, защищавшим наше будущее, склоняем головы и отдаем дань
                памяти умершим. Спасибо вам за возможность мечтать, строить свое будущее. В нашей стране есть все
                условия, чтобы шаг за шагом развиваться, получать лучшее образование, воспитывать в себе таланты, а в
                дальнейшем направить созидательный труд на благо страны.</p>
            <p>С праздником! Крепкого здоровья, благополучия и новых свершений!</p>
            <p>Министр просвещения Сергей Кравцов</p>
        </div>
        <ToNews/>
    </div>
}


export default News08052022;