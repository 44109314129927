import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {DownOutlined} from "@ant-design/icons"
import Falldown, {Falldown2} from "../FalldownList/Falldown";
import {FileLeftLineKDN, HrefsKDN} from "./FilesKDNNews";

export const hrefsArrKDN = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", to: "/kdnivents"},
    {text: "История создания КДН и ЗП", to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", to: "/kdnInternational"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},
]
const FilesKDNGos = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    console.log(respData)
    return <>
    <div className={s.filesLeftPage}>

        <div className={s.newHeader}>
            <h2>
                Правительственная КДНиЗП
            </h2>
            <Falldown header={"Планирование деятельности"} style={{width: "50wv", maxWidth: "750px"}}>
                <li>
                    <FileLeftLineKDN text={respData[95]?.description} refers={respData[95]?.file} ico={ico}
                                     styles={{width: "650px"}}/>
                </li>
                <li>
                    <FileLeftLineKDN text={respData[1]?.description} refers={respData[1]?.file} ico={ico}
                                     styles={{width: "650px"}}/>
                </li>
                <li>
                    <FileLeftLineKDN text={respData[2]?.description} refers={respData[2]?.file} ico={ico}
                                     styles={{width: "650px"}}/>
                </li>
                <li>
                    <FileLeftLineKDN text={respData[3]?.description} refers={respData[3]?.file} ico={ico}
                                     styles={{width: "650px"}}/>
                </li>
                <li>
                    <FileLeftLineKDN text={respData[4]?.description} refers={respData[4]?.file} ico={ico}
                                     styles={{width: "650px"}}/>
                </li>


            </Falldown>
            <Falldown header={"Протоколы заседаний Правительственной КДН и ЗП"}
                      style={{width: "50wv", maxWidth: "750px"}}>
                <Falldown2 header={"2021 год"} style={{width: "650px"}}>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 28 от 01.04.2021 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__28_от_01.04.2021г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 29 от 28.06.2021 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__29_от_28.06.2021г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 30 от 12.11.2021 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__30_от_12_.11.2021_г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 31 от 30.12.2021 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__31_от_30.12.2021г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>
                </Falldown2>
                <Falldown2 header={"2022 год"} style={{width: "650px"}}>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 32 от 30.03.2022 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__32_от_30.03.2022_г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 33 от 30.08.2022 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__33_от_30.08.2022_г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 34 от 07.12.2022 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__34_от_07.12.2022_г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>
                </Falldown2>
                <Falldown2 header={"2023 год"} style={{width: "650px"}}>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 2пр от 21.12.2023 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__2пр_от_21.12.2023_г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                        <p></p>
                        <FileLeftLineKDN text={"Протокол № 1пр от 10.10.2023 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/Протокол__1-пр_от_10.10.2023.pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                        <p></p>
                        <FileLeftLineKDN text={"Протокол № 2 от 07.07.2023 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/Протокол__2_от_07.07.2023.pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                        <p></p>
                        <FileLeftLineKDN text={"Протокол № 1 от 30.03.2023 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/протокол__1_от_30.03.2023_г..pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>

                </Falldown2>
                <Falldown2 header={"2024 год"} style={{width: "650px"}}>
                    <li>
                        <FileLeftLineKDN text={"Протокол № 3пр от 16.07.2024 г."}
                                         refers={"https://fcprc.ru/media/media/behavior/2_5341634028418128481.pdf"}
                                         ico={ico}
                                         styles={{width: "550px"}}/>
                    </li>

                </Falldown2>
            </Falldown>
            <Falldown header={"Положение и состав Правительственной КДНиЗП"}
                      style={{width: "50wv", maxWidth: "750px"}}>
                <li>
                    <FileLeftLineKDN text={respData[26]?.description} refers={respData[26]?.file} ico={ico}
                                     styles={{width: "650px"}}/>
                </li>
                <li>
                    <FileLeftLineKDN text={"Состав Правительственной КДН и ЗП"}
                                     refers={"https://fcprc.ru/media/media/behavior/Состав_ПКДН_и_ЗП__на_25_июня_2024_г.docx"}
                                     ico={ico} styles={{width: "650px"}}/>
                </li>
            </Falldown>
        </div>
        <div style={{marginTop:"-3px"}}>
            <HrefsKDN hrefsArr={hrefsArrKDN}/>
        </div>
    </div>
    </>
}

export default FilesKDNGos