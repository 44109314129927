import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const ChildDefence2023 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Всероссийская научно-практическая конференция по актуальным вопросам профилактики девиантного
                    поведения
                    несовершеннолетних «Безопасная образовательная среда: стратегии, практики, ресурсы»

                </h2>
                <p>
                    7 декабря 2023 году Минпросвещения России проводит Всероссийскую научно-практическую конференцию по
                    актуальным вопросам профилактики девиантного поведения несовершеннолетних «Безопасная
                    образовательная среда:
                    стратегии, практики, ресурсы», цель которой – обсуждение эффективных механизмов предупреждения и
                    нивелирования факторов формирования девиантного поведения несовершеннолетних в образовательной
                    среде, с
                    учетом актуальных рисков и негативных агентов социализации, на основе применения передового
                    научно-практического опыта.
                </p><p>
                Оператор Конференции – ФГБУ «Центр защиты прав и интересов детей».
            </p><p>
                В Конференции запланировано участие представителей аппарата Уполномоченного при Президенте Российской
                Федерации по правам ребенка; заинтересованных федеральных органов власти; органов и учреждений системы
                профилактики безнадзорности и правонарушений несовершеннолетних субъектов Российской Федерации; научных,
                общественных организаций; советников по воспитанию, педагогов, педагогов-психологов, социальных
                педагогов,
                специалистов служб медиации и примирения образовательных организаций.
            </p><p>
                Проведение Конференции запланировано в комбинированном формате: очном/дистанционном (пленарное
                заседание),
                дистанционном (секционная работа), дистанционном/заочном (марафон мастер-классов) и заочном (публикация
                в
                электронном сборнике, доступ к материалам Конференции).
            </p><p>
                Ссылки на доступ к мероприятиям Конференции доступны в программе Конференции.
            </p><p>
                <a className={s.concepcA}
                   href="https://fcprc.ru/media/media/behavior/Программа_конференции_Профилактика.pdf">Программа
                    конференции</a>
            </p><p>
                <a className={s.concepcA} href="https://disk.yandex.ru/d/D_TUVziMFnxb8w">Материалы конференции</a>
            </p><p>
                <a className={s.concepcA} href="https://fcprc.ru/media/media/behavior/Сертификат.pdf">Сертификат
                    участника</a></p>
                <p>
                    <a className={s.concepcA} href="https://fcprc.ru/media/media/behavior/Отчет_26.pdf">Отчет о
                        реализации пункта 26 плана мероприятий на 2021–2025 годы по реализации Концепции развития
                        системы профилактики безнадзорности
                        и правонарушений несовершеннолетних на период до 2025 года, утвержденной распоряжением
                        Правительства Российской Федерации
                        от 22 марта 2017 г. № 520-р</a></p>
                <p>
                    <a className={s.concepcA} href="https://fcprc.ru/media/media/behavior/Сборник_Конференции_Профилактика-2023_jzsUy6R.pdf">Сборник по
                        итогам Всероссийской научно-практической конференции</a>
                </p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>


        </div>
    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
    {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default ChildDefence2023