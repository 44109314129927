import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News08062023.png"

const News08062023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сотрудники Федерального подросткового центра приняли участие в круглом столе Министерства внутренних дел РФ
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p></p>
            6 июня сотрудники Федерального подросткового центра приняли участие в круглом столе «Совершенствование деятельности органов и учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних по снижению асоциального поведения несовершеннолетних, формированию у подростков и молодежи традиционных российских духовно-нравственных ценностей, их патриотическому воспитанию».<p></p>
            Круглый стол состоялся на площадке Министерства внутренних дел РФ. В очном формате присутствовали представители Управления Президента Российской Федерации по общественным проектам, аппарата Уполномоченного при Президенте Российской Федерации по правам ребенка, Министерства просвещения РФ, федеральных органов исполнительной власти, Генеральной прокуратуры Российской Федерации, Института изучения детства, семьи и воспитания Российской академии образования, Фонда поддержки детей, находящихся в трудной жизненной ситуации.<p></p>
          <p></p>
            Одной из тем обсуждения стала организация подростковых пространств, лагерей для подростков в конфликте с законом.
<p></p>
        </div>
        <ToNews/>
    </div>
}


export default News08062023;