import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"


const FilesAntimonopo = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/aboutus").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>

        <div className={s.filesLeftPage}>
            <div>
                <div className={s.newHeader}>
                    <h2>
                        Федеральное законодательство
                    </h2>
                </div>
                <FileLeftLine text={respData[157]?.description} refers={respData[157]?.file} ico={ico}/>
                <FileLeftLine text={respData[158]?.description} refers={respData[158]?.file} ico={ico}/>
                <FileLeftLine text={respData[159]?.description} refers={respData[159]?.file} ico={ico}/>
                <div className={s.newHeader}>
                    <h2>
                        Локальные акты ФГБУ «Центр защиты прав
                        и интересов детей»
                    </h2>
                </div>
                <FileLeftLine text={respData[160]?.description} refers={respData[160]?.file} ico={ico}/>
                <FileLeftLine text={respData[161]?.description} refers={respData[161]?.file} ico={ico}/>
                <div className={s.newHeader}>
                    <h2>
                        Риски нарушения антимонопольного законодательства и планы мероприятий по их снижению
                    </h2>
                </div>
                <FileLeftLine text={respData[165]?.description} refers={respData[165]?.file} ico={ico}/>
                <FileLeftLine text={respData[163]?.description} refers={respData[163]?.file} ico={ico}/>
                <FileLeftLine text={respData[164]?.description} refers={respData[164]?.file} ico={ico}/>
                <FileLeftLine text={respData[162]?.description} refers={respData[162]?.file} ico={ico}/>
                <div className={s.newHeader}>
                    <h2>
                        Доклады об антимонопольном комплаенсе
                    </h2>
                </div>
                <FileLeftLine text={respData[169]?.description} refers={respData[169]?.file} ico={ico}/>
                <FileLeftLine text={respData[168]?.description} refers={respData[168]?.file} ico={ico}/>
                <FileLeftLine text={respData[167]?.description} refers={respData[167]?.file} ico={ico}/>
                <FileLeftLine text={respData[166]?.description} refers={respData[166]?.file} ico={ico}/>
            </div>

        </div>
    </>
}
export default FilesAntimonopo