import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News120722.jpg"

const News120722 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            X Съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>7–8 июля 2022 г. в режиме онлайн-трансляции состоялся юбилейный X Съезд руководителей организаций для
                детей-сирот и детей, оставшихся без попечения родителей.</p>
            <p>Ключевые темы Съезда:</p>
            <p>I. Подготовка детей в организациях для детей-сирот к самостоятельному проживанию и успешной социальной
                адаптации в самостоятельной жизни:</p>
            <p>
                <ul>внедрение программ воспитания;</ul>
                <ul>психологическое сопровождение детей;</ul>
                <ul>показатели и оценка готовности и успешности выпускников в самостоятельной жизни.</ul>
            </p>
            <p>II. Реализация мероприятий по переходу до 2024 года к единой ведомственной подчиненности организаций для
                детей-сирот и органов опеки и попечительства в субъектах Российской Федерации.</p>
            <p>III. Разработка концепции единой модели организации для детей-сирот всех возрастов и особенностей
                развития.</p>
            <p>С приветствием к участникам Съезда обратились Министр просвещения Российской Федерации Сергей Сергеевич
                Кравцов, Уполномоченный при Президенте Российской Федерации по правам ребенка Мария Алексеевна
                Львова-Белова, председатель Комитета Государственной Думы по вопросам семьи, женщин и детей Останина
                Нина Александровна, и председатель Высшего Координационного совета Всероссийской общественной
                организации «Содружество выпускников детских домов «Дети всей страны» Альберт Маратович Сарбалаев.</p>
            <p>Свое приветствие участникам Съезда направила сенатор Совета Федерации Федерального собрания Российской
                Федерации Римма Федоровна Галушина.</p>
            <p>В пленарном заседании Съезда выступили директор Департамента государственной политики в сфере защиты прав
                детей Лариса Павловна Фальковская, Первый заместитель Министра труда и социальной защиты Ольга Юрьевна
                Баталина, заместитель директора Департамента медицинской помощи детям, службы родовспоможения и
                общественного здоровья Минздрава России Ольга Васильевна Чумакова, начальник отдела Управления по
                надзору за соблюдением прав и законных интересов несовершеннолетних Генеральной прокуратуры Российской
                Федерации Бугаева Дария Павловна, председатель правления Фонда поддержки детей, находящихся в трудной
                жизненной ситуации Гордеева Марина Владимировна.</p>
            <p>По актуальным вопросам, касающимся деятельности организаций для детей-сирот, на Съезде были организованы
                вебинары, по вопросам эффективного менеджмента организации для детей-сирот проведен круглый стол, также
                проведена открытая экспертная сессия с участием членов Совета руководителей организаций для детей-сирот
                и детей, оставшихся без попечения родителей, и представителей негосударственных некоммерческих
                организаций, осуществляющих деятельность в сфере защиты прав и интересов детей-сирот и детей, оставшихся
                без попечения родителей.</p>
            <p>В работе Съезда приняли участие более 1000 человек, включая представителей федеральных органов
                исполнительной власти, органов исполнительной власти субъектов Российской Федерации, руководителей
                организаций для детей-сирот, образовательных организаций высшего образования, некоммерческих
                организаций.</p>
        </div>
        <ToNews/>
    </div>
}


export default News120722;