import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/OAVVVD.JPG"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const OAVVVD = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2 >
                    «Об актуальных вопросах, возникающих в деятельности комиссий по делам несовершеннолетних и защите их
                    прав при рассмотрении дел об административных правонарушениях»
                </h2>
            </div>
            <div>
                <img src={logo}/>
            </div>

            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><a style={{fontWeight: "600"}} href="https://fcprc.ru/media/media/behavior/OAVVVDp.pdf">Программа
                    вебинара по вопросам деятельности комиссий по делам несовершеннолетних и защите их прав по теме: «Об
                    актуальных вопросах, возникающих в деятельности комиссий по делам несовершеннолетних и защите их
                    прав при рассмотрении дел об административных правонарушениях»</a></p>
                <p></p>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default OAVVVD