import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News091023.jpg"

const News091023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Утвержден новый профессиональный стандарт «Специалист органа опеки и попечительства в отношении
            несовершеннолетних»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Утвержден новый профессиональный стандарт «Специалист органа опеки и попечительства в отношении
                несовершеннолетних» приказом Министерства труда и социальной защиты Российской Федерации от 6 сентября
                2023 г. № 691н.
            </p>
            <p>
                Профессиональный стандарт «Специалист органа опеки и попечительства в отношении несовершеннолетних»
                применяется работодателями при формировании кадровой политики и в управлении персоналом, при организации
                обучения и аттестации работников, заключении трудовых договоров, разработке должностных инструкций и
                установлении систем оплаты труда с 1 января 2015 года.
            </p>
            <p>
                Актуализация профессионального стандарта вызвана изменениями в законодательство Российской Федерации в
                сфере опеки и попечительства, в общероссийские классификаторы видов экономической деятельности, занятий,
                специальностей по образованию, а также результатами анализа применения профессионального стандарта.
            </p>
            <p>
                В новый стандарт внесены изменения в группы занятий, конкретизированы требования к образованию для
                специалистов, уточнены и определены требования к опыту практической работы в состав и наименование
                обобщенных трудовых функций с перераспределением входящих в них трудовых функций. С учетом внедрения
                информационных технологий произведена корректировка формулировок при описании трудовых действий, а также
                требования к необходимым умениям и знаниям.
            </p>
            <p>
                Ознакомиться с новым профессиональным стандартом «Специалист органа опеки и попечительства в отношении
                несовершеннолетних» можно по ссылке:<br/><a
                href="https://frcopeca.ru/documents-category/akty-foiv/">https://frcopeca.ru/documents-category/akty-foiv/</a>.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News091023;