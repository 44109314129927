import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import adobe from "../../../assets/Behavior/adobe.png"
import presen from "../../../assets/Behavior/presen.jpg"


const BelozerovaVICH = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.header}>
                <h2 style={{
                    fontWeight: "normal",
                    color: "#3a4663",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "30px",
                    textAlign: "start"
                }}>
                    Белозерова М. В. Роль некоммерческих организаций в профилактике ВИЧ-инфекции
                </h2>
            </div>

            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p><strong>// Электронный журнал «Общество. Культура. Наука. Образование». 2014. Вып. 4</strong></p>
            </div>
            <div style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    <div style={{textAlign: "center"}}><strong>РОЛЬ НЕКОММЕРЧЕСКИХ ОРГАНИЗАЦИЙ
                        В ПРОФИЛАКТИКЕ ВИЧ-ИНФЕКЦИИ</strong></div>
                    <br/>
                    <strong>М.В. Белозерова,</strong> научный сотрудник ФГБНУ<br/>
                    «Центр исследования проблем воспитания,<br/>
                    формирования здорового образа жизни, профилактики наркомании,<br/>
                    социально-педагогической поддержки детей и молодежи», г. Москва<br/>
                </p>
            </div>
            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p>В России около 1,3 млн. ВИЧ-инфицированных, и, по данным Федерального центра по профилактике и борьбе
                    со СПИДом, их число быстро растет. Вирусом, вызывающим СПИД, заражен уже каждый 40-й мужчина в
                    России в возрасте 25—40 лет. Жители России стали чаще заражаться ВИЧ половым путем, это обусловило
                    переход из маргинальных групп в другие группы населения. И часто это социально благополучные люди:
                    сотрудник «Газпрома», владелец бизнеса, чиновник [9]. Именно поэтому большое значение в
                    предупреждении распространения ВИЧ принадлежит широкой многоплановой профилактической деятельности
                    среди молодых людей, организованной на межведомственном уровне. Большая роль в этом принадлежит
                    некоммерческим организациям, которые накопили достаточный опыт в области ВИЧ-профилактики.
                    Проведенный анализ представленных в сети Интернет программ и разработок некоммерческих организаций
                    (далее — НКО), посвященных профилактике ВИЧ-инфекций и противодействию немедицинского потребления
                    наркотиков, позволил выявить степень заинтересованности различных организаций в профилактической
                    работе.</p>
                <p>Так, <strong>Проект ГЛОБУС 2004—2009 гг.</strong> направлен на стимулирование эффективной
                    национальной стратегии
                    борьбы с ВИЧ/СПИДом на территории Российской Федерации и реализация основных принципов этой
                    стратегии на десяти выбранных территориях РФ (Волгоградская, Нижегородская, Оренбургская, Псковская,
                    Тверская и Томская области, Красноярский край, Республики Бурятия и Татарстан и г. Санкт-Петербург).<br/>
                    Основные задачи проекта:<br/>
                    1. Поддержка профилактических проектов, направленная на повышение информированности и снижение
                    темпов распространения ВИЧ/СПИДа среди молодежи и широких слоев населения;<br/>
                    2. Поддержка профилактических проектов, направленная на снижение темпов распространения ВИЧ/СПИДа
                    среди наиболее уязвимых социальных групп;<br/>
                    3. Пропаганда здорового образа жизни, предоставление лечения, ухода и социальной защиты, людям с
                    ВИЧ;<br/>
                    4. Содействие изменению национальной политики в области здравоохранения и созданию условий для
                    снижения негативных последствий ВИЧ/СПИДа для людей с ВИЧ, уязвимых социальных групп и всех людей,
                    так или иначе пострадавших от эпидемии.</p>
                <p>Деятельность проекта включала организацию кампаний в средствах массовой информации, развитие программ
                    охраны здоровья в школах, расширение охвата населения добровольным консультированием и тестированием
                    на ВИЧ, а также улучшения доступа к качественным презервативам [8]. Данный проект направлен на
                    поддержку профилактических мероприятий среди наиболее уязвимых к эпидемии ВИЧ-инфекции социальных
                    групп, таких как потребители инъекционных наркотиков (далее — ПИН), безнадзорные дети, осужденные,
                    лица, оказывающие сексуальные услуги за плату и мужчины, вступающие в сексуальные отношения с
                    мужчинами</p>
                <p>При реализации задачи, направленной на пропаганду здорового образа жизни, в рамках проекта
                    осуществлялась деятельность по развитию медицинских и консультационных услуг, в том числе
                    предоставление антиретровирусной терапии, поддержка ВИЧ-положительных родителей после рождения
                    ребенка, развитие паллиативной помощи при ВИЧ/СПИДе, развитие солидарности с людьми, живущими с ВИЧ.
                    Мероприятия, направленные на содействие развитию политики в области ВИЧ/СПИДа для обеспечения
                    адекватных мер в борьбе с эпидемией (в том числе информирование законодателей и лиц, принимающих
                    решения), снижение цен на препараты антиретровирусной-терапии (далее — АРВ-терапии), улучшение
                    координации и мобилизации дополнительных национальных и международных ресурсов, реализовывались в
                    соответствии с задачей содействия изменению национальной политике в области здравоохранения.</p>
                <p>В ходе реализации проекта, свыше 106 тысяч представителей уязвимых групп получили профилактические
                    услуги, лечение ВИЧ-инфекции получали свыше 3330 человек, в медицинские учреждения поставлены
                    медикаменты и оборудование на сумму свыше 13 млн. долларов, в 3 раза снижена стоимость АРВ-терапии,
                    свыше 10 млн. человек посмотрели региональное ток-шоу «Время жить», посвященное вопросам ВИЧ/СПИДа в
                    России, свыше 70 000 человек — медицинских работников, волонтеров прошли обучение по профилактике,
                    лечению, уходу и поддержки при ВИЧ/СПИДе, 90 млн. человек было охвачено информационной кампанией по
                    пропаганде менее опасного сексуального поведения и солидарности с людьми, живущими с ВИЧ, число
                    молодых людей, имеющих правильное представление о путях передачи ВИЧ и способах защиты, выросло на
                    15%, на 9% увеличилось число молодежи, использующей презервативы для защиты от заражения ВИЧ и
                    другими инфекциями, передаваемыми половым путем, 63% молодежи указали, что выбирают более безопасные
                    модели поведения под влиянием полученных знаний.</p>
                <p><strong>Комплексная программа по профилактике и лечению социально значимых заболеваний среди
                    потребителей
                    инъекционных наркотиков (Некоммерческое партнерство по поддержке социально-профилактических программ
                    в сфере общественного здоровья «ЭСВЕРО»).</strong> Издана в рамках проекта ЮНЭЙДС.<br/>
                    Программа строится на принципах интеграции в общую систему медико-социальной помощи, с целью
                    улучшения здоровья и повышения качества жизни наркопотребителя. Описывается концепция «Комплексной
                    программы по профилактике и лечению социально значимых заболеваний среди потребителей инъекционных
                    наркотиков». Даны сведения по эпидемиологической ситуации с ВИЧ-инфекцией, потреблением инъекционных
                    наркотиков и распространенностью ВИЧ-инфекции и туберкулеза среди потребителей инъекционных
                    наркотиков. Подробно рассматриваются мероприятия в рамках «Комплексной программы по профилактике и
                    лечению социально значимых заболеваний среди ПИН», их содержание и обеспечение, партнеры по
                    организации медико-социальной помощи потребителям инъекционных наркотиков, их функции, роль в
                    мотивации пациентов к обращению за медицинской помощью, сохранению приверженности к лечению на
                    высоком уровне.</p>
                <p>Программа содержит описание конкретных организационных форм работы с ПИН: стационарный пункт
                    профилактики, мобильный пункт профилактики, аутрич-работа с максимальным охватом. Также дано
                    подробное описание взаимодействия лечебно-профилактических учреждений наркологического профиля и
                    неправительственных организаций по профилактике осложнений, вызванных употреблением наркотиков.
                    Программа содержит инновационную для отечественного здравоохранения модель профилактики
                    передозировок опийсодержащими наркотиками с использованием лекарственного средства «налоксон».
                    Представлена таблица с подробным перечнем мероприятий, проводимых субъектами
                    лечебно-профилактической программы.</p>
                <p>Модель подкреплена модулем однодневного тренинга «Профилактика передозировок среди потребителей
                    инъекционных наркотиков». Представленный модуль тренинга содержит исчерпывающий объем информации по
                    проблеме передозировок, обучению навыкам оказания помощи при передозировках. В разделе
                    «Антиретровирусная терапия» подробно описана деятельность мультидисциплинарной команды по
                    формированию приверженности ПИН/ЛЖВ (людей, живущих с ВИЧ), к лечению. Дается изложение задач,
                    стоящих перед командой, принципов работы команды, функций участников. Внедрение в наркологическую
                    практику работы мультидисциплинарных команд позволит вдохнуть новую жизнь в деятельность учреждений
                    по диспансерному наблюдению пациентов с химической зависимостью.</p>
                <p>В программе также рассматривается непростая проблема оказания лечебно-диагностической помощи ПИН с
                    сочетанной ВИЧ-инфекцией и туберкулезом: приводятся статистические данные, рассматриваются вопросы
                    лечения, принципы химиотерапии и антиретровирусной терапии, дается понятие «школы пациента». Большим
                    подспорьем для организаторов «Комплексных программ по профилактике и лечению социально значимых
                    заболеваний среди ПИН» являются содержащиеся в VII и VIII главах критерии качества реализации
                    программ, методы организации работы, принципы мониторинга и оценки программы. Содержание разработки
                    иллюстрируют схемы и таблицы. Внушительный список использованной литературы и руководящих документов
                    будет полезен всем интересующимся темой профилактики и лечения социально-значимых заболеваний среди
                    ПИН. Также представлен подробный Глоссарий и список общепринятых сокращений. Данная разработка будет
                    интересна и полезна работникам органов здравоохранения, организаторам здравоохранения, специалистам
                    и членам НПО, участвующим в оказании медико-социальной помощи потребителям инъекционных наркотиков,
                    а также другим специалистам, реализующим ВИЧ-профилактику при межведомственном уровне.</p>
                <p><strong>Доказательное руководство «ВИЧ-инфекция: важно знать» (Межрегиональное Общество специалистов
                    доказательной медицины).</strong><br/>
                    Данная разработка включает в себя различные медико-социальные аспекты ВИЧ-инфекции. Описывается
                    клиническое течение заболевания, приводятся точные эпидемиологические данные и анализируется средняя
                    продолжительность жизни серопозитивных людей, способы ее значительного, кардинального увеличения.
                    Важное значение уделяется диагностике ВИЧ-инфекции и СПИДА, анализу применяемых методов и алгоритмов
                    тестирования. Представлены современные данные по профилактике заражения, с описанием правильных
                    действий после незащищенного полового акта, попадания инфицированных жидкостей на кожу, слизистые и
                    в других ситуациях, при которых повышен риск инфицирования. Рассматриваются принципы лечения ВИЧ- и
                    оппортунистических инфекций, критерии начала антиретровирусной инфекции. Даны рекомендации о
                    возможности применения определенных видов вакцин на фоне ВИЧ-инфекции, а также даны рекомендации
                    важнейших социально-гигиенических аспектов жизни серопозитивных людей, включая питание, физическую
                    культуру, а также прием солнечных ванн. Важным аспектом программы, являются риски заражения
                    ВИЧ-инфекцией на фоне других венерических заболеваний, трудности лечения серопозитивных ПИН и
                    способы их преодоления. Текст каждой главы сопровождают перечень наиболее важных выводов, список
                    литературы, стратегия поиска информации, а также вставочные статьи, разъясняющие некоторые термины и
                    понятия доказательной медицины.</p>
                <p><strong>Обучение персонала программам профилактики ВИЧ-инфекции среди потребителей инъекционных
                    наркотиков
                    (Открытый институт Здоровья).</strong><br/>
                    Данная разработка содержит апробированный теоретический и практический материал, предназначенный
                    для: сотрудников проекта снижения вреда (руководители, координаторы, менеджеры, аутрич-работники);
                    участников проекта (представители органов исполнительной власти, сотрудники милиции, медицинские и
                    немедицинские специалисты органов здравоохранения: центры СПИД, инфекционные службы,
                    Госсанэпиднадзор, наркологические службы и др.), целевых групп (ПИН, секс-работники, люди, живущие с
                    ВИЧ, люди, находящиеся в местах лишения свободы, дети и подростки улиц). Целью данной разработки
                    является обучение персонала программам действующих на территории РФ проектов «снижения вреда» с
                    целью повышения эффективности их работы.</p>
                <p>В задачи разработки входит предоставление информации по созданию нормативно-правовой базы для
                    создания условий успешной работы собственных проектов. Обучение практическим методам работы на
                    улице, в мобильном пункте обмена шприцев, стационарном пункте обмена шприцев. Обучение методам и
                    приемам ведения тренинговой работы, индивидуального консультирования, вовлечения в проект волонтеров
                    из числа клиентов. Развитие навыка поиска ресурсов для своей организации и предоставление
                    методических материалов. Также в пособии представлены методы работы направленные на повышение
                    эффективности обучения такие как: проведение тренингов для обучаемых по теоретическим и
                    организационным аспектам снижения вреда; практическая работа обучаемых совместно с
                    аутрич-работниками в местах, где доступны потребители наркотиков, в пунктах обмена шприцев, а также
                    с секс-работниками, употребляющими наркотики инъекционно.</p>
                <p>Программа разработки состоит из двух частей: первая часть содержит программу работы учебных центров
                    (базовый курс, продвинутый курс и спецкурс), вторая часть — теоретические материалы по всем
                    представленным курсам. Базовый курс направлен на подготовку специалистов в области снижения вреда,
                    включая в себя историю возникновения, философию, принципы, основные цели и задачи снижения вреда,
                    подходы в наркополитике. Важное значение в курсе уделяется видам наркотиков, способам их
                    приготовления и употребления, путям передачи вирусных инфекций, видам риска и рискованного
                    поведения. Содержание курса дает возможность для новых проектов ознакомиться с методами работы
                    аутрич, обучиться менеджменту и фандрайзингу, а также получить навыки тренерской работы. Обучение
                    дает сотрудникам проекта снижения вреда получить как практическую, так и теоретическую подготовку.
                    Продвинутый курс направлен на обучение и повышение квалификации персонала, как управленческого, так
                    и аутрич-работников, включая такие актуальные темы как: фандрайзинг, устойчивость и имидж проекта и
                    PR организации, профилактика синдрома выгорания, организация групп самопомощи. Важное значение в
                    данном курсе разработки уделяется организации работы с людьми живущими с ВИЧ, основам правового
                    статуса и защите прав ВИЧ-инфицированных, улучшению состояния здоровья и качества жизни людей,
                    живущих с ВИЧ, до- и послетестового консультирования. Рассматривается информация по созданию групп
                    взаимопомощи среди сообщества ЛЖВ включая в себя цели, задачи и методы планирования
                    деятельности.<br/>
                    Спецкурс включает в себя два проекта:<br/>
                    1.»Профилактика ВИЧ-инфекции в УИН». Данный проект дает представление о проведении программ/проектов
                    в учреждения УИС для того чтобы участники обучения могли определить, как они могут начать или
                    развивать работу по профилактике ВИЧ-инфекции, парентеральных гепатитов, ИППП среди ПИН, находящихся
                    в учреждениях системы УИС. В данном проекте рассматривается важность профилактической работы в
                    тюрьмах, новые направления в работе, как возможное улучшение эффективности работы.<br/>
                    2. Проект «Профилактика ВИЧ-инфекций и других социально значимых заболеваний среди уличных детей и
                    подростков», дающий представление о построении профилактической и реабилитационной работы с детьми,
                    живущими или проводящими большую часть времени на улице, для повышения эффективности работы
                    действующих проектов, а также для организации работы с беспризорными и безнадзорными детьми в
                    регионах.</p>
                <p><strong>Программа «Профилактика ВИЧ-инфекций среди потребителей инъекционных наркотиков» (Открытый
                    институт
                    Здоровья).</strong><br/>
                    Разработка представляет собой комплекс мероприятий, направленный на противодействие эпидемии
                    ВИЧ-инфекции, а также снижение других негативных последствий для индивидуума и общества, связанных с
                    инъекционным употреблением наркотиков. Целью программы является предотвращение распространения
                    ВИЧ-инфекции и других гемоконтактных заболеваний среди ПИН и перехода эпидемии ВИЧ-инфекции из
                    популяции ПИН в слои населения, не употребляющие наркотики. Задачами разработанной программы
                    являются: организация и проведение среди уязвимых групп ПИН целенаправленной работы по профилактике
                    ВИЧ-инфекции и другого вреда наносимого здоровью при употреблении инъекционных наркотиков без
                    медицинского назначения; привлечения ПИН к медицинским службам; организация легкого доступа к
                    медицинской помощи ПИН, включающих как тестирование на ВИЧ, так и лечение инфекции, обеспечение ПИН
                    доступа к стерильному инъекционному инструментарию и другим профилактическим средствам для снижения
                    риска заражения ВИЧ-инфекцией, ИППП и другими гемоконтактными заболеваниями; профилактика среди ПИН
                    вирусных гепатитов с парентеральным путем передачи, инфекций, передаваемых половым путем,
                    туберкулеза, септических заболеваний, передозировок и др. путем воздействий направленных на развитие
                    навыков безопасного поведения ПИН в отношении сохранения личного и общественного здоровья;
                    профилактика полового и вертикального пути передачи ВИЧ-инфекции от ВИЧ-инфицированных ПИН; создание
                    условий в общественной среде, способствующих предотвращению негативных последствий для здоровья,
                    связанных с инъекционным потреблением наркотиков.</p>
                <p>Целевой группой программы являются ПИН состоящие и не состоящие на учете в наркологических службах,
                    оказывающие сексуальные услуги за плату, находящиеся в местах лишения свободы, ВИЧ-инфицированные
                    ПИН и больные СПИДом, люди живущие с ВИЧ/СПИД, а также половые партнеры ПИН и ЛЖВС. Группой
                    опосредствованного воздействия программы являются медицинские работники, работники милиции,
                    сотрудники системы УИН, журналисты, широкие слои населения, представители законодательной и
                    исполнительной власти, члены семей ПИН/ЛЖВС/РКС. Структурные компоненты программы: программа
                    «снижение вреда» от употребления наркотиков; программа медико-социальной специализированной помощи
                    ПИН; информационное, техническое и адвокативное обеспечение Программы; обучение сотрудников
                    Программы и групп опосредованного воздействия; органы управления Программой [1]. Программа
                    основывается на рекомендация ВОЗ по проведению профилактики ВИЧ-инфекции среди ПИН, включающих:
                    информационную работу и образование широких слоев населения; обеспечение доступности социальных и
                    медицинских служб для ПИН; активную информационную и образовательную по вопросам ВИЧ/СПИДа и
                    вопросам здоровья среди ПИН; обеспечение ПИН стерильными инструментами для инъекций и
                    дезинфицирующими средствами; предоставление возможности ПИН получать заместительную терапию.</p>
                <p><strong>Позитивная жизнь (РОО «СПИД инфосвязь» по программе «Паллиативная помощь и уход» в рамках
                    проекта
                    «ГЛОБУС»).</strong><br/>
                    Данная разработка создана для людей, впервые узнавших о своем диагнозе, для того чтобы они были как
                    можно больше осведомлены о ВИЧ-инфекции, нашли ответы на интересующие их вопросы. В первой части
                    разработки рассматривается сам диагноз ВИЧ-инфекции, возможные пути его передачи, иммунный статус и
                    вирусная нагрузка. Во второй части рассматривается душевное благополучие человека, узнавшего о своем
                    ВИЧ-положительном статусе, его кризисных моментах и возможности их преодоления, в том числе и с
                    помощью участников группы взаимопомощи людей, живущих с ВИЧ, близких людей и родственников. О
                    влиянии вируса на самооценку человека и отношение с другими людьми. Также в этом разделе
                    рассматриваются возможности интимных отношений ВИЧ-положительных людей со здоровыми партнерами и
                    способами предохранения, во избежание инфицирования других людей. О возможности создания
                    дискордантных пар (пара в которой один партнер ВИЧ-положительный, другой ВИЧ-отрицательный) и
                    высокой вероятности рождения у них здоровых детей, при соблюдении всех мер профилактики.</p>
                <p>Разработка содержит раздел, в котором рассмотрен аспект правильного и здорового питания,
                    необходимости разнообразия, сбалансированности, режима в приеме пищи. Содержится информацию о
                    физическом здоровье, важности внимательного и бережного отношения к своему организму, во избежание
                    инфицирования любыми вирусными заболеваниям, необходимости регулярного наблюдения у врача,
                    проведения необходимых осмотров, анализов, обследований на ИППП, туберкулез, вакцинации. Также даны
                    рекомендации по периодичности проведения обследований и сдачи крови на исследование иммунного
                    статуса. Описываются возможные меры предосторожности и представлены рекомендации по профилактики
                    оппортунистических инфекций, с подробным описанием данных инфекций и их возбудителях. В разделе
                    также рассматривается лечение оппортунистических инфекций, с помощью назначения специфических
                    препаратов, а также АРТ-терапии или высокоактивной антиретровирусной терапии (ВААРТ), способной
                    приостановить течение ВИЧ-инфекции и предотвратить развитие стадии СПИДа. Более подробно
                    представлена информация о взаимодействии пациента с врачом. Даны подробные инструкции и рекомендации
                    по подготовке к встрече с врачом, учитывая занятость специалиста. Представлена базовая информация,
                    связанная с рождением ребенка с указанием процентного соотношения риска передачи ВИЧ от матери
                    ребенку, при несоблюдении мер профилактики.</p>
                <p>Правовые вопросы рассмотрены в четвертой части программы, в числе которых ознакомление с Федеральным
                    законом № 38-ФЗ «О предупреждении распространения в Российской Федерации заболевания, вызываемого
                    вирусом иммунодефицита человека (ВИЧ-инфекции)», рассмотрены права ВИЧ-позитивных граждан при
                    обращении за медицинской помощью, также представлена информация о врачебной тайне и гарантии
                    конфиденциальности передаваемых сведений, при факте обращения за медицинской помощью. Важной
                    информацией, представленной в разработке, является рассмотрение статьи 4 Закона № 38-ФЗ о гарантии
                    обеспечения бесплатными медикаментами при лечения ВИЧ-позитивных граждан в амбулаторных условиях в
                    федеральных специализированных медицинских учреждениях. Также рассмотрены следующие правовые
                    документы: Постановление Правительства Р Ф № 123 от 25.02.2003 о признании негодности к военной
                    службе ВИЧ-позитивных людей; Ст. 122 Уголовного Кодекса Р Ф «О поставлении в опасность заражения или
                    заражения ВИЧ-инфекцией другого лица».</p>
                <p>Пятая часть программы содержит в себе не только информацию о действующих службах, группах
                    взаимопомощи, СПИД-сервисных организациях, куда можно обратиться за помощью и поддержкой, но и
                    телефоны доверия, полезные ссылки на интернет источники и тематические форумы. Наиболее важным
                    аспектом, является представленный в программе опыт людей, оказавшихся в подобной ситуации, что
                    является мощным инструментов воздействия на сознание и поведение ВИЧ-положительных граждан, узнавших
                    о своей болезни.</p>
                <p><strong>Эффективные стратегии разработки и реализации региональных программ противодействия
                    распространению
                    ВИЧ-инфекции (Благотворительный фонд поддержки приоритетных стратегий в сфере общественного
                    здравоохранения).</strong>
                    Целью данной разработки является повышение уровня информированности государственных и муниципальных
                    служащих по вопросам законодательства, политики и бюджетирования в сфере ВИЧ/СПИДа и формированию
                    навыков использования современных подходов разработки и реализации комплексных межведомственных и
                    межсекторных программ противодействия ВИЧ/СПИДу. Основными задачами разработки стали: предоставление
                    информации о современном состоянии эпидемии ВИЧ-инфекции и эффективных стратегиях предотвращения
                    дальнейшего ее распространения; предоставление информации о законодательных основах разработки и
                    реализации региональных программ противодействия ВИЧ-инфекции; раскрытие понятия комплексного
                    подхода, межведомственного и межсекторного взаимодействия в разработке и реализации программ
                    противодействия ВИЧ/СПИДу; формирование навыков использования стратегического планирования,
                    управления, мониторинга и оценки для разработки и реализации бюджетных целевых программ;
                    формирование навыков использования современных подходов к финансированию комплексных программ
                    профилактики и борьбы со СПИДом; формирование представления о способах повышения эффективности
                    профилактических программ с использованием потенциала НКО и бизнеса.</p>
                <p>Программа разработана для целевой аудитории государственных и муниципальных служащих, работающих в
                    сферах здравоохранения, образования, социальной защиты, труда и занятости населения, молодежной
                    политики, преподавателей вузов, а также для руководителей и сотрудников ФСКН, ФСИН, МВД,
                    некоммерческих организаций, работающих в области сохранения здоровья. В структуре программы
                    рассматриваются такие аспекты как общие сведения о ВИЧ-инфекции и подходах к предупреждению ее
                    распространения, законодательная основа программ по предупреждению распространения ВИЧ/СПИДа, уровни
                    принятия решений (международный, национальный, региональный), права лиц с ВИЧ/СПИДом и меры их
                    социальной поддержки. С детальным описанием представлен стратегический подход к разработке и
                    реализации бюджетной целевой программы, включающий в себя этапы стратегического планирования,
                    выявление факторов, влияющих на реализацию программы (анализ среды), определение миссии и целей
                    программы, выработка и выполнение стратегии программы, мониторинг и контроль реализации стратегии,
                    также представлена практика разработки и реализации бюджетных целевых программ.</p>
                <p>В теме «Комплексные и межведомственные подходы в разработке и реализации бюджетных целевых программ»
                    рассмотрены руководящие принципы противодействия эпидемии ВИЧ/СПИДа, с описанием системы
                    взаимодействия ведомств и представлением практического опыта создания координационных механизмов в
                    области противодействия эпидемии ВИЧ/СПИДа в Российской Федерации. Также в программе детально
                    раскрыта тема использования потенциала некоммерческого и частного секторов в реализации программ
                    противодействия распространению ВИЧ/СПИДа. Заключительным этапом программы, стал мониторинг и оценка
                    региональных целевых программ противодействия распространению ВИЧ/СПИДа.</p>
                <p><strong>ВИЧ, беременность и здоровье женщин (Региональная общественная организация «Сообщество людей,
                    живущих
                    с ВИЧ»).</strong><br/>
                    Целевой аудиторией данной разработки, стали ВИЧ-положительные женщины. Цель программы — помочь
                    женщинам разобраться в особенностях планирования и течения беременности, связанной с ВИЧ-инфекцией.
                    Программа информирует о возможности рождения здорового ребенка ВИЧ-положительной женщиной, включает
                    в себя рекомендации для ВИЧ-положительных женщин, отличающиеся от общих рекомендаций для беременных,
                    информирует о факторах риска, повышающих вероятность передачи ВИЧ от матери ребенку.</p>
                <p>В программе с подробным описанием представлена рекомендательная информация по зачатию для различных
                    видов пар (дискордантных, ВИЧ-положительных пар). Разработаны рекомендации по применению АРВ-терапии
                    в соответствии с рекомендациями врача, для снижения риска передачи ВИЧ ребенку, а также ее
                    особенностях, достоинствах и рисках для здоровья ребенка и матери, необходимости прохождения
                    дополнительных обследований и возможности проведения некоторых вакцинаций. Важное значение в
                    программе уделяется вопросу рисков преждевременных родов и вероятных способах родоразрешения
                    ВИЧ-положительных женщин. Особое внимание уделяется вопросу поддержания здоровья матери в
                    послеродовой период, вероятной возможности проведения АРВ-терапии для ребенка, необходимости
                    проведения обследования новорожденного, а также даны настоятельные рекомендации не кормить ребенка
                    грудным молоком ВИЧ-инфицированной женщины.</p>
                <p><strong>Таким образом,</strong> анализ научных разработок показал, что некоммерческими организациями
                    наработан
                    достаточно большой опыт в области противодействия немедицинскому потреблению наркотиков и
                    профилактики ВИЧ-инфекции, которые рассматриваются в различных аспектах (юридическом, социальном,
                    медицинском, педагогическом, психологическом и т. д.). Такое многоплановое изучение при многих
                    достоинствах имеет и существенные недостатки: отсутствие органической связи между рассматриваемыми
                    аспектами негативного явления, изолированность друг от друга полученных результатов. Научные
                    исследования, проводимые учеными по данной проблеме, выявляют позитивные сдвиги в её решении, однако
                    остается ряд вопросов, среди которых: недостаточно научно обоснованные и гибкие подходы, требуемые
                    для оперативного реагирования на происходящие социальные изменения в стране, недостаточный объем и
                    качество проводимых антинаркотических мероприятий, а также низкий уровень развития антинаркотической
                    инфраструктуры.</p>
                <p>ЛИТЕРАТУРА
                    1. Васильева Н. В. Профилактика ВИЧ-инфекций среди потребителей инъекционных наркотиков / Бобрик А.
                    В., Летягина В. А., Бадриева Л. И., Петунин Е. И., Карпец А. В., Кириллова Л. Д., Панасенко Е. А.,
                    Дмитриева И. Н. // Открытый институт Здоровья. — 2006 — [Электронный ресурс] —
                    omp.ucoz.com/_tbkp/tttt/pin.pdf (дата обращения — 14.10.2014).<br/>
                    2. Васильева Н. В. Профилактика ВИЧ-инфекций среди потребителей инъекционных наркотиков / Бобрик А.
                    В., Летягина В. А., Бадриева Л. И., Петунин Е. И., Карпец А. В., Кириллова Л. Д., Панасенко Е. А.,
                    Дмитриева И. Н. // Открытый институт Здоровья — 2006. — [Электронный ресурс] —
                    omp.ucoz.com/_tbkp/tttt/pin.pdf (дата обращения — 10.10.2014).<br/>
                    3. Карпец А. В. Комплексная программа по профилактике и лечению социально значимых заболеваний среди
                    потребителей инъекционных наркотиков / Карпец А. В., Корень С. В., Ладная Н. Н., Мусатов В. Б.,
                    Петунин Е. И., Потомова А. В., Царев С. А., Хамзина Р. В. // Некоммерческое партнерство по поддержке
                    социально-профилактических программ в сфере общественного здоровья «ЭСВЕРО». — 2011. — [Электронный
                    ресурс] — www.esvero.ru/files/Concept.pdf (дата обращения — 14.10.2014).<br/>
                    4. Козырина Н. В. ВИЧ, беременность и здоровье женщин. / Региональная общественная организация
                    «Сообщество людей, живущих с ВИЧ» — 2008. — [Электронный ресурс] —
                    xn--b1am9b.xn--p1ai/doc/beremennost_i_vich1.pdf (дата обращения — 15.10.2014).<br/>
                    5. Крючков Н. А. Доказательное руководство. ВИЧ-инфекция: важно знать / Крючков Н. А., Корнышева Е.
                    А., Гиль А. А, Власов В. В., Карауловская Н. Н., Регушевская Е. В., Амлаев К. Р., Данишевский К. Д.,
                    Борисенко О. В., Трофимов В. М., Каитова О. А., Кукушкина Е. А., Ткаченко Т. Н. // Межрегиональное
                    Общество специалистов доказательной медицины — 2009. — [Электронный ресурс] —
                    www.demoscope.ru/weekly/2009/0399/biblio05.php (дата обращения — 21.10.2014).<br/>
                    6. Никитина М. Позитивная жизнь / Н. Скибневская, М. Никитина, С. Хороших, Н. Бурцева, Ю. Кузнецова,
                    А. Воробьева, И. Жуков// РОО «СПИД инфосвязь» по программе «Паллиативная помощь и уход» в рамках
                    проекта «ГЛОБУС». — 2005. — [Электронный ресурс] — www.вич.рф/doc/bookllet_positiv_life.pdf (дата
                    обращения — 16.10.2014).<br/>
                    7. Обучение персонала программам профилактики ВИЧ-инфекции среди потребителей инъекционных
                    наркотиков/ Открытый институт Здоровья — 2006. — [Электронный ресурс] —
                    www.redcross-irkutsk.org/ru/catalog/group-52/product-427/info.html (дата обращения — 14.10.2014).<br/>
                    8. Проект ГЛОБУС 2004—2009. — [Электронный ресурс] — www.hivpolicy.ru/upload /File/RelatedFiles
                    /organization /164/glob_rep_3raund.pdf (дата обращения — 10.10.2014).<br/>
                    9. Партийная мораль против СПИДа / Бизнес и общество: Феномены. — [Электронный ресурс] —
                    hbr-russia.ru/biznes-i-obshchestvo/fenomeny/a14943/# (дата обращения — 11.12.2014)</p>
            </div>
            <div style={{fontSize: "17px", color: "#212529", fontWeight: "550"}} className={s.concept}>
                <p><strong>Белозерова М.В.</strong> Роль некоммерческих организаций в профилактике
                    ВИЧ-инфекции // Электронный журнал «Общество. Культура. Наука. Образование». 2014. Вып. 4.</p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>
    </div>
}
export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
    {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet, to
                     }) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default BelozerovaVICH