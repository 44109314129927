import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News060324.jpg"
import img1 from "../../assets/News/News060324_1.jpg"

const News060324 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подписано соглашение о сотрудничестве между Центром защиты прав и интересов детей и МГПУ
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            4 марта 2024 года между ФГБУ «Центр защиты прав и интересов детей» и ГАОУ ВО г. Москвы «Московский городской
            педагогический университет» (МГПУ) было подписано соглашение о сотрудничестве.
            <p></p>
            Сфера сотрудничества касается вопросов медиации и альтернативных способов разрешения споров, образования,
            науки, молодежной политики, воспитания, социальной поддержки и социальной защиты обучающихся.
            <p></p>
            Следует отметить, что активное сотрудничество между организациями продолжается уже в течение трех лет в
            вопросах популяризации медиативно-восстановительных технологий и развития служб медиации и примирения в
            образовательных организациях, а также организации международного фестиваля «Медиация. Молодость. Будущее!».
            <p></p>
            В мае 2024 года планируется совместное проведение третьего фестиваля на базе МГПУ с пленарным заседанием,
            тематическими секциями, мастер-классами для детей и взрослых.
            <p></p>
            Также заметим, что в состав Экспертного совета по развитию служб медиации и примирения в образовательных
            организациях, входит представитель МГПУ 一 кандидат педагогических наук, заведующий кафедрой педагогических
            технологий непрерывного образования института непрерывного образования Шевелёва Наталия Николаевна. Центр
            осуществляет организационно-техническое сопровождение проведения заседаний данного Экспертного совета.
            <p></p>
            На фото:<br/>
            Носова Лариса Викторовна – старший преподаватель МГПУ, психолог, медиатор.<br/>
            Козлов Анатолий Андреевич – начальник отдела медиации Центра.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <ToNews/>
    </div>
}


export default News060324;